@import '../../marketplace.css';

.modalContent {
    & p {
        margin-bottom: 40px;
    }

    & footer {
        @media (--viewportSmallMax) {
            position: fixed;
            left: 24px;
            right: 24px;
            bottom: 24px;
        }

        & a {
            &,
            &:hover {
                text-decoration: none;
            }
        }

        & > button {
            margin-top: 16px;

            border: none;
            text-decoration: underline;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
