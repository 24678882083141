@import '../../../marketplace.css';

:root {
    --flexAlignY {
        display: flex;
        align-items: center;
    }
}

.pricingPageTwoWrapper {
    width: 100%;
}

.secondPageTitle {
    @apply --flexAlignY;
    justify-content: space-between;

    margin: 0 0 32px 0;
}

.paymentFailureAlert {
    margin-bottom: 32px;
}

.checkoutInfoProtectionSidenote {
    @apply --flexAlignY;
    @apply --paragraphAntiSM;
    margin: 0;
    color: #1e9f55;

    & svg {
        height: 16px;
        width: 16px;
        & path {
            fill: #1e9f55;
        }
    }
}

.errorActionsHolder {
    display: flex;
    column-gap: 16px;

    margin-bottom: 24px;

    @media (--viewportSmallMax) {
        flex-direction: column;
        row-gap: 16px;
    }
}
