@import '../../common.css';

.content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    column-gap: 24px;
    row-gap: 24px;

    @media (--viewportSmallMax) {
        flex-direction: column;
        row-gap: 16px;
    }
}

.quickSearchItem {
    display: flex;
    column-gap: 16px;
    /* flex-basis: 270px; */
    max-width: 270px;

    flex: 1;
    white-space: nowrap;

    padding: 24px;

    border-radius: 16px;
    color: #1a2b49;
    border: 1px solid #e6e6e6;
    background: #fff;

    transition: 0.35s;

    &:hover {
        cursor: pointer;
        text-decoration: none;
        border: 1px solid #1a2b49;
    }

    & path {
        fill: #8f2593;
    }

    & p {
        font-weight: 600;
        margin: 0;
    }

    @media (--viewportSmallMax) {
        max-width: unset;
        border-radius: 8px;
    }
}

.heading {
    text-align: left;
    margin-bottom: 32px;
}

.root {
    margin-bottom: 64px;
}
