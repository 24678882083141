@import '../../common.css';

.root {
    margin-bottom: 64px;
}

.description {
    white-space: normal;
    @media (--viewportMedium) {
        white-space: break-spaces;
    }
}

.content {
    margin-bottom: 64px;
}

.criteriaBlock {
    & h3 {
        max-width: 563px;
        @apply --fontSizeXL;
        font-weight: 700;
        margin: 32px 0;

        @media (--viewportMedium) {
            @apply --fontSizeXXL;
            margin: 64px 0;
        }
    }
}

.criteriaItemHolder {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    row-gap: 16px;

    @media (--viewportMedium) {
        column-gap: 24px;
        row-gap: 24px;
    }
}

.criteriaItem {
    flex-basis: 100%;
    padding: 24px;

    display: flex;
    column-gap: 16px;
    align-items: center;

    border-radius: 8px;
    border: 1px solid #e6e6e6;
    background: #fff;

    & p {
        font-weight: 600;
        margin: 0;
    }

    & path {
        fill: #8f2593;
    }

    @media (--viewportMedium) {
        border-radius: 16px;
        flex-basis: calc(33.333% - 16px);
    }
}
