/* Fonts */
/* These are imported into marketplace.css */

:root {
    /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
    --fontWeightRegular: 400;
    --fontWeightMedium: 400;
    --fontWeightSemiBold: 600;
    --fontWeightBold: 700;
    /* ================ Default font ================ */
    --marketplaceDefaultFontStyles: {
        font-family: 'sofiapro', Helvetica, Arial, sans-serif;
        font-weight: var(--fontWeightMedium);
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.25px;
        /* No margins for default font */
    }
    --marketplaceSmallFontStyles: {
        font-family: 'sofiapro', Helvetica, Arial, sans-serif;
        font-weight: var(--fontWeightMedium);
        font-size: 15px;
        line-height: 24px;
    }
    /* ================ Body font ================ */
    --marketplaceBodyFontStyles: {
        font-family: 'sofiapro', Helvetica, Arial, sans-serif;
        font-weight: var(--fontWeightMedium);
        font-size: 18px;
        line-height: 27px;
        letter-spacing: -0.25px;
        /* margin-top + n * line-height + margin-bottom => x * 6px */
        margin-top: 0px;
        margin-bottom: 12px;

        @media (--viewportMedium) {
            font-size: 18px;
            line-height: 28px;
            /* margin-top + n * line-height + margin-bottom => x * 8px */
            margin-top: 0px;
            margin-bottom: 16px;
        }
    }
    /* ================ Hero title ================ */
    --marketplaceHeroTitleFontStyles: {
        font-weight: var(--fontWeightBold);
        font-size: 42px;
        line-height: 42px;
        letter-spacing: -1px;
        /* margin-top + n * line-height + margin-bottom => x * 6px */
        margin-top: 25px;
        margin-bottom: 20px;

        @media (--viewportMedium) {
            font-size: 70px;
            line-height: 70px;
            /* margin-top + n * line-height + margin-bottom => x * 8px */
            margin-bottom: 60px;
        }

        @media (min-width: 1681px) {
            font-size: 70px;
            line-height: 74px;
            letter-spacing: -1px;
        }
    }
    /* ================ Modal title ================ */
    --marketplaceModalTitleStyles {
        font-weight: var(--fontWeightBold);
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -0.5px;
        margin: 0;

        @media (--viewportMedium) {
            font-weight: var(--fontWeightBold);
            line-height: 40px;
            letter-spacing: -0.9px;
            margin: 0;
        }
    }
    /* ================ H1, H2, H3, H4, H5 & H6 ================ */
    --marketplaceH1FontStyles: {
        font-weight: var(--fontWeightBold);
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -1px;
        /* margin-top + n * line-height + margin-bottom => x * 6px */
        margin-top: 18px;
        margin-bottom: 18px;

        @media (--viewportMedium) {
            font-size: 40px;
            font-weight: var(--fontWeightBold);
            line-height: 50px;
            letter-spacing: -1px;
            /* margin-top + n * line-height + margin-bottom => x * 8px */
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }
    --marketplaceH2FontStyles: {
        font-weight: var(--fontWeightBold);
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.25px;
        /* margin-top + n * line-height + margin-bottom => x * 6px */
        margin-top: 21px;
        margin-bottom: 17px;

        @media (--viewportMedium) {
            line-height: 48px;
            font-size: 40px;
            letter-spacing: -0.5px;
            /* margin-top + n * line-height + margin-bottom => x * 8px */
            margin-top: 21px;
            margin-bottom: 16px;
        }
    }
    --marketplaceH3FontStyles: {
        font-weight: var(--fontWeightBold);
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.5px;
        /* margin-top + n * line-height + margin-bottom => x * 6px */
        margin-top: 16px;
        margin-bottom: 14px;

        @media (--viewportMedium) {
            /* margin-top + n * line-height + margin-bottom => x * 8px */
            margin-top: 16px;
            margin-bottom: 16px;
            font-size: 32px;
            line-height: 40px;
        }
    }
    --marketplaceH4FontStyles: {
        font-weight: var(--fontWeightMedium);
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0;
        /* margin-top + n * line-height + margin-bottom => x * 6px */
        margin-top: 16px;
        margin-bottom: 16px;
    }
    --marketplaceH5FontStyles: {
        font-weight: var(--fontWeightRegular);
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0;
        /* margin-top + n * line-height + margin-bottom => x * 6px */
        margin-top: 10px;
        margin-bottom: 8px;

        @media (--viewportMedium) {
            line-height: 24px;
            /* margin-top + n * line-height + margin-bottom => x * 8px */
            margin-top: 10px;
            margin-bottom: 14px;
        }
    }
    --marketplaceH6FontStyles: {
        font-weight: var(--fontWeightBold);
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        /* margin-top + n * line-height + margin-bottom => x * 6px */
        margin-top: 10px;
        margin-bottom: 8px;

        @media (--viewportMedium) {
            line-height: 16px;
            /* margin-top + n * line-height + margin-bottom => x * 8px */
            margin-top: 10px;
            margin-bottom: 6px;
        }
    }
    /* ================ Other fonts ================ */
    --marketplaceTinyFontStyles: {
        font-family: 'sofiapro', Helvetica, Arial, sans-serif;
        font-weight: var(--fontWeightRegular);
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.1px;
        /* margin-top + n * line-height + margin-bottom => x * 6px */
        margin-top: 9.5px;
        margin-bottom: 8.5px;

        @media (--viewportMedium) {
            line-height: 16px;
            /* margin-top + n * line-height + margin-bottom => x * 8px */
            margin-top: 10.5px;
            margin-bottom: 13.5px;
        }
    }
    --marketplaceMessageFontStyles {
        font-family: 'sofiapro', Helvetica, Arial, sans-serif;
        font-weight: var(--fontWeightRegular);
        font-size: 16px;
        line-height: 24px;
    }
    --marketplaceMessageDateFontStyles {
        font-family: 'sofiapro', Helvetica, Arial, sans-serif;
        font-weight: var(--fontWeightRegular);
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.1px;

        @media (--viewportMedium) {
            font-weight: var(--fontWeightMedium);
            font-size: 13px;
            line-height: 24px;
        }
    }
    --marketplaceTxTransitionFontStyles {
        font-family: 'sofiapro', Helvetica, Arial, sans-serif;
        font-weight: var(--fontWeightMedium);
        font-size: 16px;
        line-height: 20px;

        @media (--viewportMedium) {
            font-size: 20px;
            line-height: 32px;
        }
    }
    --marketplaceSearchFilterLabelFontStyles {
        font-family: 'sofiapro', Helvetica, Arial, sans-serif;
        font-weight: var(--fontWeightSemiBold);
        font-size: 13px;
        line-height: 18px;

        @media (--viewportMedium) {
            font-weight: var(--fontWeightMedium);
            font-size: 16px;
            line-height: 20px;
        }
    }
    --marketplaceSearchFilterSublabelFontStyles {
        font-family: 'sofiapro', Helvetica, Arial, sans-serif;
        font-weight: var(--fontWeightMedium);
        font-size: 18px;
        line-height: 18px;
    }
    --marketplaceListingAttributeFontStyles {
        font-family: 'sofiapro', Helvetica, Arial, sans-serif;
        font-weight: var(--fontWeightMedium);
        font-size: 18px;
        line-height: 32px;
    }
    /* ================ Tabbed navigation font styles ================ */
    --marketplaceTabNavFontStyles {
        font-weight: var(--fontWeightMedium);
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;

        @media (--viewportLarge) {
            font-weight: var(--fontWeightSemiBold);
            font-size: 24px;
            line-height: 32px;
        }
    }
    --marketplaceTabNavHorizontalFontStyles {
        font-weight: var(--fontWeightMedium);
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
    }
}
