@import '../../marketplace.css';

.root {
    & h4 {
        margin-top: 0;
        font-weight: 600;
    }
}

.collectInfoDescription {
    margin-bottom: 24px;
}

.benefitsList {
    margin-bottom: 32px;

    & > p {
        display: flex;
        align-items: center;
        column-gap: 8px;

        & path {
            fill: var(--marketplaceColor);
        }
    }
}

.msgSection {
    @apply --paragraphAntiSM;

    display: flex;
    align-items: center;
    column-gap: 4px;

    & svg {
        flex-shrink: 0;
        width: 16px;
        height: 16px;

        & path {
            fill: var(--matterColorAnti);
        }
    }
}

.recipientsListection {
    margin-bottom: 40px;

    & textarea {
        min-height: 184px;

        @media (--viewportSmallMax) {
            min-height: 116px;
        }
    }
}

.recommendationsActionInfo {
    margin-top: 8px;
    margin-bottom: 24px;
    @apply --paragraphAntiSM;
}

.delimiter {
    @apply --delimiterWithMiddleText;
    z-index: 1;
}
