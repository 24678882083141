@import '../../marketplace.css';

.error {
    color: var(--failColor);
}

.contentWrapper {
    padding: 24px;

    max-width: 1152px;
    width: 100%;
    margin: 0 auto;
}

.form {
    max-width: 480px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.backButton {
    color: var(--marketplaceColor);

    &:hover {
        text-decoration: underline;
        color: var(--marketplaceColorDark);
        cursor: pointer;
    }
}

.badge {
    @apply --badge;
}
