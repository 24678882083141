@import '../../common.css';

:root {
    --flex_centered {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    --image {
        width: 48px;
        height: 48px;
        border: 2px solid #fff;
    }
    --column {
        display: flex;
        column-gap: 24px;
    }
}

.heading {
    text-align: left;
}

.content {
    margin-bottom: 32px;

    @media (--viewportSmallMax) {
        margin-bottom: 24px;
    }
}

.activitiesHolder {
    @apply --column;
    flex-wrap: wrap;
    position: relative;

    overflow: hidden;
}

.activityContainer {
    opacity: 0;
    transition: 0.5s;
    min-height: 101px;

    padding: 8px;

    border-bottom: 1px solid #e6e6e6;

    flex-basis: calc(50% - 24px);

    @media (--viewportMediumMax) {
        flex-basis: 100%;
    }

    @media (--viewportSmallMax) {
        padding: 12px;

        & p {
            text-align: center;
        }
    }

    & p {
        margin: 0;
    }

    & > div {
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &:hover {
        cursor: pointer;
        background-color: #f2f2f2;
    }
}

.activityLocationDate {
    color: #b2b2b2;
    align-self: end;

    & > span {
        display: inline-block;
    }

    & > .delimiter {
        margin: 0 8px;
    }

    @media (--viewportSmallMax) {
        align-self: center;
    }
}

.activityContent {
    @apply --column;
    /* 24px - 10px relative */
    column-gap: 14px;
    /* description */
    & p {
        align-self: center;
    }

    @media (--viewportSmallMax) {
        flex-direction: column;
        row-gap: 8px;
        margin-bottom: 8px;
    }
}

.imagesHolder {
    @apply --flex_centered;
}

.image,
.imagePlaceholder,
.iconHolder {
    @apply --rounded_image;
    @apply --image;
}

.image,
.imagePlaceholder {
    z-index: 2;
}

.imagePlaceholder {
    background-color: #8f2593;
    text-align: center;
    line-height: 44px;
    color: #fff;
}

.iconHolder {
    @apply --flex_centered;
    left: -10px;
    background-color: #f9f4fa;
    & svg {
        width: 24px;
        height: 24px;
        & path {
            fill: #8f2593;
        }
    }
}

.activityType {
    font-weight: 600;
    text-transform: capitalize;
}

.footer {
    margin: 64px 0;

    @media (--viewportSmallMax) {
        margin: 32px 0 64px 0;
    }
}
