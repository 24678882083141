@import '../../styles-account.css';

.modalContent {
    display: flex;
    flex-direction: column;
    height: 100%;

    & > svg {
        flex-shrink: 0;
    }
}

.modalDesc {
    margin-bottom: 40px;
}

.modalAction {
    margin-top: auto;
}
