.layout {
    overflow: hidden;
    position: relative;

    & > svg {
        position: absolute;
        &:first-of-type {
            top: 100px;
        }
    }
}

.container {
    padding: 24px 24px 40px 24px;

    @media (--viewportLarge) {
        padding: 64px 0 80px 0;
    }
}
