@import '../../marketplace.css';

.root {
    width: 28px;
    height: 28px;

    stroke: var(--marketplaceColor);
    stroke-width: 3px;

    stroke-dasharray: 25;
}

.native {
    /* change color here */
    color: #8f2593;
}
.native,
.native div {
    box-sizing: border-box;
}
.native {
    display: inline-block;
    position: relative;
    width: 28px;
    height: 28px;
}
.native div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 28px;
    height: 28px;
    border: 3px solid currentColor;
    border-radius: 50%;
    animation: native 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;
}
.native div:nth-child(1) {
    animation-delay: -0.45s;
}
.native div:nth-child(2) {
    animation-delay: -0.3s;
}
.native div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes native {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
