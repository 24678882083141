@import '../../marketplace.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }

  & input {
    font-size: 16px;
    @media (--viewportMedium) {
      font-size: 18px;
    }
  }
}

.name {
  display: block;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
  }
}

.firstNameRoot,
.lastNameRoot {
  width: 100%;
  margin-bottom: 24px;
  @media (--viewportMedium) {
    width: calc(50% - 9px);
    margin-bottom: 0;
  }
}

.password,
.userType {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
  margin-top: 32px;
}

.bottomWrapperText {
  @apply --authSubsection;
  @apply --marketplaceModalBottomWrapperText;
  text-align: center;

  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-bottom: 80px;
  }
}

.termsLink {
  @apply --marketplaceModalHelperLink;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
