@import '../../../marketplace.css';

.marker {
    width: 48px;
    height: 48px;

    background-color: rgba(143, 37, 147, 0.3);
    border-radius: 50%;

    @apply --flex-centered;

    & > div {
        background-color: rgb(143, 37, 147);

        width: 50%;
        height: 50%;
        border-radius: 50%;
        border: 2px solid #fff;
    }
}
