.packageWrapper {
    width: 100%;
    padding: 16px 16px 0 16px;
    margin-bottom: 24px;

    border-radius: 4px;
    border: 1px solid #e6e6e6;
    background: #fff;
}

.nonActivePackage {
    padding-bottom: 16px;
}

.flexiblePackageWrapper {
    padding-bottom: 24px;
}

.packageWrapperTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: #1a2b49;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.25px;
}

.packageContent {
    margin-top: 24px;
}

.oneRowWrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 24px;
    margin: 24px 0;
}

.packagePrice {
    width: 100%;

    @media (--viewportMedium) {
        width: 251px;
    }
}

.packageCurreny {
    width: 100%;

    @media (--viewportMedium) {
        width: 171px;
    }
}

.noPaddingCheckbox > label {
    padding: 0;
}
