@import '../../marketplace.css';

.root {
    display: flex;
    flex-direction: column;

    /* Layout: size and positioning */
    width: 100%;
    height: auto;
    margin-top: 0;

    display: flex;
    flex-grow: 1;
    flex-direction: column;

    @media (--viewportMedium) {
        display: block;
    }
}

.error {
    color: var(--failColor);
}

.errorPlaceholder {
    @media (--viewportMedium) {
        width: 100%;
        height: 32px;
    }
}

.reviewRating {
    margin-bottom: 10px;
}

.reviewContent {
    flex-shrink: 0;
    margin: 0;
}

.submitButton {
    color: var(--failColor);

    &:hover {
        background: white;
    }
}

.reason {
    margin: 0 0 16px 0;
}

.option {
    margin: 0;
}

.commentField {
    margin-bottom: 40px;
}
