@import '../../marketplace.css';

:root {
    /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

    --EditListingDescriptionForm_formMargins: {
        margin-bottom: 24px;

        @media (--viewportMedium) {
            margin-bottom: 32px;
        }
    }
}

.root {
    width: 100%;
    height: auto;
    display: flex;
    flex: 1;
    flex-direction: column;

    @media (--viewportMedium) {
        padding: 0;
        flex-wrap: wrap;
        align-content: center;
    }
}

.error {
    color: var(--failColor);
}

.title {
    @apply --EditListingDescriptionForm_formMargins;
}

.fieldItem {
    @apply --marketplaceEditListingFieldItem;

    & > label {
        display: inline-block;
    }
}

.checkBoxWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    margin-top: 30px;
    max-width: 480px;
    gap: 24px;

    @media (--viewportMedium) {
        flex-direction: row;
        gap: 0;
    }
}

.tooltip {
    width: 192px;

    @media (--viewportSmallMax) {
        transform: initial;
        width: calc(100vw - 54px);
    }

    & + div {
        display: flex;
    }
}

.description {
    @apply --EditListingDescriptionForm_formMargins;
    flex-shrink: 0;
}

.category {
    @apply --EditListingDescriptionForm_formMargins;
}

.submitButton {
    @apply --marketplaceEditListingSubmitButton;

    @media (--viewportLarge) {
        width: 300px;
    }

    position: relative;
}

.submitButton [data-icon-type='arrow'] {
    position: absolute;
    top: 50%;
    transform: translateY(-6px);
}

.submitButton:disabled [data-icon-type='arrow'] path {
    stroke: #b2b2b2;
    fill: #b2b2b2;
}

@media (--viewportMiniSmall) {
    .submitButton [data-icon-type='arrow'] {
        right: 40px;
    }
}

@media (--viewportSmall) {
    .submitButton [data-icon-type='arrow'] {
        right: 50px;
    }
}

@media (--viewportMedium) {
    .submitButton [data-icon-type='arrow'] {
        right: 200px;
    }
}

@media (--viewportLarge) {
    .submitButton [data-icon-type='arrow'] {
        right: 35px;
    }
}

.radioButton {
    display: flex;
    min-width: 312px;
    flex-direction: column;

    @media (--viewportMedium) {
        width: 100%;
        max-width: 480px;
    }
}

.noBorder {
    border: none;
}

.firstRadioButton:hover {
    background: #f9f9f9;
    border-color: #b2b2b2;
    & > label > code > svg {
        & circle {
            &:first-of-type {
                fill: white !important;
            }
        }
    }
}

.firstRadioButton {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border: 1px solid transparent;
    border-bottom-color: #e6e6e6;
}

.firstRadioButtonSelected > label > code > svg > circle {
    &:first-of-type {
        fill: white !important;
    }
}

.horseTypeTitle {
    @apply --fontSizeXL;

    color: #1a2b49;
    font-weight: 700;

    margin: 0 0 16px 0;

    @media (--viewportMedium) {
        @apply --fontSizeXXL;
    }
}

.horseTypeSubTitle {
    color: var(--brand-secondary, #1a2b49);
    margin-bottom: 32px;
}

.tooltipPosition {
    position: absolute;
    top: calc(50% - 9px);
    right: 16px;

    & ul {
        list-style: disc;
        padding-left: 16px;
    }

    & li {
        font-size: inherit;
        line-height: inherit;
    }
}
.fieldSelectPageFour {
    margin: 32px 0 16px 0;
}

.fieldSelectPageSixWrapper {
    padding: 8px 16px;
}

.fieldSelectPageSix {
    margin-bottom: 0px;
}

.fieldSelectPageSevenEmpty {
    color: #b2b2b2;
    margin-bottom: 0;

    text-align: center;
}

.multiSelect span {
    align-items: center;
    color: #fff;
    display: inline-flex;
    color: var(--brand-primary, #8f2593);
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 4px 8px 4px 16px;
    border-radius: 48px;
    border: 1px solid var(--brand-primary, #8f2593);
    background: var(--brand-primary-005, #f9f4fa);
}

.multiSelect li input {
    min-width: 10%;

    @media (--viewportMedium) {
        width: 15px;
    }
}

.multiSelect ul li {
    background: transparent;
}

.multiSelect ul li:hover {
    background: #f9f9f9;
}

.multiSelect li input[type='checkbox'] {
    width: 24px;
    height: 24px;
}

.multiSelect li input[type='checkbox']:checked {
    accent-color: #8f2593;
}

.startDateWrapper {
    margin-top: 32px;
}

.startDateTitle {
    margin-bottom: 24px;

    color: var(--brand-secondary, #1a2b49);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.5px;
}

.endDateWrapper {
    margin-top: 24px;
}

.starFlexWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.namedLink {
    text-decoration: none !important;
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.checkboxHolder {
    position: relative;

    & > *:not(code) {
        margin: 0;
    }
}

.starContainer {
    display: flex;
    align-items: center;
    column-gap: 8px;

    position: absolute;
    top: 3px;
    right: 58px;

    z-index: 1;

    padding: 17px;

    & svg {
        width: 20px;
        height: 20px;
    }

    @media (--viewportMedium) {
        top: 0px;
        right: 64px;
    }

    &.selectAllowed:hover {
        cursor: pointer;
    }

    &.starContainerQualification {
        right: 0;
    }
}

.mobileDisplay {
    color: #b2b2b2;
    margin-bottom: 0;
    display: none;

    @media (--viewportMedium) {
        display: block;
    }
}

.selectedValue {
    color: #8f2593;
}

.dropBoxesContainer {
    @apply --formFieldsItem;

    position: relative;

    & > .fieldSelectPageSixWrapper:last-of-type {
        padding-bottom: 24px;
    }

    &.dropBoxesContainerSelected {
        &:hover {
            border: 1px solid #e6e6e6;
            background: white;
        }
    }
}

.formFieldsItem {
    @apply --formFieldsItem;

    &.singleFormField {
        border-radius: 4px;
    }
}

.qualificationFormField {
    padding: 16px 16px 24px 16px;

    &:hover {
        border: 1px solid #e6e6e6;
        background: white;
    }
}
