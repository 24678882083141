@import '../../marketplace.css';

.root {
  @apply --marketplaceModalFormRootStyles;
  justify-content: unset;
}

.email {
  margin-top: 32px;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
  margin-top: 8px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
  text-align: left;
}

/* Helper links */
.modalHelperLink {
  @apply --marketplaceModalHelperLink;
  color: var(--marketplaceColor);
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  @apply --marketplaceModalHelperText;
}
