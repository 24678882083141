@import '../../marketplace.css';

.modalContainer {
    border: none;
}

.modalContent {
    display: flex;
    flex-direction: column;
    height: 100%;

    & h3 {
        margin-top: 0;
    }

    & svg {
        flex-shrink: 0;
    }

    & button {
        margin-top: auto;

        @media (--viewportMedium) {
            margin-top: 40px;
        }
    }
}

/* Title of the modal */
.modalTitle {
    @apply --fontSizeXXL;
    font-weight: 700;
}
