@import '../../marketplace.css';

.nav {
    display: flex;

    align-items: center;
    justify-content: center;

    position: relative;

    padding: 14px 24px;

    @media (--viewportLarge) {
        padding: 18px 24px;
        justify-content: space-between;
    }

    width: 100%;
    position: sticky;
    top: 0;
    z-index: var(--zIndexTopbar);

    background-color: white;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.05);
}

.logoLink {
    position: relative;
    top: -2px;

    & img {
        height: 40px;
        width: auto;
        max-width: 220px;
        object-fit: contain;
        object-position: left center;
    }
}

.saveAndContinue {
    @apply --paragraphAntiMedium;

    text-decoration: underline;
    margin: 0;

    position: absolute;
    right: 24px;
    top: calc(50% - 11px);

    & code {
        display: flex;

        & svg {
            width: 18px;
            height: 18px;

            &:hover {
                cursor: not-allowed;
            }

            & path {
                fill: #b2b2b2;
            }
        }
    }
    & span {
        display: none;
    }

    @media (--viewportLarge) {
        position: relative;
        right: unset;
        top: -2px;

        & code {
            display: none;
        }
        & span {
            display: initial;
        }
    }

    &:hover {
        cursor: not-allowed;
    }
}

.progressBar {
    position: absolute;
    bottom: 0;
    height: 4px;
    left: 0;
    right: 0;
    background-color: #e6e6e6;

    & > div {
        height: 100%;
        background-color: #8f2593;
    }
}

.interruptionAllowed {
    color: #1a2b49;

    & code {
        & svg {
            &:hover {
                cursor: pointer;
            }
            & path {
                fill: #1a2b49;
            }
        }
    }

    &:hover {
        cursor: pointer;
    }
}

.logoLinkNavigateAllowed {
    & img {
        &:hover {
            cursor: pointer;
        }
    }
}
