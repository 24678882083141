@import '../../marketplace.css';

.otherInfoContainer {
  display: flex;
  flex-wrap: wrap;
}
.otherInfoItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 104px;
    margin-right: 10px;
    margin-bottom: 10px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 11px 19px;
    border: 1px solid #e7e7e7;

    & > div {
      font-style: normal;
      font-size: 16px;
    }
    & > div:nth-of-type(1) {
      color: #923395;
      font-weight: 700;
    }
    & > div:nth-of-type(2) {
      font-weight: 500;
      white-space: nowrap;
    }
    @media (--viewportMediumMax) {
      padding: 14px 9px;
      width: 22vw;
      max-width: 111px;
      margin-right: 7px;
      font-size: 13px;
      & > div {
        font-size: 14px;
      }
    }
  }

.otherPartyContainer {
  display: flex;
  flex-wrap: wrap;

@media (--viewportMediumMax) {
  flex-wrap: nowrap;
}
}
