@import '../../marketplace.css';

.root {
    &.single {
        border-radius: 0;

        /* togglerMode and isSingleElem */
        & input + label {
            border-radius: 4px;
            border: 1px solid #e6e6e6;
            transition: 0.35s;
            /* toggler wrapper */
            & > span {
                align-self: unset;
            }

            &:hover {
                cursor: pointer;
                background: #f9f9f9;
                border: 1px solid #b2b2b2;
            }
        }

        & input:checked + label {
            &:hover {
                background: unset;
                border: 1px solid #e6e6e6;
            }
        }
    }
}

.root:not(.toggle) {
    @apply --formFieldsItem;
    background: unset;
}

.selected:not(.toggle) {
    @apply --formFieldsItem-selected;
}

.label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 16px;
}

.togglerWrapper {
    width: 42px;
    height: 24px;
    background-color: var(--matterColorAnti);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 2px;
}

.togglerItem {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: var(--matterColorBright);
    transition: 0.35s;
    transform: translateX(0px);
}

input[class*='FieldCheckbox_input_'],
.input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    /* Highlight the borders if the checkbox is hovered, focused or checked */
    &:hover + label .box,
    &:focus + label .box {
        stroke: var(--marketplaceColor);
        fill: none;
    }

    &:checked + label .box {
        stroke: var(--marketplaceColor);
        fill: var(--marketplaceColor);
    }

    /* successColor version */
    &:hover + label .boxSuccess,
    &:focus + label .boxSuccess,
    &:checked + label .boxSuccess {
        stroke: var(--marketplaceColor);
        fill: var(--marketplaceColor);
    }

    /* Display the "check" when checked */
    &:checked + label .checked {
        display: inline;
        stroke: white;

        fill: none;
    }

    /* Display the "check" when checked */
    &:checked + label .checkedSuccess {
        display: inline;
        stroke: var(--successColor);
    }

    /* Hightlight the text on checked, hover and focus */
    &:focus + label .text,
    &:hover + label .text,
    &:checked + label .text {
        color: var(--matterColorDark);
    }

    &:disabled + label .text,
    &:checked:disabled + label .text {
        color: #b2b2b2;
    }

    &:hover:disabled + label {
        &,
        & * {
            cursor: not-allowed;
        }
        & .box {
            stroke: lightgrey;
        }
    }
    /* checked has a higher priority */
    &:hover:disabled:checked + label {
        & .box {
            stroke: #b2b2b2;
        }
    }

    &:disabled:checked:not(:checked) + label {
        & .checkboxWrapper rect {
            stroke: #b2b2b2;
            fill: #b2b2b2;
        }
    }
}

.inputToggle {
    &:checked + label {
        & .togglerWrapper {
            justify-content: flex-end;
        }
    }

    &:checked:not(:disabled) + label {
        & .togglerWrapper {
            background-color: var(--marketplaceColor);
        }
    }

    &:disabled + label {
        & .togglerWrapper {
            background-color: #e6e6e6;
            &:hover {
                cursor: not-allowed;
            }
        }
    }
}

.checkboxWrapperReversed,
.checkboxWrapper {
    /* This should follow line-height */
    height: 32px;
    margin-top: -1px;
    margin-right: 12px;
    align-self: baseline;

    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.checkboxWrapperReversed {
    margin-left: auto;
    margin-right: 0;
}

.checked {
    display: none;
    fill: var(--marketplaceColor);
}

.checkedSuccess {
    display: none;
    fill: var(--successColor);
}

.boxSuccess,
.box {
    stroke: lightgrey;
    fill: none;
}

.text {
    cursor: pointer;
}

.textRoot {
    margin-bottom: 0;
}
