@import '../../../marketplace.css';

.section {
    height: 100%;
    display: flex;
    flex-direction: column;

    & h3 {
        @apply --fontSizeXXL;
        margin-top: 0;

        @media (--viewportSmallMax) {
            font-size: 24px;
            line-height: 32px;
        }
    }

    & > p {
        margin: 0 0 16px 0;

        @media (--viewportSmallMax) {
            @apply --fontSizeSM;
        }
    }
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* need to be more than the parent modal */
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.2);

    @media (--viewportSmallMax) {
        position: fixed;
    }
}

.container {
    position: absolute;
    background: white;
    z-index: 101;
    left: 4px;
    right: 4px;
    top: 10%;

    padding: 63px 24px 0 24px;
    border-radius: 8px;

    @media (--viewportSmallMax) {
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }
}

.closeButton {
    @apply --marketplaceModalCloseStyles;

    &,
    &:hover,
    &:enabled:hover {
        background: white;
    }
}

.closeText {
    @apply --marketplaceModalCloseText;
}

.closeIcon {
    @apply --marketplaceModalCloseIcon;
}

.overlayDeleteContent {
    @media (--viewportSmallMax) {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    & button {
        color: #ff0000;
    }

    & > svg {
        margin-top: 16px;

        & path {
            fill: #ff0000;
        }
    }

    & h3 {
        margin: 16px 0 16px 0;
    }

    & > p {
        margin: 0;
    }
}

.userDataProtectedMessage {
    @apply --paragraphAntiSM;

    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
        width: 16px;
        height: 16px;
        fill: none;

        margin-right: 4px;
        margin-top: 4px;
    }
}

.levelContainer {
    margin-top: 40px;
    margin-bottom: 48px;

    & > div {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.levelItem {
    display: inline-block;
    margin-left: 4px;
    transition: 0.35s;

    background: none;
    border: 1px solid #8f2593;
    border-radius: 8px;

    width: 96px;
    height: 16px;
}

.editItemNoItem {
    @apply --paragraphAntiSM;
    @apply --fontSizeMM;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 144px;

    @media (--viewportSmallMax) {
        @apply --fontSizeSM;
        height: 116px;
    }
}

.tooltipContent {
    @media (--viewportSmallMax) {
        width: calc(100vw - 48px) !important;
    }
}

.levelInfoRoot {
    transform: translateX(-12px);

    @media (--viewportSmallMax) {
        width: calc(100vw - 48px) !important;
        transform: unset;
    }
}

.editUserProfileItem {
    @media (--viewportMedium) {
        & > div {
            &:nth-of-type(1) {
                & > span {
                    display: inline-block;
                    max-width: 200px;

                    white-space: nowrap;
                    @apply --marketPlaceTextOverflow;
                }
            }
        }
    }
}
