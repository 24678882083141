@import '../../marketplace.css';

:root {
    --benefitsItemCommon {
        & h3 {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
        }

        & p {
            display: block;
            font-size: 18px;
            line-height: 28px;
        }
    }
    --marketplaceDivider {
        content: '';
        width: 42%;
        border-bottom: solid 1px #d2d2d2;
        position: absolute;
        top: 50%;
        z-index: 1;
    }
}

.hide {
    display: none;
}

.content {
    @apply --marketplaceModalBaseStyles;
    @apply --marketplaceModalResetStyles;
    max-width: 100%;
    margin-bottom: 32px;
    margin-top: 0;
    @media (--viewportMedium) {
        padding: 0;
    }
}

.heading {
    margin: 0;
    font-size: 24px;

    @media (--viewportMedium) {
        font-size: 32px;
    }
}

.signupContent {
    flex-basis: 424px;
}
.signupOptionContent {
    margin-bottom: 0;
    background: white;

    @media (--viewportLarge) {
        padding-left: 120px;
        min-width: 464px;
    }
}

.subHeading {
    @apply --subHeading;
}

.form {
    /* We don't want the form to take the whole space so that on mobile view
    the social login buttons will be after the sign up button
    and not in the bottom of the page */
    @media (--viewportMedium) {
        padding-top: 2px;
    }
}

.backLink {
    color: #1a2b49;
    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
}

.benefitsHolder {
    margin-left: 8px;

    @media (--viewportMedium) {
        margin-right: auto;
    }
}

.benefitsHolderDesktop {
    display: none;

    @media (--viewportMedium) {
        margin-left: 48px;
        display: block;
    }
}

.benefitsHolderMob {
    display: block;
    margin-top: -8px;
    margin-bottom: 24px;

    @media (--viewportMedium) {
        display: none;
    }

    & p {
        display: flex;
        align-items: center;
        justify-content: center;

        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;

        & svg {
            margin-left: 8px;
            transform: rotate(90deg);
            transition: 0.4s;
        }

        & path {
            stroke: #1a2b49;
            fill: #1a2b49;
        }
    }

    &.opened {
        & p {
            & svg {
                margin-left: 8px;
                transform: rotate(-90deg);
            }
        }
    }
}

.benefitsItem {
    margin-bottom: 8px;

    display: flex;
    max-width: 360px;

    & svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }

    & p {
        display: none;
    }

    & h3 {
        @apply --fontSizeSM;
        margin: 0;
    }

    &:nth-of-type(1) {
        margin-top: 16px;
    }

    @media (--viewportMedium) {
        margin-bottom: 0;

        @apply --benefitsItemCommon;

        &:nth-of-type(1) {
            margin-top: 0;
        }

        & svg {
            width: 48px;
            height: 48px;
            flex-shrink: 0;
            margin-right: 16px;
        }
    }
}

.benefitsItemSlider {
    text-align: center;

    max-width: calc(100% - 80px);
    margin: auto;

    @apply --benefitsItemCommon;
}

.listingBenefitsHolderDesktop {
    display: none;
    margin-top: 32px;

    max-width: 395px;

    & > div h4 {
        margin-top: 0;
        display: flex;
        align-items: center;

        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.5px;

        & > svg {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            margin-top: 2px;
        }
    }

    & > p {
        font-size: 18px;
    }

    @media (--viewportMedium) {
        display: block;
        margin-left: 48px;
    }
}

.listingCard {
    display: none;

    background-color: white;

    @media (--viewportMedium) {
        display: block;
    }
}

.listingBenefitsHolderMob {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;

    padding: 24px;

    margin: -24px -24px 24px -24px;
    background: #f0fff6;

    @media (--viewportMedium) {
        display: none;
    }

    & img {
        /* max-width: 88px; */
        max-height: 88px;
        margin-right: 16px;
    }
    & p {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.25px;
        margin: 0;
        & + div {
            display: none;
        }
    }
    & h4 {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        margin: 0 0 8px 0;
        letter-spacing: -0.5px;

        display: flex;
        align-items: center;

        & svg {
            width: 18px;
            height: 18px;
            margin-right: 8px;
        }
    }
}

.carouselHolder {
    margin-top: 32px;
    position: relative;
}

.sliderButton {
    border: none;
    position: absolute;

    top: calc(50% - 15px);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;

    & path {
        stroke: #1a2b49;
        fill: #1a2b49;
    }
}
.sliderButtonBack {
    left: 0;
    & svg {
        transform: rotate(180deg);
    }
}
.sliderButtonNext {
    right: 0;
}

.dotGroup {
    display: flex;
    justify-content: center;

    & button {
        border: none;
        height: 8px;
        width: 8px;
        display: inline-block;
        border-radius: 50%;
        padding: 0;
        background: var(--matterColorAnti);
        margin-left: 4px;
        opacity: 0.5;

        &:nth-of-type(1) {
            margin-left: 0;
        }

        &:disabled {
            opacity: 1;
            height: 10px;
            width: 10px;
        }
    }
}

.benefitImageMob {
    display: block;

    @media (--viewportMedium) {
        display: none;
    }
}

.optionSection {
    @apply (--authSubsection);

    white-space: break-spaces;
    margin: 16px 0 32px 0;
}

.responsiveImage {
    width: 88px;
    height: 88px;

    flex-shrink: 0;

    background-size: cover;

    border-radius: 4px;
    margin-right: 16px;

    @media (--viewportMedium) {
        display: none;
    }
}

.socialButtonsOrText {
    background-color: unset;
    color: var(--matterColorAnti);
    font-size: 18px;
    width: auto;
    display: inline-block;
    z-index: 3;
    padding: 0 20px 0 20px;
    position: relative;
    margin: 0;
}
.socialButtonsOr {
    width: 100%;
    height: 32px;
    margin: 32px 0;
    text-align: center;
    position: relative;

    &:after {
        @apply --marketplaceDivider;
        left: 0;
    }

    &:before {
        @apply --marketplaceDivider;
        right: 0;
    }

    & + div {
        margin-bottom: 10px;
    }
}

@media (--viewportMedium) {
    .socialButtonsOr {
        height: 34px;
    }
}
