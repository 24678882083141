@import '../../marketplace.css';

.pseudoLink {
    text-decoration: none;
    color: #8f2593;
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.leaveReviewLink {
    color: var(--marketplaceColor);
}
