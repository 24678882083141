@import '../../common.css';

.root {
    margin-bottom: 56px;
}

.container {
    display: flex;

    @media (--viewportMediumMax) {
        display: block;
    }
}

.headerRoot {
    @media (--viewportLarge) {
        & h1,
        & p {
            text-align: left;
        }

        & p {
            padding: 0;
        }
    }
}

.column {
    flex-basis: 50%;

    & > img {
        width: 100%;
    }
}

.followers {
    margin: 24px 0 0 0;
}

.footer {
    display: block;
    @media (--viewportMediumMax) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px;
    }
}
