@import '../../marketplace.css';

.wishlistPanel {
    width: 100%;
    max-width: 1150px;

    padding: 24px 24px 40px 24px;
    margin: 0 auto;

    @media (--viewportMedium) {
        padding: 64px 24px 80px 24px;
    }
}

.noResults {
    @media (--viewportMedium) {
        margin: 81px 0 0 86px;
    }
}

.wishlistsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
}

.listingCard {
    background: white;
    flex-basis: 100%;
    margin-right: 0;

    @media (--viewportMedium) {
        flex-basis: calc((100% / 2) - (48px / 3));
    }

    @media (--viewportLarge) {
        flex-basis: calc((100% / 3) - (64px / 3));
    }
}

.wishlistsHelmet {
    & h3 {
        margin-top: 0;
    }

    & p {
        margin-bottom: 32px;
    }
}

.closeAlert {
    @media (--viewportMedium) {
        display: block;
    }
}

.statusChangedPanel {
    margin-bottom: 32px;
}
