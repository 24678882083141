@import '../../marketplace.css';

.root {
    /** hide error */
    overflow: hidden;
    position: relative;

    min-height: calc(100vh - 88px - 74px);

    & form {
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 24px;
        padding-right: 24px;

        min-height: calc(100vh - 88px - 32px - 16px - 24px - 64px);

        @media (--viewportMedium) {
            padding-left: 0;
            padding-right: 0;
            min-height: calc(100vh - 88px - 32px - 16px - 64px - 64px);
        }
    }
}

.error {
    @apply --marketplaceModalErrorStyles;

    max-width: 320px;

    margin: 0;

    position: absolute;
    z-index: 2;
    top: 40px;
    right: 16px;
}

.genericErrorContent {
    padding: 16px 22px;
    border-radius: 4px;
    background-color: var(--errorColorLight);
    border-radius: 4px;

    position: relative;

    & svg {
        position: absolute;
        right: 2px;
        top: 2px;

        fill: none;
        margin-left: 16px;

        &:hover {
            cursor: pointer;
        }

        & path {
            stroke: white;
        }
    }
}

.genericErrorText {
    @apply --marketplaceH5FontStyles;
    font-weight: var(--fontWeightSemiBold);
    margin: 0;
    color: var(--matterColorLight);

    @media (--viewportMedium) {
        margin: 0;
    }
}

.collectInfoWrapper {
    height: 100%;
    margin: 24px auto 0 auto;
    position: relative;

    &.loading {
        @apply --loadingOverlay;
    }

    @media (--viewportMedium) {
        margin: 64px auto 0 auto;
    }
}

.collectInfoStep {
    max-width: 480px;

    display: flex;
    justify-content: space-between;

    @apply --paragraphAntiMedium;

    margin: 0 auto 16px auto;

    padding: 0 24px 0 24px;

    @media (--viewportMedium) {
        font-size: 20px;
        line-height: 32px;
        padding: 0;
    }
}

.msgSection {
    display: flex;
    align-items: center;

    @apply --paragraphAntiSM;

    margin-bottom: 16px;
    margin-top: 24px;

    & > svg {
        width: 16px;
        height: 16px;

        margin-right: 6px;

        & path {
            fill: #b2b2b2;
        }
    }
}

.recommendationStep {
    overflow: initial;

    & [datatype='preview-button'] {
        bottom: 120px;
    }

    & form {
        padding-bottom: 120px;
    }
}
