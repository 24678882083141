@import '../../../marketplace.css';

.skillsItem {
    & > div {
        width: 100%;
    }
}

.skillText {
    margin: 0;

    max-width: 320px;

    @media (--viewportSmallMax) {
        max-width: 200px;
    }

    @apply --fontSizeMM;
}

.removeCustomIcon {
    position: absolute;
    right: 16px;
}

.skillsHolderWithError {
    margin-bottom: 30px;
}

.skillsHolder {
    & > *:not(code) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & svg {
            fill: none;
            flex-shrink: 0;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.customSkillOptionHolder {
    & > p {
        margin: 0;
        @apply --fieldset;
        left: 52px;
    }

    @apply --customFieldHolder;

    display: flex;

    border-radius: 0 0 4px 4px;
}

.customSkillOption {
    & > div {
        border: none;
    }
}

.preSkillsOverlay {
    @media (--viewportSmallMax) {
        padding-bottom: 132px;
    }
}
