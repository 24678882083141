@import '../../marketplace.css';

.aggregatedValuesHolder {
    display: flex;
    flex-wrap: wrap;

    margin-top: 8px;
    margin-bottom: -17px;
    padding: 17px 60px 0px 17px;

    & > div {
        display: flex;
        align-items: center;

        background-color: #f9f4fa;
        border: 1px solid #8f2593;
        color: #8f2593;
        border-radius: 48px;

        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.25px;
        text-align: center;

        padding: 8px 16px;
        margin-right: 8px;
        margin-top: 8px;

        &.invalid {
            color: #ff0000;
            border-color: #ff0000;
            background-color: #fff0f0;
        }

        & > svg {
            fill: none;
            width: 16px;
            height: 16px;
            margin-left: 8px;

            &:hover {
                cursor: pointer;
            }
        }
    }
}
