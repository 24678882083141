@import '../../marketplace.css';

[data-role='tooltipRequest'] {
    pointer-events: all;

    & * {
        pointer-events: none;
    }
}

.tooltipWrapper {
    position: relative;

    &:after {
        content: '';
        z-index: 1000;
        position: absolute;

        width: 0;
        height: 0;

        left: calc(50% - 8px);

        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
    }

    &.bottom {
        &:after {
            border-bottom: 8px solid white;

            top: calc(100% + 5px);
        }
    }

    &.top {
        &:after {
            border-top: 8px solid white;

            top: calc(0% - 12px);
        }
    }

    &.pointerHidden {
        &:after {
            transform: scale(0);
        }
    }
}

.tooltipTarget {
    display: flex;
}

.tooltipContent {
    display: none;

    position: absolute;
    z-index: 1000;
    /*
  * left position is computed automatically and set as styles
  */
    left: 0;

    background: #ffffff;
    border-radius: 4px;

    box-shadow: 1px 1px 9px 7px rgba(125, 125, 125, 0.1);

    white-space: break-spaces;

    padding: 16px;
    cursor: auto;

    text-align: left;

    opacity: 0;

    min-width: 300px;
    /* svg - for static positioned tooltips */
    & > *:not(svg) {
        pointer-events: none;

        @apply --fontSizeXS;
        font-style: normal;
        color: #1a2b49;
        margin-bottom: 0;

        &:first-of-type {
            font-weight: 600;
            margin-bottom: 10px;
        }

        &:last-of-type {
            font-weight: 300;
            margin-bottom: 0;
        }
    }

    &.visible {
        display: block;
    }
}

.content-top {
    bottom: calc(100% + 12px);
}

.content-bottom {
    box-shadow: 1px 0px 9px 7px rgba(125, 125, 125, 0.1);

    bottom: unset;
    top: calc(100% + 12px);
}
