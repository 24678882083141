@import '../../common.css';

.root {
    margin-top: 64px;
}

.content {
    max-width: 760px;
    margin: 0 auto 24px auto;
    text-align: center;
}
