@import '../../common.css';

.header {
    @apply --subPages_root;
    margin: 0 auto 64px auto;
}

.packageHolder {
    display: flex;
    column-gap: 32px;

    @media (--viewportMediumMax) {
        flex-direction: column;
        align-items: center;
        row-gap: 24px;
    }
}

.packageBlock {
    border: 1px solid #e6e6e6;
    border-radius: 24px;
    max-width: 564px;
    flex: 1;

    @media (--viewportMediumMax) {
        width: 100%;
    }

    position: relative;

    &:hover {
        cursor: pointer;
    }

    &.selected {
        box-shadow: 0 0 0 4px #8f2593;
        border: 1px solid transparent;
    }

    & > section,
    & > footer {
        padding: 32px;
    }

    & > footer {
        border-top: 1px solid #e6e6e6;

        & > p {
            text-align: center;
            font-weight: 600;
        }
    }

    & > section {
        & > svg {
            @media (--viewportMediumMax) {
                width: 48px;
                height: 48px;
            }

            & path {
                fill: #8f2593;
            }
        }
    }

    & .title {
        font-size: 60px;
        font-style: normal;
        font-weight: 700;
        line-height: 72px; /* 120% */
        letter-spacing: -0.5px;
        color: #8f2593;
        margin: 16px 0;

        @media (--viewportMediumMax) {
            @apply --fontSizeXL;
            font-weight: 700;
        }
    }

    & .list {
        margin-bottom: 24px;
    }

    & .listTitle {
        font-weight: 600;
    }

    & .listItem {
        display: flex;
        column-gap: 8px;

        &.notAllowed {
            & path {
                fill: #b2b2b2;
            }
        }
    }

    & .packageBenefit {
        position: absolute;
        left: calc(50% - 131px);
        top: -32px;

        width: 262px;

        @apply --fontSizeM;
        font-weight: 600;
        color: #fff;
        text-align: center;

        border-radius: 128px;
        background: #8f2593;

        padding: 17px 24px;

        @media (--viewportMediumMax) {
            @apply --fontSizeSM;
            width: 162px;
            left: calc(50% - 81px);
            padding: 8px 16px;

            top: -20px;
        }
    }
}
