@import '../../marketplace.css';

.carouselModalScrollLayer {
    @apply --marketplaceCarouselModalScrollLayer;
}

.carouselModalContainer {
    @apply --marketplaceCarouselModalContainer;
    @apply --modalNoBorderContainer;

    margin-top: 0;
    padding-top: 58px;

    & > button {
        background: unset;
        color: white;
        position: absolute;

        & path {
            stroke: white;
        }
    }
}

.carouselModalContainer,
.carouselModalScrollLayer {
    width: 100vw;
    height: 100vh;
}

.carouselModalContent {
    width: 100%;
    height: 100%;
    padding: 0;

    display: flex;
    padding: 0;
}

.img {
    width: 100%;
    object-fit: contain;
}
