@import '../../marketplace.css';

.container {
    margin-top: 0;
}
.icon {
    fill: none;
}
.notification {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: var(--matterColorAnti);
    white-space: pre-wrap;
    text-align: center;
}

.questionItem {
    @apply --paragraphAntiSM;
    font-weight: 400;
    margin-bottom: 8px;

    display: flex;
    align-items: center;
    column-gap: 8px;

    &:hover {
        cursor: pointer;
    }
}
.modalContainer {
    max-width: 480px;

    @media (--viewportMedium) {
        margin-top: 7.5vw;
    }
}
.modalHeader {
    margin: 0 0 16px 0;
    @apply --fontSizeXL;
    font-weight: 700;

    @media (--viewportMedium) {
        @apply --fontSizeXXL;
    }
}

.modalParagraph {
    margin-bottom: 40px;
}

.modalContent {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.modalActionButton {
    margin-top: auto;
}
