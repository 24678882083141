@import '../../marketplace.css';

.wordNumSection {
  margin-top: 8px;

  & > div {
    height: 8px;
    background-color: #e6e6e6;
    border-radius: 4px;

    & > div {
      height: 100%;
      transition: 0.35s;
      background-color: #8f2593;
      border-radius: 4px;
    }
  }

  & p {
    @apply --paragraphAntiSM;

    margin: 8px 0 0 0;
  }
}

.collectInfoDescription {
  @apply --fontSizeSM;

  color: var(--matterColorDark);
  margin-bottom: 32px;

  @media (--viewportMedium) {
    font-size: 18px;
    line-height: 28px;
  }
}
