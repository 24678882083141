@import '../../marketplace.css';

:root {
    --Price_Filter_Form_prices {
        @apply --marketplaceSearchFilterSublabelFontStyles;
        width: 48px;
        text-align: center;
        border-bottom-color: var(--successColor);
        border-bottom-width: 3px;

        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &:hover {
            border-bottom-color: var(--successColor);
        }
        &:focus {
            border-bottom-color: var(--successColor);
            color: #000;
            padding: 0;
        }
    }
}

.root {
    display: none;

    /* Borders */
    outline: none;
}

.popup {
    /* By default hide the content */
    display: block;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;

    /* Position */
    position: absolute;
    z-index: var(--zIndexPopup);

    /* Layout */
    padding: 16px;
    margin-top: 7px;
    background-color: var(--matterColorLight);

    /* Borders */
    border-top: 1px solid var(--matterColorNegative);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    transition: var(--transitionStyleButton);
}

.isOpenAsPopup {
    display: block;
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
}

.priceInput {
    width: 100%;
}

.plain {
    width: 100%;
}

.isOpen {
    display: block;
}

.contentWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 16px 0;

    @media (--viewportLarge) {
        margin: 0 0 32px 0;
    }
}

.inputsWrapper {
    display: flex;
    width: 100%;
}

.minPrice,
.maxPrice {
    @apply --Price_Filter_Form_prices;
}

.priceSeparator {
    display: flex;
    align-items: center;
    margin: 0 16px;
}

.separatorLine {
    @apply --separatorLine;
    margin: 32px -16px 16px -16px;

    @media (--viewportSmallMax) {
        display: none;
    }
}

.sliderWrapper {
    display: flex;
    padding: 17px 0 25px 0;

    @media (--viewportLarge) {
        padding: 16px 0 24px 0;
    }
}

.buttonsWrapper {
    padding: 0 8px;
    display: flex;
}

.clearButton {
    @apply --marketplaceFilterClearButton;

    /* Layout */
    margin: 0 auto 0 0;

    /* Override button styles */
    cursor: pointer;
    text-decoration-line: underline;

    &:focus,
    &:hover {
        color: var(--matterColor);
        transition: width var(--transitionStyleButton);
    }
}

.submitButton {
    @apply --fontSizeXS;
    font-weight: var(--fontWeightMedium);
    color: var(--matterColorBright);
    background-color: var(--marketplaceColor);

    border-radius: 5px;
    /* Layout */
    margin: 0 0 0 19px;
    padding: 7px 11px;

    /* Override button styles */
    outline: none;
    border: none;
    cursor: pointer;

    &:disabled {
        background-color: #e6e6e6;
        color: #b2b2b2;

        &:hover {
            cursor: not-allowed;
        }
    }
}
