@import '../../../marketplace.css';

.availabilityItem {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.availabilityItemProposal {
    & svg {
        flex-shrink: 0;
        transform: scale(-1, 1);

        & path {
            fill: #8f2593;
        }
    }
}
