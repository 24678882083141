@import '../../marketplace.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
    @apply --marketplaceModalInMobileBaseStyles;

    height: auto;

    @media (--viewportMedium) {
        flex-basis: 576px;
        padding: var(--modalPaddingMedium);
        background-color: var(--matterColorLight);
        margin-top: 12.5vh;
        margin-bottom: 12.5vh;
    }

    @media (--viewportLarge) {
        padding: 0;
        background-color: transparent;
        margin-top: 0;
        margin-bottom: 0;
    }
}
.modalContent {
    @apply --modalContent;

    & h1 {
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        margin: 0 0 16px 0;
    }

    & p {
        margin: 0 0 16px 0;
    }
}
.modalIcon {
    fill: none;
    flex-shrink: 0;
}
.modalHeading {
    margin-top: 89px;
    margin-bottom: 36px;
    padding: 0 24px;

    @media (--viewportMedium) {
        padding: 0;
    }

    @media (--viewportLarge) {
        display: none;
    }
}

.title {
    /* Font */
    @apply --marketplaceH1FontStyles;
    color: var(--matterColor);
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 333px;
    /* Layout */
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 5px;
    }
}

.author {
    width: 100%;
    @apply --marketplaceH4FontStyles;
    margin-top: 7px;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
        color: var(--matterColor);
    }
}

.bookingHeading {
    display: none;

    @media (--viewportLarge) {
        display: block;
        margin-top: -2px;
        margin-bottom: 33px;
    }
}

.bookingTitle {
    /* Font */
    color: var(--matterColor);

    margin-top: 0;
    margin-bottom: 9px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bookingHelp {
    display: none;
    @apply --marketplaceH5FontStyles;

    @media (--viewportMedium) {
        color: var(--matterColor);
        display: block;
        margin-top: 0;
    }
}

.bookingForm {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 0;

    @media (--viewportMedium) {
        min-height: 400px;
    }

    @media (--viewportLarge) {
        min-width: 312px;
        min-height: auto;
        margin: 0 0 84px 0;
    }
}

.bookingDatesSubmitButtonWrapper {
    flex-shrink: 0;
    padding: 0 24px 24px 24px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: var(--matterColorLight);

    @media (--viewportMedium) {
        padding: 0;
        width: 100%;
        position: static;
        bottom: unset;
        background-color: transparent;
    }
}

.openBookingForm {
    /* Ensure that mobile button is over Footer too */
    display: flex;
    z-index: 9;
    position: fixed;
    bottom: 100px;
    left: 0;
    bottom: 0;
    width: 100vw;
    padding: 18px 24px 18px 16px;
    background-color: #f9f9f9;
    box-shadow: var(--boxShadowTop);

    /* Contain repainting to this component only */
    /* 3D painting container helps scrolling */
    transform: translate3d(0, 0, 0);

    @media (--viewportMedium) {
        padding: 18px 60px 18px 60px;
    }

    @media (--viewportLarge) {
        display: none;
    }
}

.priceContainer {
    /* Layout */
    display: block;
    flex-shrink: 0;
    margin-right: 22px;
    padding: 5px 12px;

    @media (--viewportMedium) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.priceValue {
    /* Font */
    @apply --marketplaceH2FontStyles;
    color: var(--marketplaceColor);

    font-weight: 600;
    font-size: 24px;
    line-height: 34px;

    margin-top: 0;
    margin-bottom: 0px;
}

.perUnit {
    /* Font */
    @apply --marketplaceTinyFontStyles;

    font-size: 13px;
    line-height: 16px;

    margin-top: 0;
    margin-bottom: 0px;
}

.bookButton {
    @apply --marketplaceButtonStyles;

    /* Clear padding that is set for link elements looking like buttons */
    padding: 0;
}

.closedListingButton {
    border-left: 1px solid var(--matterColorNegative);
    width: 100%;
    padding: 15px 24px 15px 24px;
    text-align: center;
}
