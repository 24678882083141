@import '../../marketplace.css';

.input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 62px;
    padding: 8px 16px;
    border-radius: 4px 4px 0px 0px;
    border-top: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    border-bottom: 2px solid #1a2b49;

    & input {
        border-bottom: none;
    }
}

.innerInputWrapper > div > input {
    padding: 0;
}

.inputSuccess {
    border-bottom-color: var(--successColor);
}

.inputError {
    border-bottom-color: var(--failColor);
}

.inputActive {
    border-bottom-color: #8f2593;
}

.priceInputLabel {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.25px;
}

.innerInputWrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.innerInputWrapper > div {
    width: 100%;
}

.closeIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-left: 16px;
}

.hiddenCloseIcon {
    width: 24px;
    height: 24px;
    opacity: 0;
}
