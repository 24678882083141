@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;

  & label {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  & textarea,
  & input,
  & select {
    font-size: 16px;
    line-height: 32px;
  }
}

.card {
  @apply --marketplaceInputStyles;

  /* Layout */
  padding: 8px 0 6px 0;
  height: 36px;

  /* Border */
  border-bottom-color: var(--attentionColor);

  font-size: 16px;
  line-height: 32px;

  @media (--viewportMedium) {
    height: 38px;
  }
  @media (--viewportLarge) {
    height: 45px;
    padding: 6px 0 9px 0;
  }
}

.cardSuccess {
  border-bottom-color: var(--successColor);
}

.cardError {
  border-bottom-color: var(--failColor);
}

.error {
  color: var(--failColor);
}

.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
}

.paymentHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);

  padding-top: 4px;
  padding-bottom: 2px;

  font-size: 24px;
    line-height: 30px;

  @media (--viewportMedium) {
    margin: 0 0 16px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentMethodSelector {
  margin-bottom: 32px;
}

.saveForLaterUse {
  padding-top: 6px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding-top: 8px;
    margin-top: 0px;
  }
}

.saveForLaterUseCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }
}

.saveForLaterUseLabel {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}

.saveForLaterUseLegalInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  display: inline-block;
  padding: 0px 0 0px 26px;
  margin-top: -2px;
  margin-bottom: 2px;

  @media (--viewportMedium) {
    margin-top: -1px;
    margin-bottom: 1px;
  }
}

.billingHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);

  padding-top: 4px;
  padding-bottom: 2px;

  font-size: 18px;
  line-height: 24px;
  @media (--viewportMedium) {
    margin: 0 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentLabel {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 2px;
  }
}

.messageHeading {
  @apply --heading;
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
  & textarea {
    height: 31px;
  }
}

.paymentInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  padding: 0 42px;
  margin: 28px 0 0 0;
  @media (--viewportLarge) {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 14px;
    padding: 0 20px;
  }
}

.missingStripeKey {
  color: var(--failColor);
}
.checkIcon {
  width: 12px;
  height: 12px;
}
.paymentAddressField {
  margin-top: 62px;
}
