@import '../../marketplace.css';

:root {
    /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

    --EditListingPhotosForm_imageWidthDesktop: calc(50% - 12px);
}

.root {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 120px;
}

.progressWrapper {
    display: flex;
    justify-content: flex-start;
    column-gap: 15px;
    align-items: center;
    margin-bottom: 16px;
    flex-wrap: wrap;

    @media (--viewportMedium) {
        flex-wrap: nowrap;
        align-items: flex-end;
    }
}

.progressWrapperSecond {
    display: flex;
    justify-content: flex-start;
    column-gap: 15px;
    align-items: center;
    margin-bottom: 32px;
    flex-wrap: wrap;

    @media (--viewportMedium) {
        flex-wrap: nowrap;
        align-items: flex-end;
    }
}

.circleProgress {
    border: 5px solid #e6e6e6;
    background-color: #ffffff;
    height: 30px;
    border-radius: 50%;
    width: 30px;
}

.progressText {
    @apply --fontSizeSM;
    color: var(--brand-secondary, #1a2b49);
}

.progressPercentage {
    @apply --badge-new-item;
    color: var(--service-success, #1e9f55);
    background: var(--service-success-010, #f0fff6);
    padding: 0px 8px;
    margin-left: 40px;

    @media (--viewportMedium) {
        margin-left: 0px;
    }
}

.uploadHorseTitle {
    @apply --fontSizeXL;
    font-weight: 700;
    margin: 0 0 16px 0;

    @media (--viewportMedium) {
        @apply --fontSizeXXL;
    }
}

.uploadHorseSubTitle {
    @apply --fontSizeMM;
    margin-bottom: 24px;
}

.chooseImageText {
    @apply --marketplaceChooseImageText;
}

.chooseImage {
    @apply --marketplaceChooseImage;
}

.imageTypes {
    @apply --marketplaceImageTypes;
}

.imageRequiredWrapper {
    width: 100%;
    clear: both;
}

.tip {
    @apply --marketplaceH5FontStyles;
    flex-shrink: 0;
    color: var(--matterColorAnti);
    margin-top: 24px;
    margin-bottom: 60px;

    @media (--viewportLarge) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.submitButton {
    @apply --marketplaceEditListingSubmitButton;

    @media (--viewportLarge) {
        width: 250px;
    }

    position: relative;
}

.submitButton [data-icon-type='arrow'] {
    position: absolute;
    top: 50%;
    transform: translateY(-6px);
}

.submitButton:disabled [data-icon-type='arrow'] path {
    stroke: #b2b2b2;
    fill: #b2b2b2;
}

@media (--viewportMiniSmall) {
    .submitButton [data-icon-type='arrow'] {
        right: 40px;
    }
}

@media (--viewportSmall) {
    .submitButton [data-icon-type='arrow'] {
        right: 50px;
    }
}

@media (--viewportMedium) {
    .submitButton [data-icon-type='arrow'] {
        right: 200px;
    }
}

@media (--viewportLarge) {
    .submitButton [data-icon-type='arrow'] {
        right: 35px;
    }
}

.title {
    max-width: 480px;
    width: 100%;
}

.toYourHorseTitle {
    @apply --fontSizeXL;
    font-weight: 700;

    margin: 0 0 16px 0;

    @media (--viewportMedium) {
        @apply --fontSizeXXL;
    }
}

.toYourHorseSubTitle {
    margin-bottom: 32px;
}

.activeCheckboxWrapper {
    column-gap: 8px;
    margin-bottom: 24px;

    @media (--viewportMedium) {
        display: flex;
        align-items: center;
    }
}
.activeCheckbox,
.activeCheckboxWrapper label {
    border: none !important;
    background: none !important;
    padding: 0 !important;
}

.preselectAnonymOption {
    color: #8f2593;
    margin: 0;

    @media (--viewportSmallMax) {
        margin-left: 36px;
    }

    &:hover {
        cursor: pointer;
    }
}

.activeCheckbox label span {
    @apply --fontSizeMM;
    color: var(--matterColorDark);
}

.activeCheckbox label {
    padding: 0px;
}

.fieldPricingWrapper {
    margin-bottom: 24px;
}

.showMore {
    @apply --flex-centered;
    @apply --fontSizeSM;
    color: var(--brand-primary, #8f2593);
    margin-top: 32px;
}

.showMore:hover {
    cursor: pointer;
}

.notFlexibleWarning {
    margin-bottom: 24px;
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    border-radius: 4px;
    border: 1px solid #1e9f55;
    background: #f0fff6;
    flex-direction: column;

    @media (--viewportMedium) {
        flex-direction: row;
    }
}

.nfwInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    gap: 10px;

    @media (--viewportMedium) {
        justify-content: unset;
        align-items: flex-start;
    }
}

.nfwText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.nfwTitle {
    align-self: stretch;
    color: var(--brand-secondary, #1a2b49);
    @apply --fontSizeSM;
    font-weight: 600;
}

.nfwDesc {
    align-self: stretch;
    @apply --fontSizeSM;
}

.nfwDesktopClose {
    display: none;
    cursor: pointer;

    @media (--viewportMedium) {
        display: block;
    }
}

.nfwMobileClose {
    display: block;
    cursor: pointer;

    @media (--viewportMedium) {
        display: none;
    }
}

.bar {
    border: 4px solid #e6e6e6;
    border-radius: 8px;
}

.tbWithBorder {
    border-bottom: 0px;
    background-repeat: no-repeat;
    background-size: 100% 4px;
}

.newTopBar {
    @apply --flex-centered;

    width: 100%;

    border-radius: 8px;
    padding: 4px;

    @media (--viewportMedium) {
        padding: 4px;
        justify-content: space-between;
    }
}

.wordNumSection {
    margin-top: 8px;

    & > div {
        height: 8px;
        background-color: #e6e6e6;
        border-radius: 4px;

        & > div {
            height: 100%;
            transition: 0.35s;
            background-color: #8f2593;
            border-radius: 4px;
        }
    }

    & p {
        @apply --paragraphAntiSM;

        margin: 8px 0 0 0;
    }
}

.collectInfoDescription {
    @apply --fontSizeSM;

    color: var(--matterColorDark);
    margin-bottom: 32px;

    @media (--viewportMedium) {
        @apply --fontSizeMM;
    }
}

.generateText {
    color: #8f2593;
    display: flex;
    align-items: center;

    margin: 24px 0 0 0;

    & svg {
        margin-right: 8px;
        fill: none;
    }

    &:hover {
        cursor: pointer;
    }
}

.generateTextInProgress {
    color: #b2b2b2;
}

.levelDescription {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    white-space: break-spaces;

    & svg {
        margin-left: 8px;
        margin-bottom: 2px;

        &:hover {
            cursor: pointer;
        }
    }
}

.namedLink {
    text-decoration: none !important;
    display: flex;
    align-items: center;
    column-gap: 8px;
}
