@import '../../marketplace.css';

.statusDeclined {
    color: #ff0000;
}
.statusApproved {
    color: #1e9f55;
}
.appointmentMessage {
    display: flex;
    margin-top: 23px;
}
.ownMessage {
    float: right;
}

.appointmentField {
    display: flex;
    align-items: center;

    & svg {
        margin-right: 8px;
        width: 24px;
        height: 24px;
    }
}
.appointmentFieldFirst {
    margin-bottom: 8px;
}
.appointmentFieldOwnMessage {
    & path {
        stroke: #1a2b49;
    }
}
.appointmentWrapper {
    width: 275px;
    padding: 0;
    margin-left: 12px;
    border-radius: 8px;
    box-shadow: 0px 0px 20px 0px #0000001a;
    @apply --fontSizeSM;

    & a {
        color: #1a2b49;
    }

    @media (--viewportMedium) {
        width: 326px;
    }
}

.ownMessageWrapper {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);

    & a {
        color: white;
    }
}
.appointmentContent {
    padding: 12px 14px 16px 14px;
    text-align: left;
}
.appointmentHeading {
    margin: 0 0 16px 0;
}

.appointmentStatus {
    @apply --fontSizeXS;

    text-align: center;
    margin: 0;
    display: block;
    border-top: 1px solid #0000001a;
    padding: 16px 19px;
}

.googleBtn {
    text-align: center;
    display: block;
    text-decoration: underline;
    margin-top: 24px;
}
button.appointmentButton {
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    margin-top: 23px;
    min-height: 40px;
}
.appointmentButtonAccept {
    @apply --marketplaceButtonStyles;
}
.buttonGroup {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 16px 14px 16px 14px;

    & > button {
        margin-bottom: 8px;
        margin-top: 0;
    }
}
.userMessage {
    display: block;
    margin: 16px 0;
    word-break: break-word;
}
.appointmentError {
    font-size: 12px;
    color: var(--failColor);
    text-align: center;
}
.modalContainer {
    border-top: none !important;

    & h4 {
        margin-top: 0;
        margin-bottom: 16px;
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
    }
}
.modalContent {
    @apply --modalContent;

    & button {
        margin-top: 0px;
    }
}
.modalIcon {
    fill: none;
}
.link {
    text-decoration: underline;
    color: white;
    display: inline;

    @apply (--fontSizeXS);

    &:hover {
        cursor: pointer;
    }
}
.transitionMessageWrapper {
    float: left;
    margin-top: 16px;
}
.messageDate {
    @apply --marketplaceMessageDateFontStyles;

    text-align: left;

    margin: 11px 0 0 0;
    color: var(--matterColorAnti);

    @media (--viewportMedium) {
        margin: 6px 0 0 0;
    }
    margin-left: 13px;

    &:after {
        content: ' Uhr';
    }
}
.ownMessageDate {
    text-align: right;
}
