@import '../../marketplace.css';

:root {
    --Range_Filter_Form_entries {
        @apply --marketplaceSearchFilterSublabelFontStyles;
        width: 48px;
        text-align: center;
        border-bottom-color: var(--attentionColor);
        border-bottom-width: 3px;

        -moz-appearance: textfield;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

.root {
    display: none;

    /* Borders */
    outline: none;
}

.popup {
    @apply --marketplaceFilterPopup;
}

.isOpenAsPopup {
    display: block;
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
}

.plain {
    width: 100%;
}

.isOpen {
    display: block;
}

.contentWrapper {
    display: flex;
    flex-wrap: wrap;
}

.label {
    @apply --marketplaceSearchFilterSublabelFontStyles;
    padding: 8px 0 12px 0;
    margin-left: 8px;
}
@media (--viewportMedium) {
    .label {
        padding: 8px 0 16px 0;
    }
}

.inputsWrapper {
    display: flex;
    width: 100%;
    margin: 0 0 16px 0;

    & > div {
        width: 100%;
    }

    & input,
    /* dropdown list elements */
    & section > p {
        @media (--viewportMediumMax) {
            @apply --fontSizeSM;
        }
    }

    @media (--viewportLarge) {
        margin: 0 0 32px 0;
    }
}

.minValue,
.maxValue {
    @apply --Range_Filter_Form_entries;
}

.rangeSeparator {
    margin: 0 16px;

    display: flex;
    align-items: center;
}

@media (--viewportMedium) {
    .priceSeparator {
        margin: 2px 8px 0 8px;
    }
}

.sliderWrapper {
    display: flex;
    padding: 17px 0 25px 0;

    @media (--viewportMedium) {
        padding: 16px 0 24px 0;
    }
}

.buttonsWrapper {
    display: flex;
}

.clearButton {
    @apply --marketplaceFilterClearButton;

    @apply --fontSizeMM;

    /* Layout */
    margin: 0 auto 0 0;

    &:focus,
    &:hover {
        color: var(--matterColor);
        transition: width var(--transitionStyleButton);
    }
}

.cancelButton {
    @apply --marketplaceH4FontStyles;
    font-weight: var(--fontWeightMedium);
    color: var(--matterColorAnti);

    /* Layout */
    margin: 0;
    padding: 0;

    /* Override button styles */
    outline: none;
    border: none;
    cursor: pointer;

    /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
    margin-left: 48px;

    &:focus,
    &:hover {
        color: var(--matterColor);
        transition: width var(--transitionStyleButton);
    }
}

.submitButton {
    @apply --marketplaceH4FontStyles;
    font-weight: var(--fontWeightMedium);
    color: var(--marketplaceColor);

    /* Layout */
    margin: 0 0 0 19px;
    padding: 0;

    /* Override button styles */
    outline: none;
    border: none;
    cursor: pointer;

    &:focus,
    &:hover {
        color: var(--marketplaceColorDark);
        transition: width var(--transitionStyleButton);
    }
}
