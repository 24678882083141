@import '../../common.css';

.content {
    max-width: 760px;
    text-align: center;
    margin: 64px auto 0 auto;
}

.advantagesHolder {
    margin-top: 32px;
    margin-bottom: 64px;

    @media (--viewportLarge) {
        margin-top: 64px;

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        column-gap: 24px;
        row-gap: 64px;
    }
}

.advantagesItem {
    flex-basis: 33.33%;
    max-width: 367px;

    & svg {
        width: 80px;
        height: 80px;

        & path {
            fill: #8f2593;
        }
    }

    & h3 {
        @apply --fontSizeXL;
        font-weight: 700;
        margin-bottom: 8px;
        max-width: 242px;
        white-space: break-spaces;

        @media (--viewportMediumMax) {
            white-space: normal;
        }
    }

    & p {
        margin: 0;
    }

    @media (--viewportMediumMax) {
        margin-bottom: 32px;
        max-width: unset;

        & h3 {
            max-width: unset;
            margin-bottom: 16px;
        }

        & svg {
            width: 48px;
            height: 48px;
        }
    }
}
