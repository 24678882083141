@import '../../marketplace.css';

.modalContainer {
    border: none;
}

.stickyFooter {
    @media (--viewportSmallMax) {
        margin-top: auto;
    }

    & p {
        margin-bottom: 16px;
        text-align: center;
        @apply --paragraphAntiSM;
    }
}

.modalContent {
    @media (--viewportSmallMax) {
        height: 100%;

        display: flex;
        flex-direction: column;
    }

    & > svg {
        flex-shrink: 0;
        & path {
            fill: #ff0000;
        }
    }

    & > h3 {
        margin: 16px 0;

        @media (--viewportMedium) {
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -0.5px;
        }
    }
    /* confirmInterruptionDesc */
    & > p {
        margin-bottom: 40px;

        @media (--viewportSmallMax) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    & button {
        color: var(--failColor);
    }
}
