@import '../../marketplace.css';
@import '../SelectMultipleFilter/SelectMultipleFilter.css';

.root {
    position: relative;
    display: inline-block;

    @media (--viewportMediumMax) {
        display: block;
    }
}

.parallelDivider {
    height: 16px;
}
