@import '../../marketplace.css';

.root {
    position: relative;
}

/* Badge */
.badge {
    @apply --fontSizeSM;

    display: flex;
    align-items: center;
    column-gap: 8px;

    max-width: 150px;

    border-radius: 48px;
    border: 1px solid #e6e6e6;
    background: #fff;
    color: #1a2b49;

    padding: 4px 16px 4px 4px;

    & > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media (--viewportMediumMax) {
        padding: 0;

        & > span {
            display: none;
        }
    }

    &:hover {
        cursor: pointer;
    }

    & * {
        opacity: 0.33;
    }

    &.selected {
        border: 2px solid var(--Brand-Primary, #8f2593);
        background: var(--Brand-Primary-005, #f9f4fa);
        color: #8f2593;

        & * {
            opacity: 1;
        }
    }
}
.badgeImgHolder {
    width: 28px;
    height: 28px;
    border-radius: 50%;

    background-size: cover;
    background-position: center;

    flex-shrink: 0;
}

.popup {
    position: absolute;
    background: white;
    left: 0;

    padding: 6px 0 0 0;

    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-radius: 16px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

    max-height: calc(100vh - 144px);
    overflow-y: auto;
    overflow-x: hidden;

    @apply --scrollBarStyled;

    width: 252px;
}

.title {
    font-size: 18px;
    font-weight: 400;
    color: var(--matterColorDark);
}

.radioButton {
    accent-color: #8f2593;
    zoom: 1.85;
    width: fit-content;
}

.radioButtonLabel {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    padding: 8px 16px;

    &:hover {
        cursor: pointer;
        background-color: #f9f4fa;
    }
}

.modalContent {
    & h4,
    & p {
        padding: 0 24px;
    }

    & p {
        margin-bottom: 32px;
    }

    & h4 {
        @apply --fontSizeXL;
        font-weight: 600;
    }
}

.selectedHorseForm {
    & .label {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    /* radio button list */
    & > div {
        &,
        &:last-of-type {
            @apply --radionDropDownListItem;
        }
    }
}
