@import '../../marketplace.css';

.ellipsis {
    display: inline-block;
    width: 32px;
    height: 32px;

    font-size: 36px;
    line-height: 8px;
    letter-spacing: -1.5px;

    padding-right: 2px;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
}

.controls {
    bottom: unset;

    box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.1);

    top: calc(100% + 4px);
}

.imageControlTooltipContent {
    border-radius: 8px;
    left: unset !important;
    right: 0;
    top: 38px;

    width: 112px;
    min-width: 112px;

    padding: 8px 0;

    &:after {
        display: none;
    }

    & ul {
        margin: 0;
    }

    & li {
        @apply --fontSizeXS;
        text-align: left;
        pointer-events: initial;

        padding: 8px 16px;

        &:last-of-type {
            margin-bottom: 0;
        }

        &:hover {
            cursor: pointer;
            background: rgba(0, 0, 0, 0.025);
        }
    }
}

.control {
    /* three dots */
    position: absolute;
    background: rgba(255, 255, 255, 0.8);

    width: 32px;
    height: 32px;

    top: 16px;
    right: 16px;
    z-index: 2;

    border-radius: 50%;

    transition: 0.35s;

    &::after {
        display: none;
    }

    &:hover {
        cursor: pointer;
        background: rgba(255, 255, 255, 1);
    }
}

.controlOpened {
    z-index: 3;
    background: rgba(255, 255, 255, 1);
}
