@import '../../common.css';

@keyframes carouselAnimationForward {
    0% {
        transform: translate(0);
    }

    to {
        transform: translate(calc(-100% - 24px));
    }
}

@keyframes carouselAnimationBackward {
    0% {
        transform: translate(calc(-100% - 24px));
    }

    to {
        transform: translate(0);
    }
}

.root {
    max-width: unset;
    margin: 64px -24px 0 -24px;

    @media (--viewportMedium) {
        overflow: hidden;
    }
    @media (--viewportSmallMax) {
        margin: 64px 0 0 0;
    }
}

.header {
    max-width: 760px;
    margin: 0 auto 32px auto;

    @media (--viewportMedium) {
        margin: 0 auto 32px auto;
    }
}

.description {
    white-space: normal;
    @media (--viewportMedium) {
        white-space: break-spaces;
    }
}

.sidenote,
.ratingsHolder,
.summaryRatings,
.summary {
    display: flex;
    align-items: center;
    justify-content: center;
}

.summary {
    margin-bottom: 24px;
    row-gap: 16px;

    flex-wrap: wrap;

    @media (--viewportMedium) {
        margin-bottom: 32px;
    }

    & p {
        margin: 0;
    }
}
.summaryRatings,
.summary {
    column-gap: 16px;
}

.reviewsHolder {
    margin-bottom: 48px;

    & ul {
        padding: 16px 0;
    }
}
.secondHolder {
    min-width: calc(100vw + 96px);

    margin-left: -48px;
    margin-right: -48px;
}

.reviewItem {
    display: flex;
    flex-direction: column;

    flex-shrink: 0;

    margin: 8px 32px 8px 0;
    padding: 32px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    width: 480px;
    background: #fff;

    @media (--viewportSmallMax) {
        margin: 16px 24px 32px 24px;
        width: auto;
        height: 240px;
    }
}

.upperSection {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    & svg {
        flex-shrink: 0;
    }

    @media (--viewportSmallMax) {
        & svg {
            width: 24px;
            height: 24px;
        }
    }
}

.userName {
    margin-right: 16px;

    @media (--viewportSmallMax) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.sidenote {
    @apply --fontSizeXS;
    color: #1e9f55;

    column-gap: 4px;
    justify-content: flex-start;
    margin-bottom: 8px;

    & svg {
        width: 16px;
        height: 16px;
    }
}

.comments {
    line-height: 24px;
}

.mobSliderClassName {
    margin-bottom: 32px;

    @media (--viewportSmallMax) {
        /* width: 100vw; */
        margin-left: -24px;
        margin-right: -24px;
        margin-bottom: 48px;
    }

    & > div > div {
        /* > .carouselHolder > .carousel */

        & > button {
            bottom: -16px;

            &:first-of-type {
                left: 24px;
            }

            &:last-of-type {
                right: 24px;

                /* .DotGroup */
                & + div {
                    bottom: 0px;
                    position: absolute;
                    left: 0;
                    right: 0;
                }
            }
        }
    }
}

.timeSince {
    color: #b2b2b2;
    text-align: right;
    margin-bottom: 0;
    margin-top: auto;
}

.carousel {
    &,
    & > div {
        display: flex;
    }

    & > div {
        animation-duration: 120s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    &.previewed {
        & > div {
            animation-play-state: paused;
        }
    }

    &.forward {
        margin-bottom: 16px;

        & > div {
            animation-name: carouselAnimationForward;
        }
    }

    &.backward {
        margin-bottom: 32px;

        & > div {
            animation-name: carouselAnimationBackward;
        }
    }
}

.summaryLink {
    color: #1a2b49;

    &,
    &:hover {
        text-decoration: none;
    }

    &:hover {
        cursor: pointer;
    }
}
