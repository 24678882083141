@import '../../marketplace.css';

:root {
    --focusedPadding: 17px 60px 4px 17px;
}

.root {
}

.autocomplete {
    position: relative;

    & input {
        caret-color: transparent;

        & ~ section {
            transition: 0s;
            transition-delay: 0.15s;
            overflow: hidden;
            width: calc(100% + 2px);
            position: absolute;
            left: -1px;
            right: -1px;
            z-index: 101;

            max-height: 0;

            &.selectListVisible {
                background: white;

                max-height: 180px;

                padding: 8px 0;

                border: 1px solid #e6e6e6;
                border-radius: 4px;
                /** allow option list to be scrollable */
                overflow-y: scroll;

                @media (--viewportMedium) {
                    max-height: 194px;
                }

                &.searchEnabled,
                &.dropdownEnabled {
                    max-height: 244px;

                    @media (--viewportMedium) {
                        max-height: 274px;
                    }
                }
            }

            &.dropdownEnabled {
                background: white;
                padding: 8px 0;
                border: 1px solid #e6e6e6;
                border-radius: 4px;
                overflow: scroll;
            }
        }
    }
}

input[class*='FieldSelect_select_'],
.input {
    position: relative;
    color: #1a2b49;
    cursor: pointer;
    text-align: left;
    border-radius: 0;
    border: none;

    height: 62px;
    padding-top: 0;

    & ~ code[datatype='border'] {
        content: '';
        position: absolute;
        height: 2px;
        bottom: 0px;
        left: 0;
        right: 0;
        background-color: var(--matterColorAnti);
    }

    & + label {
        top: 0px;
        font-size: 14px;
        font-weight: 400;

        display: none;
    }

    &.inputFocused {
        & ~ code[datatype='border'] {
            background-color: var(--marketplaceColor);
        }
    }
}

.purpleBorderFocusedInput {
    border-bottom-color: var(--marketplaceColor) !important;
    & ~ svg {
        transform: rotate(-90deg) !important;
    }
}

input[class*='FieldSelect_selectSuccess_'],
.selectSuccess {
    padding-top: 14px;

    &:hover {
        cursor: pointer;
    }

    & ~ code[datatype='border'] {
        background-color: var(--successColor);
    }

    &.inputFocused {
        @apply --inputFocused;
    }
}

.wrapper {
    position: relative;

    border-radius: 4px 4px 0 0;
    border: 1px solid var(--matterColorNegative);
    border-bottom: none;

    &.topPosOpened {
        border-radius: 0;
    }

    & input {
        box-sizing: border-box;
        height: 56px;
        border-bottom: 2px solid var(--marketplaceColorDarkMedium);
        color: var(--marketplaceColorDarkMedium);
        padding: 16px 26px 16px 16px;

        background-color: transparent !important;

        @media (--viewportMedium) {
            height: 62px;
        }

        & ~ svg {
            /** arrow icon */
            position: absolute;
            top: 22px;
            right: 18px;

            pointer-events: none;

            transform: rotate(90deg);
            transition: 0.35s;

            & path {
                stroke: #1a2b49;
                fill: #1a2b49;
            }
        }

        &::placeholder {
            color: transparent;
        }

        &.inputFocused {
            padding: var(--focusedPadding);
            @apply --inputFocused;

            /** arrow icon */
            & ~ svg {
                transform: rotate(-90deg);
            }
        }
    }
}

input[class*='FieldSelect_selectSuccess_'],
.selectSuccess {
    @apply --inputSuccess;

    &.inputFocused {
        @apply --inputFocused;
    }
}

input[class*='FieldSelect_selectError_'],
.selectError {
    @apply --inputError;
}

.fieldset {
    @apply --fieldset;
    color: #b2b2b2;

    @media (--viewportSmallMax) {
        @apply --fontSizeSM;
    }
}

.fieldsetVisible {
    @apply --fieldsetVisible;
    color: #1e9f55;
}

.focused {
    color: var(--marketplaceColor) !important;
}

input.filledInput {
    padding: var(--focusedPadding);
}

.option {
    padding: 8px 16px;
    margin: 0;

    &:hover {
        cursor: pointer;
        background: #f2f2f2;
    }
}

.optionSelected {
    color: #8f2593;
    &:hover {
        background: #ffffff;
    }
}

.optionDisabled {
    color: #b2b2b2;
    cursor: not-allowed !important;

    &:hover {
        cursor: not-allowed !important;
        background: #ffffff;
    }
}

.optionSegregation {
    color: #767676 !important;
    font-weight: 600 !important;
}

input.empty,
input.disabled {
    color: #b2b2b2;
    border-bottom-color: var(--matterColorDark);
}

input.disabled {
    border-bottom-color: #b2b2b2;

    &:hover {
        cursor: not-allowed;
        border-bottom-color: #b2b2b2;
    }

    & + .fieldset {
        color: #b2b2b2 !important;
    }
}

input.disabled ~ svg path {
    stroke: #b2b2b2;
    fill: #b2b2b2;
}

.top {
    top: initial;
    bottom: calc(100% + 1px);
    border-bottom: none;
    border-radius: 4px 4px 0 0;
}

.down {
    top: 100%;
    border-top: none;
    border-radius: 0 0 4px 4px;
}

input:-internal-autofill-selected {
    padding: var(--focusedPadding);

    & + .fieldset {
        @apply --fieldsetVisible;
    }
}

.optionList {
    overflow-x: hidden !important;
    @apply --scrollBarStyled;
}

.searchWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    margin: 8px 16px;

    border-radius: 4px 4px 0px 0px;
    border: 1px solid #e6e6e6;
    border-bottom: 2px solid var(--marketplaceColorDarkMedium);
    /* input inside search bar - searchEnabled */
    & input {
        border-bottom: none;

        color: #1a2b49;
        height: 56px;
        caret-color: #1a2b49;

        &,
        &:focus {
            padding: 0 0 0 56px;
        }

        &::placeholder {
            color: #b2b2b2;
        }

        @media (--viewportMedium) {
            height: 62px;
        }
    }

    & > svg {
        position: absolute;
        top: 17px;
        left: 16px;

        @media (--viewportMedium) {
            top: 20px;
        }
    }
}
