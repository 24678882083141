@import '../../../marketplace.css';

:root {
    --holder {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 160px;
    }
}

.loadingHolder {
    @apply --holder;
}

.errorHolder {
    @apply --holder;
}
