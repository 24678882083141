@import '../../../marketplace.css';

.modalContainer {
    border: none;
}

.modalContent {
    display: flex;
    flex-direction: column;

    height: 100%;

    & svg {
        margin-bottom: 16px;
        flex-shrink: 0;
    }

    & h3 {
        margin-top: 0;
    }

    & p {
        margin-bottom: auto;

        @media (--viewportMedium) {
            margin-bottom: 40px;
        }
    }
}
