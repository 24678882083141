@import '../../styles-account.css';

.message {
    line-height: 118.6%;
    padding-right: 72px;
}

.dataProtectionInfo {
    @apply --paragraphAntiSM;

    margin-bottom: 16px;
    margin-top: auto;

    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;

    @media (--viewportMedium) {
        margin-top: 32px;
    }

    & > svg {
        width: 18px;
        height: 18px;

        & path {
            fill: #b2b2b2;
        }
    }
}

.contactSupport {
    margin-top: 24px;
    margin-bottom: 32px;

    @apply --fontSizeSM;

    text-align: center;

    @media (--viewportMedium) {
        @apply --fontSizeMM;
    }

    & > span {
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
        color: #8f2593;
    }
}
