@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  margin-bottom: 33px;
  /* tx is accepted */
  margin-top: -16px;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 32px;
  padding-top: 16px;
  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;

  @media (--viewportMedium) {
    border-top: 1px solid #e6e6e6;
  }
}

.priceBreakdownContainer {
  padding: 0;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.timeSlotsError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.feeInfo {
  @apply --marketplaceTinyFontStyles;
  flex-shrink: 0;
  margin-bottom: 16px;
  color: var(--matterColorAnti);
  padding: 0;
  text-align: center;
  display: block;
  margin-top: 0;
}

.submitButtonWrapper {
  flex-shrink: 0;
  /* padding: 0 24px 24px 24px; */
  width: 100%;
  padding: 0;
  margin-top: auto;

  @media (--viewportMedium) {
    width: 100%;
  }

  & button {
    background-color: var(--marketplaceColor);
  }
}
.paymentIconList {
  display: flex;
  justify-content: center;
  margin-top: 16.64px !important;
  margin-bottom: 25.33px;
  gap: 5.75px !important;

  & svg {
    height: 20.02px;
    width: 27.75px;
    margin-right: 0 !important;
    fill: none;
  }

  @media (--viewportLarge) {
    justify-content: center;
    margin-top: 24px !important;
    margin-bottom: 0;
    gap: 7.61px !important;
    & svg {
      width: 36.74px;
      height: 26.51px;
      fill: none;
    }
  }
}
