@import '../../../../marketplace.css';

.root {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 90;
    width: 100%;

    background-color: transparent;
}

.viewListingWrapper {
    width: 100%;
    padding-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.namedLink {
    text-decoration: none !important;
    display: flex;
    align-items: center;
    column-gap: 8px;

    & path {
        stroke: #1a2b49;
    }
}

.navWrapper {
    display: flex;
    width: 100%;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-top: 1px solid #e6e6e6;
}

.innerWrapper {
    width: 100%;
    max-width: 480px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    row-gap: 11px;

    @media (--viewportMedium) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

.rowDirection {
    flex-direction: row;
    justify-content: space-between;
}

.fullBtn {
    width: 100%;

    @media (--viewportMedium) {
        width: unset;
    }
}

.halfBtn {
    width: unset;
}

.backBtn {
    color: #8f2593;
    @apply --fontSizeMM;

    font-weight: 600;
    text-decoration-line: underline;

    width: 100%;

    @media (--viewportMedium) {
        width: unset;
    }
}

.nextBtn {
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;

    column-gap: 8px;

    border-radius: 4px;
    background: #8f2593;
    @apply --fontSizeMM;

    color: #fff;
    font-weight: 600;

    width: 100%;
    min-width: 108px;

    @media (--viewportMedium) {
        width: unset;
        padding: 18px 16px;
    }
}

.nextBtn:disabled > svg > path {
    stroke: #b2b2b2;
    fill: #b2b2b2;
}

.backToListingBtn {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 18px 16px;

    border-radius: 4px;
    border: 1px solid #e6e6e6;

    @apply --fontSizeMM;

    color: #1a2b49;
    font-weight: 600;

    width: 100%;
    &,
    &:hover {
        background: #fff;
    }

    &:disabled {
        color: #b2b2b2;

        &,
        &:hover {
            background-color: #e6e6e6;
        }
    }

    @media (--viewportMedium) {
        width: unset;
    }
}
