@import '../../marketplace.css';

:root {
    /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

    --DateInput_selectionHeight: 36px;
    --DateInput_hoveredOverlayColor: rgba(255, 255, 255, 0.2);

    /* Distance to top */
    --DateInput_top: 252px;
}

.wrapper {
    position: relative;
}

.inputRoot {
    position: relative;
    border-bottom: unset;

    /* Contain repainting to this component only */
    /* this one has been recently commented out because it over rides the other inputs in the creation of the listing process */
    /* transform: translate3d(0, 0, 0); */

    /* Override react-dates default styles to match input styles */

    & :global(.SingleDatePicker) {
        display: block;
    }

    & :global(.SingleDatePicker_picker__directionLeft) {
        /* !important is added to top because react-dates uses inline style for height */
        /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
        width: 100%;
        /* min-height   : calc(100vh - var(--DateInput_top)); */
        background-color: white;
        z-index: 200;

        @media (--viewportMedium) {
            /* !important is added to top because react-dates uses inline style for height */
            /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
            top: 59px !important;
            min-height: auto;
            box-shadow: 0px 10px 15px -3px #0000000d;
            border-radius: 0 0 2px 2px;
            width: 0px;
        }
    }

    & :global(.DateInput_input) {
        transition: border-bottom-color var(--transitionStyle);
        transition: all 0.15s ease-out;
        background: none;
        color: var(--matterColor);
    }

    & :global(.SingleDatePickerInput) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border: none;
        background: none;

        & input {
            padding: 22px 16px 10px 16px;

            border-radius: 4px 4px 0px 0px;
            border-top: 1px solid var(--ground-ground-010, #e6e6e6);
            border-right: 1px solid var(--ground-ground-010, #e6e6e6);
            border-left: 1px solid var(--ground-ground-010, #e6e6e6);
            border-bottom: 2px solid #1a2b49;
            color: var(--matterColor);

            &:disabled {
                border-bottom: 2px solid #b2b2b2;
            }
            /*     label used instead        */
            &::placeholder {
                color: transparent;
            }
        }
    }

    & :global(.DayPicker__horizontal) {
        margin: 0 auto;
        background-color: white;
        /* box-shadow: none; */
        box-shadow: 0px 10px 15px -3px #0000000d;
    }

    & :global(.DayPickerNavigation__horizontal) {
        position: relative;
        width: 100%;
    }

    & :global(.DayPickerNavigation_button__horizontal) {
        padding: 6px 9px;
        top: 19px;
        position: absolute;
        cursor: pointer;
        display: inline;

        &:first-of-type {
            left: 24px;
        }

        &:last-of-type {
            right: 24px;
        }
    }

    & :global(.DayPickerNavigation_button) {
        color: #1a1a1a;
        border: 0;
    }

    & :global(.CalendarMonth),
    & :global(.CalendarMonthGrid) {
        background-color: transparent;
    }

    & :global(.DateInput) {
        display: block;
        width: 100%;
    }

    & :global(.DateInput__disabled) {
        background: #fff;
        color: #b2b2b2;
    }

    & :global(.DayPicker_weekHeader) {
        color: #1a1a1a;
        top: 57px;
    }

    & :global(.DayPicker_weekHeader_li) {
        font-weight: 400;
    }

    & :global(.CalendarMonth_caption) {
        color: #1a1a1a;
        @apply --marketplaceH2FontStyles;
        margin: 1px 0 14px;
        font-weight: 400;
        line-height: 38px;

        @media (--viewportMedium) {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    & :global(.CalendarDay__default) {
        background-color: white;
        border: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }

    & :global(.CalendarDay) {
        @apply --marketplaceH4FontStyles;
        color: var(--marketplaceColorDark);
        border: 0;
        margin-top: 0;
        margin-bottom: 0;

        @media (--viewportMedium) {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    /* Add an underline for '.renderedDay' */
    & :global(.CalendarDay__today .renderedDay) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: var(--DateInput_selectionHeight);
        background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23FFF' fill-rule='evenodd'/></svg>");
        background-position: center 28px;
    }

    /* Remove default bg-color and use our extra span instead '.renderedDay' */
    & :global(.CalendarDay__hovered_span),
    & :global(.CalendarDay__selected_span) {
        background-image: transparent;
        background-color: transparent;
    }

    & :global(.CalendarDay__hovered_span .renderedDay) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: var(--DateInput_selectionHeight);
        background-color: var(--DateInput_hoveredOverlayColor);
    }

    & :global(.CalendarDay__selected_span .renderedDay) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: var(--DateInput_selectionHeight);
        background-color: var(--marketplaceColor);
        color: white;
        transition: all 0.2s ease-out;
    }

    /* Remove default bg-color and use our extra span instead '.renderedDay' */
    & :global(.CalendarDay__selected) {
        background-color: transparent;
        background-image: none;
    }

    & :global(.CalendarDay__selected .renderedDay) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: var(--DateInput_selectionHeight);
        background-color: var(--marketplaceColor);
        color: white;
        border-radius: calc(var(--DateInput_selectionHeight) / 2);
    }

    /* Remove default bg-color and use our extra span instead '.renderedDay' */
    & :global(.CalendarDay__after-hovered) {
        background-color: transparent;
    }

    & :global(.CalendarDay__after-hovered .renderedDay) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: var(--DateInput_selectionHeight);
        background-color: var(--DateInput_hoveredOverlayColor);
    }

    & :global(.CalendarDay:hover .renderedDay) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: var(--DateInput_selectionHeight);
        background-color: #f9f4fa;
        border-radius: 50%;
        color: var(--marketplaceColorDark);
    }

    /* Remove default bg-color and use our extra span instead '.renderedDay' */
    & :global(.CalendarDay__blocked_out_of_range),
    & :global(.CalendarDay__blocked_out_of_range:active),
    & :global(.CalendarDay__blocked_out_of_range:hover) {
        background-color: transparent;
        color: #b2b2b2;
        border: 0;
    }

    /* Remove default bg-color and use our extra span instead '.renderedDay' */
    & :global(.CalendarDay__blocked_calendar),
    & :global(.CalendarDay__blocked_calendar:active),
    & :global(.CalendarDay__blocked_calendar:hover) {
        background-color: transparent;
        color: #b2b2b2;
        border: 0;
    }

    & :global(.CalendarDay__blocked_out_of_range .CalendarDay__blocked_calendar .renderedDay) {
        background-color: transparent;
    }

    & :global(.DateInput_fang) {
        display: none;
    }

    & :global(.CalendarMonth_caption) {
        text-transform: capitalize;
    }
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.withMobileMargins {
    & :global(.SingleDatePickerInput) {
        width: calc(100vw - 48px);

        @media (--viewportMedium) {
            width: 100%;
            margin: 0;
        }
    }

    & :global(.SingleDatePicker_picker__directionLeft) {
        /* !important is added to top because react-dates uses inline style for height */
        /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
        top: 34px !important;
        border-top: 2px solid var(--matterColorDark);
        min-height: calc(100vh - var(--DateInput_top) + 2px);

        @media (--viewportMedium) {
            /* !important is added to top because react-dates uses inline style for height */
            /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
            top: 40px !important;
            border-top: 0;
            min-height: auto;
        }
    }

    /* Create gutter between inputs */
    & :global(.DateInput) {
        width: 100%;
        background: none;
    }
}

.rootNextMonthIcon,
.rootPreviousMonthIcon {
    stroke: var(--marketplaceColorDark);
    fill: var(--marketplaceColorDark) !important;
}

.fieldset {
    @apply --fieldset;
    z-index: 1;

    @media (--viewportSmallMax) {
        @apply --fontSizeSM;
    }
}
.fieldsetVisible {
    @apply --fieldsetVisible;
}

.inputSuccess {
    @apply --inputSuccess;

    /** crossed icon */
    & ~ .IconCloseCircleL {
        transform: scale(0);
    }
}

.inputFocused {
    @apply --inputFocused;

    & ~ .IconCloseCircleL {
        transform: scale(1) !important;
    }
}

.inputError {
    @apply --inputError;
}

.IconCloseCircleL {
    transition: 0s;
    transition-delay: 0.25s;

    position: absolute;
    right: 16px;
    top: calc(50% - 12px);

    &:hover {
        cursor: pointer;
    }
}
