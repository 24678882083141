@import '../common.css';

.gridItem:last-of-type p {
    margin-bottom: 0;
}

.gridItem {
    h3 {
        margin: 0 0 16px 0;

        @media (--viewportMedium) {
            margin: 0 0 8px 0;
        }
    }
}

.imageHolder {
    width: 100%px;
    height: 312px;

    @media (--viewportMedium) {
        width: 345px;
        height: 345px;
    }

    background-position: center;
    background-size: contain;
}

.locationHolder {
    margin: 32px 0;

    @media (--viewportMedium) {
        margin: 64px 0;
    }

    & h2 {
        @apply --fontSizeXL;
        font-weight: 700;

        @media (--viewportMedium) {
            @apply --fontSizeXXXL;
        }
    }
}

.locationGrid {
    display: flex;
    flex-wrap: wrap;

    margin-top: 64px;

    row-gap: 24px;
    column-gap: 24px;

    & > div {
        border-radius: 16px;

        background-position: center;
        background-size: cover;
        height: 365px;

        @media (--viewportMedium) {
            width: calc(50% - 16px);
        }

        &:first-of-type {
            width: 100%;
        }
    }

    @media (--viewportSmallMax) {
        margin-top: 32px;

        & > div {
            &,
            &:first-of-type {
                width: 100%;
            }
        }
    }
}
