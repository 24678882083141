@import '../../marketplace.css';

.infoBlock {
    max-width: 1150px;
    display: flex;
    column-gap: 32px;

    @media (--viewportSmallMax) {
        flex-direction: column;
        row-gap: 32px;
    }
}

.infoItem {
    & img,
    & svg {
        @media (--viewportSmallMax) {
            width: 48px;
            height: 48px;
        }
        & path {
            fill: var(--marketplaceColor);
        }
    }

    & h2 {
        margin: 16px 0 8px 0;
        @apply --fontSizeXL;
        font-weight: 700;
        white-space: break-spaces;
    }

    & p {
        margin: 0;
    }
}

.container {
    max-width: 1150px;

    margin: 0 auto;
    padding: 24px 24px 40px 24px;

    @media (--viewportMedium) {
        padding: 64px 24px 80px 24px;
    }
}

.content {
    max-width: calc(100vw - 48px);
    margin: 0 auto;
    margin-bottom: 64px;

    @media (--viewportMedium) {
        max-width: 480px;
    }
}

.heading {
    color: var(--marketplaceColor);
    @apply --fontSizeXL;
    font-weight: 700;
    text-align: center;

    margin: 0 0 16px 0;

    @media (--viewportSmallMax) {
        @apply --fontSizeSM;
        font-weight: 700;
    }
}

.subHeading {
    @apply --fontSizeXXXL;
    text-align: center;
    white-space: break-spaces;

    @media (--viewportSmallMax) {
        @apply --fontSizeXL;
        font-weight: 700;
    }

    margin: 0 0 32px 0;
}

.description {
    text-align: center;
}

.copyReferralLink {
    margin-bottom: 32px;
}
