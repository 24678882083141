@import '../common.css';

.root {
  @apply --sectionRoot;
}

.title {
  @apply --sectionTitle;
  padding-bottom: 32px;
}

.matchingContainer {
  @apply --matchingContainer;
}

.mobility {
  display: flex;
  align-items: start;
  margin-bottom: 16px;

  & p {
    margin-bottom: 0;
  }

  & > svg {
    margin-right: 8px;
  }

  @media (--viewportMedium) {
    align-items: center;
    margin-bottom: 24px;
  }
}

.disabled {
  color: #b2b2b2;
  text-decoration-line: line-through;
}

.flexWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: center;
    row-gap: 0;
  }
}

.flexItem {
  width: 100%;

  @media (--viewportMedium) {
    width: calc(100% / 3);
  }
}

.subTitle {
  @apply --sectionSubTitle;

  & + p {
    margin-bottom: 0;
  }
}

.selectedIcon {
  & path {
    stroke: #8f2593;
  }
}

.notSelectedIcon {
  & path {
    stroke: #b2b2b2;
  }
}

.error {
  color: var(--failColor);
}

.calculateTimeAction {
  color: #8f2593;

  &:hover {
    cursor: pointer;
  }
}

.containerDiv {
  height: 276px;

  @media (--viewportMedium) {
    height: 350px;
  }
}

.mapDiv {
  height: 100%;

  border-radius: 8px;

  @media (--viewportMedium) {
    border-radius: 4px;
  }
}
