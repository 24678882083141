@import '../../marketplace.css';


.couponWrapper {
    display: block;
}
.couponHeading {
    @apply --heading;

    margin-bottom: 16px;
    margin-top: 16px;
  }
  .couponContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    & input {
      font-size: 18px;
      line-height: 27px;
      border-bottom-color: var(--matterColorAnti)
    }

    & > * {
      width: 100%;
    }

    @media (--viewportMedium) {
      flex-wrap: nowrap;
    }
  }
  .couponContainerError {
    & input {
      color: var(--failColor);
      border-bottom-color: var(--failColor);
    }
  }
  .couponContainerSuccess {
    & input {
      color: var(--successColor);
      border-bottom-color: var(--successColor);
    }
  }
  .couponButton {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    flex-shrink: 0;
    height: 40px;
    min-height: 40px;
    margin-left: 0;
    margin-top: 13px;

    &:hover {
      cursor: pointer;
    }

    @media (--viewportMedium) {
      width: 181px;
      margin-left: 16px;
      margin-top: 0;
    }
    &:disabled {
      color: var(--matterColorAnti);
    }
  }
  .couponStatus {
    margin-top: 9px;
    font-size: 14px;
    line-height: 20px;
  }
  .couponError {
    color: var(--failColor);
  }
  .couponSuccess {
    color: var(--successColor);
  }
