@import '../../../../marketplace.css';

.root {
    display: none;
    /* is set to viewportSmallMax not to overlap with zendesk widget */
    @media (--viewportSmallMax) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;

        @apply --flex-centered;
        justify-content: space-evenly;
        column-gap: 8px;

        background-color: #fff;
        padding: 8px;
    }

    &.loggedOut {
        justify-content: center;
    }
}

.item {
    position: relative;
    text-align: center;
    color: var(--matterColor);
    max-width: 75px;
    flex: 1;

    &.currentPage {
        color: var(--marketplaceColor);

        & path {
            stroke: var(--marketplaceColor);
        }

        &:hover {
            cursor: auto;
        }
    }

    & svg {
        width: 24px;
        height: 24px;
    }

    & p {
        margin: 0;
        @apply --fontSizeXS;
        font-size: 12px;
    }

    &:hover {
        cursor: pointer;
        text-decoration: none;
    }

    &.wp {
        /* wisepops notification holder */
        & > div {
            top: 3px !important;
            right: 25px !important;

            width: 7px !important;
            height: 7px !important;

            background-color: #ff0000 !important;

            & > span {
                display: none !important;
            }
        }
    }
}

.overlay {
    @apply --flex-centered;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    & ~ * {
        opacity: 0.35;
    }
}

.notificationDot {
    @apply --notificationDot;
    top: 3px;
    right: 32%;
}
