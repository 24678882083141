@import '../../common.css';

.logo {
    @apply --offer_logo;

    margin-bottom: 24px;
    flex-shrink: 0;
}

.modalContainer {
    overflow: hidden;
}

.modalContent {
    overflow: scroll;
    max-height: 780px;

    display: flex;
    flex-direction: column;
    row-gap: 24px;

    & > * {
        margin: 0;
    }

    & > h3 {
        @apply --fontSizeXL;
        font-weight: 700;
    }
}

.voucherCodeSidenote {
    @apply --paragraphAntiSM;
    margin: 0;
}

.accordeonItem {
    padding-top: 0;

    & h3 {
        @apply --fontSizeXL;
        font-weight: 700;
    }
}

.separator {
    height: 0.5px;
    background-color: #e6e6e6;

    flex-shrink: 0;
    width: 100%;
}
