@import '../../marketplace.css';

.topbar {
    z-index: 1; /* ensure that Topbar shadow overlays content background */
}

.mobileTopbar {
    /* Size */
    width: 100%;
    height: var(--topbarHeight);

    /* Layout for child components */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    /* fill */
    background-color: var(--matterColorLight);

    @media (--viewportLarge) {
        display: none;
    }
}

.desktopTopbar,
.mobileTopbar {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

    @media (--viewportLarge) {
        box-shadow: var(--boxShadowLight);
    }
}

.error {
    color: var(--failColor);
    margin: 0 0 16px 0;
}

.outerWrapper {
    background: unset;
}

.rootFooter {
    z-index: 56;
}

.noResults {
    margin-bottom: 0;
    margin-top: 0;

    @media (--viewportMedium) {
        margin: 0;
    }

    & h3 {
        margin-top: 0;
    }

    & form {
        background-color: white;
    }
}
