@import '../../marketplace.css';

.content {
    @apply --marketplaceModalBaseStyles;
    @apply (--marketplaceModalResetStyles);
    margin-top: unset;
}

.error {
    @apply --marketplaceModalErrorStyles;
    & a {
        color: inherit;
        text-decoration: underline;
    }
}

.subHeading {
    @apply --subHeading;
}

.backLink {
    color: #1a2b49;
    &:hover {
        cursor: pointer;
    }
}

/* ================ Social logins & SSO ================ */
.confirmInfoText {
    @apply --marketplaceH4FontStyles;
    margin: 16px 0;
}

.widgetHolder {
    max-width: 1000px;
    width: 100vw;
    margin: 32px auto 0 auto;

    & > div:nth-of-type(1),
    & > div:nth-of-type(2) {
        display: none;
    }
}

.signupWithIdpTitle {
    @apply --marketplaceModalTitleStyles;
    margin: 0;
    padding-top: 0;

    color: var(--matterColorDark);
}

.form {
    /* We don't want the form to take the whole space so that on mobile view
    the social login buttons will be after the sign up button
    and not in the bottom of the page */
    @media (--viewportMedium) {
        padding-top: 2px;
    }
}
