@import '../../marketplace.css';

.statusItem {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;

  color: var(--matterColorAnti);
  
  & svg {
    width: 14px;
    height: auto;
    margin-bottom: 5px;
    fill: var(--matterColorAnti);
    margin-left: 21px;
    transform: scale(.85);
  }

}

.statusItemActive {
  color: var(--matterColor);
}

.statusItemFinished {
  color: var(--marketplaceColor);
}