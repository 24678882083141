@import '../../marketplace.css';

.root {
    & p {
        @apply --marketplaceH4FontStyles;
    }
    & h2 {
        /* Adjust heading margins to work with the reduced body font size */
        margin: 0;
        scroll-margin-top: 80px;
    }

    & .lastUpdated {
        @apply --marketplaceBodyFontStyles;
        margin-top: 0;
        margin-bottom: 55px;

        @media (--viewportMedium) {
            margin-top: 0;
            margin-bottom: 54px;
        }
    }
    & li {
        font-size: 16px !important;
    }
    & ul {
        list-style: unset !important;
        padding-left: 30px !important;
    }
    & ul ul {
        list-style: circle !important;
    }
    & .tbox {
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        margin-top: 24px;
    }
    & .tbox p {
        padding: 10px;
    }

    & .accordionItem {
        &:last-of-type {
            border-bottom: unset;
        }
    }
}
