@import '../../common.css';

.heading {
    @media (--viewportLarge) {
        white-space: break-spaces;
    }
}

.content {
    max-width: 760px;
    text-align: center;
    margin: 64px auto 0 auto;
}
