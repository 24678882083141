@import '../../marketplace.css';

:root {
}

.root {
}

.autocomplete {
    position: relative;

    & input {
        & ~ section {
            transition: 0s;
            transition-delay: 0.25s;

            position: absolute;
            transform: scaleY(0);

            max-height: 172px;
            overflow: auto;

            background: white;

            left: 0;
            right: 0;
            border: 1px solid var(--matterColorNegative);
            z-index: 101;

            @apply --scrollBarStyled;
            border-radius: 0 0 4px 4px;
        }
    }

    & input:focus {
        & ~ section {
            transform: scaleY(1);
        }
    }
}

.wrapper {
    position: relative;

    border-radius: 4px 4px 0 0;
    border: 1px solid var(--matterColorNegative);
    border-bottom: none;

    & > svg {
        @apply --inputRemoveValueIcon;

        right: 17px;
        top: calc(50% - 12px);

        & path {
            fill: var(--matterColorAnti);
        }
    }

    & input,
    & textarea {
        box-sizing: border-box;
        height: 56px;

        /* max-height: 188px; */
        overflow: auto;

        border-bottom: 2px solid var(--marketplaceColorDarkMedium);
        color: var(--marketplaceColorDarkMedium);
        padding: 16px 26px 16px 16px;

        background-color: transparent !important;
        border-radius: 0;

        @media (--viewportMedium) {
            height: 62px;
        }

        &::placeholder {
            color: transparent;
        }

        &:focus {
            padding: var(--textareaFocusPadding);
            @apply --inputFocused;
            /** crossed icon */
            & ~ svg {
                transform: scale(1);
            }

            & ~ .searchFieldComponent {
                & path {
                    fill: var(--marketplaceColor);
                }
            }
        }
    }

    & textarea {
        height: 96px;
    }
}

input[class*='FieldTextInput_input_'],
.input {
    border-bottom-color: var(--marketplaceColorDarkMedium);
}

.textarea {
    /* 4 rows */
    min-height: 131px;

    @media (--viewportMedium) {
        /* 6 rows */
        min-height: 185px;
    }
}

input[class*='FieldTextInput_inputSuccess_'],
input.inputSuccess,
.textarea.inputSuccess {
    @apply --inputSuccess;

    & ~ .searchFieldComponent {
        & path {
            fill: var(--successColor);
        }
    }

    /** crossed icon */
    & ~ svg {
        transform: scale(0);
    }
}

.textarea.inputDefault {
    border-bottom-color: #1a2b49;

    & + .fieldset {
        color: #b2b2b2;
    }
}

input.inputError,
.textarea.inputError {
    @apply --inputError;

    /** crossed icon */
    & ~ svg {
        transform: scale(1);
    }

    & ~ .searchFieldComponent {
        & path {
            fill: var(--failColor);
        }
    }

    @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */

        & + .fieldset {
            @apply --fieldsetVisible;
            color: var(--failColor);
        }
        padding: var(--textareaFocusPadding);
    }

    @supports not (-webkit-touch-callout: none) {
        /* CSS for other than iOS devices */

        & + .fieldset {
            @apply --fieldsetVisible;
            color: var(--failColor);
        }
        padding: var(--textareaFocusPadding);
    }
}

.textarea.disabled,
input.disabled {
    border-bottom-color: var(--matterColorAnti);
    color: #b2b2b2;

    & + .fieldset {
        color: var(--matterColorAnti);
    }
    &:focus,
    &:hover {
        border-bottom-color: var(--matterColorAnti);
    }
    cursor: not-allowed;
}

input.filledInput,
textarea.filledInput {
    padding: var(--textareaFocusPadding);
}

.fieldset {
    @apply --fieldset;

    @media (--viewportSmallMax) {
        @apply --fontSizeSM;
    }
}
.fieldsetVisible {
    @apply --fieldsetVisible;
}

.FieldTextInput_input_coupon {
    border-bottom-color: var(--matterColorAnti);
}

.searchFieldComponentHolder {
    padding-left: 52px !important;
    left: 0;
}
.IconCloseCircleL {
    transition: 0s;
    transition-delay: 0.25s;
}

input:-internal-autofill-selected {
    padding: var(--textareaFocusPadding);

    & + .fieldset {
        @apply --fieldsetVisible;
    }
}

.restrictionsItem {
    text-align: right;
    margin-top: 8px;

    @apply --paragraphAntiSM;
    @apply --fontSizeXS;
}

.searchFieldComponent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 16px;
    display: flex;
    align-items: center;

    & + section {
        border-radius: 0 0 4px 4px;
    }
}

.autocompleteDefaultItem {
    @apply --autocompleteDefaultItem;
}
