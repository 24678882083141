@import '../../../marketplace.css';

.root {
    @apply --fontSizeXS;
}

p.createdAt {
    display: flex;
    align-items: center;

    @apply --paragraphAntiSM;

    margin: 0;

    & > svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
}

.createdAtIcon {
    & path {
        fill: #b2b2b2;
    }
}

.actionSection {
    margin: 16px 0 0 0;
}

.link {
    color: #8f2593;
    display: inline;
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.matchingScore {
    margin-top: 16px;
    margin-bottom: 8px;

    font-weight: 600;
}

.matchingInsufficient {
    background: #fff0f0;

    & > mark {
        background: #ff0000;
    }
}

.matchingSufficient {
    background: #fff7f0;

    & > mark {
        background: #ffaa00;
    }
}

.matchingTotal {
    background: #f0fff6;

    & > mark {
        background: #1e9f55;
    }
}

.progressBar {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 16px;

    & > mark {
        display: block;
        height: 100%;
        width: 0;
        border-radius: 4px;
    }
}
