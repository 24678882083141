@import '../../../marketplace.css';

.checklistDataProtectedMsg {
    color: #b2b2b2;

    @apply --fontSizeSM;

    display: flex;
    align-items: center;

    @media (--viewportMedium) {
        @apply --fontSizeMM;
    }

    & svg {
        width: 24px;
        height: 24px;

        & path {
            stroke: #b2b2b2;
        }
    }
}

p.checklistTasks {
    text-align: center;

    &:hover {
        cursor: pointer;
    }

    @apply --fontSizeSM;
    font-weight: 600;

    & svg {
        margin-left: 8px;
        margin-bottom: 5px;

        transition: 0.25s;

        & path {
            stroke: #1a2b49;
            fill: #1a2b49;

            stroke-width: 0.5px;
        }
    }
}

.closed {
    transform: rotate(90deg);
}

.open {
    transform: rotate(-90deg);
}

.checklistItem {
    display: flex;
    align-items: center;

    &:hover:not(.completed) {
        cursor: pointer;
        text-decoration: underline;
    }

    &.completed {
        color: #b2b2b2;
    }

    & svg {
        width: 24px;
        height: 24px;
        margin-left: 4px;
        margin-right: 12px;
        flex-shrink: 0;
    }
}

.placeholder {
    flex-shrink: 0;
    display: inline-block;
    width: 24px;
    height: 24px;

    border: 1px solid #b2b2b2;
    border-radius: 50%;

    margin: 4px;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 12px;
}

.transparentCircle {
    stroke-width: 17;
    stroke: #f9f9f9;
}

.progressCircle {
    stroke-width: 17;
    stroke: #8f2593;
}

.checklistHolder {
    position: relative;

    max-width: 340px;
    max-height: 210px;

    margin: 0 auto;
    overflow: hidden;

    & > svg {
        max-width: 100%;
    }

    & > div {
        font-size: 48px;
        line-height: 64px;
        font-weight: 700;
        color: #8f2593;
        letter-spacing: -0.5px;

        position: absolute;

        left: calc(50% - 41px);
        top: 35%;
    }

    & > p {
        position: absolute;
        top: calc(50% + 70px);

        color: #b2b2b2;

        @media (--viewportMiniSmallMax) {
            top: calc(50% + 60px);
        }

        @apply --fontSizeMM;

        &:last-of-type {
            right: 0;
        }
    }
}

.alert {
    align-items: center;
    margin-bottom: 24px;
    & svg {
        width: 24px;
        height: 24px;
    }
}
