@import './assets/sanitize.css';
@import './marketplace.css';

/* ================ Fonts ================ */

body {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased; /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
    -moz-osx-font-smoothing: grayscale; /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
    text-rendering: optimizeSpeed;

    background-color: var(--matterColorBright);

    @apply --scrollBarStyled;
}

::selection {
    background: var(--marketplaceColor); /* WebKit/Blink Browsers */
    color: var(--matterColorLight);
}

::-moz-selection {
    background: var(--marketplaceColor); /* Gecko Browsers */
    color: var(--matterColorLight);
}

button {
    outline: none;
}

a {
    @apply --marketplaceLinkStyles;
}

h1 {
    @apply --marketplaceH1FontStyles;
}
h2 {
    @apply --marketplaceH2FontStyles;
}
h3 {
    @apply --marketplaceH3FontStyles;
}
h4 {
    @apply --marketplaceH4FontStyles;
}
h5 {
    @apply --marketplaceH5FontStyles;
}
h6 {
    @apply --marketplaceH6FontStyles;
}

input,
textarea,
select,
li {
    @apply --marketplaceDefaultFontStyles;
}

p,
pre {
    @apply --marketplaceBodyFontStyles;
}

p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;

    @media (--viewportMedium) {
        font-size: 18px;
        line-height: 28px;
    }
}

/* ================ Normalisations ================ */

html {
    @apply --marketplaceDefaultFontStyles;
    color: var(--matterColor);
    padding: 0;
    margin: 0;
}

ul {
    list-style: none;
    padding: 0;
}

legend,
label {
    @apply --marketplaceH5FontStyles;
    font-weight: var(--fontWeightMedium);
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;

    @media (--viewportMedium) {
        font-weight: var(--fontWeightMedium);
        margin-top: 0;
        margin-bottom: 0;
    }
}

button {
    font-family: Helvetica, Arial, sans-serif;
}

select {
    @apply --marketplaceSelectStyles;
}

input {
    @apply --marketplaceInputStyles;
}

textarea {
    @apply --marketplaceInputStyles;

    /* Todo: All textareas are using auto-sizing extension which currently calculates required space incorrectly when box-sixing is "border-box" */
    box-sizing: content-box;
    padding: 0 0 10px 0;

    &::placeholder {
        padding: 0 0 10px 0;
    }

    @media (--viewportMedium) {
        padding: 4px 0 10px 0;
    }
}

:global(.fontsLoaded) {
    & body {
        font-family: 'sofiapro', Helvetica, Arial, sans-serif;
    }
    & button {
        font-family: 'sofiapro', Helvetica, Arial, sans-serif;
    }
}

:global(.scrollingDisabled) {
    overflow: hidden;
    max-height: 100vh;
}

:global(.AppointmentScheduler_input_success) {
    border-bottom-color: var(--successColor);
}

:global(#confetti-canvas) {
    pointer-events: none !important;
}
