@import '../../marketplace.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  margin-top: 24px;
}

.bottomWrapper {
  margin-top: 32px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  margin-top: 24px;

  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.recoveryLink {
  @apply --marketplaceModalHelperLink;
  color: var(--marketplaceColor);
}

.recoveryLinkInfo {
  @apply --marketplaceModalHelperText;
}
