@import '../../marketplace.css';

:root {
    --flex-centered {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.root {
    width: 100%;
    height: auto;

    @apply --flex-centered;
    flex-direction: column;
}

.noTrackerRoot {
    padding: 24px 0 0 0;

    @media (--viewportMedium) {
        padding: 64px 0 0 0;
    }
}

.title {
    width: 100%;
    margin: 0 0 16px 0;
    align-self: flex-start;

    @apply --fontSizeXL;

    color: #1a2b49;
    font-weight: 700;

    @media (--viewportMedium) {
        align-self: unset;
        @apply --fontSizeXXL;
    }
}

.extraPackagesHeading,
.editPriceHeading {
    margin: 24px 0 24px 0;
    letter-spacing: -0.5px;
    color: var(--matterColorAnti);

    @media (--viewportMedium) {
        margin: 32px 0 24px 0;
    }
}

.extraPackagesHeading {
    margin: 12px 0 24px 0;
    color: #1a2b49;

    @media (--viewportMedium) {
        margin: 20px 0 24px 0;
    }
}

.error {
    color: var(--failColor);
}

.priceInput {
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 17px;
}

.hiddenPriceInput {
    display: none;
}

.currencyInput {
    margin: 9px 0 16px 0;

    & option[disabled] {
        color: var(--matterColorAnti) !important;
    }
}

.empty select {
    color: var(--matterColorAnti) !important;
}

.tooltipLink {
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
}

.termsOfUseContainer {
    margin-top: 48px;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 56px;
    }

    & label {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
}

.page1SubTitle {
    margin-bottom: 32px;
    width: 100%;

    text-align: left;
}

.nfwBtnContainer {
    display: flex;
    align-self: flex-end;

    margin: 16px 0 0 0;

    @media (--viewportMedium) {
        margin: 0 8px 0 16px;
        align-self: center;
    }
}

.nfwBtn {
    width: 78px;
    height: 40px;
    min-height: 40px;
    @apply --flex-centered;

    padding: 0px 16px;
    border-radius: 4px;
    background-color: #607d9f;

    transition: 0.35s;

    color: #fff;
    font-weight: 600;
    &:focus,
    &:hover {
        background-color: #485e78;
    }

    @apply --fontSizeXS;
}

.nfwCloseAction {
    @media (--viewportMedium) {
        display: block;
        position: static;
        margin-bottom: auto;
    }
}

.nfwMobileClose {
    display: block;
    cursor: pointer;

    @media (--viewportMedium) {
        display: none;
    }
}

.nfwModalBtn {
    margin-top: 0;
    width: calc(100% - 48px);
    position: fixed;
    bottom: 24px;
    left: 24px;

    @media (--viewportMedium) {
        margin-top: 40px;
        width: 100%;
        position: unset;
    }
}

.packagesWrapper {
    width: 100%;
}

.noPaddingCheckbox > label {
    padding: 0;
}

.namedLink {
    text-decoration: none !important;
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.modalContainer {
    background-color: white;
    border-radius: 8px;
    width: 100%;
}

.modalContainer > button {
    position: unset;
    margin-left: auto;
    margin-bottom: 16px;
    padding: 0;
    display: flex;
    align-items: center;

    @apply --fontSizeMM;
    color: #1a2b49;
    font-weight: 400;
}

.infoIcon {
    & path {
        fill: #607d9f;
    }
}

.notFlexibleWarningAlertRoot {
    margin-bottom: 24px;
    column-gap: 16px;
}
