@import '../../marketplace.css';

.root {
    position: relative;
    display: inline-block;
}

.label {
    @apply --marketplaceButtonStylesSecondary;
    @apply --marketplaceSearchFilterLabelFontStyles;
    @apply --marketplaceFilterLabel;
    padding: 9px 19px 10px 19px;

    &:focus {
        @apply --marketplaceFilterLabelFocused;
    }
}

.labelSelected {
    @apply --marketplaceButtonStyles;
    @apply --marketplaceSearchFilterLabelFontStyles;
    @apply --marketplaceFilterLabel;
    @apply --marketplaceFilterButtonStyle;

    white-space: nowrap;
    font-weight: var(--fontWeightRegular);

    &,
    &:hover,
    &:focus {
        background-color: #f9f4fa;
        color: #8f2593;
        border: 2px solid #8f2593;
    }
}
