.slider {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}

.wrapper {
  display: flex;
  align-items: center;
  max-width: 400px;
  width: 100%;
}

.line {
  background: #e6e6e6;
  height: 8px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  position: relative;
  align-items: center;
}

.fill {
  background: #8f2593;
  border-radius: 5px;
  align-self: stretch;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
}

.circle {
  width: 30px;
  height: 30px;
  margin-left: -15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transition-duration: 0.2s;
  transition-property: background-color, box-shadow;
  background-color: transparent;
}

.innerCircle {
  border-radius: 50%;
  background: white;
  border: 2px solid #8f2593;
  width: 20px;
  height: 20px;
  transform: scale(1);
  transition-duration: 0.1s;
  transition-property: transform;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 7px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px;
}

.focus {
  transform: scale(1.2);
}
