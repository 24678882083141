@import '../../marketplace.css';

.root {
    display: flex;
    justify-content: space-between;

    position: sticky;
    z-index: 6;

    top: 96px;

    &.scrolled {
        /* in order to avoid navbar + editProfile bars overflowing */
        top: 152px;
        /*
        * for riders, Navbar
        */
        &.actionBarEmailUnverifiedRider {
            top: 214px;

            @media (--viewportMediumMax) {
                top: 248px;
            }
        }
        /*
        * for owners, no Navbar
        */
        &.actionBarEmailUnverifiedOwner {
            top: 154px;

            @media (--viewportMediumMax) {
                top: 178px;
            }
        }
    }

    background: #1a2b49;
    color: white;

    padding: 16px 18px;
    margin: -40px 0 64px 0;

    border-radius: 4px;

    & p {
        display: flex;
        align-items: center;

        @apply --fontSizeSM;
        margin: 0;
    }

    @media (--viewportSmallMax) {
        margin: 16px 0 0 0;
        top: 80px;
    }

    & svg {
        & path {
            fill: white;
        }
    }

    & a {
        color: white;
        display: flex;
        align-items: center;
        column-gap: 8px;

        @apply --fontSizeMM;

        &:hover {
            text-decoration: none;
        }

        @media (--viewportSmallMax) {
            @apply --fontSizeSM;
            margin-left: auto;
        }
    }
}
