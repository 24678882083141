@import '../../common.css';

.offerBlock {
    @apply --offer_block;

    display: flex;
    flex-direction: column;

    & > footer {
        margin-top: auto;

        & p {
            font-weight: 600;
            text-align: center;
        }

        & a {
            @apply --marketplaceButtonStyles;

            /* Clear padding that is set for link elements looking like buttons */
            padding: 0;
        }

        & button {
            &:first-of-type {
                margin-bottom: 8px;
            }
        }
    }

    & .logo {
        @apply --offer_logo;
        height: 240px;
        margin-bottom: 24px;

        @media (--viewportSmallMax) {
            height: 186px;
        }
    }

    & .badge {
        @apply --badge;
        @apply --fontSizeXS;
        text-transform: capitalize;
        padding: 4px 8px;

        &.premium {
            @apply --badge_selected;
        }

        margin-bottom: 16px;
    }

    & .title {
        @apply --fontSizeXL;
        font-weight: 700;
        margin: 0;

        display: flex;
        column-gap: 8px;

        & .tooltipContent {
            @apply --fontSizeXS;
        }

        & svg {
            width: 16px;
            height: 16px;

            &:hover {
                cursor: pointer;
            }

            & path {
                fill: #b2b2b2;
            }
        }
    }

    & .teaserLocation {
        @apply --fontSizeSM;
        color: #b2b2b2;

        @media (--viewportMediumMax) {
            @apply --fontSizeXS;
        }
    }

    & .description {
        text-align: left;

        &,
        & + p {
            @apply --fontSizeSM;
            margin: 0;

            @media (--viewportMediumMax) {
                @apply --fontSizeXS;
            }
        }

        & + p {
            color: #8f2593;

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}
