@import '../../marketplace.css';

.root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.newSearchInProgress {
    opacity: 0.1;
}

.listings {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.listingCards {
    padding: 0 24px 40px 24px;
    column-gap: 24px;

    @media (--viewportMedium) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    @media (--viewportLarge) {
        padding: 0 24px 80px 24px;
    }
}

.listingCardsInLanding {
    justify-content: center;
}

.listingCard {
    margin-bottom: 30px;
    width: 100%;

    @media (--viewportMedium) {
        width: calc(50% - 12px);
    }

    @media (--viewportLarge) {
        /* 16px takes into account x paddings of root container */
        width: calc(33.333% - 16px);
    }

    & [data-tooltip='item'] * {
        pointer-events: none;
    }
}

.activeListing {
    box-shadow: 0px 0px 0px 4px #8f2593 !important;
}

.dynamicListingCard:nth-of-type(2n) {
    @media screen and (min-width: 720px) and (max-width: 900px) {
        margin-right: 0;
    }
}

.dynamicListingCard:nth-of-type(3n) {
    @media screen and (min-width: 900px) {
        margin-right: 0;
    }
}

.pagination {
    /* margin-top: auto; pushes pagination to the end of the page. */
    margin-top: auto;
    background-color: var(--matterColorLight);
    padding: 8px 24px;
}
.preflightSection,
.noResultspreflightSection {
    padding: 0 24px 0 24px;

    @media (--viewportLarge) {
        & h4 {
            @apply --fontSizeXXL;
        }
    }

    & h4 {
        @apply --fontSizeXL;
        font-weight: 700;
        margin: 0 0 8px 0;
    }

    p {
        margin: 0;
    }
}

.preflightSection {
    border-top: 1px solid #e6e6e6;

    padding-top: 32px;
    margin: 32px 0;
}
.subSection {
    padding-bottom: 0;
}
