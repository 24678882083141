@import '../common.css';

.root {
    margin-bottom: 64px;
}

.reasonToJoinBlock {
    &:last-of-type p {
        margin-bottom: 0;
    }

    & svg {
        margin-bottom: 16px;
    }

    & path {
        fill: #8f2593;
    }

    & h3 {
        @apply --fontSizeXL;
        font-weight: 700;
        margin: 0 0 8px 0;
    }

    @media (--viewportSmallMax) {
        & svg {
            height: 48px;
            width: 48px;
        }
    }
}
