@import '../../marketplace.css';

:root {
  --navButton {
    width: auto;
    min-height: auto;
    max-height: 40px;

    padding: 8px 16px;
    border-radius: 48px;

    @apply --fontSizeSM;
  }
}
