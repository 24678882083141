@import '../../marketplace.css';

:root {
    /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

    --TabNav_linkWidth: 240px;
    --TabNav_linkBorderWidth: 4px;
}

.root {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    overflow-x: auto;
}

.tab {
    margin-left: 16px;

    &:first-child {
        margin-left: 0;
    }

    @media (--viewportLarge) {
        margin-left: 24px;
    }
}

.link {
    display: inline-block;
    white-space: nowrap;

    /* Font */
    @apply --marketplaceTabNavFontStyles;

    color: var(--matterColor);
    padding-bottom: 10px;
    padding-top: 10px;

    /* push tabs against bottom of tab bar */
    margin-top: auto;

    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: transparent;

    -webkit-transition: var(--transitionStyleButton);
    -o-transition: var(--transitionStyleButton);
    transition: var(--transitionStyleButton);
    /* SelectedLink's width (200px) + border thickness (4px) = 204px */

    @media (--viewportMediumMax) {
        &:hover {
            text-decoration: none;
            color: var(--matterColorDark);
            border-bottom: 3px solid #1a2b49;
        }
    }

    @media (--viewportLarge) {
        width: var(--TabNav_linkWidth);
        font-weight: 700;
        letter-spacing: -0.5px;
        border-bottom-width: 0px;
        margin-top: 0;
        margin-bottom: 16px;

        padding-top: 6px;
        /* Drop the text to adjust it to correct baseline */
        padding-bottom: 2px;

        border-right: 4px solid transparent;

        &:hover {
            text-decoration: none;
            border-right: 4px solid #1a2b49;
        }
    }
}

.selectedLink {
    border-bottom-color: var(--matterColorDark);
    color: var(--matterColorDark);

    @media (--viewportMediumMax) {
        border-bottom: 3px solid #8f2593;

        &:hover {
            border-bottom: 3px solid #8f2593;
        }
    }

    @media (--viewportLarge) {
        border-right: 4px solid #8f2593;

        &:hover {
            border-right: 4px solid #8f2593;
        }
    }
}

.disabled {
    pointer-events: none;
    color: var(--matterColorAnti);
    text-decoration: none;
}
