@import '../../common.css';

.timeLeftInfo {
    display: flex;
    align-items: center;
    column-gap: 4px;

    margin: 0;

    @apply --paragraphAntiSM;

    & path {
        fill: #b2b2b2;
    }

    &.timeLeftAlerted {
        color: #ff0000;
        & path {
            fill: #ff0000;
        }
    }
}
