@import '../../../marketplace.css';

.bio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  word-wrap: break-word;
  margin: 0;

  & button {
    @media (--viewportSmallMax) {
      justify-content: center;
    }

    &:after,
    &:before {
      display: none;
    }
  }
}

.placeholder {
  height: 18px;
  background-color: #e6e6e6;
  width: 100%;

  border-radius: 16px;
  margin-bottom: 16px;

  &:last-of-type {
    width: 90%;
    margin-bottom: 0;
  }

  @media (--viewportMiniSmallMax) {
    display: none;
  }
}

.viewProfileRequestWrapper {
  max-height: 221px;
}

aside.viewProfileRequestAction {
  height: 65%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 0.5) 55%,
    rgba(255, 255, 255, 0.1) 100%
  );
}
