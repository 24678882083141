@import '../../marketplace.css';

.root {
    width: 100%;
    height: 100%;
    background-color: var(--matterColorNegative);
}

.mapRoot {
    width: 100%;
    height: 100%;
    /* 
    * to prevent map controls overlap
    * with SearchMapInfoCard
    */
    z-index: 2;
}
