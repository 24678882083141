@import '../../marketplace.css';

.modalContent {
    display: flex;
    flex-direction: column;
    height: 100%;

    & p {
        margin-bottom: 40px;
    }

    & button {
        @media (--viewportSmallMax) {
            margin-top: auto;
        }
    }
}
