@import '../../../marketplace.css';

.carouselHolder {
    margin-bottom: 32px;
    position: relative;

    & ul {
        transition: transform 0.001s;
    }
}

.instructionsHolder {
    display: flex;
    column-gap: 24px;
    margin-bottom: 64px;
}

.descriptionHolder,
.contentHolder {
    flex: 1;
}

.contentHolder {
    background-size: cover;
}
.contentHolderMob {
    display: block;

    width: 100%;
    height: 312px;

    background-size: contain;
    background-position: 50%;
}

.contentHolder,
.contentHolderMob {
    border-radius: 16.028px;
}

.sliderButton {
    @apply --sliderButton;
    z-index: 1;
    position: absolute;
    top: 336px;
}

.sliderButtonBack {
    @apply --sliderButtonBack;
}

.sliderButtonNext {
    @apply --sliderButtonNext;
}

.dotGroup {
    @apply --dotGroup;

    display: flex;
    align-items: center;

    top: 0;

    margin-top: 48px;
}

.descriptionItem {
    border-radius: 16px;
    padding: 24px 0;

    @media (--viewportLarge) {
        padding: 24px;
    }

    @media (--viewportSmallMax) {
        margin-top: 24px;

        & h3 {
            margin: 0 0 16px 0;
        }
    }

    & h3 {
        margin: 0 0 8px 0;
        @apply --fontSizeXL;
        font-weight: 700;
    }

    & p {
        margin: 0;
    }

    &.selected {
        background-color: #8f2593;
        color: #fff;
    }

    &:hover {
        cursor: pointer;
    }
}
