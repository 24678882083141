@import '../../marketplace.css';

:root {
    /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

    --ContactDetailsForm_infoText: {
        @apply --marketplaceH4FontStyles;
        color: var(--successColor);

        display: inline-block;
        padding-left: 18px;
        background-repeat: no-repeat;
        background-position: top 7px left;

        margin-top: 11px;
        margin-bottom: 0;

        @media (--viewportMedium) {
            margin-top: 14px;
            margin-bottom: 1px;
        }
    }
}

.root {
}

.confirmChangesSection {
    flex-grow: 1;
    flex-direction: column;
    margin-bottom: 32px;
    padding: 0;
    display: none;
}

.confirmChangesSectionVisible {
    display: flex;
}

.bottomWrapper {
    margin-top: 0;

    & path {
        fill: #fff;
    }
}

.error {
    @apply --fontSizeXS;
    color: var(--failColor);
    text-align: center;
    display: inline-block;
    margin-bottom: 24px;
}

.phoneInfoHolder {
    display: block;

    & > *:nth-of-type(1) {
        margin-bottom: 16px;
    }

    @media (--viewportMedium) {
        display: flex;

        & > *:nth-of-type(1) {
            width: 144px;
            margin-right: 16px;
            margin-bottom: 0;
        }

        & > *:nth-of-type(2) {
            flex: 1;
        }
    }
}

.verificationBlock {
    display: flex;

    margin-top: 8px;
    margin-bottom: 24px;

    color: #ff0000;

    & > svg {
        width: 16px;
        height: 16px;
        margin-right: 4px;

        @media (--viewportSmallMax) {
            margin-top: 2px;
        }
    }

    &,
    & p {
        @apply --fontSizeXS;
    }

    @media (--viewportMedium) {
        align-items: center;

        & > div {
            display: flex;
        }
    }
}

.verified {
    color: #1e9f55;
}

.verificationLink {
    margin-bottom: 0;

    color: #1a2b49;

    @media (--viewportMedium) {
        margin-left: 8px;
    }

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.dataProtectionInfo {
    @apply --paragraphAntiSM;

    margin-top: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;

    & > svg {
        width: 18px;
        height: 18px;

        & path {
            fill: #b2b2b2;
        }
    }
}

.warningIcon {
    & path {
        stroke: #ff0000;
    }
}
