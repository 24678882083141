@import '../../../marketplace.css';

/* TODO use global styles */

.badge {
    @apply --badge-new-item;
}

.item {
    & p {
        margin-bottom: 0;

        &:first-of-type {
            @apply --fontSizeSM;
            font-weight: 600;
        }

        &:last-of-type {
            @apply --fontSizeXS;
        }
    }
}

.linkItem {
    color: var(--marketplaceColorDarkMedium);
    &:hover {
        text-decoration: none;
        cursor: pointer;
    }
}
