@import '../../marketplace.css';

.modalHeading {
    @apply --fontSizeXL;
    font-weight: 700;

    margin: 0 0 16px 0;

    @media (--viewportMedium) {
        @apply --fontSizeXXL;
    }
}

.modalContainer {
    border: none;
}

.modalDesc {
    margin: 0;
    @apply --fontSizeSM;

    @media (--viewportMedium) {
        @apply --fontSizeMM;
    }
}

.modalAction {
    margin-top: auto;

    @media (--viewportMedium) {
        margin-top: 64px;
    }

    & button {
        color: var(--failColor);
    }
}

.modalContent {
    @media (--viewportSmallMax) {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.form {
    margin: 32px 0 40px 0;

    & textarea {
        height: 184px;

        @media (--viewportSmallMax) {
            height: 116px;
        }
    }
}

.actionButton {
    @media (--viewportSmallMax) {
        margin-top: auto;
    }
}
