@import '../../../../marketplace.css';
@import '../common.css';

.root {
    @apply --sectionRoot;
}

.title {
    @apply --sectionTitle;

    @media (--viewportMedium) {
        padding-bottom: 32px;
    }
}

.sectionContent {
    @apply --fontSizeSM;

    @media (--viewportMedium) {
        @apply --fontSizeMM;
    }
}

.addBtn {
    display: none;

    &:hover {
        text-decoration: none;
    }

    @media (--viewportMedium) {
        display: flex;
        justify-content: flex-end;
        margin-top: 32px;
    }
}

.addBtn > span {
    display: flex;
    height: 52px;
    padding: 11px 16px 13px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    background: #8f2593;

    @apply --fontSizeMM;

    color: #fff;
    font-weight: 600;
}

.qBoxes {
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;

    @media (--viewportMedium) {
        column-gap: 24px;
    }
}

.qBox {
    width: 100%;
    height: 136px;

    border-radius: 4px;
    background: #8f2593;
    color: #fff;
    font-weight: 600;

    padding: 16px;
    margin: 0;

    @media (--viewportMedium) {
        width: calc(50% - 12px);
    }

    & > span {
        display: block;
        margin-bottom: 8px;
    }
}

.matchingContainer {
    @apply --matchingContainer;
}
