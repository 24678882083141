@import '../../marketplace.css';

:root {
  --box {
    display: block;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    padding: 20px 20px 30px 20px;
    margin-bottom: 24px;

    border-radius: 4px;

    @media (--viewportMedium) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
  }
}

.wrapperSvg {
  display: flex;

  & svg {
    fill: none;
    width: 100%;
  }
}
.verificiationBox {
  position: relative;
  @apply --box;

  & svg {
    fill: none;
  }
}

.verificiationBoxWrap {
  @apply --box;
  flex-wrap: wrap;
}

.verificiationBoxTextWrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0 3px 0px;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    padding: 5px 24px 3px 0px;
  }
}

@media (--viewportMedium) {
  .verificiationBoxTextWrapper {
    margin-bottom: 0px;
  }
}

.verificationBoxTitle {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  font-size: 15px;
  line-height: 24px;

  align-self: center;

  width: 100%;
  margin-top: 0;
  margin-bottom: 8px;
}
.verificationSuccessTitle {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 15px;
    line-height: 24px;
  }

  & > svg {
    margin-right: 10px;
  }
}
.verificationBoxText {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightRegular);
  font-size: 15px;
  line-height: 16px;

  max-width: 536px;

  margin-top: 0;
  margin-bottom: 0;
}

.getVerifiedButton {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);

  border-radius: 4px;
  min-height: 38px;
  width: 122px;
  flex-shrink: 0;
}

/* Verification required box */
.verficiationNeededBox {
  border: 1px solid var(--attentionColor);
  background: var(--attentionColorLight);
}

/* Verification error box */
.verficiationErrorBox {
  background: var(--failColorLight);
  border: 1px solid var(--failColor);
}

/* Verification success box */
.verificiationSuccessBox {
  background: var(--successColorLight);
  border: 1px solid var(--successColor);
  padding: 8px 16px;

  @media (--viewportMedium) {
    padding: 8px 24px;
  }
}

.verificationBoxSuccessTextWrapper {
  margin-bottom: 0;
}
.editVerificationButton {
  display: flex;
  align-items: center;

  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;

  min-height: 46px;
  margin: 0;
  margin-left: auto;
  padding-top: 3px;

  &:hover,
  &:focus {
    outline: none;
    color: var(--matterColorDark);
  }

  & > svg {
    margin-right: 7px;
    fill: none;
  }
}

.icon {
  margin-right: 10px;
}

.iconEditPencil {
  stroke: var(--matterColor);
}

.spinner {
  width: 24px;
  height: 24px;
  stroke: var(--successColor);
  stroke-width: 3px;
}
.stripeAccountError {
  color: var(--failColor);
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  right: 0;
}
.flexElement {
  flex: 1;
}
.iconListHolder {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  margin-top: 16px;
  gap: 4px;

  & > svg {
    margin-right: 4px;
    fill: none;
  }
}
