@import '../../marketplace.css';

.modalContainer {
    border: none;
}

.modalContent {
    & > svg {
        margin-bottom: 16px;
        flex-shrink: 0;

        & path {
            fill: #ff0000;
        }
    }

    @media (--viewportSmallMax) {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.modalHeading {
    @apply --fontSizeXL;
    font-weight: 700;
    margin: 0 0 16px 0;

    @media (--viewportMedium) {
        @apply --fontSizeXXL;
    }
}

.modalDesc {
    margin: 0;
    @apply --fontSizeSM;

    @media (--viewportMedium) {
        @apply --fontSizeMM;
    }
}

.modalAction {
    margin-top: auto;

    @media (--viewportMedium) {
        margin-top: 64px;
    }

    & button {
        color: var(--failColor);
    }
}
