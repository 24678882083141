@import '../../marketplace.css';

.root {
    background-color: var(--matterColorLight);
}
.content {
    width: calc(100% - 48px);
    margin: 0px 24px 24px 24px;

    position: relative;

    @media (--viewportMedium) {
        max-width: 1150px;
        margin: 64px auto 80px auto;
    }
}

.topbarDesktop {
    box-shadow: none;
}

.topbarMob {
    display: flex;
    justify-content: space-between;
    padding: 18px 16px;
    box-shadow: 0px 4px 6px -1px #0000000d;
    background: white;

    & button {
        width: auto;
        min-height: unset;
        letter-spacing: -0.25px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #1a2b49;
        font-weight: 400;

        &,
        &:hover {
            background: none;
        }

        & svg {
            transform: rotate(180deg);
            margin-right: 8px;

            & path {
                fill: #1a2b49;
                stroke: #1a2b49;
            }
        }
    }
}

.headingContainer {
    display: flex;
    flex-direction: row;

    margin-top: 0;
}

.heading {
    margin: 0;
    hyphens: auto;

    @media (--viewportMedium) {
        margin: 0 24px 0 0;
    }
}

.modalContainer {
    @apply --marketplaceModalBaseStyles;

    color: transparent;
}

.modalContent {
    @apply --modalContent;

    & > svg {
        margin: 0 0 16px 0;
        flex-shrink: 0;
    }
}

.modalBody {
    color: #1a2b49;
    @apply --modalContent;

    & button {
        @media (--viewportSmallMax) {
            margin-top: 0;
        }
    }
}

.hideUserInfoCheckboxLabel {
    margin-top: 13px;
    display: inline-block;
    width: 100%;

    &:nth-of-type(1) {
        margin-top: 0px;
    }

    & label {
        width: 100%;
        justify-content: space-between;

        & span {
            font-weight: 600;
            @apply --fontSizeM;
        }
    }
}

/* Title of the modal */
.modalTitle {
    @apply --marketplaceModalTitleStyles;

    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.5px;
    margin: 0 0 16px 0;
}

/* Paragraph for the Modal */
.modalMessage {
    @apply --marketplaceModalParagraphStyles;
    @apply --fontSizeM;
    margin: 0 0 48px 0;
}

.modalSidenote {
    color: #b2b2b2;
    font-size: 13px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 63px;

    @media (--viewportSmallMax) {
        margin-top: auto;
    }
}

.noResultsRoot {
    position: relative;
    z-index: 1;

    @media (--viewportSmallMax) {
        padding: 0 24px;
    }
}

.userNotAvailable {
    margin-top: 24px;
    & > svg {
        z-index: unset;
    }
}

.iconHolder {
    width: 34px;
    height: 34px;
    background: #8f2593;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchForm {
    max-width: 296px;
    margin-top: 32px;

    & > div {
        border: 1px solid #e6e6e6;
        border-radius: 26px;
        background: white;
    }

    & input {
        @apply --fontSizeSM;
        height: 42px;
        padding: 9px 56px 9px 16px;

        &:hover {
            padding: 9px 56px 9px 16px;
        }

        &:focus {
            padding-left: 16px;
        }

        /* icon holder  */
        & ~ div {
            top: calc(50% - 17px);
            left: unset;
            right: 6px;
            /* predictions list */
            & ~ div {
                top: 42px;
                margin: 0;
            }
        }
    }
}
