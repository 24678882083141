@import '../../marketplace.css';

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
    padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
    background: var(--matterColorNegative); /* Loading BG color */
}

.aspectWrapperNoBackground {
    background: unset;
}

.threeToTwoWrapper {
    /* Layout */
    display: block;
    width: 100%;
    position: relative;
}

.identitySection {
    position: absolute;
    left: 16px;
    bottom: 40px;

    z-index: 3;

    pointer-events: none;
}

.title {
    @apply --fontSizeXL;
    color: white;
    font-weight: 700;

    margin: 0 0 4px 0;
}

.locationHolder {
    display: flex;
    align-items: center;

    color: white;
    white-space: nowrap;
    height: 22px;
    margin-bottom: 5px;

    & svg {
        fill: none;
        margin-right: 4px;

        & path {
            stroke: white;
        }
    }

    & span {
        font-size: 14px;
        line-height: 18px;
        font-weight: normal;

        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.locationInfo {
    display: flex;
    align-items: center;

    & svg {
        fill: white;
        width: 4px;
        height: 4px;
        position: relative;
        right: -10px;
        top: 1px;
    }
}

.locationDisctance {
    position: relative;
    margin-left: 12px;

    & + svg {
        fill: #9c9c9c;
        width: 4px;
        height: 4px;
    }
}

.tempListing {
    display: flex;
    align-items: center;

    margin-left: 16px;
}

.recommendationsNumSection {
    margin-left: 16px;
    margin-bottom: 0;

    display: flex;
    align-items: center;

    @apply --fontSizeXS;
    white-space: break-spaces;

    & svg {
        width: 16px;
        height: 16px;
    }
}

.loadingOverlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    &:after,
    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        animation-name: overlayShadowing;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }

    &:after {
        background: linear-gradient(45deg, #e6e6e6, #333333d9);
    }

    &:before {
        background: linear-gradient(45deg, #333333d9, #e6e6e6);
        animation-delay: 1s;
    }

    & svg {
        z-index: 1;

        fill: transparent;

        position: absolute;
        left: calc(50% - 33.5px);
        top: calc(50% - 36px);

        & path {
            stroke: white;
        }

        @media (--viewportSmallMax) {
            display: none;
        }
    }
}

.galleryCarouselWrapper {
    z-index: 1;

    & > div:last-of-type {
        z-index: 2;
    }

    & video {
        pointer-events: none;
    }
}

.actionItem {
    padding-bottom: 50%;
    position: absolute;
    height: 100%;

    left: 0;
    right: 0;

    & > button {
        width: 176px;

        position: absolute;
        top: calc(50% - 32px);
        left: calc(50% - 88px);

        @media (--viewportMedium) {
            width: 226px;
            left: calc(50% - 113px);
        }
    }
}

.rootForImage {
    /* Layout - image will take space defined by aspect ratio wrapper */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
}

.overlayBottom {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);

    pointer-events: none;
}
