@import '../../marketplace.css';

:root {
  margin: 0;
    /* Base for all avatars */
    --Avatar_base: {
      font-family: 'sofiapro', Helvetica, Arial, sans-serif;
      border-radius: 50%;
      line-height: 0;
  
      /* Position possible initials to the center of the component */
      display: flex;
      align-items: center;
      justify-content: center;
  
      /* Colors */
      background-image: linear-gradient(45deg, #8F2C90 0%, #FB4E41 100%);
      color: var(--matterColorLight);
      border: 2px solid white;
      &:hover {
        text-decoration: none;
        background-image: linear-gradient(45deg, #7B187C 0%, #E73A2D 100%);
      }
    }
}

.reviewItem {
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 39px;
  }
}

.review {
  display: flex;
  flex-direction: row;
  flex-basis: 564px;
  flex-shrink: 1;
  flex-grow: 0;
}

.avatar {
  flex-shrink: 0;
  margin-right: 16px;
  cursor: pointer;
  @media (--viewportMedium) {
    margin-right: 24px;
  }
}

.reviewWrapper{
  display: flex;
  flex-direction: column;
  margin-top: 2px
}
.reviewRatingWrapper{
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.verified{
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 14px;
  color: var(--marketplaceColor)
}

.reviewContent {
  @apply --marketplaceH4FontStyles;
  font-size: 14px;
  margin: 0;
  white-space: pre-line;
  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.reviewInfo {
  @apply --marketplaceH5FontStyles;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 14px;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.mobileReviewRating {
  display: block;
  margin-top: 3px;
  margin-bottom: 9px;

  @media (--viewportMedium) {
    display: none;
  }
}

.reviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;

  white-space: nowrap;
}

.reviewRatingStar {
  height: 14px;
  width: 14px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}

.rootAvater {
  --Avatar_size: 40px;
  --Avatar_sizeMedium: 60px;
  --Avatar_sizeLarge: 96px;

  /* Font is specific to this component, but defining it in marketplace.css makes it easier
   * to change font if brand look-and-feel needs such changes.
   */
  @apply --Avatar_base;

  /* Layout */
  width: var(--Avatar_size);
  height: var(--Avatar_size);

  flex-shrink: 0;
  margin-right: 16px;
  cursor: pointer;
  @media (--viewportMedium) {
    margin-right: 24px;
  }
}

.initials {
  font-size: 14px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 4px;
}

/*  Asher wrote css for "show more review button"  */

.showMoreReview {
  margin-top: 2em;
  margin-bottom: 2em;
  display: block;
  font-size: 16px;
}