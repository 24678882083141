.root {
    height: calc(166px + 40px);

    @media (--viewportMedium) {
        height: calc(166px + 80px);
    }
}

.noViewBtn {
    height: calc(166px + 40px);

    @media (--viewportMedium) {
        height: calc(88px + 80px);
    }
}