@import '../../marketplace.css';

.root {
    @apply --badge-status;
    column-gap: 8px;

    border: 1px solid #f9f9f9;
    margin-right: 8px;

    transition: 0.35s;

    &.lightRoot {
        box-shadow: unset;
    }

    &:hover {
        cursor: pointer;
    }

    & > span {
        display: inline-block;
    }
}

.tooltipRoot {
    position: relative;
    z-index: 1;
}

.editAllowed {
    &:hover {
        border: 1px solid #1a2b49;
    }
}

.availabilityStatus {
    width: 16px;
    height: 16px;

    display: inline-block;
    border-radius: 50%;

    flex-shrink: 0;
}

.confirmed {
    background-color: #1e9f55;
}
.notConfirmed {
    border: 3px solid #1e9f55;
}
.notAvailable {
    border: 3px solid #ff0000;

    + span {
        color: #f00;
    }
}
