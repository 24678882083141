@import '../../marketplace.css';

.root {
    display: flex;
    align-items: center;
    column-gap: 8px;
    border-radius: 37px;
    border: 1px solid var(--matterColorNegative);
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 10px 16px;

    & span {
        @apply --fontSizeSM;
        color: var(--marketplaceColorDarkMedium);
    }

    &:hover {
        cursor: pointer;
        border: 1px solid var(--marketplaceColorDarkMedium);
    }
}
