@import '../../common.css';

.tabsNavigation {
    @apply --tabs_navigation;

    & .tab {
        @apply --tab;

        &.tabSelected {
            @apply --tab_selected;
        }
    }
}

.offersHolder {
    margin: 64px 0;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    column-gap: 32px;
    row-gap: 32px;

    @media (--viewportMediumMax) {
        margin: 32px 0 64px 0;
    }
}

.footer {
    margin-bottom: 64px;
}
.error {
    color: var(--failColor);
}
