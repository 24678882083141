@import '../../marketplace.css';

:root {
  --selected {
    color: var(--marketplaceColorDark);
  }
}
.staticPageWrapper {
  & h1 {
    margin-bottom: 0;
  }
}

.contentWrapper {
  max-width: 680px;
  margin: 24px auto;

  & button {
    min-height: 48px;
    height: 48px;
    padding: 8px 16px;
    width: auto;
    white-space: nowrap;
    margin-right: 8px;

    @apply --fontSizeXS;
  }
}

.infoBlock {
  padding: 24px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  margin-bottom: 24px;
}

.staticPageWrapper {
  padding: 24px;
}

.status {
  text-transform: capitalize;
}

.approved {
  color: var(--successColor);
}

.pending {
  color: var(--attentionColor);
}

.profilePreview {
  & a {
    color: var(--matterColorDark);
    text-decoration: underline;
  }
}

.controls {
  display: flex;
  margin: 24px 0;
}

.selected {
  @apply --selected;
  &:hover {
    @apply --selected;
  }
}

.deleteAction {
  color: var(--failColor);
}

.reviewDateReviewrInfo {
  display: flex;
  justify-content: space-between;
}
