.root {
  /* Expand to the full remaining width of the viewport */
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.blobElem {
  display: none;

  @media (--viewportMedium) {
    display: block;
    position: fixed;
    z-index: -1;
  }
}
.blobElemLeft {
  left: 0;
  top: 200px;
}
.blobElemRight {
  right: 0;
  top: 270px;
}
