@import '../../../marketplace.css';

.noInfoBlock {
    padding-right: 16px;
}

.skillsSection {
    display: flex;
    flex-wrap: wrap;

    & .skillsItem {
        @apply --fontSizeXS;
    }
}

.skillsItem {
    padding: 8px 16px;
    margin-right: 8px;

    margin-bottom: 16px;

    @media (--viewportSmallMax) {
        margin-bottom: 8px;
        @apply --fontSizeXS;
        margin-left: 0;
    }

    &:last-of-type {
        margin-right: 0;
    }
}

.placeholderWrapper {
    display: flex;
    flex-wrap: wrap;

    & > div {
        height: 36px;
        min-width: 88px;

        border-radius: 20px;
        background-color: #e6e6e6;

        margin-right: 8px;
        margin-bottom: 16px;

        &:nth-of-type(1),
        &:nth-of-type(4) {
            min-width: 144px;
        }

        &:nth-of-type(3),
        &:nth-of-type(5),
        &:nth-of-type(7),
        &:nth-of-type(10) {
            min-width: 104px;
        }

        &:nth-of-type(8) {
            min-width: 170px;
        }
    }
}

.viewProfileRequestWrapper {
    max-height: 236px;
}

aside.viewProfileRequestAction {
    height: 65%;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 40%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0.1) 90%,
        rgba(255, 255, 255, 0) 100%
    );
}

.toggleText {
    & button {
        display: flex;
        align-items: center;
        justify-content: center;

        &:after,
        &:before {
            display: none;
        }
    }
}

.actionBtn {
    margin-top: 16px;
}
