@import '../../marketplace.css';

.root {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    /* Content of EditListingWizard should have smaller z-index than Topbar */
    z-index: 0;
}

.rootZindex {
    z-index: 1;
}

.tabsContainer {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 100%;
    height: 100%;

    padding: 0 24px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    @media (--viewportMedium) {
        max-width: 480px;
        margin: 0 auto;
        padding: 0;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background-color: var(--matterColorLight);
    border: none;
    overflow-x: unset;
    gap: 16px;

    @media (--viewportMedium) {
        background-color: var(--matterColorBright);
        -webkit-box-shadow: none;
        box-shadow: none;
        border-top: none;
    }
}

/* .tab {
    padding: 0 24px;
    white-space: nowrap;
} */

.draftWizard {
    padding-bottom: 114px;
}

.footerWrapper {
    margin-top: 60px;

    @media (--viewportMedium) {
        margin-top: 120px;
    }
}

.panel {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.infoWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    @media (--viewportMedium) {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.infoTitle,
.infoSubTitle {
    width: 480px;
    color: #1a2b49;
}

.infoTitle {
    @apply --fontSizeXL;
    font-weight: 700;
    margin: 0 0 16px 0;

    @media (--viewportMedium) {
        @apply --fontSizeXXL;
    }
}

.infoSubTitle {
    margin-bottom: 20px;

    @media (--viewportMedium) {
        margin-bottom: 32px;
    }
}

.backTitle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    -webkit-column-gap: 8px;
    -moz-column-gap: 8px;
    column-gap: 8px;

    color: #1a2b49;

    @apply --fontSizeSM;

    margin-bottom: 16px;

    @media (--viewportMedium) {
        font-size: 20px;
        line-height: 32px;
        width: 480px;
    }

    & svg {
        transform: rotate(180deg);

        & path {
            fill: #1a2b49;
            stroke: #1a2b49;
        }
    }

    &:hover {
        cursor: pointer;
    }
}

.infoContainer {
    padding: 32px 24px 0 24px;

    @media (--viewportMedium) {
        padding: 64px 0 0 0;
        display: block;
    }
}

.tbContent > img {
    width: 200px;
    height: 40px;
    cursor: pointer;
}

.tbContent > div {
    display: none;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    color: var(--matterColorDark);
    cursor: pointer;
    text-decoration: underline;

    @media (--viewportMedium) {
        display: block;
    }
}

.wizardBottomNavigation {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 90;

    padding: 12px 24px;
    border-top: 1px solid #e6e6e6;
    background-color: #fff;

    & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        max-width: 480px;
    }

    & button {
        width: auto;
        padding: 16px;

        & svg {
            margin-left: 8px;
        }

        &:first-of-type {
            background-color: unset;
            color: #923395;
            text-decoration: underline;
        }
    }
}
