@import '../../marketplace.css';

.root {
    & > h4 {
        letter-spacing: -0.5px;
        line-height: 32px;
        font-size: 24px;
        font-weight: 700;

        margin-bottom: 32px;
        margin-top: 48px;
    }
}

.heading {
    text-align: center;
    letter-spacing: -0.5px;

    @media (--viewportMedium) {
        font-size: 32px;
        line-height: 40px;
    }
}

.description {
    text-align: center;
    margin-bottom: 0;
}

.avatar {
    width: 120px;
    height: 120px;

    margin: 16px auto;
}

.msgSection {
    display: flex;
    align-items: center;

    @apply --paragraphAntiSM;

    margin-bottom: 32px;
    margin-top: -16px;

    & > svg {
        width: 16px;
        height: 16px;
        margin-right: 6px;

        & path {
            fill: #b2b2b2;
        }
    }
}

.bottomWrapperText {
    @apply --marketplaceModalBottomWrapperText;
    @apply (--authSubsection);
    text-align: center;
    margin-bottom: 16px;

    @media (--viewportMedium) {
        margin-bottom: 4px;
    }
}

.field {
    margin-bottom: 24px;
}

.fieldRow {
    @media (--viewportSmallMax) {
        & > * {
            &:first-of-type {
                margin-bottom: 24px;
            }
        }
    }

    @media (--viewportMedium) {
        display: flex;

        & > * {
            max-width: 228px;

            &:first-of-type {
                margin-right: 24px;
            }
        }
    }
}

.recommendationField {
    & textarea {
        height: 116px;

        @media (--viewportMedium) {
            height: 184px;
        }
    }
}

.assessment {
    display: flex;
    align-items: center;

    margin-bottom: 24px;

    @media (--viewportSmallMax) {
        flex-direction: column-reverse;
        align-items: baseline;
        margin-bottom: 16px;

        & span {
            margin-bottom: 4px;
        }
    }

    & svg {
        margin-right: 4px;

        &:last-of-type {
            margin-right: 16px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.submitButton {
    margin-top: 48px;
}

.requestedViewAction {
    margin-left: auto;
    margin-right: auto;

    margin-top: 48px;

    @media (--viewportMedium) {
        max-width: 240px;
    }
}

.requestedViewRoot {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.alert {
    margin-top: 48px;
}

.editAction {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;

    margin-left: 16px;

    &:hover {
        cursor: pointer;
    }

    & svg {
        margin-right: 4px;
    }

    @media (--viewportSmallMax) {
        margin-left: 0;
        justify-content: flex-end;
        margin-top: 16px;
    }
}

.mob {
    @media (--viewportMedium) {
        display: none;
    }
}

.desktop {
    @media (--viewportSmallMax) {
        display: none;
    }
}

.mobFooter {
    border-top: 1px solid #e6e6e6;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;

    padding: 12px 24px;
    z-index: 1;
    & p,
    & button {
        margin: 0;
    }

    & button {
        margin-top: 8px;
    }
}
