@import '../../marketplace.css';

.followersHolder {
    max-width: 448px;
    margin: 0 auto;

    @media (--viewportLarge) {
        display: flex;
        align-items: center;
        column-gap: 12px;
    }

    & p {
        @apply --fontSizeXS;
        text-align: center;
        margin: 0 auto;
        max-width: 240px;

        @media (--viewportLarge) {
            text-align: left;
            margin: 0;
            max-width: unset;
        }
    }
}

.followers {
    display: flex;
    justify-content: center;
    flex-shrink: 0;

    margin: 0 0 16px 48px;

    @media (--viewportLarge) {
        margin: 0 -48px 0 0;
    }

    & img {
        object-fit: cover;

        border-radius: 50%;
        border: 2px solid white;

        position: relative;
    }
}
