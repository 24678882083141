@import '../../marketplace.css';

:root {
    --box {
        border-radius: 8px;
        border: 1px solid var(--matterColorNegative);
        padding: 32px;
    }

    --action {
        color: var(--marketplaceColor);

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.container {
    max-width: 480px;

    margin: 0 auto;
    padding: 24px 24px 40px 24px;

    @media (--viewportMedium) {
        padding: 64px 24px 80px 24px;
    }

    & .sidenote,
    & .error {
        @apply --paragraphAntiSM;
    }

    & .error {
        color: var(--failColor);

        & + button {
            max-width: 132px;
        }
    }
}

.heading {
    color: var(--marketplaceColor);
    @apply --fontSizeXL;
    font-weight: 700;
    text-align: center;

    @media (--viewportSmallMax) {
        @apply --fontSizeSM;
        font-weight: 700;
    }

    margin: 0 0 16px 0;
}

.subHeading {
    @apply --fontSizeXXXL;
    text-align: center;

    @media (--viewportSmallMax) {
        @apply --fontSizeXL;
        font-weight: 700;
    }

    margin: 0 0 32px 0;
}

.creditsPurchaseBox {
    position: relative;

    margin: 0 0 32px 0;

    & .supportLink {
        @apply --fontSizeXS;
        font-weight: 400;

        margin: 0;
        position: absolute;
        right: 16px;
        top: 16px;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
.box {
    @apply --box;

    &:first-of-type {
        margin: 0 0 16px 0;
    }

    &:nth-last-of-type(2) {
        margin: 0 0 32px 0;
    }
}

.creditsReferralBox {
    & p {
        font-weight: 600;
        margin: 0;
        & a {
            font-weight: 400;
        }
    }
}

.creditsPurchaseHeading {
    @apply --fontSizeXL;
    font-weight: 700;

    margin: 0 0 16px 0;
}

.creditsPurchaseAction {
    @apply --action;
    margin: 0;
}

.creditsPurchaseHistory {
    margin: 0 0 16px 0;

    & > h3 {
        margin: 0;

        @apply --fontSizeMM;
        font-weight: 600;

        @media (--viewportSmallMax) {
            @apply --fontSizeSM;
            font-weight: 600;
        }
    }
}

.creditTxItem {
    display: flex;

    padding: 8px 0;
    border-bottom: 1px solid var(--matterColorNegative, #e6e6e6);

    & > p {
        margin: 0;

        &:nth-of-type(1) {
            margin-right: 32px;
            width: 84px;

            @media (--viewportSmallMax) {
                width: 64px;
            }
        }

        &:nth-of-type(2) {
            margin-right: auto;
        }

        @media (--viewportSmallMax) {
            @apply --fontSizeXS;
        }
    }
}
