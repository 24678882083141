@import '../../marketplace.css';

.appTeaserWrapper {
    color: var(--brand-secondary, #1A2B49);
    font-family: 'sofiapro', Helvetica, Arial, sans-serif;
    margin: 32px 0;
    position: relative;
    width: 100%;
    padding: 224px 24px 24px 24px;
    border-radius: 16px;
    background: var(--Ground-Ground-000, #FFFFFF);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.10);
}

.appTeaserContent {
    position: relative;
    z-index: 1;
}

.appTeaserTitle {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.5px;
    margin: initial;
}

.appTeaserDescription {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    margin: 16px 0 24px 0;
}

.pageTextBreak {
    display: none;
}

.appTeaserStoreLinks .storeLink {
    display: inline-block;
}

.appTeaserStoreLinks .storeLink:last-child {
    margin-top: 16px;
}

.appTeaserStoreLinks .storeLink:last-child .appPlayStoreIcon {
    height: 46px;
}

.appTeaserImage {
    width: 100%;
    border-radius: 16px 16px 0 0;
    background: center top no-repeat url(HorseDealAppTeaserBackgroundImage.jpg);
    height: 200px;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
}

/* viewport medium */
/* min-width: 768px */
@media (--viewportMedium) {
    .appTeaserWrapper {
        padding: 70px 40px;
    }

    .appTeaserContent {
        width: 50%;
        max-width: 520px;
        padding-right: 24px;
    }

    .pageTextBreak {
        display: initial;
    }

    .pageBreak {
        display: none;
    }

    .appTeaserTitle {
        font-size: 40px;
        line-height: 48px;
    }

    .appTeaserStoreLinks .storeLink:last-child {
        margin-left: 16px;
    }

    .appTeaserImage {
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0 16px 16px 0;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0.06) 6.34%, #FFF 96.58%), center top no-repeat url(HorseDealAppTeaserBackgroundImage.jpg), lightgray -1.784px 0 / 101.035% 100% no-repeat;
    }
}
