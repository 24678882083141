@import '../../marketplace.css';

.root {
    @apply --marketplaceModalRootStyles;
}

.layoutWrapperMain {
    min-height: calc(100vh - var(--topbarHeight));

    @media (--viewportMedium) {
        min-height: calc(100vh - var(--topbarHeightDesktop));
    }
}

.submitEmailContent,
.genericErrorContent,
.emailSubmittedContent,
.emailNotVerifiedContent {
    @apply --marketplaceModalBaseStyles;
    @apply (--marketplaceModalResetStyles);
    padding: 24px;

    @media (--viewportMedium) {
        padding: 0;
    }
}
.submitEmailContent {
    @media (--viewportMedium) {
        margin-top: 48px;
    }
}
.emailSubmittedContent {
    flex-basis: 480px;
    max-width: 480px;

    margin-left: auto;
    margin-right: auto;

    text-align: center;

    @media (--viewportMedium) {
        margin-top: 64px;
    }

    & > svg {
        margin: 0 auto 16px auto;
    }
}

.bottomWrapper {
    @apply --marketplaceModalBottomWrapper;
    margin-top: 48px;
    /* Align the helper links to the left since there isn't a Primary Button anymore */
    text-align: left;

    & > button {
        max-width: 240px;
        margin: 48px auto 0 auto;
        & a {
            color: white;
            text-decoration: none;
        }
    }
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
    margin: 0;
    margin-bottom: 0;

    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.5px;

    @media (--viewportMedium) {
        font-size: 32px;
        line-height: 40px;
    }
}

/* Paragraph for the Modal */
.modalMessage {
    margin: 16px 0 -16px 0;
    font-size: 16px;

    @media (--viewportMedium) {
        font-size: 18px;
    }
}

/* Make the email pop */
.email {
    @apply --marketplaceModalHighlightEmail;
}

.helperLink {
    @apply --marketplaceLinkStyles;
    @apply --marketplaceModalHelperLink;
    color: var(--marketplaceColor);
}

.helperText {
    @apply --marketplaceModalHelperText;
}

.subHeading {
    display: flex;
    align-items: center;

    @apply (--marketplaceSubheadingSectionStyles);
    margin-bottom: 16px;
    color: #1a2b49;

    & a {
        display: flex;
        align-items: center;

        color: inherit;
        &:hover {
            text-decoration: none;
        }
    }
    & svg {
        transform: rotate(180deg);
        margin-right: 8px;
    }
    & path {
        fill: #1a2b49;
        stroke: #1a2b49;
    }

    @media (--viewportSmallMax) {
        margin-top: 0;
    }
}
