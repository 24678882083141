@import '../../marketplace.css';
@import './FilterPopupBaseStyles.css';

.labelEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.popup {
    @apply --marketplaceFilterPopup;
    left: 0;
}

.popupSize {
    padding: 15px 30px 17px 16px;
    max-height: 600px;
    overflow-y: scroll;
}

.isOpen {
    display: block;
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
}
.separatorLine {
    @apply --separatorLine;
    margin-top: 17px;
}
