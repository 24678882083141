@import '../../marketplace.css';

.accordion {
    margin: 32px 0;
    border-bottom: 1px solid var(--ground-ground-010, #E6E6E6);
}

.accordion h3 {
    margin: initial;
}

.gridContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
}

.gridItem {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: initial;
}

.accordionItem {
    padding: 24px 0;
    border-top: 1px solid var(--ground-ground-010, #E6E6E6);
}

.accordionItem.active .accordionTitle {
    color: var(--marketplaceColor, #8f2593);
}

.accordionItem.active .accordionIconShevron {
    transform: rotate(90deg);
}

.accordionItem:first-child {
    margin-top: initial;
}

.accordionIconShevron {
    flex: 0 0 40px;
}

.accordionItemHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.accordionItemHeader svg {
    transition: 0.35s;
    transform: rotate(-90deg);
}

.accordionTitle {
    color: var(--marketplaceColorDarkMedium, #8f2593);
    font-family: 'sofiapro', Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.5px;
    margin: initial;
}

.accordionContent {
    display: none;
    margin-top: 24px;
}

.accordionContent.active {
    display: block;
}

.accordionContentInner {
    color: var(--marketplaceColorDarkMedium, #1A2B49);
    font-family: 'sofiapro', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.25px;
}

.accordionContentInner ul {
    list-style: initial;
    margin-left: 26px;
}

.tableOfContents {
    margin-left: 26px;
    margin-top: initial;
    margin-bottom: initial;
}

.tableOfContentsListItem {
    list-style: initial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
}

/* viewport medium */
/* min-width: 768px */
@media (--viewportMedium) {
    .tableOfContentsListItem {
        font-size: 18px;
        line-height: 28px;
    }

    .gridContainer {
        grid-template-columns: repeat(3, 1fr);
    }

    .accordionTitle {
        font-size: 32px;
        line-height: 40px;
    }

    .accordionContentInner {
        font-size: 18px;
    }
}
