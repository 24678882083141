@import '../../marketplace.css';

.feed {
    &.enquired {
        margin-bottom: 32px;
    }

    & button[data-role='reinitiate-transaction'] {
        margin: 0 auto;
    }
}

.messageError {
    color: var(--failColor);
    margin: 13px 0 22px 0;

    @media (--viewportMedium) {
        margin: 13px 0 23px 0;
    }

    @media (--viewportLarge) {
        margin: 12px 0 23px 0;
    }
}

.carouselModalScrollLayer {
    @apply --marketplaceCarouselModalScrollLayer;
}
.carouselModalContainer {
    @apply --marketplaceCarouselModalContainer;
}
