@import '../../marketplace.css';

.modalContainer {
    border: none;
}

.modalContent {
    & button {
        margin-top: 40px;
    }

    @media (--viewportSmallMax) {
        height: 100%;
        display: flex;
        flex-direction: column;

        & button {
            margin-top: auto;
        }
    }
}
.error {
    color: var(--failColor);
    text-align: center;
}
