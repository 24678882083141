@import '../../marketplace.css';

.root {
    & > nav {
        & > div > a {
            font-weight: 700;

            & h2 {
                @apply --fontSizeXL;
                margin: 0;
                font-weight: 700;
            }
        }

        @media (--viewportMediumMax) {
            min-height: 48px;
            padding-top: 2px;

            & > div {
                margin-left: 16px;

                & a {
                    font-weight: 400;

                    border-right: unset;

                    color: #1a2b49;
                    padding-bottom: 10px;
                    padding-top: 10px;
                    margin-top: auto;
                    margin-bottom: auto;

                    width: auto;

                    & h2 {
                        @apply --fontSizeSM;
                        font-weight: 400;
                        letter-spacing: -0.5px;
                    }
                }

                &:first-of-type {
                    margin-left: 0;
                }
            }
        }
    }
}

.linkActive {
    color: #8f2593 !important;
}
