@import '../../marketplace.css';

.container {
    width: 100%;

    max-width: 1152px;
    padding: 0 24px;
    margin: 24px auto 80px auto;

    @media (--viewportLarge) {
        padding: 0;
        margin: 64px auto 80px auto;
    }
}

.heading {
    text-align: center;
    margin-bottom: 32px;

    & h1 {
        color: #8f2593;
        margin: 0 0 16px 0;

        @apply --fontSizeSM;

        @media (--viewportLarge) {
            @apply --fontSizeXL;
        }

        font-weight: 700;
    }
}

.subheading {
    margin: 0 0 16px 0;
    @apply --fontSizeXXL;

    @media (--viewportLarge) {
        @apply --fontSizeXXXL;
    }

    font-weight: 700;
}

.description {
    white-space: normal;

    @media (--viewportMedium) {
        white-space: break-spaces;
    }
}

.kodexItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;
    margin-bottom: 32px;

    & .description {
        margin-bottom: 0;
        @media (--viewportLarge) {
            max-width: 564px;
        }
    }

    @media (--viewportLarge) {
        column-gap: 24px;
        flex-direction: row;

        &:nth-of-type(odd) {
            flex-direction: row-reverse;
        }
    }

    & img {
        width: auto;
        height: 196.629px;

        @media (--viewportLarge) {
            height: 368px;
            width: 368px;
        }
    }

    & h2 {
        margin-top: 0;

        @apply --fontSizeXL;

        @media (--viewportLarge) {
            @apply --fontSizeXXL;
        }

        font-weight: 700;
    }
}

.footer {
    margin-top: 48px;

    & p {
        margin: 0;
    }
}
