@import '../../marketplace.css';

.gridContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px 80px;
    margin: 32px 0;
}

.gridContainer.emptyState {
    display: initial;
    text-align: center;
}

.noDataText {
    margin: initial;
}

.gridItem {
    overflow: hidden;
    max-width: 100%;
    height: 24px;
}

.gridItem.gridItemSubText {
    height: 60px;
}

.mainText {
    color: var(--Brand-Secondary, #1A2B49);
    font-feature-settings: 'liga' off;
    font-family: 'sofiapro', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
}

.mainText:hover {
    text-decoration: underline;
}

.subText {
    color: var(--Ground-Ground-040, #B2B2B2);
    font-feature-settings: 'liga' off;
    font-family: 'sofiapro', Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.25px;
    margin: -3px 0 0 0;
}


/* viewport medium */
/* min-width: 768px */
@media (--viewportMedium) {
    .gridContainer {
        grid-template-columns: repeat(3, 1fr);
    }

    .gridItem {
        height: 28px;
    }

    .mainText {
        font-size: 18px;
        line-height: 28px;
    }
}
