@import '../../../marketplace.css';

.flex {
    display: flex;
}

.tiny {
    height: 14px;
    width: 14px;
}

.userNameSection {
    display: flex;
    align-items: center;
    max-width: 215px;
    margin-bottom: 16px;

    @media (--viewportLarge) {
        max-width: 500px;
    }

    & h1 {
        margin: 0;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.5px;
        flex-shrink: 0;
        display: inline-block;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (--viewportMedium) {
            font-size: 40px;
            line-height: 54px;
        }
    }

    & code > svg {
        margin-left: 8px;
    }
}

.upperSection {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 32px;
    margin-top: 0;

    @media (--viewportMedium) {
        flex-direction: row;
        /** public view **/
    }
}

.recommendationsNumSection {
    & svg {
        fill: none;

        transform: scale(-1, 1);
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.userIdentitySection {
    display: block;
    z-index: 1;
    @media (--viewportMedium) {
        display: flex;
    }
    & svg {
        fill: none;
    }
}

.sectionItem {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre;

    background: #f9f9f9;
    border-radius: 16px;
    border: 1px solid transparent;

    padding: 4px 16px;
    margin-right: 8px;

    @apply --fontSizeXS;

    & > span {
        display: inline-block;
        margin-right: 4px;
    }

    max-height: 28px;

    transition: 0.35s;

    &:hover {
        cursor: pointer;
        border: 1px solid #1a2b49;
    }
}

.sectionItemAction {
    &:hover {
        cursor: pointer;
    }
}

.sectionItemHolder {
    display: flex;
    flex-wrap: wrap;

    & > * {
        margin-bottom: 16px;
    }

    @media (--viewportSmallMax) {
        justify-content: center;

        & > * {
            margin-bottom: 16px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    @media (--viewportMedium) {
        align-items: center;

        & > * {
            flex-shrink: 0;
        }
    }
}

.sectionItemPublic {
    background: none;
    border: 1px solid #e6e6e6;

    &:hover {
        cursor: auto;
        border: 1px solid #e6e6e6;
    }
}

.recommendAndCheckAvailabilityHolder {
    display: flex;
    align-items: center;

    @media (--viewportSmallMax) {
        justify-content: space-between;
        width: 100%;
        margin-top: 32px;
        margin-bottom: 16px;

        & > div {
            margin: 0;
        }
    }

    @media (--viewportMedium) {
        & > div {
            margin-left: auto;
        }
    }
}

.favorite {
    display: flex;
}

.favoriteIcon {
    position: static !important;

    @media (--viewportMedium) {
        margin: 0 24px 0 16px;
    }

    & svg {
        stroke: #8f2593;

        & path {
            stroke-width: 2px;
        }
    }
}

.favoriteIconinWishlist {
    & svg {
        fill: #ff0000;
        stroke: #ff0000;
        margin: 0;
    }
}

.recommendAndCheckAvailabilityHolderPublic {
    @media (--viewportSmallMax) {
        margin-top: 24px;
    }
}
.recommendAndCheckAvailabilityHolderOwn {
    @media (--viewportSmallMax) {
        margin-top: 0;

        & > div {
            margin-left: auto;
        }
    }
}

.tooltipRecommendTarget {
    display: flex;

    @media (--viewportMedium) {
        margin: 0 24px;
    }
}

.inquiryDisabled {
    & path {
        stroke: #b2b2b2;
    }
}

.uploadAvatarRoot {
    & aside {
        margin: 0 0 16px 0;
    }

    & > label {
        width: 200px;

        & > div {
            margin-bottom: 16px;
        }
    }

    @media (--viewportMedium) {
        & aside {
            display: none;
        }
    }

    &.publicUserPreview {
        @media (--viewportSmallMax) {
            margin-top: 24px;
        }
    }
}

.uploadAvatarHiddenControls {
    & aside {
        display: none;
    }
}

.userIdentity {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (--viewportMedium) {
        margin-left: 32px;
        /* display: block; */
        align-items: flex-start;
    }

    & > p {
        display: flex;
        align-items: center;
        white-space: pre-wrap;
        margin-bottom: 16px;

        font-size: 16px;
        line-height: 24px;

        @media (--viewportMedium) {
            font-size: 18px;
            line-height: 28px;
        }
    }
}

.userPreferencesSection {
    display: none;

    margin-left: 0;
    margin-bottom: 16px;

    position: relative;

    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column-reverse;

    text-align: right;

    @media (--viewportMedium) {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        margin-bottom: 0;
        align-items: center;
    }
}

.profileLink {
    display: none;

    & svg {
        margin-right: 8px;

        fill: none;

        & path {
            fill: #8f2593;
        }
    }

    @media (--viewportMedium) {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        background: transparent;
        padding-top: 0;
    }

    @media (--viewportLarge) {
        width: 100%;
    }
}

.availabilityBadge {
    display: flex;
    align-items: center;

    font-weight: 400;

    &:hover {
        cursor: pointer;
    }
}
.recommendationsAllowed {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &:hover {
        cursor: pointer;
    }
    & svg {
        margin-right: 8px;
        margin-bottom: 4px;

        transform: scale(-1, 1);
        width: 20px;
        height: 20px;
    }
    & path {
        stroke: #8f2593;
    }
}
.noRecommendationsParapgraph {
    @apply --paragraphAntiSM;
}

.editExperience {
    margin-bottom: 16px;

    & svg {
        cursor: pointer;
    }
}

.experienceTooltip {
    width: 312px;
}

.experienceTooltipWrapper {
    display: inline-block;
}

.recommendUserTooltip {
    z-index: 1;
    max-width: 342px;
}

.recommendUserIcon {
    &:hover {
        cursor: pointer;
    }
}

.desktop {
    @media (--viewportSmallMax) {
        display: none !important;
    }
}

.mob {
    @media (--viewportMedium) {
        display: none !important;
    }
}

.smallIcon {
    width: 20px;
    height: 20px;
}

.bodyParamsMatches,
.bodyParamDoesNotMatch {
    & > svg {
        margin-right: 4px;
        margin-left: -8px;
    }
}

.bodyParamsMatches {
    &,
    &:hover {
        border: 1px solid #1e9f55;
    }

    & > svg {
        width: 16px;
        height: 16px;
    }
}

.bodyParamDoesNotMatch {
    &,
    &:hover {
        border: 1px solid var(--failColor);
    }

    & > svg {
        width: 12px;
        height: 12px;
        & path {
            fill: var(--failColor);
        }
    }
}
