@import '../../marketplace.css';

.heading {
    font-weight: 600;
}

.idpButtons {
    display: flex;
    column-gap: 16px;

    & > button {
        border: 1.5px solid;
        border-color: var(--matterColorNegative, #e6e6e6);
        background: var(--matterColorLight, #fff);
        border-radius: 50%;
        width: 48px;
        height: 48px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;

        &:hover {
            cursor: pointer;
            border-color: var(--matterColorDark, #1a2b49);
        }
    }
}
