@import '../../common.css';

.container {
    & .badge {
        @apply --badge;
        font-weight: 700;
        text-transform: unset;
        border: 1px solid #e6e6e6;
        margin: 0;

        &.selected {
            @apply --badge_selected;
        }
    }

    & > div {
        &:first-of-type {
            margin-bottom: 64px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.tabsNavigation {
    @apply --tabs_navigation;

    & .tab {
        @apply --tab;

        &.tabSelected {
            @apply --tab_selected;
        }
    }
}
