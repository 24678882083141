@import '../../common.css';

.heading {
    @apply --subPages_description;
    margin-bottom: 0;
}

.videoContentContainer {
    column-gap: 24px;
    align-items: flex-start;
}

.ourMissionDescription {
    & h3 {
        @apply --subPages_description;
    }
}

.videoHolder {
    @media (--viewportMediumMax) {
        height: auto;
    }
}
