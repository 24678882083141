@import '../../marketplace.css';
:root {
  --ProfilePage_header {
    color: #b2b2b2;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }
}
.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }
  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.userRatingTotalRating {
  font-style: normal;
  font-weight: 600;
  /* font-size: 14.62px; */
  /* line-height: 21px; */
  color: var(--marketplaceColor);
  margin: 4px 0 0 10px;
}
.error {
  color: var(--failColor);
}

.aside {
  box-shadow: none;
}
.contactButton {
  @apply --marketplaceModalBtnMainStyle;

  &:hover,
  &:focus {
    cursor: pointer;
  }
  text-decoration: none !important;

  min-height: 64px;
  width: 312px;

  &.disabled {
    @apply --marketplaceNotActiveBtnStyles;
    &:hover,
    &:focus {
      cursor: not-allowed;
      background-color: transparent;
    }
  }

  margin-top: 0;
}
.contactButtonWrapper {
  margin-top: 32px;
}
.calendarButtonWrapper {
  margin-top: 10px;
}
.asideContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-bottom: 1px solid var(--matterColorNegative); */
  margin: 0;
  padding-bottom: 26px;
  @media (--viewportLarge) {
    border-bottom: none;
    margin: 0 61px 44px 0;
    padding-bottom: 0;
  }
}
.contantsContainer {
  width: 312px;
}
.avatar {
  flex-shrink: 0;
  margin: 26px auto;
  height: 122px;
  width: 122px;
}

.mobileHeading {
  flex-shrink: 0;
  margin: 0 0 8px;
  font-size: 24px;
  line-height: 30px;

  /* @media (--viewportMedium) {
    margin: 49px 0 0 0;
  } */
  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkMobile {
  margin-top: 17px;

  /* Pull the link to the end of the container */
  margin-left: auto;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkDesktop {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.desktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0px 0 0px 0;
  }
}

.listingsContainer {
  @apply --clearfix;
  /* border-top: 1px solid var(--matterColorNegative);
  border-bottom: 1px solid var(--matterColorNegative); */
  padding-bottom: 23px;

  @media (--viewportMedium) {
    padding-bottom: 22px;
  }
}

.listingsTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 8px;
  }
  @media (--viewportLarge) {
    margin-top: 58px;
    margin-bottom: 8px;
  }
}

.withBioMissingAbove {
  /* Avoid extra borders in mobile when bio is missing */
  border-top: none;

  @media (--viewportLarge) {
    border-top: 1px solid var(--matterColorNegative);
  }
}

.listings {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.listing {
  width: 100%;

  /* Single column in mobile */
  margin-top: 34px;
  &:first-of-type {
    margin-top: 0;
  }

  @media (--viewportMedium) {
    /* Two columns in desktop */
    width: calc(50% - 12px);
    margin-bottom: 36px;

    /* Horizontal space */
    &:nth-of-type(odd) {
      margin-right: 12px;
    }
    &:nth-of-type(even) {
      margin-left: 12px;
    }

    /* Vertical space */
    margin-top: 0;
    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-top: 0;
    }
  }
}

.infoSection {
  word-break: break-word;
  margin-bottom: 30px;

  @media (--viewportLarge) {
    max-width: 600px;
  }
  & p {
    font-size: 18px;
    line-height: 24px;
    margin-top: 0;
  }
}
.infoItem {
  width: 47%;
  max-width: initial;
  padding: 11px 19px;

  @media (--viewportMedium) {
    width: 31%;
    &:nth-of-type(3n + 3) {
      margin-right: 0;
    }
  }
}

.carouselModalScrollLayer {
  @apply --marketplaceCarouselModalScrollLayer;
}
.carouselModalContainer {
  @apply --marketplaceCarouselModalContainer;
}

.otherInfoContainer {
  justify-content: space-between;
  /* max-width: 500px; */
}
.rootForImage {
  width: calc(50% - 6px);
  margin: 0 12px 12px 0;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
  }
  &:nth-of-type(even) {
    margin-right: 0;
  }
}
.galleryImagesWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contactButtonSecond {
  background-color: transparent;
  color: #1a2b49;
  border: 1px solid #e6e6e6;
  border-radius: 4px;

  &:hover {
    border-color: var(--matterColor);
  }
}
.noTransactionsAvailavleBtn {
  @apply --marketplaceNotActiveBtnStyles;
  color: var(--matterColorAnti);
  background-color: transparent;
  &:focus,
  &:hover {
    background-color: transparent;
    border-color: var(--matterColorNegative);
  }
}
.fieldItem {
  display: flex;
  align-items: center;
  color: #b2b2b2;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  & > svg {
    margin-right: 10px;
  }
}
.fieldVerified {
  color: limegreen;
}
.minorHeader {
  @apply (--ProfilePage_header);
  margin-top: 0;
}
.secondaryHeader {
  @apply (--ProfilePage_header);
  font-size: 18px;
  line-height: 27px;
  margin-top: 8px;
  margin-bottom: 30px;
}
.secondaryHeaderDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}
.secondaryHeaderMobile {
  @apply (--ProfilePage_header);
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 14px;

  @media (--viewportLarge) {
    display: none;
  }
}
.primaryHeader {
  font-size: 24px;
}
.infoWrapper {
  border-top: 1px solid var(--matterColorNegative);
  /* padding: 32px 0;
  margin-top: 44px; */
  padding-top: 32px;
}
.userRatingCotainer {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
}

.cusSeprator {
  border-top: 1px solid #e0e0e0;
  width: 100%;
  margin: 0 0 24px;

  @media (--viewportLarge) {
    display: none;
  }
}
.mobileAboutUser {
  font-size: 24px;
  line-height: 24px;
  color: #b2b2b2;
  font-weight: 600;
  margin-top: 0;

  @media (--viewportLarge) {
    display: none;
  }
}
.noDecoration,
.noDecoration:hover {
  text-decoration: none;
}
.singleWrapper {
  @media (--viewportSmallMax) {
    border: none;
    padding-top: 0;
  }
}

/* NL: from here is the css for the items we added */
/* this is a very static css that needs to be changed */
.noRatingsYetContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 90%;
  margin: auto;
  padding: 2em 0;

  @media (--viewportSmallMax) {
    width: 100%;
    padding: 1em 0;
  }
}
