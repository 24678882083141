@import '../../marketplace.css';

.viewProfileRequestAction {
  @apply --marketplaceGradientLine;

  bottom: 0;

  @apply --fontSizeXS;
  color: #8f2593;

  & svg {
    margin-right: 4px;
    fill: none;

    & path {
      stroke: #8f2593;
      stroke-width: 1.125px;
    }
  }

  & > div {
    display: flex;
    align-items: center;

    position: absolute;
    bottom: 24px;
    left: 24px;

    &:hover {
      cursor: pointer;
    }

    & a:hover {
      text-decoration: underline;
    }
  }
}

.link {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
