@import '../../marketplace.css';

.root {
    position: relative;
    display: inline-block;
}

.label {
    @apply --marketplaceButtonStylesSecondary;
    @apply --marketplaceSearchFilterLabelFontStyles;

    padding: 9px 16px 10px 16px;
    width: auto;
    height: auto;
    min-height: 0;
    border-radius: 4px;

    &:focus {
        outline: none;
        background-color: var(--matterColorLight);
        border-color: transparent;
        text-decoration: none;
        box-shadow: var(--boxShadowFilterButton);
    }
}

.popupContainer {
    display: block;
    @media (--viewportMedium) {
        display: inline-block;
    }
}

.labelSelected {
    @apply --marketplaceButtonStyles;
    @apply --marketplaceSearchFilterLabelFontStyles;
    font-weight: var(--fontWeightRegular);

    padding: 9px 16px 10px 16px;
    width: auto;
    height: auto;
    min-height: 0;
    border-radius: 4px;
    border: 2px solid var(--matterColor);

    &:hover,
    &:focus {
        border: 1px solid var(--matterColor);
    }
}

.fieldGroup {
    margin: 0;
    padding: 0;
    border: none;
}

.fieldGroupPlain {
    margin: 0;
    padding: 0;
    border: none;
}

.list {
    margin: 0;
}

.item {
    /* Fix broken multi-column layout in Chrome */
    page-break-inside: avoid;

    & .itemCheckboxItem {
        &,
        &:hover {
            border: none;

            &:last-of-type {
                border-bottom: none;
            }
        }

        & label {
            padding: 8px 16px;
        }
    }
}
.threeColumns {
    columns: 3;
}
.groupFilterContainer {
    & [data-role='clear'] {
        display: none;
    }
}
.groupSubLabel {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
}
.twoColumns {
    columns: 2;
}

.categoryName {
    @apply --fontSizeXL;
    font-weight: 700;

    padding: 12px 16px;
    margin: 0;
}
