@import '../../marketplace.css';

.root {
  width: 100%;
  /* border-radius: 4px; */
}

.noImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--matterColorNegative);
}

.noImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & svg {
    width: 72px;
    height: 72px;
  }
}

.noImageIcon {
  padding-top: 24px;
  box-sizing: content-box;
  stroke: var(--matterColor);
}
