@import '../../marketplace.css';

.pickerSuccess {
    & :global(input.DateInput_input) {
        border-bottom-color: var(--successColor);
    }
}

.pickerError {
    & :global(input.DateInput_input) {
        border-bottom-color: var(--failColor);
    }
}
