@import '../../styles-account.css';

.sideNavigationLayout {
    z-index: 1;
}

.footer {
    z-index: 1;
}

.containerClassName {
    & > [role='main'] {
        background: none;

        & > svg:first-of-type {
            display: none !important;
        }
    }
}
