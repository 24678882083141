@import '../../marketplace.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
  }
}

.firstNameRoot {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    width: calc(50% - 9px);
    margin-bottom: 0;
  }
}

.lastNameRoot {
  @media (--viewportMedium) {
    width: calc(50% - 9px);
  }
}

.password,
.userType {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  margin-top: 32px;
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
  @apply (--authSubsection);
  text-align: center;
}
