@import '../../../../marketplace.css';
@import '../common.css';

.root {
  @apply --sectionRoot;
}

.title {
  @apply --sectionTitle;

  padding-bottom: 32px;
}

.sectionContent1 {
  @apply --fontSizeSM;

  display: flex;
  flex-direction: column;

  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid #e6e6e6;

  @media (--viewportMedium) {
    @apply --fontSizeMM;

    flex-direction: row;
  }
}

.sectionContent2 {
  @apply --fontSizeSM;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (--viewportMedium) {
    @apply --fontSizeMM;

    flex-direction: row;
    flex-wrap: wrap;
  }
}

.item1 {
  width: auto;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    width: calc(100% / 3);
    margin-bottom: 0;
  }
}

.item2 {
  width: auto;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    width: calc(100% / 3);
    margin-bottom: 24px;
  }
}

.subTitle {
  @apply --sectionSubTitle;
}

.itemFlex {
  display: flex;
  align-items: center;

  & svg {
    margin-right: 8px;
  }
}

.disabled {
  color: #b2b2b2;
  text-decoration-line: line-through;
}

.contentHolder {
  position: relative;
}

.contentAndTooltipWrapper {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.tooltip {
  display: flex;
  align-items: center;
}

.tooltip > div {
  line-height: 18px;
}

.selectedIcon {
  & path {
    stroke: #8f2593;
  }
}

.notSelectedIcon {
  & path {
    stroke: #b2b2b2;
  }
}
