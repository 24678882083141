@import '../../marketplace.css';

.topbar {
    z-index: 1;
}

.desktopTopbar,
.mobileTopbar {
    box-shadow: none;

    @media (--viewportLarge) {
        box-shadow: var(--boxShadowLight);
    }
}

.mobileTopbar {
    /* Size */
    width: 100%;
    height: var(--topbarHeight);

    /* Layout for child components */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    /* fill */
    /* background-color: #fcfcfc; */

    /* shadows */
    box-shadow: var(--boxShadow);

    @media (--viewportMedium) {
        display: none;
    }
}

.wizard {
    flex-grow: 1;
}

.layoutWrapperMain {
    @media (--viewportMediumMax) {
    }
}
