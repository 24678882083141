@import '../../marketplace.css';

.root {
    display: block;
    position: relative;
    height: auto;
    border: 0;
    padding: 0;
    font-family: 'sofiapro', Helvetica, Arial, sans-serif;
    /* Borders */
    border-radius: 8px;
    box-shadow: var(--boxShadowPopupLight);
    background: white;
    width: 312px;

    &:hover {
        cursor: pointer;
        box-shadow: var(--boxShadowPopup);
    }
}
