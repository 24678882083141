@import '../../../../marketplace.css';

.root {
    display: flex;
    justify-content: space-between;

    @apply --fontSizeMM;

    color: #b2b2b2;

    margin-bottom: 16px;
    margin-top: 28px;
    width: 100%;

    @media (--viewportMedium) {
        @apply --fontSizeM;
        margin-top: 64px;
    }
}
