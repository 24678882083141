@import '../../marketplace.css';

.formRowAdaptive {
    display: block;
    @media (--viewportMedium) {
        display: flex;

        & > * {
            width: 50%;
        }
    }
}

.formRowLocation {
    @media (--viewportMedium) {
        & > div:nth-of-type(1) {
            margin-right: 16px;
        }
    }
}

.formRowLocationMain {
    & > div:nth-of-type(1) {
        margin-top: 0;
    }

    @media (--viewportMedium) {
        & > div:nth-of-type(2) {
            margin-top: 0;
        }
    }
}

.fieldItem {
    position: relative;
    margin-top: 24px;

    & input,
    & textarea,
    & select {
        font-size: 18px;
        line-height: 27px;
    }

    & label {
        @apply --fontSizeMM;
        font-weight: 400;

        padding-bottom: 4px;
    }

    @media (--viewportSmallMax) {
        flex-basis: calc(100% - 12px);
    }

    & textarea {
        @media (--viewportSmallMax) {
            max-height: 200px;
        }
    }
}

.inputLocationWrapper {
    & input {
        border-left: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
        border-top: 1px solid #e6e6e6;
        border-radius: 4px 4px 0 0;
    }
}

.notLocationFound {
    @apply --paragraphAntiSM;
    color: #8f2593;
    margin-top: 8px;
    margin-bottom: 0;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.locationPrediction {
    @apply --locationPredictionsPrimary;
}

.mapSection {
    height: 200px;
    margin-bottom: 24px;

    border-radius: 8px;
    overflow: hidden;
}
