@import '../common.css';

.prioritiesHolder {
    margin: 64px auto;
    max-width: 760px;
    text-align: left;

    & .subHeading,
    & .heading {
        text-align: center;
        @media (--viewportMedium) {
            text-align: left;
        }
    }

    & .heading {
        margin-bottom: 24px;
    }

    & h3 {
        margin: 0 0 16px 0;
    }

    & p {
        margin-bottom: 24px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
