@import '../../marketplace.css';

.sliderWrapper {
    height: 100%;
    padding-top: 0 !important;

    & > div {
        height: 100%;
    }
    & ul {
        height: 100%;
        & li {
            height: 100%;
            & img {
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.video {
    width: 100%;
    height: 100%;
    /* min-width: calc(100vw + 80px);

    @media (--viewportLarge) {
        min-width: 40vw;
    }

    @media (--viewportMedium) {
        min-width: 60vw;
    } */
}
