@import '../../marketplace.css';

.root {
    outline: none;
}

.contentWrapper > fieldset:last-of-type {
    margin-bottom: 24px !important;
}

.buttonsWrapper {
    display: flex;
    padding: 16px 24px 6px 24px;
    border-top: 1px solid #e6e6e6;

    position: sticky;
    bottom: -1px;
    z-index: 1;

    background: white;
}

.clearButton {
    @apply --marketplaceFilterClearButton;

    /* Layout */
    margin: 0 auto 0 0;
    padding: 0;

    border: none;
    cursor: pointer;
    text-decoration-line: underline;

    &:focus,
    &:hover {
        color: var(--matterColor);
        transition: width var(--transitionStyleButton);
    }

    &:disabled {
        color: #b2b2b2;

        &:hover {
            cursor: not-allowed;
        }
    }
}

.submitButton {
    @apply --fontSizeXS;
    font-weight: 600;
    color: var(--matterColorBright);
    background-color: var(--marketplaceColor);

    border-radius: 5px;
    /* Layout */
    margin: 0 0 0 19px;
    padding: 7px 11px;

    /* Override button styles */
    outline: none;
    border: none;
    cursor: pointer;

    &:disabled {
        background-color: #e6e6e6;
        color: #b2b2b2;

        &:hover {
            cursor: not-allowed;
        }
    }
}
