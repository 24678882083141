@import '../../marketplace.css';

.dumbInputRoot {
    @apply --inputFieldWrapper;
    max-width: 294px;
}
.dumbFieldIconHolder {
    @apply --inputFieldIconHolder;
}

.noResults {
    margin-bottom: 0;
    margin-top: 0;

    max-width: 632px;
    min-height: 55vh;

    @media (--viewportMedium) {
        margin: 81px 0 0 166px;
    }
    & h3 {
        margin-top: 0;
    }

    & h1 {
        /* Font */
        @apply --fontSizeXL;
        color: var(--matterColor);
        margin-top: 0;
        margin-bottom: 16px;

        font-weight: 700;
        @media (--viewportMedium) {
            @apply --fontSizeXXL;
        }
    }

    & p {
        color: var(--matterColor);
        margin-top: 0;
        margin-bottom: 32px;
    }

    & button {
        width: 261px;
    }
}

.buttonContainer {
    display: flex;
    flex-direction: column;

    @media (--viewportMedium) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.link {
    margin-bottom: 2rem;
    width: 100% !important;
    color: var(--matterColor);
}

.button {
    margin-bottom: 2rem;
    width: 100% !important;
}
