@import './MissingPhoneNumberModal.css';

.contactDetailsSection {
    display: block;
    margin-bottom: 40px;
    position: relative;

    & > div:nth-of-type(1) {
        margin-bottom: 24px;
    }

    @media (--viewportMedium) {
        display: flex;

        & > *:nth-of-type(1) {
            width: 144px;
            margin-right: 16px;
            margin-bottom: 0;
        }

        & > *:nth-of-type(2) {
            flex: 1;
        }
    }
}
