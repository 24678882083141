@import '../../marketplace.css';

.statusHolder {
  border: 1px solid var(--matterColorNegative);
  padding: 14px 24px;
  margin-bottom: 21px;

  z-index: 9;

  background: var(--matterColorBright);
  border-radius: 0;

  width: 100%;
  min-width: 375px;

  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  border-left: 0;
  border-right: 0;

  & *[data-role="link"] {

    &:hover{
      cursor: pointer;
    }
  }

  @media(--viewportLarge) {
    position: static;
    border-radius: 5px;
    border-left: 1px solid var(--matterColorNegative);
    border-right: 1px solid var(--matterColorNegative);
  }

  @media(--viewportMedium) {
    top: 72px;
  }
}

.statusPanel {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 19px;
    line-height: 24px;
  }
  .notificationTab {
    display: flex;
    justify-content: center;
    padding: 11px 15px 12px;
    align-items: center;

    background: rgba(209, 225, 244, 0.5);
    border: 1px solid #607D9F;
    box-sizing: border-box;
    border-radius: 4px;

    margin-top: 2px;

    white-space: nowrap;

    & svg {
      fill: none;
    }

    & svg[data-role="close"] {
      flex-shrink: 0;

      &:hover {
        cursor: pointer;
      }
    }
@media(--viewportLarge) {
  padding: 13px 16px;
  margin-top: 14px;
  justify-content: space-between;
    & svg[data-role="close"] {
    margin-bottom: 0;
  }
  }
}
  .notificationBody {
    color: #607D9F;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
    padding: 0 12px 0 8px;
    & > span:nth-of-type(2) {
      text-decoration: underline;
    }
@media(--viewportLarge) {
  padding: 0;
}
  }
