@import '../../marketplace.css';

:root {
    /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

    --flexAlignY: {
        display: flex;
        align-items: center;
    }

    --overlay: {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    --placeholder: {
        background: #e6e6e6;
        border-radius: 16px;
    }
}

.root {
    /* Layout */
    display: flex;
    flex-direction: column;
    position: relative;
    background: white;

    width: 100%;

    @apply --listing_card_wrapper;

    @media (--viewportMedium) {
        max-width: calc(50% - 16px);
    }

    @media (--viewportLarge) {
        max-width: calc(33.3333% - 22px);
    }
}

.threeToTwoWrapper {
    display: block;
    width: 100%;
    position: relative;
}

.overlay {
    @apply --flexAlignY;

    justify-content: center;
    flex-direction: column;

    pointer-events: none;

    z-index: 1;
    @apply --fontSizeXS;
    @apply --overlay;

    background: rgba(255, 255, 255, 0.8);

    text-align: center;
}

.overlayActionBtn {
    @apply --fontSizeXS;

    min-height: 40px;
    margin: 0 auto;
    padding: 6px 16px;
    width: auto;

    pointer-events: auto;

    border-radius: 4px;
}

.overlayContent {
    max-width: 270px;
}

.aspectWrapper {
    padding-bottom: 66.6667%;
    background: #f9f4fa;
}

.horseListingSection {
    @apply --flexAlignY;
    justify-content: space-between;
}

.listingInfoSection {
    padding: 16px;
}

.assetHolder {
    display: flex;
    background-size: cover;
    background-position: center;
    @apply --overlay;

    outline: none;

    overflow: hidden;
    transition: 0.35s;

    z-index: 1;
}

.navigationAllowed {
    &:hover {
        cursor: pointer;
    }
}

.divider {
    height: 1px;
    width: 100%;
    background-color: #e6e6e6;
    margin: 16px 0;
}

.createdAtIcon {
    & path {
        fill: #b2b2b2;
    }
}

.listingCreationInfo {
    @apply --flexAlignY;

    @apply --paragraphAntiSM;

    & svg {
        flex-shrink: 0;
        margin-right: 6px;

        width: 16px;
        height: 16px;
    }

    & .placeholder {
        @apply --placeholder;
        width: 88px;
        height: 14px;
    }
}

.price {
    /* Layout */
    @apply --flexAlignY;
    justify-content: flex-start;
    flex-shrink: 0;
}

.priceValue {
    @apply --fontSizeXL;
    color: var(--marketplaceColor);
    font-weight: 700;

    margin-right: 6px;
}

.perUnit {
    /* Font */
    @apply --marketplaceTinyFontStyles;
    color: var(--matterColor);
    font-size: 14px;
    line-height: 18px;
    /* Remove default margins from font */
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.horseIdentitySection {
    @apply --flexAlignY;
    justify-content: space-between;

    & h4 {
        @apply --fontSizeXL;
        font-weight: 700;
        margin: 0;

        max-width: 205px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.placeholder {
            width: 144px;
            height: 32px;
            @apply --placeholder;
        }
    }
}

.statusBadge {
    @apply --flexAlignY;
    @apply --fontSizeXS;

    padding: 4px 8px;
    border-radius: 16px;

    background: #f9f9f9;

    & > code {
        width: 16px;
        height: 16px;
        border-radius: 50%;

        margin-right: 8px;
    }
}

.draft {
    color: #b2b2b2;

    & > code {
        border: 3px solid #b2b2b2;
        background: white;
    }
}

.pendingApproval {
    & > code {
        background: #ffaa00;
    }
}

.published {
    & > code {
        background: #1e9f55;
    }
}

.closed {
    color: #ff0000;

    & > code {
        border: 3px solid #ff0000;
        background: white;
    }
}

.viewsNum {
    @apply --badge-status;
    column-gap: 8px;

    position: absolute;
    left: 16px;
    top: 16px;

    z-index: 3;

    background: white;
}

.pricePlaceholder {
    @apply --placeholder;
    width: 144px;
    height: 32px;
}
