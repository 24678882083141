@import '../../marketplace.css';

.toggleTextWrapper {
  position: relative;
}

.toggleButton {
  @apply --marketplaceToggleTextBtn;
}
.toggleButtonExpanded {
  @apply --withArrowIconUp;

  @media (--viewportMedium) {
    width: 170px;
  }
}
.gradient {
  @apply --marketplaceGradientLine;
}
.overflow {
  overflow: hidden;
}

.toggler {
  display: flex;
  margin-left: 6px;
}
