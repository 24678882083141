@import '../../marketplace.css';

.root {
    border-radius: 8px;
    border: 1px solid var(--matterColorNegative);
    background: #fff;

    margin-bottom: 24px;

    position: relative;
    padding: 20px 16px 16px 16px;

    & > * {
        display: flex;
    }

    & .icon {
        @apply --roundedBadgeWithIconInsideM;
    }

    & > .heading {
        align-items: center;
        justify-content: space-between;

        font-size: 18px;
        font-weight: 600;

        & p {
            margin: 0;
            font-weight: 600;
        }
    }

    & .toggler label {
        padding: 0;
        & > span {
            margin: 0;
        }
    }

    & .teaser {
        @apply --fontSizeSM;
        font-weight: 600;
        color: var(--marketplaceColor);
        margin-bottom: 8px;
    }

    & .description {
        display: inline-block;
        margin-bottom: 16px;

        @apply --fontSizeXS;
    }

    & .badge {
        @apply --badge-new-item;
        padding: 0 8px;
        min-width: 41px;
        width: auto;
    }

    & .footer {
        justify-content: space-between;

        & .badge {
            &:first-of-type {
                color: #1e9f55;
                background-color: #f0fff6;
            }
            &:last-of-type {
                color: var(--marketplaceColor);
                background-color: var(--marketplaceColorFade);
            }
        }
    }
}
