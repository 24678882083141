@import '../../marketplace.css';

.root {
    max-width: 528px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 24px 36px 24px;

    position: relative;

    @media (--viewportSmallMax) {
        padding: 0 24px 24px 24px;
    }

    & form {
        transition: 0.5s;
    }
}

.form {
    & button {
        margin-bottom: 40px;
    }

    @media (--viewportSmallMax) {
        & [datatype='preview-button'] {
            bottom: 140px;
        }
    }
}

.addImagesRoot {
    @media (--viewportSmallMax) {
        max-width: calc(100vw - 48px);
    }
}

.backButton {
    margin-top: 64px;
    min-width: 480px;

    color: #1a2b49;

    display: flex;
    align-items: center;

    @apply --fontSizeM;

    & svg {
        margin-right: 8px;
        transform: rotate(180deg);

        & path {
            stroke: #1a2b49;
            fill: #1a2b49;
        }
    }

    &:hover {
        cursor: pointer;
    }

    @media (--viewportSmallMax) {
        @apply --fontSizeSM;

        margin-top: 24px;
        min-width: auto;
    }
}
.collectInfoHeader {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.5px;

    @media (--viewportSmallMax) {
        font-size: 24px;
        line-height: 32px;
    }
}

.msgSection {
    display: flex;
    align-items: center;
    justify-content: center;

    @apply --paragraphAntiSM;
    margin-bottom: 16px;
    margin-top: 32px;

    & > svg {
        width: 16px;
        height: 16px;
        fill: none;
        margin-right: 6px;

        flex-shrink: 0;
    }
}
.collectInfoDesc {
    margin-bottom: 32px;
}

.error {
    @apply --fontSizeSM;
    color: var(--failColor);
}

.sendRecommendationFtr {
    @media (--viewportSmallMax) {
        width: 100%;
        padding: 24px;

        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: 1px solid #e6e6e6;
        background: #fff;
        z-index: 3;

        & button {
            margin: 0;
        }
    }
}
