@import '../../marketplace.css';

.preview {
    width: 195px;
    margin: auto;

    position: sticky;
    z-index: 3;
    bottom: 32px;
    left: 0;
    right: 0;
}

.scrollLayer {
    background-color: #fff !important;
}

.modalContainer {
    flex-basis: 100%;
    height: 100vh;
    margin: 0;
    border-radius: 0;

    & h4 {
        @apply --fontSizeXL;
        font-weight: 700;
        margin: 0 0 32px 0;
    }
}

.modalContent {
    max-width: 718px;
    margin: auto;
    padding-bottom: 24px;
}

.delimiter {
    height: 1px;
    background-color: var(--matterColorNegative);
    margin: 32px 0;
}
