@import '../../marketplace.css';

:root {
}

.root {
    max-width: 960px;

    margin: 24px auto 40px auto;

    position: relative;
    text-align: center;

    @media (--viewportMedium) {
        margin-top: 64px;
        margin: 64px auto 80px auto;
    }

    & > h2 {
        margin: 16px 0;
        padding: 0 16px;

        @media (--viewportMedium) {
            padding: 0 228px;
        }
    }

    & > p {
        margin: 0;
        padding: 0 50px;

        @media (--viewportMedium) {
            padding: 0 228px;
        }
    }
}

.listingsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;

    & > li {
        list-style: none;

        width: calc(100% - 48px);

        margin-right: 24px;
        margin-left: 24px;

        @media (--viewportMedium) {
            width: calc(50% - 48px);
        }
    }
}

.visibilityNotification {
    position: fixed;
    left: 16px;
    right: 16px;
    bottom: 16px;

    z-index: 5;
}

.emailVerifiedOverlay {
    @media (--viewportSmallMax) {
        bottom: 128px;
    }
}

.visibilityHolder {
    display: flex;
    align-items: center;
    max-width: 720px;

    margin: 0 auto;
    position: relative;

    @media (--viewportSmallMax) {
        display: block;
    }

    & > svg {
        display: none;

        position: relative;
        top: -26px;

        @media (--viewportMedium) {
            display: block;
        }
    }

    & a {
        display: block;

        width: 172px;
        flex-shrink: 0;

        @apply --fontSizeXS;

        padding: 10px 16px;
        margin-left: auto;
        margin-top: 16px;

        border: 1px solid #ffaa00;
        background: #ffaa00;
        color: white;
        border-radius: 4px;

        @media (--viewportMedium) {
            margin-left: initial;
            margin-top: 0;
        }

        &,
        &:hover {
            text-decoration: none;
        }
    }
}

.emailVerifiedOverlayShifted {
    @media (--viewportSmallMax) {
        bottom: 80px;
    }
}

.gradientOverlay {
    height: 500px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #ffffff 100%);

    pointer-events: none;

    z-index: 1;

    @media (--viewportMedium) {
        height: 600px;
    }
}

.profileNotComplete {
    max-height: 700px;
    overflow: hidden;

    @media (--viewportMedium) {
        max-height: 600px;
    }
}
