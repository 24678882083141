@import '../../marketplace.css';

.fieldRoot {
}

.labels {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.startDateLabel,
.endDateLabel {
  flex-basis: 50%;
  @apply --marketplaceH4FontStyles;
  font-weight: 600;
  color: var(--matterColor);
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
  }

  @media (--viewportMedium) {
    padding-bottom: 1px;
  }
}

.labelSuccess {
  color: var(--successColor);
}

.inputBorders {
  width: 100%;
  display: flex;
  justify-content: space-between;
  transition: all 0.15s ease-out;
}

.input {
  flex-basis: 100%;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: var(--attentionColor);

  transition: border-bottom-color var(--transitionStyle);
  transition: all 0.15s ease-out;

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColor) !important;
    outline: none;
  }

  @media (--viewportMedium) {
    border-bottom-width: 3px;
  }
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.hover {
  border-bottom-color: var(--matterColor);
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.mobileMargins {
  /* Mobile Safari couldn't handle width: calc(100vw - 48px); */
  width: calc(100vw - 48px);

  /* Gutter between inputs (when calendar is not visible) */
  & .startDateLabel {
    flex-basis: 100%;
  }
  & .endDateLabel {
    /* Mobile Safari couldn't handle width: calc(50% - 6px); */
    flex-basis: 0;
    display: none;
  }

  & .input {
    /* Mobile Safari couldn't handle width: calc(50% - 6px); */
    flex-basis: 100%;
    transition: all 0.15s ease-out;
  }

  @media (--viewportMedium) {
    width: 100%;
    margin: 0;

    /* Gutter between inputs (when calendar is not visible) */
    /* & .startDateLabel,
    & .endDateLabel {
      flex-basis: calc(50% - 12px);
    } */

    & .input {
      /* flex-basis: calc(50% - 12px); */
      transition: all 0.15s ease-out;
    }
  }
}
