@import '../../marketplace.css';

.wrapper {
    border-radius: 8px;
    border: 1px solid var(--matterColorNegative, #e6e6e6);
    background: #fff;

    position: relative;

    max-width: 362px;
    width: calc(33% - 18px);

    transition: 0.15s;

    &.locked {
        & .container {
            &:hover {
                cursor: initial;
            }
        }

        &:hover {
            border: 1px solid transparent;
            box-shadow: 0px 0px 0px 4px #8f2593;
        }
    }

    @media (--viewportSmallMax) {
        width: 100%;
    }
}

.lockedCardOverlay {
    position: absolute;
    top: 7px;
    bottom: 7px;
    left: 0;
    right: 0;
    z-index: 4;

    & > button {
        display: none;
    }

    &:hover {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        & > button {
            display: block;
            width: 180px;
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;

    position: relative;

    padding: 24px 16px;

    @media (--viewportSmallMax) {
        row-gap: 16px;
    }

    &:hover {
        cursor: pointer;
    }
}

.visitedAt {
    @apply --paragraphAntiSM;
}

.matchingContainer {
    & p {
        margin-bottom: 0;
    }
}

.matchingContainerPlaceholder {
    width: 212px;
    height: 44px;
    border-radius: 24px;
    background: var(--matterColorNegative, #e6e6e6);
}

.imagesHolder {
    display: flex;
    align-items: center;
    justify-content: center;

    &.locked {
        & .avatar {
            overflow: hidden;
        }

        & > div {
            filter: blur(5px);
        }
    }

    & .avatar {
        width: 96px;
        height: 96px;

        border: 4px solid #fff;
        position: relative;
        z-index: 2;

        &:hover {
            cursor: pointer;
        }
    }

    & .listingImage {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background-size: cover;

        position: relative;
        left: -10px;
        z-index: 1;

        &:hover {
            cursor: pointer;
        }
    }
}
