@import '../../marketplace.css';

.dotGroup {
    @apply --dotGroup;

    display: flex;
    align-items: center;

    top: 0;
}
