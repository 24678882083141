@import '../../marketplace.css';

:root {
    --Map_control_element {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 40px;
        height: 40px;

        z-index: 2;
        position: absolute;

        background: white;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

        &:hover {
            cursor: pointer;
        }
    }
}

@keyframes loadingMap {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0.5;
    }

    50% {
        opacity: 0.25;
    }

    75% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.zoomControlElemIn,
.zoomControlElemOut {
    @apply --Map_control_element;

    right: 24px;

    &.disabled {
        background: #e6e6e6;

        & path {
            fill: #b2b2b2;
        }
    }

    & svg {
        width: 22px;
        height: 22px;
        transition: 0.35s;

        margin-left: -1px;
    }
}

.zoomControlElemIn {
    top: 96px;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 2px solid #e6e6e6;

    @media (--viewportLarge) {
        top: 24px;
    }
}

.zoomControlElemOut {
    top: 136px;
    border-radius: 0px 0px 4px 4px;

    @media (--viewportLarge) {
        top: 64px;
    }
}

.currentLocationControlElem {
    @apply --Map_control_element;
    border-radius: 4px;

    top: 192px;
    right: 24px;

    @media (--viewportLarge) {
        top: 120px;
    }
}

.currentLocationMarkerFilled {
    & path {
        fill: #8f2593;
    }
}

.searchPanelOpenControl,
.searchPanelCloseControl {
    @apply --Map_control_element;
    border-radius: 4px;

    left: 24px;
    top: 24px;
    @media (--viewportMediumMax) {
        display: none;
    }
}

.searchPanelCloseControlMob {
    @apply --Map_control_element;
    position: fixed;
    width: auto;
    height: auto;

    @apply --fontSizeSM;

    border-radius: 37px;
    border: 1px solid transparent;
    padding: 10px 16px;

    bottom: 24px;
    left: calc(50% - 48px);

    transition: 0.35s;

    &:hover {
        border: 1px solid #1a2b49;
    }

    & svg {
        margin-right: 8px;
    }

    @media (--viewportLarge) {
        display: none;
    }
}

.searchPanelLoadingIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;

    position: absolute;
    z-index: 2;
    background: white;
    width: 64px;
    height: 40px;
    top: 94px;
    left: calc(50% - 32px);
    border-radius: 32px;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

    @media (--viewportLarge) {
        top: 24px;
    }

    & > code {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #8f2593;
        /* transition: .75s; */
        opacity: 1;

        animation-name: loadingMap;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-delay: 0.5s;

        &:first-of-type {
            animation-delay: 0s;
        }

        &:last-of-type {
            animation-delay: 0.75s;
        }
    }
}

.searchMapInfoCardMob {
    position: fixed;
    bottom: 24px;
    left: calc(50% - 156px);

    z-index: 55;

    width: 312px;
    background: white;
    border-radius: 8px;
}
