@import '../../marketplace.css';

:root {
    --actionButtonsPanel {
        z-index: 9;
        position: relative;
        width: 100%;
        padding: 0;
        margin-top: auto;
        /* Contain repainting to this component only */
        /* 3D painting container helps scrolling */
        transform: translate3d(0, 0, 0);

        @media (--viewportLarge) {
            z-index: unset;
            position: static;
            box-shadow: none;
            width: auto;
        }
    }

    --disabled {
        & button {
            &:disabled {
                color: var(--matterColorAnti);
            }
        }
    }
    /*
    * SponsoredChatLabel
    * AcceptDeclineRequestButtonsMaybe
    */
    --actionLabelRoot {
        position: relative;

        border: 1px solid hsl(0, 0%, 90%);
        border-radius: 4px;

        margin: 24px auto 24px 0;
        width: 100%;

        padding: 20px 16px 16px 16px;

        @media (--viewportMedium) {
            max-width: 548px;
        }

        & .roundedBadge {
            @apply --roundedBadgeWithIconInsideM;
        }

        & > h4 {
            @apply --fontSizeMM;
            font-weight: 600;
            margin-bottom: 8px;
        }

        & > p {
            @apply --paragraphAntiSM;
            margin-bottom: 0;
        }
    }
}

.root {
    position: relative;

    @apply --disabled;

    height: calc(100% - 48px);

    @media (--viewportMediumMax) {
        height: 100%;
    }
}

.navSection {
    & h2,
    & h3 {
        margin: 0;

        & svg {
            &:hover {
                cursor: pointer;
            }

            margin-left: 10px;
            margin-bottom: 3px;
        }

        @media (--viewportMediumMax) {
            font-size: 16px !important;
            line-height: 24px !important;
            font-weight: 600;
        }

        @media (--viewportLarge) {
            & svg {
                display: none;
            }
        }
    }

    & > button {
        margin-left: auto;
    }
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px 24px 0 24px;

    height: 100%;

    /* tx subscription requested footer*/

    @media (--viewportLarge) {
        flex-direction: row;
    }

    @media (--viewportXXLarge) {
        max-width: 1056px;
    }
}

.containerNoFooter {
    @media (--viewportMediumMax) {
        margin-bottom: 0;
    }
}

.txInfo {
    display: flex;
    flex-direction: column;

    flex: 1;

    @media (--viewportLarge) {
        max-width: 548px;
        margin: 0 auto;
    }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
    transition: 0.15s;
    /*padding-bottom: 66.6667%; !* 3:2 Aspect Ratio *!*/
    padding-bottom: 42.937%;
    background-color: var(--matterColorNegative); /* Loading BG color */
    @media (--viewportLarge) {
        padding-bottom: 66.6667%;
    }
}

.detailAvatarMobile {
    margin-top: -32px;
    z-index: 1;
    position: relative;
    @media (--viewportLarge) {
        display: none;
    }
}

.infoLine {
    position: absolute;
    top: 31px;
    left: -4px;
    z-index: 1;
}
.containerWithoutShowStatusPanel {
    margin-top: -55.45px;
    @media (--viewportLarge) {
        margin-top: 0;
    }
}
.ridingsNumButton {
    position: absolute;
    left: -4px;
    top: -4px;
    margin: 0;
    font-size: 13px;
    line-height: 16px;
    box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
    font-weight: normal;
    padding: 9px 12.38px 10px 13px;
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
    outline: none;
    z-index: 1;
    /*pointer-events: none;*/
    min-width: 105.38px;

    @media (--viewportLarge) {
        display: none;
    }
}
.tooltip {
    min-width: 260px;
    bottom: 130%;
    z-index: 10;
}

.detailCardMobile {
    display: block;
    position: relative;
    margin: -24px -24px 0 -24px;

    overflow: hidden;
    @media (--viewportLarge) {
        display: none;
    }
}

/* Prop to be passed to SendMessageForm component */
.sendMessageForm {
    /* position: relative; */
    margin-top: auto;

    margin: auto -24px 0 -24px;
    padding: 24px 24px 32px 24px;

    position: sticky;
    bottom: 0;

    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (--viewportMedium) {
        border-top: 0;
    }

    transition: 0.35s;

    & textarea {
        min-height: 90px;

        @media (--viewportMedium) {
            min-height: 105px;
        }
    }
}

.sendMessageFormHovered {
    & textarea {
        background-color: #e5edf1;
        border-color: #92b0b3;
    }
}

/* SaleActionButtonsMaybe subcomponent */
.actionButtons {
    /* Position action button row above the footer */
    @apply --actionButtonsPanel;
}

.actionButtonWrapper {
    width: 100%;
    display: block;
    margin-top: 12px;

    @media (--viewportLarge) {
        display: flex;
        flex-direction: row;
        flex-direction: column;
    }

    & button:first-child {
        margin: 0 12px 0 0;

        @media (--viewportLarge) {
            margin: 8px 0 0 0;

            /* Switch order in desktop layout with accept button being on the top */
            order: 1;
        }
    }
}

.actionError {
    @apply --marketplaceH5FontStyles;
    color: var(--failColor);
    margin: 0 0 11px 0;
    font-size: 13px;
    width: 100%;
    text-align: center;

    @media (--viewportMedium) {
        margin: 0 0 10px 0;
    }
    @media (--viewportLarge) {
        margin: 0;
    }
}

.openBookingForm {
    /* Ensure that mobile button is over Footer too */
    z-index: 9;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 18px 24px 18px 16px;
    background-color: var(--matterColorLight);
    box-shadow: var(--boxShadowTop);
    /* display: grid; */
    display: block;
    grid-template-columns: 1fr 5fr;
    column-gap: 24px;
    /* Contain repainting to this component only */
    /* 3D painting container helps scrolling */
    transform: translate3d(0, 0, 0);

    @media (--viewportLarge) {
        display: none;
    }

    @media (--viewportMedium) {
        padding: 18px 60px 18px 60px;
    }
}

.contactButton {
    @apply --marketplaceButtonStylesPrimary;
}

.modalHeader {
    @apply --marketplaceModalTitleStyles;
}
.modalBody {
    @apply --marketplaceModalParagraphStyles;
}
.bottomWrapper {
    @apply --marketplaceModalBottomWrapper;
}

.saleButtonsModalContainer {
    & h2 {
        font-weight: bold;
        font-size: 30px;
        line-height: 37px;
        margin: 0 0 16px 0;
    }

    @apply --disabled;
}
.policyTerms {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    margin-bottom: 63px;

    @media (--viewportLarge) {
        margin-bottom: 48px;
    }
}
.notification {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: var(--matterColorAnti);
    text-align: center;
    margin-bottom: 16px;
}

.modalContent {
    @apply --modalContent;
    height: auto;
    @media (--viewportLarge) {
        height: 100%;
    }
}
.customerPortalSection {
    text-align: center;
    background: #f9f9f9;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 15%);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    z-index: 1;

    @media (--viewportLarge) {
        background: transparent;
        box-shadow: none;
        position: relative;
        border: 1px solid #e6e6e6;
        padding: 32px 48px;
        margin-top: 32px;
        border-radius: 5px;
        margin-bottom: 44px;
    }

    & h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        margin: 0 0 16px 0;
        display: none;
        @media (--viewportLarge) {
            display: block;
        }
    }

    & p {
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 24px 0;
        display: none;

        @media (--viewportLarge) {
            display: block;
        }
    }
}

.dataProtectionSidenote {
    @apply --paragraphAntiSM;
    @apply --flex-centered;

    column-gap: 4px;
}

.navigateToInbox {
    display: flex;
    column-gap: 4px;
    margin: 0 auto 0 0;

    &:hover {
        cursor: pointer;
    }

    @media (--viewportLarge) {
        display: none;
    }
}

.asidePanelMob {
    margin-left: auto;
    display: none;

    &.asidePanelMobDisabled {
        & .ellipsis {
            color: var(--matterColorAnti);
            pointer-events: none;
        }
    }

    @media (--viewportMediumMax) {
        display: flex;
        align-items: center;
    }
}

.ellipsis {
    display: inline-block;
    width: 32px;
    height: 32px;

    font-size: 32px;
    line-height: 10px;
    letter-spacing: -1.5px;

    margin-left: 8px;

    overflow: hidden;
    text-align: center;

    color: #1a2b49;

    &:hover {
        cursor: pointer;
    }
}

.asidePanelActionHighlighted {
    display: none;

    border: 2px solid #8f2593;
    background-color: #f9f4fa;
    color: #8f2593;

    &:hover,
    &:focus {
        border: 2px solid #8f2593;
        background-color: #f9f4fa;
        color: #8f2593;
    }

    @media (--viewportLarge) {
        display: block;
    }
}

.asidePanelActionDisabled {
    color: var(--matterColorAnti);

    &:hover {
        cursor: not-allowed;
        border-color: #e6e6e6;
    }
}

.asidePanelDesktop {
    display: flex;
    @media (--viewportMediumMax) {
        display: none;
    }
}

.showMessageNotification {
    @apply --paragraphAntiSM;
    max-width: 319px;
    text-align: center;
    margin: 32px auto;
}
