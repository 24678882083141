@import '../../common.css';

.root {
    margin-top: 64px;
}

.heading {
    margin-bottom: 32px;
    text-align: left;

    @media (--viewportMedium) {
        font-size: 32px;
        line-height: 40px;
    }
}

.testimonialsHolder {
    margin-bottom: 80px;

    @media (--viewportMedium) {
        /*
            32px from slider to pagination
            64px from pagination to footer
            14px height of pagination
        */
        margin-bottom: 110px;
    }
}

.slider {
    max-width: unset;
    width: 100%;

    & [datatype='slider-pagination'] {
        @media (--viewportMedium) {
            bottom: -46px;
        }
    }
    /* slideInner */
    & li > div {
        height: 510px;
        width: 288px;

        margin: 0 auto;
        border-radius: 16px;
        overflow: hidden;

        @media (--viewportMedium) {
            height: 441px;
            width: 248px;
        }

        @media (--viewportLarge) {
            height: 605px;
            width: 340px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    & ul {
        padding-bottom: 0;

        @media (--viewportMedium) {
            column-gap: 12px;
        }
    }
}

.carouselModalScrollLayer {
    @apply --marketplaceCarouselModalScrollLayer;
}

.carouselModalContainer {
    @apply --marketplaceCarouselModalContainer;
    & > button {
        background: inherit;
        width: auto;
        position: absolute;
    }
}
.modalContent {
    padding: 0;
}
