@import '../../marketplace.css';

.documentsHolder {
    margin-top: 24px;
    & p {
        letter-spacing: -0.25px;
        margin-bottom: 8px;
    }
    & > p:first-of-type {
        font-size: 16px;
        line-height: 24px;
    }
    & > p:last-of-type {
        @apply --fontSizeXS;
        color: #b2b2b2;
    }

    & label.addDocumentLabel {
        display: flex;
        justify-content: center;
        align-items: center;

        border: 1px solid #e6e6e6;
        border-radius: 4px;
        padding: 9px !important;

        @apply --fontSizeXS;

        cursor: pointer;

        & > svg {
            width: 14px;
            height: 14px;
            margin-right: 8px;
            margin-bottom: -1px;
            fill: none;
        }

        & + input {
            display: none;
        }

        &.disabled {
            color: var(--matterColorAntiSecondary);
            background: none;

            & path {
                stroke: #9c9c9c;
            }
        }
    }
}

.uploadDocError {
    color: #ff0000;
    margin: 8px 0 0 0;
    @apply --fontSizeXS;
}
.uploadDocLabelError {
    color: #ff0000 !important;

    & > svg {
        fill: #ff0000 !important;
    }
}
