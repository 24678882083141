@import '../../../marketplace.css';

:root {
    --sectionRoot {
        margin: 0 0 24px 0;
        padding: 24px;
        border-radius: 8px;
        border: 1px solid #e6e6e6;

        @media (--viewportLarge) {
            margin: 0 0 24px 0;
            width: 100%;
        }
    }

    --sectionTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin: 0;
        padding-bottom: 24px;

        color: #1a2b49;
        @apply --fontSizeXL;
        font-weight: 700;
    }

    --sectionSubTitle {
        @apply --paragraphAntiSM;
        margin-bottom: 4px;
    }

    --sectionContent {
        color: #1a2b49;
    }

    --matchingContainer {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 16px;
    }

    --sectionParagraphWithIcon {
        display: flex;
        align-items: center;
        column-gap: 8px;
        margin-bottom: 4px;
    }
}

.matchingContainer {
    @apply --matchingContainer;
}

.root {
    @apply --sectionRoot;
}
