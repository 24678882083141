@import '../../common.css';
.root {
    margin-bottom: 64px;
}

.header {
    @apply --subPages_root;
    margin: 0 auto;
}

.tableHolder {
    margin-bottom: 64px;

    @media (--viewportMediumMax) {
        overflow-x: scroll;
        margin: 0 -24px 64px -24px;

        @apply --scrollBarStyled;
    }
}

.table {
    max-width: 1152px;

    @media (--viewportMediumMax) {
        width: 100%;
        min-width: 795px;
    }
}

.tableHeader {
    flex: 1;
    flex-shrink: 0;
    padding: 16px;

    white-space: nowrap;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & p {
        margin-bottom: 0;
    }

    & svg {
        width: 48px;
        height: 48px;

        margin-bottom: 8px;

        & path {
            fill: #8f2593;
        }
    }

    /* &.noFill {
        & svg {
            & path {
                fill: none;
            }
        }
    } */

    @media (--viewportMediumMax) {
        &:first-of-type {
            flex: 1.85;
        }

        & svg {
            width: 24px;
            height: 24px;
        }
    }
}

.figure {
    @apply --fontSizeXXL;
    color: #8f2593;

    @media (--viewportMediumMax) {
        @apply --fontSizeXL;
        font-weight: 700;
    }
}

.tableRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 4px;

    &:nth-of-type(2) {
        & .tableSubHeader {
            padding-top: -0;
        }
    }
}

.tableSubHeader {
    @apply --fontSizeXL;
    font-weight: 700;
    margin: 0;

    padding: 32px 16px 16px 16px;

    @media (--viewportMediumMax) {
        @apply --fontSizeMM;
        font-weight: 700;
        padding: 12px 16px 8px 16px;
    }
}

.tableCell {
    background: #f2f2f2;
    flex: 1;
    flex-shrink: 0;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
        width: 48px;
        height: 48px;

        & path {
            fill: #b2b2b2;
        }
    }

    &.active {
        & svg {
            & path {
                fill: #8f2593;
            }
        }
    }

    @media (--viewportMediumMax) {
        height: 40px;
        @apply --fontSizeSM;

        & svg {
            width: 24px;
            height: 24px;
        }
    }
}

.tableSubCell {
    justify-content: flex-start;
    padding: 16px;

    @media (--viewportMediumMax) {
        padding: 12px 16px 8px 16px;
        flex: 1.5;
        flex-shrink: 0;
    }
}

.tableFaded {
    & .tableCell {
        background: unset;
    }
}
