@import '../../marketplace.css';

.root {
    position: relative;
    padding-top: 16px;
    padding-bottom: 0;
    border-bottom: 1px solid var(--matterColorNegative);
}

.filterLabel,
.filterLabelSelected {
    /* Baseline adjustment for label text */
    @apply --fontSizeXL;
    font-weight: 700;

    margin-bottom: 16px;
    position: relative;

    &:not(span) {
        padding-left: 40px;
    }

    @media (--viewportLarge) {
        @apply --fontSizeXXL;
    }
}

.filterLabel {
    color: var(--matterColorDark);
}

.filterLabelSelected {
    color: var(--marketplaceColor);
}

.icon {
    transform: rotate(90deg);
}
.iconSelected {
    transform: rotate(-90deg);
}

.labelButton {
    @apply --marketplaceFilterLabelButton;
    font-weight: 700;

    & svg {
        position: absolute;
        left: 0;
        top: calc(50% - 8px);

        transition: 0.35s;
    }
}

.plain {
    width: 100%;
    display: none;
}

.isOpen {
    display: block;
}

.clearButton {
    @apply --marketplaceFilterClearButton;
    @apply --fontSizeXS;
    font-weight: 600;

    @media (--viewportLarge) {
        @apply --fontSizeMM;
        font-weight: 600;
    }

    &:disabled {
        color: #b2b2b2;

        &:hover {
            cursor: not-allowed;
        }
    }
}
