@import '../../common.css';

:root {
    --flex_centered {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    --image {
        width: 96px;
        height: 96px;
    }

    --container_width: 284px;
}

.heading {
    text-align: left;
}

.content {
    margin-bottom: 32px;

    @media (--viewportSmallMax) {
        margin-bottom: 24px;
    }
}

.root {
    margin-top: 64px;
    margin-bottom: 64px;

    @media (--viewportMediumMax) {
        margin-bottom: 112px;
    }
}

.sliderOuterContainer {
    @media (min-width: 1152px) {
        max-width: 100%;
        width: 100%;
    }
}

.cardWrapper {
    position: relative;
    /* width: var(--container_width); */
    margin: 0 auto;

    @media (--viewportLarge) {
        margin: 0 32px 0 0;
        /* width: calc(var(--container_width) + 64px); */

        width: 284px;
    }
}

.delimiter {
    position: absolute;
    width: 0.5px;
    height: 112px;
    background: #e6e6e6;
    top: calc(50% - 56px);
    right: -2px;
    z-index: 1;
}

/* .evenCard {
    position: relative;

    @media (--viewportLarge) {
        &:before,
        &:after {
            content: '';
            position: absolute;
            width: 0.5px;
            height: 112px;
            background: #e6e6e6;
            top: calc(50% - 56px);
            z-index: 1;
        }

        &:before {
            left: -16px;
        }
        &:after {
            right: -16px;
        }
    }
} */

.cardContainer {
    /* max-width: var(--container_width); */

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 12px 0;
    background: #fff;
    border-radius: 8px;
    transition: 0.35s;

    @media (--viewportLarge) {
        background: #fff;
        max-width: var(--container_width);
        width: var(--container_width);
    }

    &:hover {
        background: #f2f2f2;
    }
}

.cardMatchBadge {
    @apply --fontSizeXS;
    font-weight: 600;
    color: #fff;
    background: var(--marketplaceColor);
    margin-bottom: 8px;

    border-radius: 16px;
    font-weight: 600;
    padding: 4px 8px;
}

.cardMatchParties {
    font-weight: 600;
    margin-bottom: 4px;
}

.cardMatchDate {
    color: var(--matterColorAnti);
    margin-bottom: 0;
}

.cardImagesHolder {
    @apply --flex_centered;

    margin-bottom: 8px;
}

.horseImage,
.riderImage {
    @apply --rounded_image;
    @apply --image;
}
.riderImage,
.riderImagePlaceholder {
    z-index: 2;
}

.horseImage,
.horseImagePlaceholder {
    left: -10px;
}
.riderImagePlaceholder,
.horseImagePlaceholder {
    @apply --flex_centered;
    @apply --rounded_image;
    @apply --image;

    font-size: 42px;
    background: #8f2593;
    color: #fff;
}
