@import '../../marketplace.css';

.changeAuthDesc {
    @apply (--authSubsection);

    text-align: center;
    width: 100%;
    margin-bottom: 0;
}

.optionHolder {
    margin: 32px 0;
}

.option {
    display: flex;
    align-items: center;
    box-shadow: var(--boxShadow);
    background: white;
    border-radius: 4px;
    padding: 18px 16px;
    max-width: 480px;
    width: 100%;
    transition: 0.25s;

    @media (--viewportMedium) {
        padding: 32px 24px;
    }

    & h3 {
        margin: 0 0 4px 0;
        @apply --fontSizeSM;
        line-height: 20px;
        font-weight: 700 !important;

        @media (--viewportMedium) {
            @apply --fontSizeXL;
        }
    }

    & p {
        margin: 0;
        @apply --fontSizeXS;

        @media (--viewportMedium) {
            font-size: 18px;
        }
    }

    & svg {
        fill: #1a2b49;
        width: 24px;
        height: 24px;
        margin: auto 0 auto auto;

        & path {
            fill: inherit;
            stroke: inherit;
        }
    }

    &:hover {
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    }

    &:first-of-type {
        margin-bottom: 16px;
    }
}

.presudoAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* background: var(--matterColorNegative); */
    background-size: contain;
    background-position: 50%;
    margin-right: 16px;

    @media (--viewportMedium) {
        width: 60px;
        height: 60px;
    }
}
