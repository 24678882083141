@import '../../marketplace.css';

.modalHeading {
    margin-top: 0;
}

.modalDesc {
    margin-bottom: 24px;
}

.form {
    margin-bottom: 24px;
}

.field {
    & label > div {
        width: 100%;
    }
}

.option {
    display: flex;
    align-items: center;

    margin: 0;
    width: 100%;

    & .creditsToPurchase {
        margin-right: auto;
    }

    & .price {
        font-weight: 600;
        color: var(--marketplaceColor);
        margin-right: 8px;
    }

    & .spare {
        @apply --fontSizeXS;
        color: var(--successColor);
    }
}

.subOption {
    display: flex;
    align-items: center;
    min-width: 164px;

    @media (--viewportSmallMax) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        min-height: 44px;
        min-width: unset;
    }
}

.subTotal {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-bottom: 16px;
    margin-bottom: 16px;

    border-bottom: 1px solid var(--matterColorNegative, #e6e6e6);
}

.subTotalHeading {
    & > p {
        margin: 0;

        &:nth-of-type(1) {
            @apply --fontSizeM;
            font-weight: 600;
        }

        &:nth-of-type(2) {
            @apply --fontSizeXS;
        }
    }
}

.subTotalPricing {
    @apply --fontSizeXXL;
    color: var(--marketplaceColor);

    @media (--viewportSmallMax) {
        @apply --fontSizeXL;
        font-weight: 700;
    }
}

.paymentOptions {
    margin-bottom: 40px;

    & p {
        display: flex;
        align-items: center;
        column-gap: 4px;

        @apply --fontSizeXS;
        color: var(--successColor);

        & path {
            fill: var(--successColor);
        }
    }
}

.sidenote {
    @apply --paragraphAntiSM;
    text-align: center;
    margin-bottom: 16px;
}
