@import '../../marketplace.css';

.modalContainer {
    border: none;
    min-height: unset;
    height: auto;
}

.modalContent {
    & h3 {
        margin-top: 0;
    }

    & footer {
        margin-top: 40px;

        & p {
            @apply --paragraphAntiSM;
            text-align: center;
            margin-bottom: 16px;
        }
    }

    @media (--viewportSmallMax) {
        height: 100%;
        display: flex;
        flex-direction: column;

        & h3 {
            margin-top: 0;
        }

        & footer {
            margin-top: auto;
        }
    }
}
