@import '../../styles-account.css';

.helperLink {
    @apply --marketplaceLinkStyles;
    color: var(--matterColor);
    font-weight: var(--fontWeightMedium);
    text-decoration: underline;
}

.emailStyle {
    font-weight: var(--fontWeightBold);
    word-wrap: break-word;
}

.idpInfo {
    display: flex;
    @apply --fontSizeSM;
    margin-bottom: 24px;

    @media (--viewportMedium) {
        display: flex;
        align-items: center;

        @apply --fontSizeMM;
        margin-bottom: 16px;
    }

    & > img {
        margin-right: 8px;
    }
}
.editSection {
    margin-bottom: 24px;

    & p {
        margin-bottom: 0;
    }
}
.editTitle {
    @apply --fontSizeSM;

    font-weight: 600;

    @media (--viewportMedium) {
        @apply --fontSizeMM;
        font-weight: 600;
    }
}
.editInfo {
    display: flex;

    & input[type='email'] {
        display: none;
    }

    & input,
    & input:-internal-autofill-selected {
        padding: 0;
        border: none;
        background: none !important;
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255, 1);
        cursor: default;

        pointer-events: none;
    }
}
.editAction {
    display: flex;
    align-items: center;

    color: #8f2593;
    margin-left: 16px;

    & svg {
        margin-right: 8px;
    }

    & path {
        fill: #8f2593;
    }

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}
.modalContainer {
    border: none;
}

.modalContent {
    @media (--viewportSmallMax) {
        &,
        & form {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }
}
.contactSupport {
    margin-bottom: 32px;

    @apply --fontSizeSM;

    @media (--viewportMedium) {
        @apply --fontSizeMM;
    }

    & > span {
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
        color: #8f2593;
    }
}
