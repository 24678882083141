@import '../../marketplace.css';

.root {
  fill: var(--matterColorNegative);

  & path {
    transition: 0.35s;
  }
}

.filled {
  & path {
    fill: var(--marketplaceColor);
  }
}
