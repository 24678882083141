@import '../../marketplace.css';

.root {
    /* Layout */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 24px;

    border-top-width: 1px;
    border-top-color: var(--matterColorNegative);
    border-top-style: solid;
    background-color: #fcfcfc;
    box-shadow: var(--boxShadow);

    z-index: 2;

    @media (--viewportLarge) {
        padding: 80px 0 82px 36px;
        flex-direction: column;
        justify-content: flex-start;
        border: none;
        box-shadow: none;
        background-color: transparent;
    }

    @media (--viewportLargeWithPaddings) {
        padding: 64px 0 82px calc((100% - 1056px) / 2);
    }
}

.tabs {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    min-height: 48px;
    padding-top: 2px;

    @apply --scrollBarStyled;

    @media (--viewportMedium) {
        min-height: 56px;
        padding-top: 10px;
    }

    @media (--viewportLarge) {
        min-height: auto;
        flex-direction: column;
        padding-top: 0;
    }
}

.tab {
    display: flex;
    align-items: flex-end;
    height: 100%;
    margin-left: 16px;
    color: #8f2593;
}
