@import '../../marketplace.css';

.popularDisciplinesContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
}

.popularDisciplines {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    text-decoration: none;
    color: var(--matterColorBright, #FFF);
    transition: transform 0.3s ease;
    min-height: 208px;

    &:hover {
        text-decoration: none;
    }
}

.popularDisciplinesImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transition: transform 0.3s ease;
}

.popularDisciplines::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 36.19%, rgba(0, 0, 0, 0.60) 100%);
    z-index: 1;
    transition: transform 0.3s ease;
}

.popularDisciplines:hover .popularDisciplinesImage {
    transform: scale(1.1);
}

.popularDisciplinesTitle {
    width: 100%;
    padding: 16px;
    text-align: left;
    color: var(--matterColorBright, #FFF);
    font-feature-settings: 'liga' off;
    text-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.5px;
    z-index: 2;
    margin: initial;
}

.smallDisciplinesContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 24px;
}

/* viewport medium */
/* min-width: 768px */
@media (--viewportMedium) {
    .popularDisciplinesContainer {
        grid-template-columns: 1fr 1fr;
        min-height: 384px;
        gap: 8px;
    }

    .smallDisciplinesContainer {
        grid-template-columns: 1fr 1fr;
        gap: 8px;
    }

    .popularDisciplines {
        min-height: initial;
    }

    .popularDisciplinesTitle {
        padding: 32px;
        font-size: 32px;
    }

    .smallDisciplinesContainer {
        .popularDisciplinesTitle {
            padding: 24px;
        }
    }
}
