@import '../../marketplace.css';

.root {
    margin: 0;

    /* Clearfix */
    @apply --clearfix;
}
.messageContent,
.ownMessageContent {
    @apply --marketplaceMessageFontStyles;

    display: inline-block;
    margin: 0;
    padding: 8.5px 14px 8.5px 14px;
    border-radius: 8px;
    box-shadow: var(--boxShadow);

    @media (--viewportMedium) {
        padding: 8.5px 14px 8.5px 14px;
        margin: 0;
    }
}

.messageContent {
    flex: 1;
    word-break: break-word;

    max-width: 100%;
    word-break: break-word;
    box-shadow: 0px 0px 20px 0px #0000001a;
}

.ownMessageContent {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
    /* float: right; */

    max-width: 100%;
    word-break: break-word;
    text-align: left;
}

.messageDate,
.ownMessageDate {
    @apply --paragraphAntiSM;

    margin: 11px 0 0 0;
    color: var(--matterColorAnti);

    @media (--viewportMedium) {
        margin: 6px 0 0 0;
    }

    &:after {
        content: ' Uhr';
    }
}

.ownMessageDate {
    text-align: right;
}

.appointmentField {
    margin-bottom: 4px;
}

.showOlderWrapper {
    text-align: center;
    margin-bottom: 1px;

    @media (--viewportMedium) {
        margin-bottom: 0;
    }
}
.messageGroup {
    @apply --paragraphAntiSM;

    text-align: center;
    margin: 32px 0;
}
.messageItem {
    margin-bottom: 20px;

    /* Clearfix */
    @apply --clearfix;

    @media (--viewportMedium) {
        margin-bottom: 17px;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}
.s3Image,
.s3OwnImage {
    background-size: cover;
    background-position: center;
    border-radius: 6px;
    box-shadow: 0px 0px 20px 0px #0000001a;

    width: 326px;
    height: 216px;

    @media (--viewportMedium) {
        width: 275px;
        height: 182px;
    }

    &:hover {
        cursor: pointer;
    }
}

.s3Doc {
    display: flex;

    & > svg {
        width: 25px;
        fill: #1a2b49;
        stroke: #1a2b49;
        margin-right: 10px;
        transition: 0.25s;
    }
    &:hover {
        & > svg {
            fill: #923395;
            stroke: #923395;
        }
    }
}
.message,
.ownMessage {
    /* Preserve newlines, but collapse other whitespace */
    white-space: pre-line;
    max-width: 100%;

    @media (--viewportMedium) {
        max-width: 433px;
    }
}

.message {
    display: flex;
    flex-direction: row;
}

.ownMessage {
    float: right;
    overflow: hidden;
    border-radius: 6px;
}

.avatar {
    flex-shrink: 0;
    margin: 0px 12px 0 0;
    cursor: pointer;

    @media (--viewportMedium) {
        margin: 0px 12px 0 0;
    }
}
.contentWrapper,
.ownMessageContentWrapper {
    @apply --clearfix;
    height: 100%;
    width: 100%;
    font-size: 18px;
}
.ownMessageContentWrapper {
    float: right;
    text-align: right;
}
.contentWrapper {
    float: left;
    text-align: left;
}

.messageStatus {
    position: relative;
    margin-right: 6px;
    display: inline-block;
    @apply --dot;

    &:after {
        right: -6px;
    }
}

.feed {
    margin: 20px 0 40px 0;

    @media (--viewportMedium) {
        margin: 20px 0 80px 0;
    }

    & ul {
        & li {
            text-align: center;
        }
    }
    /* ReinitiateTransactionButton */
    & > button {
        margin: 0 auto;
    }
}

.transitionItem {
    @apply --paragraphAntiSM;
    text-align: center;
    margin: 32px;
}

.link {
    color: #8f2593;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}
