@import '../../marketplace.css';

.root {
    width: 100%;
    height: auto;
    display: flex;
    flex: 1;
    flex-direction: column;

    @media (--viewportMedium) {
        flex-wrap: wrap;
        align-content: center;
        padding-top: 2px;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.error {
    color: var(--failColor);
}

.cantFindStableAddress {
    margin-top: 8px;
    color: #8f2593;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.25px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.locationAutocompleteInput {
    flex-grow: 1;
    height: 56px;
    line-height: unset;
    padding-left: 0;
    margin: 0;

    border-radius: 4px 4px 0px 0px;
    border-top: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;

    &:hover,
    &:focus {
        outline: none;
    }

    /* Safari bugfix: without this Safari will print placeholder to a wrong place */
    &::-webkit-input-placeholder {
        line-height: normal;
    }

    @media (--viewportMedium) {
        height: 62px;
        width: 480px;
    }
}

.locationAutocompleteInputIcon {
    display: none;
}

.predictionsRoot {
    position: absolute;
    width: 100%;
    top: 56px;
    left: 0;
    z-index: calc(var(--zIndexPopup) + 1);

    padding: 8px 0px;
    border-radius: 0px 0px 4px 4px;
    border-right: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    background-color: #fff;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.05);

    @media (--viewportMedium) {
        top: 62px;
        width: 480px;
    }
}

.validLocation {
    border-bottom-color: var(--successColor);
}

.locationAddress,
.building {
    flex-shrink: 0;

    @media (--viewportMedium) {
        /* margin-bottom: 14px; */
    }
}

.submitButton {
    @apply --marketplaceEditListingSubmitButton;

    @media (--viewportLarge) {
        width: 280px;
    }

    position: relative;
}

.submitButton [data-icon-type='arrow'] {
    position: absolute;
    top: 50%;
    transform: translateY(-6px);
}

.submitButton:disabled [data-icon-type='arrow'] path {
    stroke: #b2b2b2;
    fill: #b2b2b2;
}

@media (--viewportMiniSmall) {
    .submitButton [data-icon-type='arrow'] {
        right: 40px;
    }
}

@media (--viewportSmall) {
    .submitButton [data-icon-type='arrow'] {
        right: 50px;
    }
}

@media (--viewportMedium) {
    .submitButton [data-icon-type='arrow'] {
        right: 200px;
    }
}

@media (--viewportLarge) {
    .submitButton [data-icon-type='arrow'] {
        right: 35px;
    }
}

.map {
    height: 140px;
    /* width           : 100%; */
    border-radius: 5px;
    overflow: hidden;
    max-width: 480px;
    background-color: #eee;
    padding: 0;
    border: 0;
    cursor: pointer;
    margin-bottom: 31px;
}

.locationTypeTitle {
    color: #1a2b49;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.5px;
    margin: 0 0 16px 0;

    @media (--viewportMedium) {
        font-size: 32px;
        line-height: 40px;
    }
}

.locationTypeSubTitle {
    color: var(--brand-secondary, #1a2b49);
    margin-bottom: 32px;
    width: 312px;

    @media (--viewportMedium) {
        width: 480px;
    }
}

.mobilityContainer {
    margin: 32px 0 24px 0px;
}

.mobilityTitle {
    @apply --fontSizeXL;
    font-weight: 700;
    margin: 0;
}

.toggleButton {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.toggleButton label {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.toggleButton label span {
    margin-right: 0px;
}

.lockerWrapper {
    margin-top: 24px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    column-gap: 8px;

    @apply --paragraphAntiSM;

    & > svg {
        width: 16px;
        height: 16px;
        & path {
            fill: #b2b2b2;
        }
    }
}

.radioButton {
    display: flex;
    min-width: 312px;
    flex-direction: column;

    @media (--viewportMedium) {
        width: 480px;
    }
}

.firstRadioButton {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-bottom: 1px solid var(--ground-ground-010, #e6e6e6);
}

.equipmentsTypeTitle {
    margin-top: 32px;
    margin-bottom: 24px;
    @apply --fontSizeXL;
    font-weight: 700;
}

.firstRadioButton:hover {
    background: var(--ground-ground-003, #f9f9f9);
}

.addressFields {
    margin-top: 24px;

    @media (--viewportMedium) {
        max-width: 480px;
    }
}

.rowContainer {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    @media (--viewportMedium) {
        flex-direction: row;
        column-gap: 16px;
        row-gap: 0;
    }
}

.halfField input {
    padding: 17px 60px 4px 17px;
}

.namedLink {
    text-decoration: none !important;
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.noBorder {
    border: none;
}

.tooltip {
    @media (--viewportSmallMax) {
        width: calc(100vw - 54px);
    }
}

.tooltipPosition {
    position: absolute;
    top: calc(50% - 9px);
    right: 16px;
}

.tooltipContentHolder {
    display: flex;
}

.tooltipLink {
    text-decoration: underline;
    cursor: pointer;
    pointer-events: auto;
    display: block;
    text-align: right;
    margin-top: 16px;
}
