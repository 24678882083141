@import '../../marketplace.css';

.shareItem {
    width: 100%;
    display: flex;
    align-items: center;

    min-width: 300px;
    margin: 0;

    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #e6e6e6 !important;

    text-decoration: none !important;
    white-space: nowrap;

    background: #fff;
    color: #1a2b49;

    padding: 16px 24px !important;

    transition: 0.35s;

    &:hover,
    &:focus {
        border: 1px solid #1a2b49 !important;
    }

    & > span {
        @apply --fontSizeMM;
        font-weight: 600;
        color: #1a2b49;

        @media (--viewportSmallMax) {
            @apply --fontSizeSM;
            font-weight: 600;
        }
    }

    & > svg {
        margin-right: 16px;

        & path {
            fill: #1a2b49;
        }
    }
}

.shareContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
}
