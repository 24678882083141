@import '../../marketplace.css';

.voucherCode {
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 8px;

    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    &.voucherType {
        justify-content: space-between;

        background: #fff;
        border: 1px dashed var(--marketplaceColor);

        & span {
            max-width: 90%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @media (--viewportSmallMax) {
                max-width: 70vw;
            }
        }

        & svg {
            flex-shrink: 0;

            & path {
                fill: #8f2593;
            }
        }

        &.copied {
            background: #f9f4fa;
        }
    }

    &.linkType {
        justify-content: center;
        column-gap: 8px;
        text-decoration: none;

        background: var(--marketplaceColor);
        color: #fff;

        font-weight: 600;

        & path {
            fill: #fff;
        }

        &:hover {
            background: var(--marketplaceColorDark);
            text-decoration: none;
        }
    }
}
