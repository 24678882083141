@import '../../marketplace.css';

.txBadge {
    @apply --badge-status;
    column-gap: 4px;

    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 2;

    background: #fff;

    &.declined {
        color: #ff0000;
        background-color: #fff0f0;
        & path {
            fill: #ff0000;
        }
    }

    &.accepted {
        color: #1e9f55;
        background-color: #f0fff6;
        & path {
            fill: #1e9f55;
        }
    }

    &.expired {
        color: #b2b2b2;
        background-color: #f2f2f2;
        & path {
            fill: #b2b2b2;
        }
    }
}

.tooltip {
    & svg {
        stroke: #b2b2b2;
        fill: none;

        &:hover {
            cursor: not-allowed;
        }
    }
}

.tooltipContent {
    z-index: 2;

    @media (--viewportSmallMax) {
        min-width: 322px;
    }

    & p {
        font-size: 14px !important;
    }
}
