@import '../../marketplace.css';

.level {
  transition: 0.35s;

  width: 24px;
  height: 8px;
  display: inline-block;

  background: #e6e6e6;

  border-radius: 4px;
  margin-left: 4px;
}

.editAvailable {
  &:hover {
    cursor: pointer;
  }
}

.selected {
  background: #8f2593;
}
