@import '../../common.css';

.root {
    margin-bottom: 64px;
}

.header {
    @apply --subPages_root;
    @apply --subPages_description;

    margin: 0 auto;
}

.benefitHolder {
    flex-wrap: wrap;
    row-gap: 24px;

    margin-bottom: 64px;
}
