@import '../../marketplace.css';

:root {
  --EditListingCharacterForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}
.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}
.error {
  color: var(--failColor);
}

.checkboxGroup {
  @apply --EditListingCharacterForm_formMargins;
}

.submitButton {
  @apply --marketplaceEditListingSubmitButton;

  @media (--viewportLarge) {
    width: 280px;
  }
  position: relative;
}

.submitButton [data-icon-type='arrow'] {
  position: absolute;
  top: 50%;
  transform: rotate(180deg) translateY(6px);
}

.submitButton:disabled [data-icon-type='arrow'] path {
  stroke: #b2b2b2;
  fill: #b2b2b2;
}

@media (--viewportMiniSmall) {
  .submitButton [data-icon-type='arrow'] {
    right: 40px;
  }
}
@media (--viewportSmall) {
  .submitButton [data-icon-type='arrow'] {
    right: 50px;
  }
}
@media (--viewportMedium) {
  .submitButton [data-icon-type='arrow'] {
    right: 200px;
  }
}
@media (--viewportLarge) {
  .submitButton [data-icon-type='arrow'] {
    right: 35px;
  }
}
