@import '../../marketplace.css';

.modalHeading {
    margin-top: 0;
}

.modalDesc {
    margin-bottom: 40px;
}

.modalAction {
    @media (--viewportSmallMax) {
        position: fixed;
        left: 24px;
        right: 24px;
        bottom: 24px;
    }

    & > button {
        &:nth-of-type(1) {
            margin-bottom: 16px;
        }
    }
}
