@import '../../marketplace.css';

.checkboxHolder {
    position: relative;

    & > *:not(code) {
        margin: 0;
    }
}

.checkboxItem {
    @apply --formFieldsItem;
}

.selected {
    @apply --formFieldsItem-selected;
}

.removeCustomIcon {
    /* icon to delete a custom discipline */
    position: absolute;
    right: 16px;
    top: calc(50% - 12px);
    fill: none;
    cursor: pointer;
}

.customOptionHolder {
    @apply --customFieldHolder;

    background: white;

    & > p {
        margin: 0;
        @apply --fieldset;
        left: 52px;
    }
}

.customOption {
    border: none;
}
