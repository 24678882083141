@import '../../../marketplace.css';

.awardsHolder {
  display: flex;
  justify-content: space-between;
}

.awardInfoHolder {
  display: flex;
}

.awardInfo {
  & > p {
    margin: 0;

    @apply --fontSizeSM;

    @media (--viewportSmallMax) {
      @apply --fontSizeXS;
    }

    &:first-of-type {
      @apply --fontSizeMM;
      font-weight: 600;

      @media (--viewportSmallMax) {
        @apply --fontSizeSM;
        font-weight: 600;
      }
    }
  }
}
.divider {
  margin: 32px 0;
  width: 100%;
  height: 1px;
  background-color: #e6e6e6;
}
.docBlock {
  color: #b2b2b2;
  margin-bottom: 0;
  margin-top: 24px;

  @media (--viewportSmallMax) {
    @apply --fontSizeSM;
  }
}
.docExists {
  color: #8f2593;

  max-width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.description {
  margin-top: 24px;
  margin-bottom: 0;
}

.placeholderWrapper {
  & footer {
    margin-top: 32px;

    & > div {
      max-width: 637px;

      &:nth-of-type(1),
      &:nth-of-type(2) {
        margin-bottom: 16px;
        max-width: 100%;
      }
    }
  }
}
.viewProfileRequestWrapper {
  max-height: 276px;
}

aside.viewProfileRequestAction {
  height: 65%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 0.5) 55%,
    rgba(255, 255, 255, 0.1) 100%
  );
}
