@import '../EditUserInfoModal.css';

:root {
    --firstColumn: {
        width: 48%;

        @media (--viewportSmallMax) {
            width: 40%;
        }
    }

    --secondColumn: {
        width: 52%;

        @media (--viewportSmallMax) {
            width: 60%;
        }
    }
}

.levelItem {
    color: #b2b2b2;
}

.editUserSkillAction {
    display: flex;
}

.skillsetHeadingNav {
    & > * {
        &:nth-of-type(1) {
            @apply --firstColumn;
        }

        &:nth-of-type(2) {
            @apply --secondColumn;
        }
    }
}

.editUserProfileItem {
    & > div {
        &:nth-of-type(1) {
            @apply --firstColumn;
        }

        &:nth-of-type(2) {
            @apply --secondColumn;
        }
    }
}
