@import '../../../marketplace.css';

:root {
    --inputHover {
        & input,
        & input:checked {
            & + label:hover {
                background-color: #f2f2f2;
                cursor: pointer;
            }
        }
    }
}

.popup {
    width: 280px;

    position: absolute;
    right: 18px;
    top: 58px;

    z-index: 2;

    padding: 16px 0 0 0;

    background: white;
    border-radius: 16px;
    box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.1);

    @apply --inputHover;

    & label,
    & .subHeading {
        padding: 8px 16px;
    }

    & label {
        width: 100%;

        display: flex;
        align-items: center;

        & span {
            line-height: normal;
        }
    }

    & .actionBar {
        margin-top: 16px;
        border-top: 1px solid #e6e6e6;
        padding: 16px 24px;

        display: flex;
        justify-content: space-between;

        & > button {
            max-width: 95px;
            min-height: 40px;

            @apply --fontSizeXS;

            font-weight: 600;
        }
    }
}

.actionBar {
    & > button {
        &:disabled {
            color: #b2b2b2;
        }

        &:first-of-type {
            border: none;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }

            &:disabled {
                background: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.subHeading {
    margin: 0;
}

.fullWidthField {
    border: none !important;
    @media (--viewportMediumMax) {
        margin: 0 -24px !important;
    }

    & label {
        padding: 8px 24px !important;
    }
}

.modalContent {
    height: 100%;

    @apply --inputHover;

    & form {
        display: flex;
        flex-direction: column;

        height: calc(100vh - 128px);
    }

    & .subHeading {
        padding: 8px 0;
    }

    & label span {
        @apply --fontSizeSM;
    }

    & h4 {
        margin: 56px 0 16px 0;

        @apply --fontSizeXL;
        font-weight: 700;
    }

    & .actionBar {
        margin-top: auto;
        margin-bottom: 24px;

        display: flex;
        flex-direction: column-reverse;

        & > button:last-of-type {
            margin-top: 16px;
        }
    }
}
