@import '../../common.css';

:root {
    --community_img {
        object-fit: cover;

        position: absolute;
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
}

.root {
    margin: 64px auto 140px auto;
    max-width: unset;
    position: relative;

    @media (--viewportSmallMax) {
        margin: 64px auto 104px auto;
    }
}

.heading {
    max-width: 760px;
    margin: 0 auto 24px auto;
}

.content {
    @media (--viewportLarge) {
        max-width: 760px;
    }
    margin: 0 auto;
}

.description {
    text-align: center;
}

.linksHolder {
    column-gap: 16px;

    @media (--viewportSmallMax) {
        flex-direction: column;
        align-items: center;
        row-gap: 16px;
    }
}
.backGroundImageHolderMob {
    position: relative;

    display: inline-block;
    height: 370px;
    width: 100%;

    margin-top: 24px;

    & img {
        @apply --community_img;
    }

    & img:nth-of-type(1) {
        left: -9%;
        top: 18%;
        @media (--viewportMedium) {
            left: 5%;
        }
    }
    & img:nth-of-type(2) {
        left: 28%;
        top: 19px;
    }
    & img:nth-of-type(3) {
        left: 53%;
        top: 105px;
    }
    & img:nth-of-type(4) {
        left: 90%;
        top: 53px;
    }
    & img:nth-of-type(5) {
        left: 20%;
        top: 165px;
    }
    & img:nth-of-type(6) {
        left: 50%;
        top: 236px;

        @media (--viewportMedium) {
            left: 40%;
        }
    }
    & img:nth-of-type(7) {
        left: 81%;
        top: 188px;
    }
    & img:nth-of-type(8) {
        left: 0%;
        top: 263px;
    }
    & img:nth-of-type(9) {
        left: 33%;
        top: 337px;
    }
    & img:nth-of-type(10),
    & img:nth-of-type(11),
    & img:nth-of-type(12) {
        @media (--viewportSmallMax) {
            display: none;
        }
    }

    & img:nth-of-type(10) {
        left: 64%;
        top: 57%;
    }
    & img:nth-of-type(11) {
        left: 83%;
        top: 92%;
    }
    & img:nth-of-type(12) {
        left: 69%;
        top: 7%;
    }
}
.backGroundImageHolderRight,
.backGroundImageHolderLeft {
    position: absolute;
    height: 415px;
    width: 370px;

    & img {
        @apply --community_img;
    }
}

.backGroundImageHolderLeft {
    left: 24px;

    & img:nth-of-type(1) {
        left: 91px;
        top: 8px;
    }
    & img:nth-of-type(2) {
        left: 188px;
        top: 60px;
    }
    & img:nth-of-type(3) {
        left: 285px;
        top: 1px;
    }
    & img:nth-of-type(4) {
        left: 63px;
        top: 156px;
    }
    & img:nth-of-type(5) {
        left: 151px;
        top: 226px;
    }
    & img:nth-of-type(6) {
        left: 261px;
        top: 170px;
    }
    & img:nth-of-type(7) {
        left: 109px;
        top: 343px;
    }
    & img:nth-of-type(8) {
        left: 261px;
        top: 326px;
    }
}

.backGroundImageHolderRight {
    right: 24px;

    & img:nth-of-type(1) {
        top: 190px;
        left: 10px;
    }
    & img:nth-of-type(2) {
        left: 239px;
        top: 348px;
    }
    & img:nth-of-type(3) {
        left: 0;
        top: 0;
    }
    & img:nth-of-type(4) {
        left: 170px;
        top: 230px;
    }
    & img:nth-of-type(5) {
        left: 207px;
        top: 88px;
    }
    & img:nth-of-type(6) {
        top: 315px;
        left: 0;
    }
    & img:nth-of-type(7) {
        left: 124px;
        top: -22px;
    }
    & img:nth-of-type(8) {
        left: 80px;
        top: 105px;
    }
    & img:nth-of-type(9) {
        left: 119px;
        top: 355px;
    }
}
