@import '../../marketplace.css';

.modalContent {
    max-height: 720px;
    overflow: auto;
}

.modalContentHorseListingStep {
    @media (--viewportSmallMax) {
        & > p {
            margin-bottom: 16px;
        }
    }
}

.modalHeading {
    margin-top: 0;

    @apply --fontSizeXL;
    font-weight: 700;

    @media (--viewportMedium) {
        font-size: 32px;
        line-height: 40px;
    }
}

.modalContainer {
    border: none;
}

.loadingHolder {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.identityPlaceholder,
.listingAvatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.identityPlaceholder {
    text-transform: uppercase;
    @apply --fontSizeXS;
    font-weight: 700;

    color: white;
    background-color: var(--marketplaceColor);

    display: flex;
    align-items: center;
    justify-content: center;
}

.listingIdentity {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
        display: flex;
    }

    & p {
        margin: 0 0 0 8px;
    }
}

.radioButton {
    margin-left: -24px;
    margin-right: -24px;

    border: none !important;

    & label {
        padding: 8px 24px;
        align-items: center;
        /* where label is located */
        & > div {
            width: 100%;
        }
    }
}

.listingsDataHolder {
    margin-bottom: 16px;
}

.scoreBadge {
    @apply --fontSizeSM;

    padding: 4px 16px;
    font-weight: 600;

    border-radius: 24px;
}
.total {
    background-color: #f0fff6;
    color: #1e9f55;
}
.sufficient {
    background-color: #fff7f0;
    color: #ffaa00;
}
.insufficient {
    background-color: #fff0f0;
    color: #ff0000;
}

.error {
    @apply --paragraphAntiSM;
}
