@import '../../marketplace.css';

.root {
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 0;

    @media (--viewportMedium) {
        padding: 100px 10vw;
    }
}

.imageWrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    @media (--viewportMedium) {
        & video {
            z-index: 1;
        }
        & video,
        & img {
            border-radius: 8px;
        }
    }

    @media (--viewportSmallMax) {
        & video {
            height: calc(70vw - 56px);
            margin: 0 auto;
        }
    }
}

.imageIndex {
    /* Fonts */
    @apply --fontSizeMM;

    font-weight: var(--fontWeightSemiBold);

    /* Position and dimensions */
    position: absolute;
    top: 21px;
    left: 20px;
    margin: 0;

    /* Colors */
    color: var(--matterColorLight);

    @media (--viewportMedium) {
        margin: 0;
        font-weight: var(--fontWeightSemiBold);
    }
}

.prev,
.next {
    /* Position and dimensions */
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: calc(50% - 32px);
    width: 48px;
    height: 48px;

    background: white;
    border-radius: 50%;
    /* Clear button borders */
    border: none;

    outline: none;
    cursor: pointer;

    box-shadow: 0px 4px 6px -1px #0000000d;
    padding: 0;

    @media (--viewportLarge) {
        transition: var(--transitionStyleButton);

        &:hover {
            opacity: 1;
        }
    }

    @media (--viewportMedium) {
        width: 64px;
        height: 64px;
    }

    & svg {
        height: 17px;
        width: 17px;

        @media (--viewportMedium) {
            height: 24px;
            width: 24px;
        }

        & path {
            fill: #1a2b49;
            stroke: #1a2b49;
        }
    }
}

.prev {
    left: 16px;

    & svg {
        transform: rotate(180deg);
        margin-left: 0;
        margin-right: 4px;
    }
}

.next {
    right: 16px;
}

.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    margin-top: -25px;
    opacity: 0;
}

.loadingVisible {
    opacity: 1;
    transition: opacity var(--transitionStyle);
}

.image {
    /* Fit image in the available space as big as possible, keeping the aspect ratio */
    object-fit: contain;
}

.imageLoading {
    opacity: 0.5;
}

.description {
    color: white;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0.8);
    padding: 0 24px;
    margin: 0;

    word-wrap: break-word;

    @media (--viewportMedium) {
        top: calc(100% + 16px);
    }
}
