@import './MissingPhoneNumberModal.css';

.otpField {
    margin-bottom: 24px;
}

.modalNotification {
    @apply --paragraphAntiSM;
    color: #8f2593;
}

.link {
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.error {
    position: static;
}
