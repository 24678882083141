@import '../../marketplace.css';
.root {
  margin-bottom: 32px;
  z-index: 52;
}
.menu {
  max-width: 100%;
  height: auto;
  z-index: calc(var(--zIndexTopbar) - 1);
}

.menuLabel {
  text-align: left;
  font-weight: 500;
  padding: 0;
  border-radius: 4px;
  border: 1px solid var(--matterColorNegative) !important;
  transition: all 0.2s ease-out;
  z-index: calc(var(--zIndexPopup) + 1);
  position: relative;

  &:hover {
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorAnti);
  }

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    text-decoration: none;
    border-radius: 4px 4px 0px 0px;
  }
}

.menuLabelWrapper,
.menuLabelWrapperExpired {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 15px 16px 15px 16px;
}

.menuLabelWrapperExpired {
  border: 1px solid var(--failColor);
}

.menuContent {
  position: absolute;
  z-index: var(--zIndexPopup);

  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowFilterButton);
  border-radius: 4px;
  border: 1px solid var(--matterColorNegative);

  transition: all 0.15s ease-out;

  /* When the dropdown is open, we need to hack the box-shadow's position
      to fit the whole dropdown menu component with padding-top

      MenuLabel height 56px + original padding-top 7px results to 63px
    */
    padding: 66px 0 8px 0;
    top: 0px;
  }

  .menuItem {
    color: var(--matterColor);
    /*margin-bottom: 16px !important;*/
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    align-items: center;


    &:last-of-type {
      /*margin-bottom: 0 !important;*/

    }

  &:hover {
    background-color: #f2f2f2;
  }
}

.menuText,
.menuTextReplaceCard {
  @apply --marketplaceMessageFontStyles;
  padding: 12px 8px;
  color: var(--matterColor);
  text-align: left;
  font-weight: 500;
  width: 80%;

    &:hover {
      text-decoration: underline !important;
  text-decoration-color: #1A2B49 !important;
    }

  &:focus {
    outline: 0;
    background-color: #f2f2f2;
  }
}

.menuTextReplaceCard svg {
  margin-right: 8px;
}

.menuDivider {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  padding: 14px 24px 2px 24px;
  margin: 0;
}
.iconArrow {
  stroke: var(--matterColor);
  fill: var(--matterColor);
  transition: all 0.2s ease-out;
}
.IconArrowAnimation {
  transform: rotate(180deg);
}

.iconCheckmark {
  stroke: var(--successColor);
  display: inline-block;
  margin: 0 8px 0 8px;
}

.iconCheckmarkHidden {
  visibility: hidden;
  display: inline-block;
}
.paymentHeading {
  @apply --checkoutFormLabel;
}
.paymentGatewayPlaceholder {
  margin: 0;
  font-size: 18px;
  line-height: 27px;
  color: var(--matterColorAnti);
}
.error {
  margin: 0;
  font-size: 16px;
  color: var(--failColor);
}
