@import '../../../../marketplace.css';

.mobile {
    /* Size */
    width: 100%;
    height: var(--topbarHeight);

    /* Layout for child components */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    /* fill */
    background-color: var(--matterColorLight);

    /* shadows */
    box-shadow: var(--boxShadow);

    @media (--viewportLarge) {
        display: none;
    }

    @media (--viewportMiniSmallMax) {
        overflow: hidden;
    }
}

.searchMenu {
    /* Layout */
    display: flex;
    width: auto;
    border-width: 0px;
    background-color: transparent;

    margin: 0;
    padding: 8px 16px;

    /* Hovers */
    &:enabled {
        cursor: pointer;
    }
    &:enabled:hover,
    &:enabled:active {
        background-color: transparent;
        box-shadow: none;
    }
    &:disabled {
        background-color: transparent;
        cursor: auto;
    }
}

.searchMenuIcon {
    width: 24px;
    height: 24px;
}

.rootSearchIcon {
    stroke: var(--matterColor);
    fill: none;
}
