@import '../../../marketplace.css';

:root {
    --link {
        @apply --fontSizeMM;
        font-weight: 600;

        display: inline-block;
        padding: 16px;

        border-radius: 4px;

        &:hover {
            cursor: pointer;
            text-decoration: initial;
        }
    }
}

.dumbInputRoot {
    @apply --inputFieldWrapper;
    margin-bottom: 24px;
}
.dumbFieldIconHolder {
    @apply --inputFieldIconHolder;
}

.subLinkOutlined {
    @apply --link;
    color: #1a2b49;
    border: 1px solid #e6e6e6;

    transition: 0.35s;

    &:hover {
        border: 1px solid #1a2b49;
    }
}

.pageTitle {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.5px;
    margin-top: 32px;
}
.pageTitleSubText {
    text-align: center;
    margin: initial;
}
.pageHeading2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.5px;
    margin-top: initial;
}

.pageTitleColor {
    color: var(--marketplaceColor, #8F2593);
}

.pageTitleBreak {
    display: none;
}

.staticPageWrapper {
    width: calc(100% - 48px);
    max-width: 1056px;
    margin: 24px auto;

    & > svg {
        position: absolute;
        &:first-of-type {
            top: 100px;
        }
    }
}

.headerMapWrapper {
    margin-top: 32px;

    .coverWrapper {
        margin-top: initial;
    }
}

.listingSection {
    margin: 32px 0;
}

h3 {
    margin-top: initial;
}

.listingCardsWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 32px;
}

.listingCard {
    width: 100%;
}

.listingFooter {
    text-align: center;
    margin-top: 64px;
}

.coverWrapper {
    display: flex;
    align-items: center;
    margin: 14px 0 64px;
    flex-direction: column-reverse;
}

.coverWrapper .flexItem {
    flex: 1 1 auto;
}

.coverWrapper .coverTitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.5px;
    margin-top: initial;
    margin-bottom: 16px;
}

.coverWrapper .coverSubTitle {
    margin-bottom: 24px;
}

.findPlacesNearby {
    border-radius: 14px;
    width: 100%;
    background: var(--Ground-Ground-000, #FFFFFF);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.10);
    padding: 24px;
    margin-top: -35px;
    z-index: 1;
}

.findPlacesNearby .findPlacesNearbyReviews {
    color: black !important;
}

.findPlacesNearby .reviewsIframe {
    max-width: 320px;
    margin: 0 auto;
}

.findPlacesNearbySearchPanel {
    position: static;
    margin: 32px 0 24px;
}

.findPlacesNearbySearchPanel label[for="location"],
.findPlacesNearbySearchPanel label[for="distance"] {
    display: none;
}

.findPlacesNearbySearchPanel a {
    top: calc(50% - 23px);
    width: 46px;
    height: 46px;
    min-height: 36px;
    right: 6px;
}

.findPlacesNearbySearchPanel form {
    margin: 0 auto;
    height: 56px;
}

.findPlacesNearbySearchPanel + p {
    text-align: center;
}

.coverImageWrapper {
    width: 100%;
}

.coverImageWrapper .coverImage {
    width: 100%;
    max-height: 460px;
    border-radius: 6px;
    object-fit: cover;
    object-position: bottom center;
    height: 230px;
}

.coverImageWrapper .coverMap {
    width: 100%;
    height: 300px;
    max-height: 460px;
    max-width: 840px;
    border-radius: 6px;
    object-fit: cover;
    border: none;
}

.coverWrapper:first-child {
    margin-bottom: initial;
}

.coverWrapper:last-child {
    margin-top: initial;
}

.coverWrapperSubTitle {
    color: var(--matterColorAnti, #B2B2B2);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.25px;
}

.coverWrapperSubText {
    margin: initial;
}

.moreOffers {
    width: 100%;
    margin-top: 16px;
}

.moreOffersList {
    color: var(--marketplaceColor, #8F2593);
    margin: 5px 0 0 30px;
}

.moreOffersList li {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    list-style: initial;
}

.locationInformation {
    margin-top: 24px;
    width: 100%;
}

.locationInformation .wikipediaLink {
    font-size: 12px;
    margin-left: 5px;
}

.findPlacesNearbyLocationForm {
    min-width: 296px;
}

.sectionWrapper,
.sectionWrapperTop {
    margin: 32px 0;

    > h2 {
        margin-top: initial;
    }

    h2 {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.5px;
    }
}
.sectionWrapperTop {
    margin-bottom: initial;
}

.faq {
    margin-bottom: 32px;
}

.contentHeading {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.5px;
    margin-top: 24px;
}

.tableOfContents {
    margin-left: 26px;
}

.tableOfContentsListItem {
    list-style: initial;
}

.reviewsioCarouselWidget {
    width: calc(100vw - 50% + 50% - 3px);
    margin-left: calc(-50vw + 50%);
    overflow: hidden;
    padding-bottom: 10px;
    margin-bottom: 22px;
    margin-top: 32px;
}

.ridingSharesEuropeWrapper {
    margin: 32px 0;
}

.ridingSharesEuropeTitle {
    margin-top: initial;
}

.ridingSharesEuropeInnerWrapper {
    width: 100%;
    margin-top: 16px;
}

.ridingSharesCountry:last-child {
    margin-top: 24px;
}

.ridingSharesTitle {
    color: var(--Brand-Secondary, #1A2B49);
}

.ridingSharesCount {
    color: var(--ground-ground-040, #B2B2B2);
    margin-top: 4px;
}

/* viewport medium */
/* min-width: 768px */
@media (--viewportMedium) {
    .sectionWrapper,
    .sectionWrapperTop {
        h2 {
            font-size: 40px;
            line-height: 48px;
        }
    }

    .headerMapWrapper {
        margin-top: 64px;
    }

    .pageTitle {
        font-size: 48px;
        line-height: 64px;
    }

    .pageHeading2 {
        font-size: 40px;
        line-height: 48px;
    }

    .pageTitleBreak {
        display: initial;
    }

    .staticPageWrapper {
        width: calc(100% - 72px);
        margin: 32px auto;
    }

    .coverWrapper {
        margin: 40px 0 64px 0;
        flex-direction: initial;

        .coverTitle {
            font-size: 32px;
            line-height: 40px;
        }

        .findPlacesNearby {
            border-radius: 16px;
            width: 33.33%;
            min-width: 355px;
            margin-right: -60px;
            margin-top: initial;
        }

        .coverImageWrapper {
            width: calc(66.66% + 60px);

            .coverImage {
                border-radius: 16px;
                height: 460px;
            }

            .coverMap {
                height: 460px;
            }
        }

        .moreOffers {
            width: calc(33.33% - 60px);
            min-width: calc(355px - 60px);
            position: relative;
            margin-top: initial;

            .moreOffersList {
                li {
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }

        .locationInformation {
            width: initial;
        }
    }

    .listingSection {
        .listingCardsWrapper {
            flex-direction: row;

            .listingCard {
                width: calc(33.33% - 22px);
            }
        }
    }

    .ridingSharesEuropeInnerWrapper {
        .ridingSharesCountry {
            display: inline-block;
            width: 40%;

            &:last-child {
                margin-top: initial;
            }
        }
    }
}

.noResultsContainer {
    @media (--viewportSmallMax) {
        margin-top: 24px;
        padding: 24px;
    }
}

.noResultsBlock {
    max-width: 1150px;
    margin: 81px auto 0 auto;
}

/* viewport large */
/* min-width: 1024px */
@media (--viewportLarge) {
    .coverWrapper {

        .moreOffers {
            top: -40px;
        }
    }
}
