@import '../../common.css';

.root {
    margin-bottom: 64px;
}

.description {
    white-space: normal;
    @media (--viewportMedium) {
        white-space: break-spaces;
    }
}

.content {
    /* max-width: 760px; */
    margin: 64px auto;

    display: flex;
    flex-direction: column;
    row-gap: 20px;

    @media (--viewportSmallMax) {
        margin: 32px auto 64px auto;
    }
}

.matchingBlock {
    display: flex;
    align-items: flex-start;
    column-gap: 24px;
    justify-content: flex-end;

    @media (--viewportSmallMax) {
        flex-direction: column;
        /* align-items: flex-start; */
        row-gap: 16px;
    }
}

.matchingBadge {
    font-weight: 600;
    border-radius: 24px;
    padding: 8px 16px;
    margin: 0;

    @media (--viewportSmallMax) {
        padding: 2px 16px;
    }

    flex-shrink: 0;

    &.total {
        color: #1e9f55;
        background-color: #f0fff6;
    }

    &.sufficient {
        color: #fa0;
        background-color: #fff7f0;
    }

    &.unsufficient {
        color: #f00;
        background-color: #fff0f0;
    }
}

.instructions {
    @media (--viewportMedium) {
        width: 55%;
        flex-shrink: 0;
    }

    & p {
        margin: 0;

        &:nth-of-type(1) {
            font-weight: 600;
        }
    }
}
