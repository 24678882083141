@import '../../../marketplace.css';

.topSectionWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;
    width: 100%;

    @media (--viewportLarge) {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 24px auto 0 auto;
        display: flex;
        max-width: 1150px;
    }
}

.textTitle {
    @apply --fontSizeXL;
    color: #1a2b49;
    font-weight: 700;

    margin: 0;

    @media (--viewportLarge) {
        font-size: 32px;
        line-height: 40px;
    }
}

.actionWrapper {
    display: none;

    @media (--viewportLarge) {
        display: flex;
        align-items: center;
        column-gap: 16px;
    }
}

.viewPhotos {
    @apply --marketplaceTinyFontStyles;
    font-weight: var(--fontWeightMedium);

    /* Position and dimensions */
    position: relative;

    font-size: 15px;
    /* Colors */
    background-color: var(--matterColorLight);

    /* Borders */
    border: none;
    border-radius: var(--borderRadius);

    cursor: pointer;
    transition: var(--transitionStyleButton);

    & svg {
        position: relative;
        left: 10px;
        height: 24px !important;
        width: 24px !important;
        stroke: rgb(26, 43, 73) !important;

        &[data-favorite='selected'] {
            stroke: rgb(255, 56, 92) !important;
        }
    }

    @media (--viewportLarge) {
        margin: 0;
    }
}

.shareSocialNetworkBtn {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (--viewportLarge) {
        position: relative;
        display: flex;
        align-items: flex-start;
        @apply --fontSizeMM;
    }

    &:hover {
        cursor: pointer;
    }

    & img {
        margin-right: 16px;
    }

    @media (--viewportLarge) {
        border-radius: 4px;
        border: 1px solid transparent;

        padding: 5px 8px;
        transition: 0.1s;

        &:hover {
            cursor: pointer;
            border: 1px solid #1a1a1a;
        }
    }
}

.telianIcon {
    width: 24px;
    height: 24px;
}

.favoritText {
    @apply --fontSizeMM;
}

.favoriteWrapper {
    padding: 0;
    border: none;
    position: relative;
    display: flex;
    align-items: center;

    top: 0px;
    right: 0px;

    &:hover {
        cursor: pointer;
    }

    & svg {
        width: 24px;
        height: 24px;
    }

    @media (--viewportLarge) {
        border-radius: 4px;
        border: 1px solid transparent;

        padding: 5px 6.5px;
        transition: 0.1s;

        & svg {
            margin-right: 8px;
        }

        &:hover {
            cursor: pointer;
            border: 1px solid #1a1a1a;
        }
    }
}

.favoriteIcon {
    position: static;

    & svg {
        fill: none;
        stroke: #1a2b49;
    }

    &:hover {
        transform: scale(1) !important;
    }
}

.inWishlist {
    & svg {
        fill: #ff0000;
        stroke: #ff0000;
    }
}

.favoriteDisabled {
    &:hover {
        cursor: not-allowed;
    }

    & span {
        color: #b2b2b2;
    }

    & svg {
        stroke: #b2b2b2;
    }

    @media (--viewportLarge) {
        &:hover {
            border: 1px solid transparent;
        }
    }
}
