@import '../../marketplace.css';

.modalContent {
    max-height: 720px;
    overflow: auto;

    & > p {
        margin-bottom: 32px;
    }

    @apply --scrollBarStyled;
}

.modalContainer {
    border: none;
    padding: 0;

    @media (--viewportMedium) {
        padding-bottom: 0;
    }

    & h3 {
        margin-top: 0;
    }
}

.commentField {
    margin-top: 24px;

    & textarea {
        height: 114px;

        @media (--viewportMedium) {
            height: 184px;
        }
    }
}

.sidenote {
    @apply --paragraphAntiSM;
    text-align: center;

    margin-bottom: 16px;
}

.error {
    color: var(--failColor);
}
.noBorderFooter {
    border: none !important;
}
