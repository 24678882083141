@import '../../marketplace.css';

.root {
    position: relative;
}

.selectError {
    @apply --inputError;
}

.disabled {
    color: #b2b2b2;
    border-bottom-color: #b2b2b2;

    &:hover {
        cursor: not-allowed;
        border-bottom-color: #b2b2b2;
    }

    & + .fieldset {
        color: #b2b2b2 !important;
    }
}

.disabled ~ svg path {
    stroke: #b2b2b2;
    fill: #b2b2b2;
}

.chipsHolder {
    position: relative;
    color: #1a2b49;
    text-align: left;
    border-radius: 0;
    border: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    overflow: hidden;
    box-sizing: border-box;
    min-height: 56px;
    border-bottom: 2px solid var(--marketplaceColorDarkMedium);
    padding: 32px 48px 8px 16px;
    background-color: transparent !important;
    cursor: pointer;

    @media (--viewportMedium) {
        min-height: 62px;
    }

    & ~ svg {
        position: absolute;
        top: calc(50% - 8px);
        right: 16px;

        pointer-events: none;

        transform: rotate(90deg);
        transition: 0.35s;

        & path {
            stroke: #1a2b49;
            fill: #1a2b49;
        }
    }
}

.wrapper {
    position: relative;
    border-radius: 4px 4px 0 0;
    border: 1px solid var(--matterColorNegative);
    border-bottom: none;
}

.wrapperSuccess > .chipsHolder {
    border-bottom-color: #1e9f55 !important;
}

.wrapperDropdownEnabled > svg {
    transform: rotate(-90deg) !important;
}

.wrapperDropdownEnabled > .chipsHolder {
    border-bottom-color: var(--marketplaceColor) !important;
}

.fullSectionWrapper {
    position: relative;
}

.fieldset {
    @apply --fieldset;
    color: #b2b2b2;

    @media (--viewportSmallMax) {
        @apply --fontSizeSM;
    }
}

.fieldsetVisible {
    @apply --fieldsetVisible;
    color: #1e9f55;
}

.focused {
    color: var(--marketplaceColor) !important;
}

.checkboxItem {
    position: relative;
    border: none;
    border-radius: unset;

    & label {
        @apply --fontSizeMM;

        border-radius: inherit;

        width: 100%;
        padding: 8px 16px;

        &:hover {
            cursor: pointer;
        }
    }

    transition: 0.35s;
    background: white;

    &:hover {
        background: #f9f9f9;
        border: none;
    }
}

.selected {
    & rect {
        stroke: #923395 !important;
    }
}

.selected,
.selectDisabled {
    &:hover {
        background: unset !important;
    }
}

.dropdownEnabled {
    border-top: none !important;
    border-radius: 0 0 4px 4px !important;
    border: 1px solid #e6e6e6;
    top: 0px;
    left: -1px;
    transition: 0s;
    transition-delay: 0.15s;
    width: calc(100% + 2px);
    position: absolute;
    z-index: 110;
    background: white;
    padding: 8px 0;
    overflow: scroll !important;
    max-height: 204px;

    & .checkboxItem label {
        padding: 8px 16px;
    }

    & .selected label p,
    & .selected.selectDisabled label p {
        color: #1a2b49 !important;
    }

    & > * {
        /*FieldCheckbox*/
        border: none !important;
    }
}

.dropdownDisabled {
    max-height: 0;
}

.chipContainer {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    padding: 4px 8px 4px 16px;
    gap: 8px;

    border-radius: 48px;
    border: 1px solid #8f2593;
    background: #f9f4fa;

    @apply --fontSizeXS;
    color: #8f2593;

    @media (--viewportSmallMax) {
        overflow: hidden;

        & > span {
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.chipContainer > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chipContainer > div:hover {
    cursor: pointer;
}
