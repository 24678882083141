@import '../../../../marketplace.css';
@import '../common.css';

.root {
    @apply --sectionRoot;

    margin: 24px auto;
    padding: 24px;

    display: flex;
    flex-direction: column;

    @media (--viewportLarge) {
        margin: 24px auto 0 auto;
        max-width: 1150px;
        flex-direction: row;
    }
}

.priceContainer {
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 24px;

    @media (--viewportLarge) {
        width: calc(360px - 66px);
        border-right: 1px solid #e6e6e6;
        border-bottom: none;
        align-items: flex-start;
        justify-content: center;
        padding-bottom: 0;
        margin-left: 53px;
    }
}

.price {
    display: inline-block;
    @apply --fontSizeXL;

    color: #8f2593;
    font-weight: 700;
    margin: 0 0 4px 0;

    @media (--viewportLarge) {
        font-size: 40px;
        line-height: 48px;
    }
}

.priceBottomText {
    color: #1a2b49;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;

    @media (--viewportLarge) {
        font-size: 18px;
        line-height: 28px;
    }
}

.profileContainer {
    display: flex;
    align-items: center;
    padding: 0;
    margin-top: 24px;

    @media (--viewportLarge) {
        margin-top: 0;
        margin-left: 32px;
    }
}

.userName {
    display: inline-block;

    @apply --fontSizeXL;

    font-weight: 700;

    margin: 0 0 4px 0;
}

.userBottomText {
    color: #b2b2b2;
    margin-bottom: 0;
}

.avatar {
    margin-right: 16px;
    width: 80px;
    height: 80px;
    border: none;
}

.buttonContainer {
    margin-left: 0;

    @media (--viewportLarge) {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 327px;
    }
}

.contactButton {
    height: 60px;
    padding: 16px;

    @apply --marketplaceButtonStyles;

    &:disabled {
        background-color: #e6e6e6;
        color: #b2b2b2;

        &:hover {
            cursor: not-allowed;
        }
    }

    &.enquired {
        @apply --marketplaceButtonStylesSecondary;
        padding: 16px;
    }

    &.contactButtonMobile {
        display: flex;
        width: 312px;

        @media (--viewportLarge) {
            display: none;
            width: 0;
        }
    }

    &.contactButtonDesktop {
        display: none;

        @media (--viewportLarge) {
            display: flex;
            width: 327px;
        }
    }
}

.contactButtonMobSection {
    display: block;
    border-top: 1px solid #e6e6e6;

    z-index: 2;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px 24px;
    background: white;

    & p {
        margin-bottom: 8px;
    }

    & button {
        width: 100%;
    }

    @media (--viewportLarge) {
        display: none;
    }
}

.linkButton {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    margin: 32px 0 0 0;

    color: #8f2593;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;

    & svg {
        & path {
            fill: #8f2593;
        }
    }

    @media (--viewportLarge) {
        margin: 24px 0 0 0;
        font-size: 18px;
        line-height: 28px;
    }
}

.notAllowed {
    @apply --paragraphAntiSM;
    text-align: center;
}

.desktopOnly {
    @media (--viewportMediumMax) {
        display: none;
    }
}
