@import '../../common.css';

.root {
    @apply --subPages_root;
}
.description,
.heading {
    @apply --subPages_description;
}

.subLinkSecondary {
    border: none;
}

.footer {
    column-gap: 24px;

    @media (--viewportSmallMax) {
        flex-direction: column;
        align-items: center;
        row-gap: 24px;

        & a {
            max-width: 207px;
        }
    }
}
