@import '../../marketplace.css';

.root {
    display: flex;
    align-items: center;
    position: relative;
}

.dumbInputRoot {
    @apply --inputFieldWrapper;
}

.dumbFieldContainer {
    display: flex;

    & input {
        @apply --inputFieldPrimary;

        padding: 5px 42px 5px 16px;
    }

    &.withMapMarker {
        & input {
            padding-left: 56px;
        }
    }

    &.dumbFieldContainerWithDistance {
        & input {
            max-width: 230px;

            @media (--viewportSmallMax) {
                max-width: 284px;
            }
        }
    }
}

.mapMarkerIcon {
    position: absolute;
    left: 16px;
    top: calc(50% - 12px);

    pointer-events: none;
}

.distanceSubField {
    position: absolute;
    right: 48px;
    top: calc(50% - 12px);

    border-left: 1px solid #e6e6e6;
    padding-left: 16px;

    pointer-events: none;

    @apply --fontSizeSM;
}

.dumbFieldIconHolder {
    @apply --inputFieldIconHolder;
    pointer-events: auto;
}

.tabsContainer {
    display: flex;

    & > p:nth-of-type(1) {
        margin-right: 24px;
    }
}
.tab {
    @apply --fontSizeMM;
    margin-bottom: 0;
    padding: 8px 0;

    transition: 0.35s;

    &:hover {
        cursor: pointer;
        color: #8f2593;
    }
}

.tabSelected {
    color: #8f2593;
    border-bottom: 3px solid #8f2593;
}
