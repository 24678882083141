@import '../../marketplace.css';

:global(.wg-default) {
    display: none;
}

.langSwitcher {
    display: flex;
    column-gap: 8px;
}

.langSwitcherOption {
    @apply --fontSizeSM;

    &:hover {
        cursor: pointer;
    }

    &.selected {
        text-decoration: underline;
        &:hover {
            cursor: auto;
        }
    }
}
