@import '../../marketplace.css';

.reportProfile {
    font-size: 16px;
    color: #717171;

    font-weight: 600;

    text-align: center;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }

    & img {
        height: 16px;
        width: 16px;

        margin-bottom: 4px;
        margin-right: 6px;
    }
}
