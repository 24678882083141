@import '../../marketplace.css';

.visibilityHolder {
    width: 100%;
    margin-bottom: 32px;

    @media (--viewportMedium) {
        position: relative;
    }

    & > svg {
        flex-shrink: 0;
        position: relative;

        @media (--viewportMedium) {
            margin-right: 16px;
            margin-bottom: 0;
        }
    }

    & ul {
        margin: 0 0 0 22px;
    }

    & li {
        color: #8f2593;
        text-decoration: underline;

        list-style-type: disc;

        @media (--viewportSmallMax) {
            font-size: 16px;
        }

        &::marker {
            color: #923395;
            font-size: 12px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}
