@import '../../common.css';

.root {
    margin-bottom: 64px;
}

.description,
.heading {
    @apply --subPages_description;
}

.grid {
    @apply --subPages_grid;

    @media (--viewportMediumMax) {
        flex-direction: column;
        row-gap: 32px;
    }
}

.gridItem {
    @apply --subPages_grid_item;
}
