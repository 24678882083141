@import '../../marketplace.css';

@keyframes carouselAnimationForward {
    0% {
        transform: translate(0);
    }

    to {
        transform: translate(calc(-100% - 24px));
    }
}

.layoutWrapperMain {
    padding-bottom: 32px;
    min-height: calc(100vh - var(--topbarHeight));

    @media (--viewportMedium) {
        padding-bottom: 64px;
        min-height: calc(100vh - var(--topbarHeightDesktop));
        margin-top: 64px;
    }
}
.hideBlobBackground {
    & > svg:nth-of-type(1) {
        display: none;
    }
}
.root {
    @apply --marketplaceModalRootStyles;

    border: none;
}

.content {
    @apply --marketplaceModalBaseStyles;
    @apply (--marketplaceModalResetStyles);
}
.signinContent {
    flex-basis: 424px;
}
/* ================ Tabs & Form ================ */

.bottomWrapper {
    @apply --marketplaceModalBottomWrapper;

    /* Align the helper links to the left since there isn't a Primary Button anymore */
    text-align: left;
}

/* Terms of Service modal*/

.termsWrapper {
    width: 100%;
    padding-top: 40px;

    @media (--viewportMedium) {
        width: 604px;
        padding-top: 11px;
    }
}

.termsHeading {
    @apply --marketplaceH1FontStyles;
    margin: 0 0 19px 0;

    @media (--viewportMedium) {
        margin: 0 0 19px 0;
    }
}

/* ================ Hide Top bar in screens smaller than 768px  ================ */

.hideOnMobile {
    display: none;

    @media (--viewportMedium) {
        display: block;
    }
}

/* ================ Close icon ================ */

.verifyClose {
    @apply --marketplaceModalCloseStyles;

    /* This is not a real modal, z-index should not be modal's z-index */
    z-index: 1;
}

.closeText {
    @apply --marketplaceModalCloseText;
}

.closeIcon {
    @apply --marketplaceModalCloseIcon;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
    @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
    @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
    @apply --marketplaceModalHighlightEmail;
}

/* Helper links */
.modalHelperLink {
    @apply --marketplaceLinkStyles;
    @apply --marketplaceModalHelperLink;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
    @apply --marketplaceModalHelperText;
}

.modalIcon {
    @apply --marketplaceModalIconStyles;

    /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
    margin-top: 47px;

    @media (--viewportMedium) {
        margin-top: 0;
    }
}

/* ================ Errors ================ */

.error {
    @apply --marketplaceModalErrorStyles;
    & a {
        color: inherit;
        text-decoration: underline;
    }
}

.mobSliderClassName {
    padding-bottom: 24px;

    & > div > div {
        /* > .carouselHolder > .carousel */

        & > button {
            bottom: 8px;

            &:first-of-type {
                left: 24px;
            }

            &:last-of-type {
                right: 24px;
                /* .DotGroup */
                & + div {
                    bottom: 24px;
                    position: absolute;
                    left: 0;
                    right: 0;

                    & > div {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

.authSliderContainer {
    max-width: unset;
    width: 100%;
    /* .carouselHolder >  .carousel > .horizontalSlider */
    /* & > div > div > div {
        padding-top: 10px;
    } */
}

.carousel {
    &,
    & > div {
        display: flex;
    }

    & > div {
        animation-name: carouselAnimationForward;
        animation-duration: 180s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    &.previewed {
        & > div {
            animation-play-state: paused;
        }
    }
}

.tempListingInfoSaved {
    max-width: 784px;
    width: auto;

    margin: 24px 24px 8px 24px;

    @media (--viewportMedium) {
        margin: 0 0 32px 120px;
        width: 100%;
    }
}

.tempListingInfoSavedCloseBtn {
    @media (--viewportMedium) {
        display: block;

        right: 8px;
        top: 8px;
    }
}
