@import '../../../../marketplace.css';
@import '../common.css';

.root {
    @apply --sectionRoot;
}

.sectionTitle {
    @apply --sectionTitle;

    border-bottom: 1px solid #e6e6e6;
}

.packageRoot {
    padding: 24px 0;
    border-bottom: 1px solid #e6e6e6;
}

.packageHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin: 0;
}

.packageTitle {
    @apply --fontSizeXL;
    font-weight: 700;
}

.packageTitleOpen {
    color: #8f2593;
}

.packageContent {
    margin-top: 24px;
}

.subItem {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    & svg {
        margin-right: 8px;
    }

    &:first-of-type {
        & svg {
            width: 24px;
            height: 24px;

            transform: scale(1, -1);

            & path {
                stroke: #8f2593;
            }
        }
    }
}

.packageDesc {
    margin: 16px 0 0 0;
}

.packageArrow {
    display: flex;
    & svg {
        transition: 0.35s;
        transform: rotate(90deg);

        & path {
            fill: #1a2b49;
            stroke: #1a2b49;
        }
    }
}

.isOpen {
    & svg {
        transform: rotate(-90deg);
    }
}
