@import '../../marketplace.css';

.paginationWrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  right: 8px;
  left: 8px;
  bottom: 20px;

  max-width: 56px;
  margin: 0 auto;
  overflow: hidden;

  & > div > div {
    transform: unset !important;
  }
}
.dotButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  outline: none;
}
.dotIcon {
  border-radius: 50%;
  background-color: var(--matterColorLight);
  display: block;
  opacity: 0.6;
  width: 8px;
  height: 8px;
}
.dotIconActive {
  width: 10px;
  height: 10px;
  opacity: 1;
}
.dotIconMoreState {
  width: 4px;
  height: 4px;
}
.slideContainer {
  width: 18px;
  height: 18px;
}
