@import '../../marketplace.css';

:root {
    /*
      These variables are available in global scope through ":root"
      element (<html> tag). Variables with the same names are going to
      overwrite each other if CSS Properties' (PostCSS plugin)
      configuration "preserve: true" is used - meaning that variables
      are left to CSS bundle. We are planning to enable it in the future
      since browsers support CSS Properties already.
   */

    --ProfileSettingsForm_avatarSize: 200px;
}

.box {
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
}
.imgPreview {
    overflow: hidden;
}

.error {
    /* Font */
    @apply --marketplaceH4FontStyles;
    color: var(--failColor);
    margin-top: 18px;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 22px;
        margin-bottom: 2px;
    }
}

.avatar {
    width: 100%;
    height: 100%;

    & > span {
        font-size: 60px;
        line-height: 72px;
        letter-spacing: -0.5px;
    }
}

.avatarInvisible {
    visibility: hidden;
    position: absolute;
    top: -1000px;
    left: -1000px;
}

.uploadingImageOverlay {
    /* Overlay style */
    position: relative;
    background: none;
    height: 200px;

    opacity: 0.8;

    /* Center content */
    display: flex;
    justify-content: center;
    align-items: center;
}

.uploadingImage {
    /* Dimensions */
    width: var(--ProfileSettingsForm_avatarSize);
    height: var(--ProfileSettingsForm_avatarSize);

    /* Image fitted to container */
    object-fit: cover;
    background-color: var(--matterColorNegative); /* Loading BG color */
    border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
    overflow: hidden;

    display: block;
    position: relative;
    margin: 0;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
    padding-bottom: 100%;
}
.avatarPlaceholderContainer {
    position: relative;
    margin-bottom: 32px;

    & > svg circle {
        transition: 0.35s;
    }

    &:hover {
        & > svg circle {
            stroke: #1a2b49;
        }
    }
}
.avatarPlaceholder,
.avatarContainer {
    /* Dimension */
    position: relative;
    width: 200px;
    height: 200px;
    margin-bottom: 24px;
    /* Center content */
    display: flex;
    align-items: center;
    justify-content: center;

    /* Initial coloring */
    background-color: transparent;
    cursor: pointer;

    &:hover {
        cursor: pointer;
    }

    & > svg {
        fill: none;
    }

    @media (--viewportMedium) {
        margin-bottom: 32px;
    }
}
.avatarPlaceholder {
    position: absolute;
    top: 0;
}
.dashedCircleIcon {
    fill: none;
    width: var(--ProfileSettingsForm_avatarSize);
    height: var(--ProfileSettingsForm_avatarSize);
}
.changeAvatarSection {
    display: flex;
    position: static;
    margin-top: 24px;
    margin-bottom: 32px;

    & > svg,
    & path {
        fill: none;
    }

    & > div:first-of-type {
        margin-right: 16px;
    }

    @media (--viewportMedium) {
        /* Position: under */
        top: calc(50% - 24px);
        left: 208px;
        margin: 0;

        position: absolute;
    }
}

.avatarUploadError {
    & circle {
        stroke: red;
    }
}

.changeAvatar {
    display: flex;
    align-items: center;

    color: #8f2593;
    @apply --fontSizeSM;

    flex-shrink: 0;

    & > svg {
        margin-right: 4px;
    }

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.avatarPlaceholder {
    flex-direction: column;
    background: transparent;
}

.avatarPlaceholderHeading {
    @apply --paragraphAntiMedium;

    color: black;
    max-width: 86px;

    text-align: center;
    font-weight: 600;
}

.avatarPlaceholderFormats {
    @apply --paragraphAntiSM;

    max-width: 116px;
    text-align: center;
    margin-top: 4px;
}

.label {
    padding: 0;
    position: relative;
    width: var(--ProfileSettingsForm_avatarSize);
    margin-left: auto;
    margin-right: auto;

    @media (--viewportMedium) {
        margin-left: initial;
        margin-right: initial;
    }
}

.uploadAvatarInput {
    display: none;
}
.modalContainer {
    border: none;
    border-radius: 8px;
    overflow-y: unset;
}
.modalContent {
    height: 100%;

    display: flex;
    flex-direction: column;
}
.editorHeading {
    margin: 0 0 32px 0;
    letter-spacing: -0.5px;
}
.instructions {
    margin: 0 0 24px 0;

    & p {
        display: flex;
        column-gap: 8px;
        margin-bottom: 8px;
    }
}
.editorDesc {
    @apply --fontSizeSM;
    margin-bottom: 32px;
}
.editorHolder {
    width: 100%;

    max-height: 299px;
    max-width: 430px;

    &,
    & > div {
        border-radius: 4px;
        height: 299px;
    }
}
.sliderSection {
    margin-top: 32px;
}
.zoomSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    & > div {
        &:hover {
            cursor: pointer;
        }
    }

    & svg {
        fill: none;
    }
}
:global(.advanced-cropper-preview__content),
:global(.preview.advanced-cropper-preview.cropper-preview-wrapper) {
    width: 100% !important;
    height: 100% !important;
}

.editPriceSliderRoot {
    & [role='button']:last-child {
        z-index: 1;
    }
    /* hide the first slider handler */
    & [role='button']:not(:last-child) {
        background: var(--marketplaceColor);
        height: 8px;
        top: 8px;
        width: 10px;
        margin: 0;
        border-radius: 6px 0 0 6px;
        left: -10px !important;
        pointer-events: none;

        & > div {
            display: none;
        }
    }
}

.actionButton {
    margin-top: auto;
}
