@import '../../../../marketplace.css';
@import '../../common.css';

.root {
    margin-bottom: 64px;
}

.description {
    white-space: normal;
    @media (--viewportLarge) {
        white-space: break-spaces;
    }
}

.tabs {
    display: flex;
    justify-content: center;
    column-gap: 32px;
    margin-bottom: 64px;

    @media (--viewportMediumMax) {
        flex-direction: column;
        row-gap: 24px;
        margin-bottom: 32px;
        align-items: center;
    }
}

.tab {
    @apply --fontSizeXL;
    font-weight: 700;
    margin: 0;

    &:hover {
        cursor: pointer;
    }

    &.tabSelected {
        color: #8f2593;
        border-bottom: 3px solid #8f2593;
    }
}
