@import '../../marketplace.css';

.root {
    display: flex;
    justify-content: flex-end;

    & p {
        border-radius: 24px;

        font-weight: 600;
        padding: 8px 16px;
        margin-bottom: 24px;

        @media (--viewportSmallMax) {
            padding: 2px 16px;
            margin-bottom: 16px;
        }
    }
}

.total {
    background-color: #f0fff6;
    color: #1e9f55;
}

.sufficient {
    color: #ffaa00;
    background-color: #fff7f0;
}

.insufficient {
    color: #ff0000;
    background-color: #fff0f0;
}
