@import '../../marketplace.css';

:root {
    /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
    --Avatar_size: 40px;
    --Avatar_sizeMedium: 60px;
    --Avatar_sizeLarge: 80px;
    /* Base for all avatars */
    --Avatar_base: {
        font-family: 'sofiapro', Helvetica, Arial, sans-serif;
        border-radius: 50%;

        /* Position possible initials to the center of the component */
        display: flex;
        align-items: center;
        justify-content: center;

        /* Colors */
        background-color: #8f2593;
        color: #ffffff;
        border: 2px solid white;
        &:hover {
            text-decoration: none;
        }
    }
}

/* Small Avatar */

.root {
    /* Font is specific to this component, but defining it in marketplace.css makes it easier
   * to change font if brand look-and-feel needs such changes.
   */
    @apply --Avatar_base;

    /* Layout */
    width: var(--Avatar_size);
    height: var(--Avatar_size);
}

.initials {
    font-size: 14px;
    font-weight: var(--fontWeightBold);
    /* padding-bottom: 4px; */
}

.avatarImage {
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

/* Medium Avatar */
.mediumAvatar {
    @apply --Avatar_base;
    /* Fixed dimensions */
    width: var(--Avatar_sizeMedium);
    height: var(--Avatar_sizeMedium);
    cursor: pointer;
}

.mediumAvatar .initials {
    font-size: 20px;
    font-weight: var(--fontWeightSemiBold);
    padding-bottom: 5px;
}

/* Large Avatar */

.largeAvatar {
    @apply --Avatar_base;

    /* Fixed dimensions */
    width: var(--Avatar_sizeLarge);
    height: var(--Avatar_sizeLarge);
    cursor: pointer;
}

.largeAvatar .initials {
    padding: 0;
    @apply --fontSizeXL;
    font-weight: 700;
}

.extraLargeAvatar {
    @apply --Avatar_base;

    /* Fixed dimensions */
    width: var(--Avatar_sizeLarge);
    height: var(--Avatar_sizeLarge);
    cursor: pointer;

    & .initials {
        padding: 0;
        font-size: 60px;
        line-height: 72px; /* 120% */
        letter-spacing: -0.5px;
        font-weight: 700;
    }
}

.bannedUserIcon {
    width: 100%;
    height: 100%;
}
.hasProfileImage {
    background-image: none;
    position: relative;
    display: block;
    background-color: unset;
}
.avatarLayer {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #88298bcc;
    border-radius: 50%;
    transition: 0.3s;
    pointer-events: none;
}

.hasProfileImage:hover {
    background-image: none;
}

.previewAllowed:hover .avatarLayer {
    opacity: 0.2;
}

.previewAllowed:hover .previewLayer {
    opacity: 1;
    pointer-events: auto;
    background-color: rgba(143, 37, 147, 0.8);
}
.previewLayer,
.preview {
    display: flex;
    justify-content: center;
    align-items: center;
}

.preview {
    flex-direction: column;
    text-align: center;

    & svg {
        margin-bottom: 4px;
    }

    & p {
        margin: 0;
        font-weight: 600;
    }

    & > span {
        padding: 0 25px;
    }
}

.disableProfileLink {
    cursor: default;
}
