@import '../../../marketplace.css';

.footer {
    margin-top: 40px;

    & p {
        @apply --paragraphAntiSM;
        text-align: center;
    }

    & button:not(:disabled) {
        color: var(--failColor);
    }
    @media (--viewportMedium) {
        border-top: none !important;
    }
}
