@import '../../marketplace.css';

:root {
    --LevelInfo_UL_styles: {
        list-style: disc;
        padding-left: 16px;
        margin: 0;

        & p {
            margin-bottom: 0;
        }
    }
}

.levelInfoWrapper {
    display: flex;
    align-items: center;
    column-gap: 4px;

    margin: 24px 0 4px 0;

    position: relative;
    column-gap: 4px;
}
.levelInfoHeading {
    font-weight: 600;
    margin-bottom: 0;
}

.tooltip {
    & + div {
        display: flex;

        &:hover {
            cursor: pointer;
        }
    }
}

.levelList {
    @apply --LevelInfo_UL_styles;

    & li {
        @apply --fontSizeXS;
        line-height: 28px;

        @media (--viewportSmallMax) {
            line-height: 24px;
        }
    }
}

.tooltipContent {
    & h4,
    & ul li,
    p {
        @apply --fontSizeXS;
    }

    & ul {
        @apply --LevelInfo_UL_styles;
    }

    & h4 {
        font-weight: 600;
        margin: 0 0 10px 0;
        /* prevent heading overlowing a close icon */
        padding-right: 24px;

        white-space: break-spaces;
    }
}

.tooltip {
    width: calc(100vw - 54px);

    @media (--viewportMedium) {
        width: 384px;
    }

    & svg {
        @apply --tooltipStaticCloseIcon;
    }
}
