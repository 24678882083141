@import '../../marketplace.css';

.layoutWrapperMain {
    min-height: calc(100vh - var(--topbarHeight));

    @media (--viewportMedium) {
        min-height: calc(100vh - var(--topbarHeightDesktop));
    }
}

.modalContent {
    display: flex;
    flex-direction: column;

    & > button {
        @media (--viewportSmallMax) {
            position: absolute;
            left: 24px;
            right: 24px;
            bottom: 24px;
            width: auto;
        }
    }

    & > svg {
        margin-bottom: 16px;
        flex-shrink: 0;
    }
}

.form {
    @apply --marketplaceModalFormRootStyles;
    row-gap: 24px;
}

.error {
    @apply --marketplaceModalErrorStyles;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
    @apply --fontSizeXXL;
    margin: 0 0 16px 0;
}

/* Make the email pop */
.email {
    @apply --marketplaceModalHighlightEmail;
}

.helperLink {
    @apply --marketplaceModalHelperLink;
}

.helperText {
    @apply --marketplaceModalHelperText;
}

.submiAction {
    margin-top: 24px;
}

.resetPasswordModalTitle {
    margin: 0 0 32px 0;
}
