@import '../../../marketplace.css';

:root {
    --flexJustifySB {
        display: flex;
        justify-content: space-between;
    }
}

.checkoutSubTitle {
    @apply --fontSizeXL;
    font-weight: 700;
    color: #b2b2b2;
    margin-top: 0;
    margin-bottom: 24px;
}

.packageCheckoutHolder {
    margin-bottom: 32px;
}

.packageCheckoutInfo {
    @apply --flexJustifySB;
    align-items: center;

    margin-bottom: 8px;

    & p {
        margin: 0;
    }
}
.packageCheckoutTotal {
    @apply --flexJustifySB;

    border-top: 1px solid #e6e6e6;
    padding-top: 8px;

    & p {
        font-weight: 600;
        margin: 0;
    }
}

.paymentMethodsInfo {
    justify-content: center;
    margin-bottom: 32px;

    @media (--viewportSmallMax) {
        margin-bottom: 147px;
    }
}

.checkoutFooter {
    margin-bottom: 80px;

    @media (--viewportSmallMax) {
        display: flex;
        justify-content: center;

        background: white;

        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;

        border-top: 1px solid #e6e6e6;

        padding: 12px 24px;
        margin: 0;
        width: auto;
    }
}
