@import '../../marketplace.css';

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
}

.breadcrumbSeparator {
    position: relative;
    width: 14px;
    height: 14px;
}

.breadcrumbSeparator path {
    fill: var(--matterColorAnti, #b2b2b2);
}

.breadcrumbItem {
    font-family: 'sofiapro', Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.25px;
    list-style: none;
    display: inline-block;
}

.breadcrumbItemLink {
    color: var(--matterColorAnti, #b2b2b2);
}

.breadcrumbItem:first-child {
    margin-left: initial;
}

.breadcrumbItem:last-child {
    margin-right: initial;
}

.breadcrumbItem:last-child .breadcrumbItemLink {
    color: var(--marketplaceColor, #8f2593);
}

/* viewport large */
/* min-width: 1024px */
@media (--viewportLarge) {
    .breadcrumbSeparator {
        width: 16px;
        height: 16px;
    }

    .breadcrumbItem {
        font-size: 16px;
        line-height: 24px;
    }
}
