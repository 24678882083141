@import '../../marketplace.css';

.container {
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid var(--matterColorAntiSoft);

    box-sizing: border-box;
    background: #fff;

    &:hover,
    &:focus {
        cursor: pointer;
        border: 1px solid var(--matterColor);
        background: #fff;
    }

    @media (--viewportLarge) {
        @apply --fontSizeSM;
        color: #1a2b49;
        min-height: unset;
        column-gap: 8px;
        border-radius: 48px;
        padding: 8px 16px;
    }

    @media (--viewportMediumMax) {
        width: 32px;
        height: 32px;
        min-height: 32px;
        border-radius: 50%;

        & > span {
            display: none;
        }

        & svg {
            width: 16px;
            height: 16px;
        }
    }

    &.disabled {
        color: #b2b2b2;
        & path {
            fill: #b2b2b2;
        }

        &:hover {
            cursor: auto;
            border: 1px solid var(--matterColorAntiSoft);
        }
    }
}

.alreadySubscribed {
    color: #8f2593;
    border: 2px solid var(--marketplaceColor);
    background-color: #f9f4fa;

    &:hover,
    &:focus {
        background-color: #f9f4fa;
        border: 2px solid var(--marketplaceColor);
    }
}

.inProgress {
    background: rgba(26, 43, 73, 0.05);

    &:hover,
    &:focus {
        cursor: not-allowed;
        background: rgba(26, 43, 73, 0.05);
        border: 1px solid var(--matterColorAntiSoft);
    }
}

.modalContainer {
    flex-basis: 480px;
    border-top: 0;

    & h3 {
        font-weight: 700;
    }
}

.modalContent {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.modalFooter {
    margin-top: 24px;

    @media (--viewportSmallMax) {
        margin-top: auto;
    }
}

.modalHeader {
    margin-bottom: 16px;

    @apply --fontSizeXXL;

    @media (--viewportSmallMax) {
        @apply --fontSizeXL;
    }
}

.modalParagraph {
    line-height: 28px;
}

.modalSmallText {
    @apply --paragraphAntiSM;
    text-align: center;
}

.modalActionButton {
    @apply --fontSizeMM;
    font-weight: 600;
}

.updateError {
    @apply --fontSizeXS;
    color: var(--failColor);
}
