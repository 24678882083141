@import '../common.css';

.root {
    @apply --sectionRoot;
}

.title {
    @apply --sectionTitle;
}

.availability {
    @apply --sectionParagraphWithIcon;

    & svg {
        width: 24px;
        height: 24px;
        transform: scale(1, -1);

        & path {
            stroke: #8f2593;
        }
    }
}

.dates {
    padding-left: 32px;
    margin-bottom: 0;
}
.matchingContainer {
    @apply --matchingContainer;
    margin-bottom: 0;
}
