@import '../../../../marketplace.css';
@import '../common.css';

.root {
  @apply --sectionRoot;
}

.title {
  @apply --sectionTitle;
  padding-bottom: 32px;
}

.contentHolder {
  position: relative;
}

.skillsWrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 8px;
  overflow: hidden;

  @media (--viewportMedium) {
    row-gap: 16px;
  }
}

.skillBox {
  border-radius: 20.35px;
  border: 0.925px solid #8f2593;
  background-color: #8f2593;
  padding: 8px 16px;

  @apply --fontSizeXS;

  color: #fff;
}

.dtOnly {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.mobileOnly {
  display: block;

  @media (--viewportMedium) {
    display: none;
  }
}

.matchingContainer {
  @apply --matchingContainer;
}
