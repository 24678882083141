@import '../../marketplace.css';

.userRatingContainer {

    & > svg {
      margin-right: 8px;
      @media (--viewportMediumMax) {
        width: 18px;
      }
    }
    & > svg:last-child {
      margin-right: 0;
    }
  }
  .filled {
    fill: #8F2593;
  }
  .empty {
    fill: #B2B2B2;
  }
