@import '../../marketplace.css';
@import './TransactionPanel.css';

.acceptDeclineBtnSection {
    @apply --actionLabelRoot;

    & button {
        @apply --fontSizeXS;
        font-weight: 600;

        width: auto;
        min-height: 40px;
        padding: 9px 16px;

        &:nth-of-type(1) {
            margin-right: 16px;
        }
    }
}

.buttonsSection {
    display: flex;
    margin-top: 24px;
}

.error {
    @apply --paragraphAntiSM;
    color: var(--failColor);
    display: inline-block;
    margin: 16px 0 0 0;
}
