@import '../../common.css';

.content {
    max-width: 760px;
    text-align: center;
    margin: 64px auto 0 auto;
}

.tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 32px;
    margin-bottom: 32px;

    & h3 {
        @apply --fontSizeXL;
        font-weight: 700;
        padding: 8px;
        border-bottom: 3px solid transparent;

        &.tabSelected {
            color: #8f2593;
            border-bottom: 3px solid #8f2593;

            &:hover {
                cursor: initial;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.countryHeader {
    @apply --fontSizeXL;
    font-weight: 700;
    margin: 0 0 24px 0;
}

.primaryLocations {
    display: flex;
    column-gap: 24px;
    margin-bottom: 32px;

    & a {
        flex: 1;
        max-width: 368px;
        height: 134px;
    }

    & a,
    & a:hover {
        text-decoration: none;
    }

    @media (--viewportSmallMax) {
        display: block;

        & a {
            display: block;
            max-width: unset;
            margin-bottom: 24px;
        }
    }
}

.primaryLink {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    height: 100%;

    background-size: cover;
    background-position: center;

    @apply --fontSizeXL;
    font-weight: 700;

    padding: 16px;
    border-radius: 8px;
    color: white;

    &:hover {
        cursor: pointer;
    }
}

.secondaryLocations {
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;

    & a {
        color: #1a2b49;

        &:hover {
            cursor: pointer;
        }

        & p {
            margin: 0;
        }
    }

    &.threeColumn {
        & a {
            @media (--viewportMedium) {
                flex-basis: 33.333%;
            }
        }
    }

    &.fourColumn {
        & a {
            @media (--viewportMedium) {
                flex-basis: 25%;
            }
        }
    }

    @media (--viewportSmallMax) {
        display: block;

        & a {
            margin-bottom: 24px;
            display: block;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
