@import '../../marketplace.css';

:root {
    --base {
        width: auto;
        padding: 0 16px;
    }
}
.root {
    display: flex;
    justify-content: center;
}

.button {
    @apply --base;

    &.accepted {
        @apply --marketplaceButtonStylesSecondary;
        @apply --base;
    }
}

.inquiryDisabledTooltip {
    width: 312px;
}
