.root {
  display: flex;
  align-items: center;
  cursor: pointer;

  & svg {
    & path {
      stroke: #8f2593;
    }
  }
}

.editText {
  margin: 0 0 0 8px;
  color: #8f2593;
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}
