@import '../../marketplace.css';

.tileGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
}

.tile {
    border-radius: 8px;
    background: var(--Ground-Ground-000, #FFF);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .05);
    padding: 16px;
    text-decoration: none;
    color: #1a1a1a;
}

.levelTitleColor {
    color: var(--marketplaceColor);
}

.heading {
    margin: initial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.5px;
}

.tile:hover {
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 0 14px 8px rgba(0, 0, 0, .05);
}

.pageTitleBreak {
    display: initial;
}

/* viewport medium */
/* min-width: 768px */
@media (--viewportMedium) {
    .tileGrid {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    .pageTitleBreak {
        display: none;
    }
}
