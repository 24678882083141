@import '../../marketplace.css';

.root {
    border-top: 1px solid #e6e6e6;

    width: 100%;
    padding: 8px 24px 80px 24px;
    background-color: var(--matterColorBright);

    @media (--viewportSmallMax) {
        padding: 8px 24px 40px 24px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (--viewportMedium) {
        max-width: 1152px;
        margin: 0 auto;
    }
}

.externalLinkIcon > svg:hover {
    fill: #88298b;
}

.sectionLinksMain {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    margin-bottom: 24px;
    column-gap: 24px;
}

.sectionSocialStores {
    margin-bottom: 32px;

    @media (--viewportMedium) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.socialStores {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.langSwitcher {
    @media (--viewportSmallMax) {
        margin-top: 24px;
    }
}

.sectionSideNote {
    @apply --fontSizeSM;

    display: flex;
    align-items: center;

    margin-bottom: 20px;

    @media (--viewportSmallMax) {
        justify-content: center;
        margin-bottom: 32px;
    }

    & svg {
        fill: #8f2593;

        width: 16px;
        height: 16px;

        margin: 0 4px;
    }
}

.list {
    margin-top: 0;
    margin-bottom: 0;
}

.listItem {
    &,
    & a,
    & span {
        @apply --fontSizeSM;
        color: #1a2b49;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    margin-bottom: 16px;

    &.copyright {
        &,
        & span {
            &:hover {
                cursor: auto;
                text-decoration: unset;
            }
        }
    }
}

.footerLinkBlock {
    margin-top: 32px;
    width: 100%;

    & p {
        @apply --fontSizeMM;
        font-weight: 600;
    }

    @media (--viewportMedium) {
        width: calc(50% - 12px);
    }

    @media (--viewportLarge) {
        width: calc(25% - 18px);
    }
}

.sectionSocialLinks {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (--viewportSmallMax) {
        justify-content: center;
    }

    & .list {
        display: flex;
        column-gap: 24px;

        & .listItem {
            margin-bottom: 0;
        }
    }
}

.socialLinks {
    display: flex;
    column-gap: 8px;

    & .socialLink {
        width: 32px;
        height: 32px;

        background: #8f2593;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        & path {
            fill: white;
        }
    }
}

.bottomLinksMobile {
    margin: 0 0 32px 0;
}

.mobileCopyright {
    display: flex;
    margin-top: 32px;
    @apply --fontSizeSM;

    @media (--viewportSmallMax) {
        justify-content: center;
    }
}

.mob {
    @media (--viewportLarge) {
        display: none;
    }
}

.desktop {
    @media (--viewportMediumMax) {
        display: none;
    }
}
