@import '../../marketplace.css';

.content {
    margin: 0 0 56px 0;

    @media (--viewportMedium) {
        margin: 32px auto 56px auto;
        width: 100%;
        max-width: 564px;
    }

    @media (--viewportLarge) {
        margin: 0;
    }
}

/* .desktopTopbar, */
.mobileTopbar {
    box-shadow: none;
}

.title {
    hyphens: auto;
    margin-top: 8px;
    margin-bottom: 19px;

    @media (--viewportMedium) {
        margin-bottom: 47px;
    }
}
