@import '../../marketplace.css';

.paymentAddressField {
    margin-bottom: 48px;

    & input,
    & select {
        font-size: 18px !important;
        line-height: 27px !important;
    }
}

.addressFieldPlaceholderWrapper {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 48px;
    & p {
        margin: 0;
        font-size: 18px;
        line-height: 27px;
    }
}

.editAddressSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;

    & svg {
        fill: none;
    }
}

.editAddressButton {
    background: none;
    border: none;
    color: #923395;
    text-decoration: underline;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 24px !important;

    &:hover {
        cursor: pointer;
    }
}
.paymentHeading {
    @apply --checkoutFormLabel;
    margin-top: 56px;
}

.paymentInfo {
    color: var(--matterColorAnti);
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    margin-top: 48px;
    padding: 0;

    @media (--viewportMedium) {
        padding: 0 25px;
    }
}
.policyTerms label span {
    font-size: 16px;
    line-height: 24px;
    max-width: 400px;
}

.paymentNameFields {
    display: block;

  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
  }

  & > * {
    width: 100%;

    @media (--viewportMedium) {
        width: calc(50% - 12px);
    }
  }

  & > *:last-of-type {
    margin-top: 32px;

    @media (--viewportMedium) {
        margin-top: 0;
    }
  }
}
.error {
    color: var(--failColor);
    font-size: 13px;
    padding: 15px 25px;
    white-space: break-spaces;
  }
