@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  @apply --marketplaceH4FontStyles;
  margin: 0;

  @media (--viewportMedium) {
    padding-top: 8px;
    padding-bottom: 2px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.bookingPeriodSectionRigth {
  text-align: right;
}

.dayLabel {
  @apply --marketplaceH5FontStyles;
  margin: 0;
  color: var(--matterColorAnti);
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding-top: 1px;
    padding-bottom: 7px;
  }
}

.dayInfo {
  @apply --marketplaceButtonFontStyles;
  margin-left: 7px;
  font-size: 18px;
  line-height: 27px;
  font-weight: normal;
}

.subTotalLineItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 0 0 8px 0;

  @media (--viewportMedium) {
    padding: 8px 0 2px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  padding-bottom: 6px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 14px;
  padding-bottom: 2px;
  margin-bottom: 32px;
@media (--viewportMedium) {
  padding-top: 8px;
}
}
.itemLabel {
  @apply --marketplaceSmallFontStyles;
  white-space: nowrap;
  margin-right: 8px;

  font-size: 18px;
  line-height: 27px;
}

.itemValue {
  @apply --marketplaceSmallFontStyles;
  margin: 0 0 0 10px;
  font-size: 18px;
  line-height: 27px;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 8px 0 8px 0;

  border: none;
  background-color: var(--matterColorNegative);

  @media (--viewportMedium) {
    margin: 6px 0 0px 0;
  }
}

.totalLabel {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.totalPrice {
  @apply --marketplaceButtonFontStyles;
  margin: 0 0 0 10px;
  padding-top: 0px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
}
.heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 15px;
}
.tooltipWrapper {
  margin-right: auto;
  margin-top: -2px;
  width: 18px;

  background-color: white;

  & svg {
    fill: none;
  }

  &:hover {
    cursor: pointer;
  }
}
.tooltip, .tooltip2 {
  width: 320px;
  left: -151px; /* 320 / 2 - (18 (tooltipWrapper width) / 2) */
  z-index: 1;
  padding: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

}
.tooltip2 {
  margin-left: -0.5px;
}
.tooltipContent {
  font-size: 14px;
  line-height: 20px;
  text-align: left;

  & * {
    font-size: inherit;
    line-height: 140%;
  }
  & p {
    text-align: left;
  }
  & h5 {
    font-weight: 600;
  }
}
.tooltipLink {
  display: block;
  text-align: right;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
  & span{
     pointer-events: all;
   }
}
