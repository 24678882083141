@import '../../marketplace.css';
@import '../FilterPlain/FilterBaseStyles.css';

.root {
    &:last-of-type {
        border-bottom: none;
    }
}

.groupLabel {
    @apply --fontSizeMM;
    font-weight: 700;

    @media (--viewportLarge) {
        @apply --fontSizeXL;
        font-weight: 700;
    }
}

.fieldGroup {
    border: none;
    padding: 0;
    margin: 0;

    & .itemGroup label {
        padding-left: 0;
    }
}

.filterLabel {
    margin-bottom: 32px;
}
