@import '../../marketplace.css';

.root {
    position: relative;
    display: inline-block;
}

.label {
    @apply --marketplaceButtonStylesSecondary;
    @apply --marketplaceSearchFilterLabelFontStyles;
    @apply --marketplaceFilterLabel;

    &:focus {
        @apply --marketplaceFilterLabelFocused;
    }
}

.labelSelected {
    @apply --marketplaceButtonStyles;
    @apply --marketplaceSearchFilterLabelFontStyles;
    @apply --marketplaceFilterLabel;

    font-weight: var(--fontWeightRegular);
    white-space: nowrap;

    &,
    &:hover,
    &:focus {
        background-color: #f9f4fa;
        color: var(--marketplaceColor);
        border: 2px solid var(--marketplaceColor);
    }
}
