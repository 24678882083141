@import '../../marketplace.css';

/* "aside" section in desktop layout */
.asidePanel {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;

    transition: 0.5s;

    padding: 24px 24px 80px 24px;

    border-left: 1px solid #e6e6e6;

    @media (--viewportLarge) {
        max-width: 330px;

        & [datatype='slider-pagination'] {
            bottom: 5px;
        }
    }
}

.asidePanelRider {
    padding-top: 74px;
}

.horseownerActionTab {
    display: flex;
    justify-content: space-between;
    width: 100%;

    margin: 16px 0;
}

.favorite {
    position: static;

    & svg {
        stroke: #8f2593;
        fill: none;
        & path {
            stroke-width: 2px;
        }
    }
}

.favoriteSelected {
    position: static;

    & svg {
        stroke: var(--failColor);
        fill: var(--failColor);
    }
}

.otherPartyAvatarLarge {
    width: 200px;
    height: 200px;
    flex-shrink: 0;

    margin-bottom: 16px;
}

.otherPartyDisplayName {
    margin: 0 0 24px 0;
    letter-spacing: -0.5px;
}

.otherPartyProfileLink {
    @apply --fontSizeSM;

    display: flex;
    align-items: center;

    & svg {
        fill: none;
        margin-right: 8px;

        & path {
            fill: #8f2593;
        }
    }
}

.divider {
    margin: 32px 0;
    width: 100%;
    height: 1px;

    background-color: #e6e6e6;
}

.requestedAdvertisement {
    @apply --fontSizeXL;
    font-weight: 700;
    width: 100%;

    margin: 0 0 24px 0;
}

.detailCard {
    @media (--viewportLarge) {
        top: 100px; /* This is a hack to showcase how the component would look when the image isn't sticky */
        background-color: var(--matterColorLight);
        border: 1px solid var(--matterColorNegative);
        border-radius: 5px;
        z-index: 1;
    }
}

.close {
    @apply --marketplaceModalCloseStyles;

    z-index: 0;
    padding: 0 !important;

    & svg {
        margin-left: 8px;
    }

    & span {
        @apply --fontSizeMM;
    }

    position: static;
}

.listingCard {
    & > div {
        max-width: calc(100vw - 48px);
    }
}

.identitySection {
    @media (--viewportLarge) {
        bottom: 15px;

        & h4 {
            margin: 0;
        }
    }
}

.matchingContainer {
    margin-top: 24px;

    & p {
        margin: 0;
    }
}

.listingDeletedNotification {
    @apply --paragraphAntiSM;
    padding: 12px 24px;
    margin: 0;
}
