@import '../../marketplace.css';

.phoneBlockWrapper {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 176px;
  }

  padding: 31px 46px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 32px;
  text-align: center;

  & h3 {
    margin-top: 0;
    margin-bottom: 10px;
    letter-spacing: -0.6px;
  }

  & p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  & a {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -0.266667px;
  }

  & svg {
    margin-right: 8px;
    fill: none;
    width: 28.12px;
    height: 28.13px;
  }
}
