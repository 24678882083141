@import '../../common.css';

.root {
    margin: 64px auto;
}

.description {
    white-space: normal;
    @media (--viewportMedium) {
        white-space: break-spaces;
    }
}

.accordeonItem {
    & p {
        white-space: break-spaces;
    }
}

.faqHolder {
    margin-bottom: 32px;
}
