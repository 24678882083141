@import '../../marketplace.css';

.root {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-end;
    /* height: 57px; */
    align-items: flex-end;
    /* padding: 13px 24px 0 24px; */
  }

  @media (--viewportLarge) {
    /* padding: 0 36px; */
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;

  &:first-child {
    margin-left: 0;
  }
}
