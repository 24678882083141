@import '../../marketplace.css';

:root {
    /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

    --CheckoutPage_logoHeight: 25px;
    --CheckoutPage_logoHeightDesktop: 27px;
}

/* Dummy Topbar */

.topbar {
    /* Size */
    width: 100%;
    height: var(--topbarHeight);

    /* Layout for child components */
    display: flex;
    align-items: center;
    justify-content: center;

    /* fill */
    background-color: var(--matterColorLight);

    /* shadows */
    box-shadow: var(--boxShadow);
    position: fixed;
    z-index: 100;

    @media (--viewportLarge) {
        height: var(--topbarHeightDesktop);
        justify-content: center;
        position: static;
    }
}

.home {
    display: flex;
    padding: calc((var(--topbarHeight) - var(--CheckoutPage_logoHeight)) / 2) 24px;

    @media (--viewportLarge) {
        padding: calc((var(--topbarHeightDesktop) - var(--CheckoutPage_logoHeightDesktop)) / 2) 36px;
    }
}

.logoMobile {
    display: block;

    & path {
        fill: var(--marketplaceColor);
    }

    @media (--viewportLarge) {
        display: none;
    }
}

.logoDesktop {
    display: none;
    width: auto;
    height: 27px;

    @media (--viewportLarge) {
        display: block;
    }
}

/* /Dummy Topbar */

/**
 * Content: mobile flow vs desktop 2 columns
 * desktop has reduced version of bookListingContainer (incl. title and Stripe paymentForm).
 * priceBreakdown is inside separate column (detailsContainerDesktop)
 */
.contentContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    padding: 0 24px 24px 24px;

    @media (--viewportLarge) {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding: 0 48px;
        margin-top: 0;
        margin-bottom: 80px;
    }
}

.bookListingContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: auto;

    @media (--viewportLarge) {
        flex-grow: 0;
        flex-basis: 540px;
        margin-top: 66px;
        margin-right: 108px;
        min-height: calc(100vh - 60px);
    }
}

.paymentTabContainer {
    margin-top: 0;

    @media (--viewportLarge) {
        margin-top: 80px;
    }
}

.panelClassName {
    border: none;
    @media (--viewportLarge) {
        border: 1px solid var(--matterColorNegative);
    }
}

.aspectWrapper {
    position: relative;
    padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
    background-color: var(--matterColorNegative); /* Loading BG color */
    display: none;

    @media (--viewportLarge) {
        display: block;
    }
}

.rootForImage {
    /* Layout - image will take space defined by aspect ratio wrapper */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;

    @media (--viewportLarge) {
        border-radius: 2px 2px 0 0;
    }
}

.avatarWrapper {
    /* Position (over the listing image)*/
    margin-left: 24px;
    margin-top: -31px;

    /* Rendering context to the same lavel as listing image */
    position: relative;

    /* Layout */
    display: block;

    @media (--viewportLarge) {
        margin-left: 48px;
    }
}

.avatarMobile {
    @media (--viewportLarge) {
        display: none;
    }
}

.heading {
    margin-top: 24px;
    margin-bottom: 16px;
    padding: 0;

    @media (--viewportLarge) {
        margin-top: 0;
    }
}

.title {
    /* Font */
    @apply --marketPlaceTextOverflow;
    @apply --marketplaceH1FontStyles;
    color: var(--matterColor);

    font-weight: bold;
    font-size: 32px;

    /* Layout */
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 39px;
    letter-spacing: -1.25px;

    @media (--viewportMedium) {
        font-size: 40px;
        line-height: 50px;
        margin-top: 0;
        margin-bottom: 0;
        letter-spacing: -0.5px;
    }
}

.author {
    width: 100%;
    @apply --marketplaceH4FontStyles;

    /* Reset margins from font styles */
    margin-top: 0;
    margin-bottom: 0;

    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.09375px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.priceBreakdownContainer {
    margin-bottom: 37px;

    @media (--viewportMedium) {
        margin-bottom: 38px;
    }
    @media (--viewportLarge) {
        /* we don't show price breakdown since it will be visible within 2nd column (detailsContainerDesktop) */
        display: none;
    }
}

.priceBreakdownTitle {
    /* Font */
    color: var(--matterColorAnti);

    margin-top: 0;
    margin-bottom: 14px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 18px;
    }
    @media (--viewportLarge) {
        margin-top: 0;
        margin-bottom: 14px;
    }
}

.paymentContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
    margin-top: -16px;
}

.orderError,
.notFoundError {
    margin: 11px 0 12px 0;

    @media (--viewportMedium) {
        margin: 10px 0 12px 0;
    }
}
.orderError {
    color: var(--failColor);
}
.notFoundError {
    color: var(--attentionColor);
}

.speculateError {
    color: var(--failColor);
    margin: 19px 0 1px 0;

    @media (--viewportLarge) {
        margin: 28px 48px 0 48px;
    }
}

.paymentForm {
    flex-grow: 1;
}

/* Details column is visible only on desktop */
.detailsContainerDesktop {
    display: none;

    @media (--viewportLarge) {
        flex-shrink: 0;
        display: block;
        /* Dimensions */
        width: 409px;

        /* Position */
        margin: 15px 0 24px 0;

        /* Coloring */
        background-color: var(--matterColorLight);
        border: 1px solid var(--matterColorNegative);
        border-radius: 2px;
    }
}

.detailsAspectWrapper {
    position: relative;
    padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
    background-color: var(--matterColorNegative); /* Loading BG color */
}

.detailsHeadings {
    display: none;

    @media (--viewportLarge) {
        display: block;
        margin: 0 48px;
    }
}

.detailsTitle {
    @apply --marketPlaceTextOverflow;
    font-size: 32px;
    line-height: 40px;

    @media (--viewportLarge) {
        margin-top: 24px;
        margin-bottom: 4px;
    }
}

.detailsSubtitle {
    @apply --marketplaceH5FontStyles;

    /* Reset margins from font styles */
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 16px;
    border-bottom: 1px solid #e6e6e6;

    @media (--viewportLarge) {
        margin-top: 0;
        margin-bottom: 24px;
    }
}

.bookingBreakdownTitle {
    /* Font */
    color: var(--matterColorAnti);

    /* Layout spacing */
    margin: 5px 24px 25px 24px;

    @media (--viewportLarge) {
        margin: 37px 48px 26px 48px;
    }
}

.bookingBreakdown {
    @media (--viewportLarge) {
        margin: 0 48px;
    }
}

.backBtn {
    display: inline-flex;
    max-width: 120px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    cursor: pointer;
    outline: none;
}

.ridingsNum {
    position: relative;
    margin-right: 10px;

    @apply --dot;

    &:after {
        right: -7px;
        top: -53%;
        font-size: 25px;
    }
}
.breakdownSubtitle {
    color: var(--matterColorAnti);
    margin-bottom: 26px;
}
.benefitsList {
    margin-bottom: 32px;

    @media (--viewportLarge) {
        margin-bottom: 56px;
    }

    & svg {
        fill: none;
        margin-right: 6px;
    }
}
.benefitItem {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    font-size: 16px;
    line-height: 24px;

    @media (--viewportLarge) {
        font-size: 20px;
        line-height: 30px;
    }
}

.notificationTab {
    display: none;

    @media (--viewportLarge) {
        display: flex;
    }
}
.gatewayPaymentListItem {
    display: flex;
    align-items: center;

    font-size: 16px;
    line-height: 24px;
    color: #1a2b49;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    & svg {
        fill: none;
        margin-right: 8px;
        height: 30px;
    }
}
.paymentMethodNotification {
    background: #e8f0fa;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: #1a2b49;
    padding: 16px;
    margin: 0 0 56px 0;
    border: 1px solid #607d9f;
    border-radius: 4px;
}
.submitButton {
    margin-top: 22px;

    @media (--viewportMedium) {
        margin-top: 26px;
    }
    @media (--viewportLarge) {
        margin-top: 17px;
    }
}
.paymentHeading {
    @apply --checkoutFormLabel;
}
.creditCardListIcon {
    display: none;
    @media (--viewportMedium) {
        display: inline-block;
    }
}
.creditCardListIconWrapper {
    display: none;
    @media (--viewportMedium) {
        width: 196px;
        height: 30px;
        display: flex;
    }
}

.creditCardSingleIcon {
    display: inline-block;
    @media (--viewportMedium) {
        display: none;
    }
}
.modalIcon {
    fill: none;

    @media (--viewportMedium) {
        display: inline-block;
        margin-top: 10px;
    }

    & + h1 {
        margin-bottom: 16px;
        font-size: 32px;
        line-height: 40px;
        font-weight: bold;
    }

    & ~ p {
        font-size: 20px;
        line-height: 30px;
        margin: 0;
    }
}
.modalContent {
    @apply --modalContent;

    & button {
        @media (--viewportMedium) {
            margin-top: 64px;
            line-height: 27px;
        }
    }
}
