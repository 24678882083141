@import '../../marketplace.css';

.root {
    position: relative;

    width: 100%;
    border-radius: 4px;

    padding: 16px;

    @media (--viewportMedium) {
        display: flex;
        align-items: flex-start;
    }

    & > svg {
        flex-shrink: 0;
        margin-right: 16px;

        width: 24px;
        height: 24px;
    }

    & h4 {
        @apply --fontSizeSM;
        text-align: left;
        font-weight: 600;
        margin: 0 0 8px 0;
    }

    & p {
        margin: 0;
        @apply --fontSizeSM;
        text-align: left;
    }
}

.warning {
    background: #fff7f0;
    border: 1px solid #ffaa00;

    & > svg {
        & path {
            stroke: #ffaa00;
        }
    }
}

.success {
    background: #f0fff6;
    border: 1px solid #1e9f55;
}

.failure {
    background: #fff0f0;
    border: 1px solid #ff0000;

    & > svg {
        & path {
            stroke: #ff0000;
        }
    }
}

.info {
    background: #e8f0fa;
    border: 1px solid #607d9f;
}

.close {
    position: absolute;
    right: 16px;
    top: 16px;

    & svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    @media (--viewportMedium) {
        display: none;
    }
}
