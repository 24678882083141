@import '../../../marketplace.css';

.infoBlock {
  display: flex;
  justify-content: space-between;

  padding: 20px 24px;

  & h4 {
    @apply --fontSizeXL;
    font-weight: 700;

    margin: 0;
  }
}

.listingsSection {
  & > div {
    margin-bottom: 32px;
  }

  @media (--viewportMedium) {
    display: flex;
    flex-wrap: wrap;

    & > div {
      width: calc(50% - 16px);
      margin-right: 32px;

      &:nth-of-type(2n + 2) {
        margin-right: 0;
      }
    }
  }
}

.tooltip {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 1;

  & svg {
    stroke: #b2b2b2;
    fill: none;

    height: 22px;
    width: 22px;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.tooltipContent {
  z-index: 2;

  @media (--viewportSmallMax) {
    min-width: 322px;
  }
}
