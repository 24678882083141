@import '../../common.css';

.offersContainer {
    & .heading {
        text-align: left;
        margin-bottom: 32px;

        @apply --fontSizeXXXL;

        @media (--viewportMediumMax) {
            @apply --fontSizeXL;
            font-weight: 700;
        }
    }

    & .badge {
        @apply --badge;
        margin: 0;
        border: 1px solid #e6e6e6;

        &.selected {
            @apply --badge_selected;
        }
    }

    & .logo {
        @apply --offer_logo;

        margin-bottom: 24px;
    }
}

.offersList {
    display: flex;
    flex-wrap: wrap;
    column-gap: 32px;
    row-gap: 32px;
}

.offerBlock {
    @apply --offer_block;

    display: flex;
    flex-direction: column;

    box-shadow: none;
    border: 1px solid #e6e6e6;

    width: calc(33% - 24px);

    @media (--viewportMediumMax) {
        width: calc(50% - 24px);
    }

    @media (--viewportSmallMax) {
        width: 100%;
    }

    & footer {
        margin-top: auto;

        & p {
            @apply --paragraphAntiSM;
            text-align: center;
            margin-bottom: 16px;
        }

        & button:nth-of-type(2) {
            margin-top: 8px;
        }
    }

    & .badge {
        @apply --fontSizeXS;
        padding: 4px 8px;

        &.selected {
            border: 1px solid #8f2593;
        }
    }

    & .dialogTitle {
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 600;

        @media (--viewportMedium) {
            font-size: 20px;
        }
    }
}

.offersCategoriesNav {
    display: flex;
    column-gap: 8px;
    margin-bottom: 32px;

    & .badge {
        @apply --fontSizeSM;
    }
}

.upperSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
}
