@import '../../marketplace.css';

.completeProfileWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    [data-type='error-message'] {
        margin-bottom: 0;
    }
}

.completeProfileHeader {
    margin: 0 0 16px 0;

    @apply --fontSizeXXL;

    @media (--viewportSmallMax) {
        @apply --fontSizeXL;
        font-weight: 700;
    }
}

.completeProfileParagraph {
    margin-bottom: 40px;

    & br {
        display: none;
    }

    @media (--viewportSmallMax) {
        & br {
            display: initial;
        }
    }
}

.completeProfileProtectionMessage {
    @apply --paragraphAntiSM;
    @apply --flex-centered;
    column-gap: 4px;
}

.link {
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.modalContainer {
    flex-basis: 480px;

    border-top: none;
}

.modalFormBody {
    & label {
        font-size: 17px;
        line-height: 20px;
    }
}

.modalFormBodyFullHeight {
    flex: 1;

    & form {
        height: 100%;
        display: flex;
        flex-direction: column;

        & footer {
            margin-top: auto;
        }
    }
}

.error {
    @apply --paragraphAntiSM;
    color: red;

    position: absolute;
    top: calc(100% + 12px);
    text-align: left;
}

.modalContent {
    height: 100%;
}

.footerOverlay {
    @media (--viewportSmallMax) {
        position: fixed;
        bottom: 24px;
        left: 24px;
        right: 24px;
    }
}

.completeProfileWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    & > svg {
        flex-shrink: 0;
    }
}
