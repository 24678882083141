@import '../../marketplace.css';

.bullet {
    margin-right: 6px;
}

.transition {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 3px 0 2px 0;

    max-width: 307px;
    margin: 32px auto 32px auto;

    @media (--viewportMedium) {
        padding: 0;
    }
}

.transitionContent {
    max-width: var(--transactionItemMaxWidth);
    @apply --marketPlaceTextOverflow;
    @apply --paragraphAntiSM;
    margin: 0;
    line-height: 28px;
}

.transitionDate {
    @apply --marketplaceMessageDateFontStyles;
    color: var(--matterColorAnti);
    margin: 7px 0 0 0;

    @media (--viewportMedium) {
        margin: -1px 0 1px 0;
    }

    &:after {
        content: ' Uhr';
    }
}
