@import '../../marketplace.css';

.heading {
    @apply --fontSizeXL;
    font-weight: 700;

    margin: 0 0 24px 0;
}

.contentBox {
    margin-bottom: 32px;
}

.formLabel {
    margin: 0;

    & span {
        @apply --fontSizeSM;
        display: block;
    }

    & .labelHeading {
        font-weight: 600;
    }
}

.formField {
    @apply --formFieldsItem;

    &.blank {
        & label > span {
            display: none;
        }

        &:hover {
            cursor: auto;
            background: white;
            border: 1px solid #e6e6e6;
        }
    }
    /* toggler */
    & > label > span {
        align-self: center;
    }
}

.formLink {
    margin-top: 24px;
}

.error {
    @apply --paragraphAntiSM;
    margin-bottom: 0;
    color: var(--failColor);
}
