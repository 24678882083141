@import '../../common.css';

.root {
    @apply --subPages_root;

    margin-bottom: 0;

    & footer button {
        width: 217px;
        margin: 0 auto;
    }
}

.description {
    @apply --subPages_description;
}

.heading {
    white-space: normal;

    @media (--viewportMediumMax) {
        white-space: break-spaces;
    }
}
