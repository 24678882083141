@import '../../marketplace.css';

.root {
}

.editSection {
    margin-top: 32px;

    & > * {
        margin-bottom: 24px;
    }
}

.bottomWrapper {
    margin-top: auto;

    @media (--viewportMedium) {
        margin-top: 40px;
    }
}

.error {
    @apply --marketplaceH4FontStyles;
    color: var(--failColor);
    text-align: center;
    display: inline-block;
    width: 100%;
    margin: 24px 0;

    @media (--viewportMedium) {
        margin: 24px 0;
    }
}

.helperLink {
    @apply --marketplaceLinkStyles;
    color: var(--matterColor);
    font-weight: var(--fontWeightMedium);
    text-decoration: underline;
}

.emailStyle {
    font-weight: var(--fontWeightBold);
    word-wrap: break-word;
}

.title {
    letter-spacing: -0.25px;
    font-size: 24px;
    line-height: 32px;

    @media (--viewportMedium) {
        font-size: 32px;
        line-height: 40px;
    }
}
.description {
    margin-bottom: 24px;
}
.idpsHolder {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    & > button {
        display: flex;
        align-items: center;
        padding: 16px 24px;
        border-radius: 4px;
        border: 1px solid var(--matterColorNegative, #e6e6e6);
        background: var(--Ground-Ground-000, #fff);
        column-gap: 16px;

        transition: 0.35s;

        width: 100%;
        height: auto;
        justify-content: flex-start;

        &:hover {
            cursor: pointer;
            border: 1px solid var(--marketplaceColorDarkMedium, #1a2b49);
        }

        & svg {
            width: 24px;
            height: 24px;
        }
        & p {
            margin: 0;
            font-weight: 600;
        }
    }
}
