@import '../../marketplace.css';

.root {
  text-align: left;
  display: block;
  /* margin-top: 16px; */

  @media (--viewportMedium) {
    display: flex;

    /* margin-top: 32px;
    margin-bottom: 40px; */

    & > div {
      width: 33%;
      margin-right: 16px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.field {
  margin-bottom: 24px;

  & svg {
    right: 16px;
    top: 20px;
  }

  & input {
    padding: 17px 30px 4px 17px;
  }

  @media (--viewportMedium) {
    margin-bottom: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
