@import '../../marketplace.css';

.generateText {
  color: #8f2593;
  display: flex;
  align-items: center;

  margin-top: 24px;

  & svg {
    margin-right: 8px;
    fill: none;
  }

  &:hover {
    cursor: pointer;
  }
}
.generateTextInProgress {
  color: #b2b2b2;
}
