@import '../../marketplace.css';

.root {
  position: relative;
  box-shadow: var(--boxShadowBottomForm);

  @media (--viewportLarge) {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    overflow: hidden;
  }
}

.textarea {
  @apply --marketplaceH4FontStyles;
  border-bottom-width: 0;

  margin: 0;

  /* We need to remove horizontal paddings,
   since textarea uses content-box (autosize library fix). */
  width: 100%;

  border-bottom-width: 2px;
  border-bottom-color: var(--attentionColor);
  background-color: transparent;

  @media (--viewportMedium) {
    margin: 0;
  }
  @media (--viewportLarge) {
    padding: 0 0 5px 0;
    margin: 0;
    width: 100%;
  }
}

.spinner {
  stroke: var(--matterColorLight);
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}

.fillSuccess {
  fill: var(--successColor);
}

.strokeMatter {
  stroke: var(--matterColor);
}
.outerContainer {
  display: flex;
}

.errorContainer {
  display: block;
  flex: 1;
  padding: 16px 0;
  text-align: center;
}

.error {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  margin: 0;
}

.submitButton {
  font-weight: var(--fontWeightMedium);

  width: auto;
  min-width: 40px;
  min-height: auto;
  height: 40px;

  padding: 0 16px;

  position: relative;

  border: 1px solid #e6e6e6;
  border-radius: 4px;
  @apply --fontSizeXS;

  &:hover {
    cursor: pointer;
    border: 1px solid;
  }
}

.submitButtonFile {
  padding: 0;

  & label {
    padding: 0 16px;
    height: 100%;

    @media (--viewportMediumMax) {
      padding: 0 10px;
    }
  }
}

.sendMessageButton {
  margin-left: auto;
  font-weight: 600;

  &:disabled {
    background-color: #e6e6e6;
    color: #b2b2b2;

    &:hover {
      border: 1px solid #e6e6e6;
      cursor: not-allowed;
    }
  }
}

.disabled {
  @apply --disabled;
}

.sendIcon {
  margin: -3px 5px 0 0;
}
.attachFileIcon {
  display: flex;
  align-items: center;

  @apply --fontSizeXS;

  padding: 0;

  & svg {
    margin-right: 8px;
  }

  &:hover {
    cursor: pointer;
  }

  & span,
  & svg {
    pointer-events: none;
  }

  @media (--viewportMediumMax) {
    & > span {
      display: none;
    }

    & svg {
      margin-right: 0;
    }
  }
}
.attachFileInput {
  display: none;
}

.sidenote {
  @apply --paragraphAntiSM;
  margin-top: 8px;
}

.input {
  background-color: white;
}
