@import '../../marketplace.css';

.infoProtectionSection {
  width: 100%;
  padding-bottom: 24px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 24px;

  & svg {
    margin-right: 8px;
    fill: none;
  }

  & p {
    @apply --fontSizeSM;
    margin: 0;

    &:first-of-type {
      font-weight: 600;

      display: flex;
      align-items: center;

      margin-bottom: 8px;
    }
  }
}
