@import '../../marketplace.css';

:root {
    --imageDraggedOver {
        & > svg rect {
            stroke: #8f2593;
            fill: #f9f4fa;
        }
    }
}

.addImageWrapper {
    float: left;
    position: relative;
    width: 100%;

    overflow: hidden;

    &::after {
        content: '.';
        visibility: hidden;
        display: block;
        height: 0;
        clear: both;
    }

    @media (--viewportLarge) {
        width: calc(50% - 8px);
    }
}

.initialStateHolder {
    position: relative;
    border-radius: 4px;

    padding-top: 62.5%;

    & svg {
        max-width: 100%;
        fill: none;
    }

    & > svg {
        position: absolute;
        top: 0;
        pointer-events: none;

        & rect {
            transition: 0.35s;
        }

        width: 100%;
        height: 100%;
    }

    &:hover {
        cursor: pointer;

        & > svg {
            & rect {
                stroke: #1a2b49;
            }
        }
    }

    &.imageDraggedOver {
        @apply --imageDraggedOver;
    }
}

.initialStateLabel {
    position: absolute;
    bottom: 0;
    top: 0;

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &:hover {
        cursor: pointer;
    }

    & p {
        text-align: center;
        margin: 0;

        &:first-of-type {
            font-size: 18px;
            line-height: 28px;
            font-weight: 600;

            @media (--viewportLarge) {
                font-size: 20px;
                line-height: 32px;
            }
        }

        &:last-of-type {
            @apply --paragraphAntiSM;

            margin: 4px 0 0 0;

            & > span {
                text-align: center;
                display: block;
            }
        }
    }
}

.backIcon {
    position: absolute;
    pointer-events: none;
}

.aspectRatioWrapper {
    padding-bottom: calc(100% * (2 / 3));

    & > svg {
        position: absolute;

        width: 100%;
        height: 100%;

        & rect {
            transition: 0.35s;
        }
    }

    &:hover {
        & > svg {
            &,
            rect {
                stroke: #1a2b49;
            }
        }
    }

    &.imageDraggedOver {
        @apply --imageDraggedOver;
    }
}

input[class*='AddImagesField_addImageInput_'],
.addImageInput {
    display: none;
}

.addImage {
    /* Layout */
    /* Maintain aspect ratio */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    border-radius: 4px;

    /* Colors */
    background-color: transparent;

    /* Behaviour */
    cursor: pointer;

    &,
    & svg path {
        transition: 0.35s;
    }

    &:hover {
        color: #1a2b49;

        & svg path {
            stroke: #1a2b49;
        }
    }

    @apply --paragraphAntiSM;
}

.chooseImage {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > svg {
        fill: none;
    }
}
