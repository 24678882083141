@import '../../marketplace.css';

.noResults {
    padding: 24px;
}

.container {
    max-width: 1150px;
    width: 100%;

    margin: 0 auto;
    padding: 24px 24px 40px 24px;

    @media (--viewportMedium) {
        padding: 64px 24px 80px 24px;
    }

    & .error {
        @apply --paragraphAntiSM;
        color: var(--failColor);

        & + button {
            max-width: 132px;
        }
    }
}

.heading {
    @apply --fontSizeXXL;
    margin: 0 0 16px 0;

    @media (--viewportSmallMax) {
        @apply --fontSizeXL;
        font-weight: 700;
    }
}

.subHeading {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 3.5px;
    margin-bottom: 32px;

    & .tooltip {
        width: calc(100vw - 48px);

        @media (--viewportMedium) {
            width: 384px;
        }
    }

    & .tooltipTarget {
        &:hover {
            cursor: pointer;
        }
    }
}

.cardHolder {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    row-gap: 32px;
    column-gap: 32px;

    @media (--viewportSmallMax) {
        align-items: center;
        flex-direction: column;
        row-gap: 24px;
    }
}

.tooltipWrapper {
    display: inline-block;
}
