@import '../common.css';

.root {
  @apply --sectionRoot;
}

.sectionTitle {
  @apply --sectionTitle;

  padding-bottom: 16px;

  @media (--viewportMedium) {
    padding-bottom: 12px;
  }
}

.item {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 8px;

  color: #b2b2b2;
  text-decoration-line: line-through;

  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 12px;
  }

  & > span {
    color: #b2b2b2;
  }
}

.itemSelected {
  text-decoration-line: unset;
  color: #1a2b49;

  & > span {
    color: #1a2b49;
  }
}

.matchingContainer {
  @apply --matchingContainer;

  @media (--viewportMedium) {
    margin-bottom: 24px;
    justify-content: flex-end;
  }
}

.selectedIcon {
  & path {
    stroke: #8f2593;
  }
}

.notSelectedIcon {
  & path {
    stroke: #b2b2b2;
  }
}
