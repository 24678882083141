@import '../../marketplace.css';

.root {
    display: flex;
}

.abbreviatedName {
    display: flex;
    align-items: center;
    justify-content: center;

    @apply --fontSizeSM;
    color: white;
    font-weight: 700;

    width: 48px;
    height: 48px;
    border-radius: 50%;

    background-color: #8f2593;
}

.avatar {
    margin-right: 8px;
    &,
    & > img {
        height: 48px;
        width: 48px;
        border-radius: 50%;
    }

    & > img {
        object-fit: cover;
    }
}

.personalInfoSection {
    @apply --fontSizeSM;

    & > p {
        font-weight: 600;
        margin-bottom: 0;
    }
}

.assessment {
    @media (--viewportMedium) {
        display: flex;
    }

    & > div {
        margin-top: 16px;
        flex-shrink: 0;

        &:nth-of-type(1) {
            margin-top: 24px;
        }

        @media (--viewportMedium) {
            margin-right: 48px;
            margin-top: 24px;
        }

        & > p {
            font-weight: 600;
            margin-bottom: 0;
        }
    }

    & svg {
        margin-right: 4px;

        @media (--viewportSmallMax) {
            width: 24px;
            height: 24px;
        }
    }
}

.recommendation {
    margin-top: 24px;
    margin-bottom: 0;
}

.recommendationsHolder {
    & > div {
        border-top: 1px solid #e6e6e6;
        padding-top: 32px;
        margin-top: 32px;

        &:nth-of-type(1) {
            border-top: none;
            padding-top: 0;
            margin-top: 0;
        }
    }
}
