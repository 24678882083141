@import '../../marketplace.css';

.backdrop {
    position: absolute;
    z-index: 1;
    overflow: auto;
    pointer-events: none;
    transition: transform 1s;
}

.highlights {
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    color: transparent;
    /* same padding as for textarea */
    padding: var(--textareaFocusPadding);
    @apply --marketplaceDefaultFontStyles;

    @media (--viewportSmallMax) {
        /*same styles as for textarea */
        font-size: 16px;
        line-height: 24px;
    }
}

.mark {
    border-radius: 3px;
    color: transparent;
    background-color: unset;
}

.markhighlighted {
    color: #ff0000;
    opacity: 0.9;
}

textarea {
    z-index: 2;
}

.markOverlayed {
    color: white;
}

.markOverlayed,
.markhighlighted {
    ::selection {
        color: white;
    }
}
