@import '../../../marketplace.css';

.disciplinesSection {
    display: block;

    @media (--viewportMedium) {
        max-width: 286px;
        width: 50%;
    }
}

.disciplinesSectionPlaceholder {
    max-width: unset;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.multipleColumnsContainer {
    display: flex;
    justify-content: space-between;

    & > section {
        flex: 1;

        &:first-of-type {
            margin-right: 80px;
        }
    }
}

.disciplinesItem {
    display: flex;
    justify-content: space-between;

    width: 100%;

    /** for placeholderItem */
    align-items: center;
    padding: 8px 0;
    border-top: 1px solid #e6e6e6;

    @media (--viewportSmallMax) {
        font-size: 16px;
        line-height: 24px;

        &:nth-of-type(1) {
            border-top: none;
        }

        &:last-of-type {
            padding-bottom: 0;
        }
    }

    @media (--viewportMedium) {
        &:nth-of-type(1) {
            border-top: none;
        }
    }

    & > div {
        flex-shrink: 0;
    }

    & > .disciplineLabel {
        max-width: 50%;

        @media (--viewportSmallMax) {
            max-width: 40%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.disciplinesItemPlaceholder {
    width: calc(50% - 40px);

    &:nth-of-type(1),
    &:nth-of-type(2) {
        border-top: none;
    }
}

.placeholderItem {
    width: 144px;
}

.viewProfileRequestWrapper {
    max-height: 228px;
}

aside.viewProfileRequestAction {
    height: 50%;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 40%,
        rgba(255, 255, 255, 0.8) 80%,
        rgba(255, 255, 255, 0.2) 100%
    );
}

.levelTooltip {
    min-width: 118px;
    width: 118px;
    text-align: center;
}

.privateLevelsHolder {
    position: relative;
}

.levelsHolder {
    display: flex;
}

.ageNotificationRoot {
    position: absolute;
    left: -22px;
    top: calc(50% - 8px);
    z-index: 1;

    &:hover {
        cursor: pointer;
    }
}

.ageNotificationActionHolder {
    display: flex;
    width: 16px;

    & svg {
        flex-shrink: 0;
        width: 16px;
        height: 16px;

        & path {
            stroke: #ff0000;
        }
    }
}

.ageNotificationTooltipContent {
    width: calc(100vw - 72px);

    @media (--viewportMedium) {
        width: 432px;
    }

    & > svg {
        margin: 0;

        position: absolute;
        right: 16px;
        top: 16px;

        z-index: 1;
        pointer-events: auto;

        &:hover {
            cursor: pointer;
        }
    }

    & p {
        @apply --fontSizeXS;

        &:nth-of-type(1) {
            font-weight: 600;
        }
    }
}
