@import '../../marketplace.css';

.form {
    & ul {
        list-style-type: disc;
        margin: 0 0 32px 0;
        padding-left: 32px;

        & li {
            @apply --fontSizeMM;
            @media (--viewportSmallMax) {
                @apply --fontSizeSM;
            }
        }
    }

    & button {
        color: var(--failColor);
    }
}

.contentBox {
    height: 160px;

    border-radius: 8px;
    border: 1px solid #e6e6e6;
    background: #fff;

    margin-bottom: 64px;
}

.contentUpperRow {
    display: flex;
    justify-content: space-between;
    padding: 16px;

    & > h4 {
        @apply --fontSizeXL;
        color: #8f2593;
        font-weight: 700;
        margin: 0;
    }
}

.contentEdit {
    display: flex;
    column-gap: 8px;
    align-items: center;

    & p {
        margin: 0;
        color: #b2b2b2;
    }

    & svg {
        width: 18px;
        height: 18px;

        & path {
            fill: #b2b2b2;
        }
    }
}

.heading {
    @apply --fontSizeXL;
    font-weight: 700;

    margin: 0 0 24px 0;
}

.description {
    margin-bottom: 24px;
}
