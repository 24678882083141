@import '../../../../marketplace.css';

:root {
    --maxHeightDesktop: 384px;
    --maxHeightMob: 240px;

    --vertical {
        height: var(--maxHeightDesktop);

        @media (--viewportSmallMax) {
            height: var(--maxHeightMob); /*according to the designs*/
        }
    }
}

.galleryImagesWrapper {
    display: flex;
    height: 100%;

    @media (--viewportLarge) {
        & > .galleryColumn:nth-of-type(3),
        & > .galleryColumn:nth-of-type(2) {
            & .gallerySecondaryImageContainer {
                margin-left: 8px;
            }
        }
    }
}

.gallerySecondaryImageContainer {
    position: relative;
    height: calc(50% - 4px);
    overflow: hidden;

    @media (--viewportLarge) {
        border-radius: 8px;

        &:nth-of-type(1) {
            margin-bottom: 8px;
        }
    }
}

.viewPhotos {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: 16px;
    bottom: 16px;

    @media (--viewportMedium) {
        right: 24px;
        bottom: 24px;
    }

    border-radius: 4px;
    border: 1px solid #e6e6e6;
    background: white;
    padding: 7px 15px;
    box-sizing: border-box;
    &:hover {
        cursor: pointer;
    }
}

.rootForImage {
    width: 100%;
    transition: ease-in-out 0.3s;

    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: 100%;
    object-fit: cover;

    &:hover {
        opacity: 1;
        transform: scale(1.05);
        box-shadow: var(--boxShadowListingCard);
    }
}

.hoveredImage {
    opacity: 0.7;
}

.galleryColumn {
    width: 25%;
}

.threeToTwoWrapper {
    /* Layout */
    display: block;
    width: 100%;
    position: relative;

    @media (--viewportMedium) {
        padding: 0;
        margin-top: 24px;
        max-width: 1150px;
    }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
    position: relative;

    /* Image carousel can be opened from the image, therefore we should show a pointer */
    cursor: pointer;

    @media (--viewportLarge) {
        padding-bottom: 0;
        /* No fixed aspect on desktop layouts */
    }
    @apply --vertical;
}

.galleryMainImageContainer {
    flex: 1;

    min-width: 50%;
    width: auto;

    position: relative;
    overflow: hidden;

    & > video {
        width: auto;
        max-width: 100vw;
    }

    @media (--viewportLarge) {
        border-radius: 8px;
    }

    @apply --vertical;
}

.galleryMainImageContainer:not(:first-of-type) {
    display: none;

    @media (--viewportLarge) {
        display: block;
    }
}

.carouselModalScrollLayer {
    @apply --marketplaceCarouselModalScrollLayer;
}

.carouselModalContainer {
    @apply --marketplaceCarouselModalContainer;

    & > button {
        background: inherit;
        width: auto;
        position: absolute;
    }
}

.modalContent {
    padding: 0;
}

.imagesPair {
    @media (--viewportLarge) {
        & > .galleryMainImageContainer {
            min-width: calc(50% - 8px);

            &:first-of-type {
                margin-right: 8px;
            }
        }
    }
}
