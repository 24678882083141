@import '../../marketplace.css';

:root {
    /*
      These variables are available in global scope through ":root"
      element (<html> tag). Variables with the same names are going to
      overwrite each other if CSS Properties' (PostCSS plugin)
      configuration "preserve: true" is used - meaning that variables
      are left to CSS bundle. We are planning to enable it in the future
      since browsers support CSS Properties already.
   */

    --actionBtn: {
        position: absolute;
        top: 0;
        width: 33px;
        height: 33px;
        background-color: rgba(0, 0, 0, 0.5);
        border: none;
        border-radius: 0;
        border-bottom-left-radius: 2px;
        cursor: pointer;
        outline: none;

        & svg {
            position: absolute;
            fill: var(--matterColorAnti);
            stroke: var(--matterColorAnti);
        }
    }
}

.root {
    width: 100%;
    min-height: 120px;
    padding-left: 0;

    &::after {
        content: '.';
        visibility: hidden;
        display: block;
        height: 0;
        clear: both;
    }

    display: block;
}

.firstImage {
    width: 100%;
    margin-bottom: 16px;
}

.restImages {
    display: block;

    & > * {
        margin: 0 0 16px 0;
    }

    @media (--viewportLarge) {
        display: flex;
        flex-wrap: wrap;

        & > * {
            width: calc(50% - 8px);
            margin: 0 16px 16px 0;

            &:nth-child(even) {
                margin: 0 0 16px 0;
            }
        }
    }
}

.thumbnail {
    display: block;
    position: relative;
    /* overflow: hidden; */
    background-color: var(--matterColorNegative); /* Loading BG color */
    border-radius: 4px;
}

.imagesField {
    flex-shrink: 0;
    margin: 0;
}

.threeToTwoWrapper {
    position: relative;
    /*
    controls popup is not visible 
    if overflow set to hidden
  */
}

.removeImage {
    @apply --actionBtn;
    right: 0;

    &:hover svg {
        fill: var(--matterColorLight);
        stroke: var(--matterColorLight);
    }

    & svg {
        top: 12px;
        left: 12px;
        width: 10px;
        height: 10px;
    }
}

.showCarousel {
    @apply --actionBtn;
    right: 0;
    background: transparent;

    &:hover svg {
        transform: scale(1.125);
    }

    & svg {
        transition: 0.25s;
        right: 16px;
        top: 16px;
        left: initial;
        fill: none;
    }
}

.aspectWrapper {
    padding-bottom: calc(100% * (2 / 3));

    & video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
    }
}

.rootForImage {
    /* Layout - image will take space defined by aspect ratio wrapper */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--borderRadius);
}

.thumbnailLoading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(211, 211, 211, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}
.imageIdDragged {
    transform: rotate(3deg) scale(0.95);
}
.imageIdToDrop {
    background: white;
    border-radius: 5px;
    border: 2px dashed #e7e7e7;

    & img,
    & video {
        opacity: 0.75;
        filter: blur(3px);
    }
}
.carouselModalScrollLayer,
.carouselModalContainer {
    @apply --modalCarouselScrollLayer;

    & > button {
        position: absolute;
    }
}

.modalContent {
    padding: 0;
}

.controls {
    bottom: unset;

    box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.1);

    top: calc(100% + 4px);
}

.firstImageControl {
    /* hightlight */
    padding: 4px 8px;

    position: absolute;
    left: 16px;
    top: 16px;
    z-index: 2;
    background: white;
    border-radius: 16px;

    & * {
        @apply --fontSizeXS;
    }
}

.firstImageControlTooltipContent {
    width: 312px;
    top: 40px;

    @media (--viewportMedium) {
        left: 0 !important;
    }

    & p {
        margin-bottom: 0;
    }
}

.imagesControl {
    /* three dots */
    position: absolute;
    background: rgba(255, 255, 255, 0.8);

    width: 32px;
    height: 32px;

    top: 16px;
    right: 16px;
    z-index: 2;

    border-radius: 50%;

    transition: 0.35s;

    &::after {
        display: none;
    }

    &:hover {
        cursor: pointer;
        background: rgba(255, 255, 255, 1);
    }
}

.controlOpened {
    z-index: 3;
    background: rgba(255, 255, 255, 1);
}

.ellipsis {
    display: inline-block;
    width: 32px;
    height: 32px;

    font-size: 36px;
    line-height: 8px;
    letter-spacing: -1.5px;

    padding-right: 2px;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
}

.imageControlTooltipContent {
    border-radius: 8px;
    left: unset !important;
    right: 0;
    top: 38px;

    width: 112px;
    min-width: 112px;

    padding: 8px 0;

    &:after {
        display: none;
    }

    & ul {
        margin: 0;
    }

    & li {
        @apply --fontSizeXS;
        text-align: left;
        pointer-events: initial;

        padding: 8px 16px;

        &:last-of-type {
            margin-bottom: 0;
        }

        &:hover {
            cursor: pointer;
            background: rgba(0, 0, 0, 0.025);
        }
    }
}

.cropper {
    max-height: 0;
    overflow: hidden;
}

.description {
    display: flex;
    align-items: center;

    color: #ffffff;

    position: absolute;
    left: 16px;
    bottom: 16px;

    margin: 0;
    z-index: 1;

    & svg {
        margin-right: 8px;
    }

    &:hover {
        cursor: pointer;
    }
}
.descriptionSmall {
    @apply --fontSizeSM;
    color: white;
}

.descriptionAbsent {
    opacity: 0.8;
}

.closeButtonClassName {
    @apply --modalCarouselContainer;
}

.loading {
    filter: blur(1px);
}
