@import '../../marketplace.css';

.contentWrapper {
    & form {
        max-width: 320px;
        margin: 24px;
        display: flex;
        flex-direction: column;
        row-gap: 24px;

        & p {
            @apply --paragraphAntiSM;
            margin: 0;

            &.error {
                color: var(--failColor);

                + button {
                    max-width: 92px;
                    min-height: 32px;
                    font-size: 12px;
                }
            }
        }

        & footer {
            & button {
                & path {
                    fill: #22b53a;
                }
            }
        }
    }
}
