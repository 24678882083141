@import '../common.css';

.root {
  @apply --sectionRoot;
}

.sectionTitle {
  @apply --sectionTitle;
  padding-bottom: 32px;
}

.sectionContent {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 8px;
  }
}

.item {
  width: auto;
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  & > img {
    margin-right: 8px;
  }

  @media (--viewportMedium) {
    width: calc(100% / 3);
    margin-bottom: 24px;
  }
}

.listingDesc {
  white-space: break-spaces;
}
