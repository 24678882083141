@import '../../marketplace.css';

.modalContainer {
    & .field {
        margin-bottom: 24px;
    }
}

.modalContent {
    @media (--viewportMedium) {
        max-height: 720px;
        overflow: scroll;
    }
}

.footer {
    border-top: 1px solid #e6e6e6;
}
.appointmentContainer {
    padding: 0;
    display: flex;
    align-items: center;

    margin-left: 8px;

    @media (--viewportLarge) {
        margin-left: 16px;
    }
}
.tooltipWrapper {
    & > button {
        @apply --marketplaceH5FontStyles;
        display: flex;
        height: 40px;
        width: 106px;
        min-height: 40px;
        padding: 0;
        margin: 0;

        &:disabled {
            @apply --disabled;
        }
    }
}

.tooltipContent,
.tooltipContentPending {
    width: 327px;
    padding: 16px 16px 14.11px;
    bottom: 135%;
    left: calc(50% - 55px);
    &:after {
        left: 15%;
    }
}
.tooltipContentPending {
    width: 260px;
}
.tooltipInfo {
    font-size: 16px;
    line-height: 22px;
    text-align: left;

    & button {
        @apply --marketplaceButtonStyles;

        display: block;
        margin-left: auto;
        width: 116px;
        min-height: 40px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        margin-top: 16px;
        pointer-events: all;
    }

    & * {
        font-size: inherit;
        line-height: 140%;
    }

    & h5 {
        font-weight: 600;
        margin: 0 0 8.03px;
    }

    & p {
        font-size: 14px;
        margin-bottom: 0;
    }
}

.modalHeader {
    @apply --fontSizeXL;
    font-weight: 700;

    @media (--viewportMedium) {
        @apply --fontSizeXXL;
    }
}

.inProgress {
    background-color: #b2b2b2;
    &:hover,
    &:focus {
        background-color: #b2b2b2;
    }
}

.modalInfo {
    margin-bottom: 32px;
}

.appointmentModalButton {
    margin-top: 8px;
    border: none;
    text-decoration: underline;
    font-weight: 600;
}

.appointmentRequestNotification {
    font-size: 14px;
    line-height: 21px;
}
.appointmentError {
    position: absolute;
    color: var(--failColor);
    font-size: 12px;
    top: 100%;
    white-space: nowrap;
}
.disabled {
    color: var(--matterColorAnti);

    &:hover {
        cursor: not-allowed;
        border-color: #e6e6e6;
    }

    & path {
        fill: var(--matterColorAnti);
    }
}

.tooltipRequestBtn {
    min-height: 40px;
    padding: 0 16px;

    @apply --fontSizeXS;

    @media (--viewportMediumMax) {
        padding: 0 10px;
        & > span {
            display: none;
        }
    }
}

.locationField {
    margin-bottom: 24px;

    & input {
        border-left: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
        border-top: 1px solid #e6e6e6;
        border-radius: 4px 4px 0 0;

        &,
        &:focus {
            padding: 8px 16px 1px 16px;
        }

        & + span {
            left: 16px;
        }

        & ~ svg {
            top: 16px;
        }
    }
}

.locationIcon {
    display: none;
}

.locationPrediction {
    @apply --locationPredictionsPrimary;
}
