@import '../../marketplace.css';

.root {
}

.sectionTitle {
    /* Font */
    color: var(--matterColorAnti);

    margin-top: 0;
    margin-bottom: 12px;
    padding-top: 3px;
    padding-bottom: 3px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 24px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

.error {
    /* Font */
    @apply --marketplaceH4FontStyles;
    color: var(--failColor);
    margin-top: 18px;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 22px;
        margin-bottom: 2px;
    }
}

.editPencilIcon {
    &:hover {
        cursor: pointer;
    }
}

.statusItem {
    display: flex;
    @apply --fontSizeSM;

    border-radius: 4px;

    padding: 16px;
    margin-bottom: 24px;

    &:hover {
        cursor: pointer;
    }

    & > svg {
        width: 24px;
        height: 24px;
        margin-right: 16px;
        flex-shrink: 0;
    }
}

.successItem {
    background-color: #f0fff6;
    border: 1px solid #1e9f55;
}

.failureItem {
    background-color: #fff0f0;
    border: 1px solid #ff0000;

    align-items: center;

    @media (--viewportMediumMax) {
        display: block;
        text-align: right;

        & svg {
            display: none;
        }
    }
}

.failureActionElem {
    &:hover {
        cursor: pointer;
    }

    align-self: center;

    @media (--viewportMediumMax) {
        font-size: 18px;
        line-height: 28px;
        text-align: right;

        margin-top: 16px;
        margin-left: auto;
    }
}

.mainInfoSectionDesktop {
    display: flex;

    & > main {
        flex: 1;
    }
}

.asideMainInfo {
    width: 100%;

    @media (--viewportLarge) {
        width: 360px;
        margin-right: 24px;
        flex-shrink: 0;
    }
}

.mainInfo {
    max-width: 790px;
}

.infoBlock {
    padding: 24px;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    margin-bottom: 24px;

    & > h2 {
        margin-top: 0;
        margin-bottom: 24px;

        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.5px;
    }

    & svg {
        margin-right: 8px;
        fill: none;
    }

    & p {
        font-size: 16px;
        line-height: 24px;

        @media (--viewportMedium) {
            font-size: 18px;
            line-height: 28px;
        }
    }
}

.noInfoBlock {
    @media (--viewportMedium) {
        background-color: #f9f9f9;
    }
}

.noInfoWarningIcon {
    width: 30px;
    height: 30px;

    & path {
        stroke: #ffaa00;
    }
}

.infoHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.infoLine {
    display: flex;

    margin-bottom: 16px;

    &:last-of-type {
        margin-bottom: 0;
    }

    & svg {
        margin-top: 6px;

        & path {
            stroke: var(--marketplaceColor);
        }
    }

    & h5 {
        /** 18-20 case */
        letter-spacing: -0.25px;
        font-size: 18px;
        line-height: 28px;

        @media (--viewportMedium) {
            font-size: 20px;
            line-height: 32px;
        }

        margin: 0;
    }
}

.editBlock {
    & path {
        fill: var(--marketplaceColor);
    }
}

.white {
    & path {
        fill: white;
    }
}

.editText {
    display: inline;
    color: var(--marketplaceColor);

    &:hover {
        text-decoration: underline;
    }

    @media (--viewportSmallMax) {
        display: none;
    }
}
.infoAncient {
    @apply --paragraphAntiMedium;

    @media (--viewportSmallMax) {
        font-size: 16px;
        line-height: 24px;
    }
}

.actionItem {
    color: white;
    background-color: #8f2593;
    border-radius: 32px;
    border: none;
}

.actionBtn {
    padding: 11px 16px;

    @apply --fontSizeMM;
    font-weight: 600;

    margin-left: auto;
    display: block;
    margin-top: 32px;

    &:hover {
        cursor: pointer;
    }

    @media (--viewportSmallMax) {
        @apply --fontSizeXS;
        font-weight: 600;
    }
}

.placeholderWrapper {
    @media (--viewportSmallMax) {
        display: none !important;
    }
}

.placeholderItem {
    background-color: #e6e6e6;
    border-radius: 16px;
    height: 18px;
}

.placeholderAvatar {
    width: 48px;
    height: 48px;
    background-color: #e6e6e6;
    border-radius: 50%;
    margin-right: 8px;
}

.placeholderUserInfo {
    & > div {
        width: 80px;

        &:nth-of-type(1),
        &:nth-of-type(2) {
            width: 144px;
            margin-bottom: 8px;
        }
    }
}

.alert {
    margin-bottom: 8px;

    @media (--viewportSmallMax) {
        & > svg:nth-of-type(1) {
            display: none;
        }
    }

    & p {
        @apply --fontSizeSM;
        margin: 0 16px;

        @media (--viewportSmallMax) {
            margin: 0;
        }
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    & code {
        /* edit pencil for verified entities */
        display: block;
        text-align: right;

        margin-left: auto;

        & svg {
            margin: 0;
        }
    }
}

.inquiryDisabledTooltip {
    left: 0;
    right: 0;

    @apply --tooltipContent;
}

.desktop {
    @media (--viewportSmallMax) {
        display: none;
    }
}

.mob {
    @media (--viewportMedium) {
        display: none;
    }
}

.profileLink {
    display: flex;
    align-items: center;
    justify-content: center;

    background: #f9f9f9;

    padding: 16px 0;
    margin: 0 -24px 24px -24px;

    & svg {
        fill: none;
        margin-right: 8px;

        & path {
            fill: #8f2593;
        }
    }
    @media (--viewportMedium) {
        display: none;
    }
}

.previewLink {
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.viewProfileRequestWrapper {
    position: relative;
    overflow: hidden;
}

.flex {
    display: flex;
}
.wrap {
    flex-wrap: wrap;
}
.vCtr {
    align-items: center;
}
.mB0 {
    margin-bottom: 0;
}
.mT8 {
    margin-top: 8px;
}
.mB8 {
    margin-bottom: 8px;
}
.mLa {
    margin-left: auto;
}
.edit {
    margin-left: auto;
}
.pntr {
    &:hover {
        cursor: pointer;
    }
}
