@import '../../../../marketplace.css';
@import '../common.css';

:root {
    --flex {
        display: flex;
        align-items: center;
    }
}

.root {
    @apply --sectionRoot;
}

.title {
    @apply --sectionTitle;
}

.subTitle {
    @apply --sectionSubTitle;
}

.statistics {
    display: flex;
    margin-bottom: 32px;

    & > div {
        width: 50%;
    }
}

.noPackages {
    @apply --flex;

    margin-bottom: 32px;

    /* @apply --fontSizeMM; */
    color: var(--matterColorAnti);

    & svg {
        margin-right: 8px;
    }
}

.advantages {
    margin-bottom: 32px;

    & > p {
        @apply --flex;

        margin-bottom: 8px;

        & svg {
            margin-right: 8px;

            & path {
                fill: var(--marketplaceColor);
            }
        }
    }
}

.addPackageButton {
    max-width: 226px;
    border-radius: 32px;
    min-height: 52px;
    margin-left: auto;

    @media (--viewportMediumMax) {
        @apply --fontSizeXS;
        font-weight: 600;
        min-height: 40px;
    }
}

.packagesAvailable {
    display: inline-block;

    color: #8f2593;

    & > p {
        @apply --flex;
        margin-bottom: 0;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    & svg:first-of-type {
        margin-right: 8px;

        & path {
            stroke: #8f2593;
        }
    }

    & svg:last-of-type {
        margin-left: 8px;

        & path {
            fill: #8f2593;
        }
    }
}
