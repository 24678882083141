@import '../../../marketplace.css';

.galleryRoot {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportSmallMax) {
    & > * {
      margin-top: 8px;
      margin-left: 8px;
      width: calc(33% - 5px);
    }

    & > *:nth-of-type(3n + 2) {
      margin-left: 0;
    }

    & > *:nth-of-type(1) {
      margin-top: 0;
      margin-left: 0;
      width: 100%;
    }

    & > *:nth-of-type(1n + 5) {
      display: none;
    }
  }
}

.firstImageColumn {
  width: calc(67% - 12px);

  @media (--viewportSmallMax) {
    width: 100%;
  }
}
.firstImage {
  @media (--viewportMedium) {
    & > div:nth-of-type(1) {
      padding-bottom: calc(100% * (2 / 2.23));
    }
  }
}
.secondColumnImages {
  margin: 0 0 16px 16px;
  width: calc(33% - 4px);

  & > * {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.secondImage {
  @media (--viewportMedium) {
    & > div:nth-of-type(1) {
      padding-bottom: calc(100% * (1 / 1.8));
    }
  }
}

.restImagesColumn {
  width: 100%;
  display: none;
  flex-wrap: wrap;

  & > * {
    width: calc(32.5% - 8px);
    margin-right: 8px;

    @media (--viewportMedium) {
      margin-right: 16px;

      &:nth-of-type(3n + 3) {
        width: calc(33.5% - 8px);
        margin-right: 0;
      }

      &:nth-of-type(1n + 4) {
        margin-top: 16px;
      }
    }
  }
}

.link {
  &:hover {
    text-decoration: none;
  }
}

.placeholderWrapper {
  & > div {
    height: 416px;
  }

  & > div:nth-of-type(1) {
    background-color: #e6e6e6;
    border-radius: 4px;
  }

  @media (--viewportSmallMax) {
    display: none;
  }
}

.placeholderSecondRow {
  width: calc(30.75% - 12px);
}

.imagePlaceholder {
  height: 30.75%;

  background-color: #e6e6e6;
  border-radius: 4px;
}

.showCarouselClassName {
  @media (--viewportSmallMax) {
    & svg {
      top: 4px;
      right: -4px;
      width: 16px;
      height: 16px;
    }
  }
}

.showCarouselNonFilled {
  & svg {
    fill: none;
    stroke: white;
  }
}
.carouselModalScrollLayer {
  @apply --modalCarouselScrollLayer;

  background: white;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  overflow: scroll;
}

.carouselModalContainer {
  @apply --modalNoBorderContainer;

  margin-top: 0;
  margin-bottom: 0;
  padding: 0;

  & > button {
    padding: 0;

    top: 16px;
    right: 24px;

    @media (--viewportMedium) {
      top: 64px;
      right: 0;
    }
  }
}

.carouselModalContent {
  padding: 88px 24px 40px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 140px 0 40px 0;
    display: flex;
    flex-wrap: wrap;
  }
}

.carouselThumbnail {
  margin-top: 16px;

  &:nth-of-type(1) {
    margin-top: 0;
  }

  @media (--viewportMedium) {
    width: calc(50% - 8px);

    &:nth-of-type(3n + 1) {
      width: 100%;
      margin-right: 0;
    }

    &:nth-of-type(3n + 2) {
      margin-right: 16px;
    }
  }
}
