@import '../common.css';
@import '../../../../marketplace.css';

.root {
    @apply --sectionRoot;
}

.title {
    @apply --sectionTitle;
    padding-bottom: 32px;
}

.dBoxes {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.dBox {
    padding-bottom: 16px;
    border-bottom: 1px solid #e6e6e6;

    display: flex;
    flex-wrap: wrap;

    &:last-of-type {
        border-bottom: none;
    }
}

.boxTitle,
.boxChips {
    width: 50%;

    @apply --fontSizeSM;
    color: #1a2b49;

    @media (--viewportMedium) {
        width: calc(100% / 4);
        @apply --fontSizeMM;
    }
}

.boxExtra {
    width: 100%;
    margin-top: 16px;

    @media (--viewportMedium) {
        width: calc(100% / 2);
        margin-top: 0;
    }
}

.boxExtra > ul {
    margin: 0;
    list-style-type: disc;
    padding-inline-start: 22px;
}

.boxExtra > ul > li {
    @apply --fontSizeXS;
    color: #1a2b49;

    margin-top: 2px;
}

.subTitle {
    @apply --sectionSubTitle;

    & + p {
        margin-bottom: 0;
    }
}

.infoContainer {
    position: relative;
    display: inline-flex;
    cursor: pointer;
    align-items: flex-end;
}

.infoBalloon {
    position: absolute;
    bottom: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    z-index: 1;
    color: #1a2b49;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 134px;
    height: 68px;

    @media (--viewportMedium) {
        width: 142px;
    }
}

.balloonArrow {
    position: absolute;
    bottom: calc(100% + 4px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 20px;
    height: 8px;
    border-style: solid;
    border-width: 8px 10px 0 10px;
    border-color: #fff transparent transparent transparent;
}

.matchingContainer {
    @apply --matchingContainer;
}
