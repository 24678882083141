@import '../../marketplace.css';

.arrow {
  width: 18px;
  height: 18px;

  & path {
    fill: #1a2b49;
    stroke: #1a2b49;
  }
}

.buttonNext {
  position: absolute;
  top: calc(50% - 24px);
  right: 16px;

  padding: 0;
  width: 48px;
  height: 48px;

  background: white;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  outline: none;
  cursor: pointer;

  z-index: 2;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}
