@import './marketplace.css';

.title {
    hyphens: auto;

    margin-top: 0;
    margin-bottom: 32px;

    @apply --fontSizeXL;
    font-weight: 700;
    letter-spacing: -0.5px;

    @media (--viewportLarge) {
        margin-bottom: 32px;
        font-size: 32px;
        line-height: 40px;
    }
}

.layoutWrapperMain {
    @media (--viewportMediumMax) {
        padding: 64px 36px 80px 36px;

        & > svg {
            display: none;
        }
    }

    @media (--viewportSmallMax) {
        padding: 24px;
    }

    & > svg:nth-of-type(1) {
        display: none;
    }
}

.content {
    margin: 0;
    width: 100%;
    max-width: 480px;

    @media (--viewportMediumMax) {
        margin: 0 auto;
        max-width: unset;

        &,
        & form {
            flex: 1;
            display: flex;
            flex-direction: column;
        }
    }
}

.mobileTopbar {
    box-shadow: none;
}

.footer {
    /** to prvent blob overlapping */
    z-index: 54;
}

.error {
    margin-top: 15px;
    color: #ff0000;
}
