@import '../../marketplace.css';

.root {
  @apply --marketplaceH5FontStyles;
  margin-top: 8px;
  margin-bottom: 24px;
  color: var(--failColor); /** DO NOT CHANGE THIS!!! THE ERROR HAS TO BE RED GLOBALLY **/
  overflow: hidden;

  line-height: 20px;
  letter-spacing: -0.25px;

  @media (--viewportMedium) {
    margin-top: 8px;
    margin-bottom: 24px;
  }
}
