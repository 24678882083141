@import '../../marketplace.css';

:root {
    /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

    --LocationAutocompleteInput_inputHeight: 50px;
    --LocationAutocompleteInput_sidePadding: 24px;
    --LocationAutocompleteInput_sidePaddingDesktop: 36px;
    --focusedPadding: 17px 60px 4px 64px;
}

.root {
    position: relative;
    display: flex;
    background: transparent;
    /* border    : 1px solid #e6e6e6; */

    /** IconCloseCircleL */
    & > svg {
        @apply --inputRemoveValueIcon;
        top: calc(50% - 12px);
        transition-delay: 0.5s;
        opacity: 0;
    }
}

.icon {
    display: flex;
    width: 24px;
    align-self: stretch;
    background-color: transparent;

    position: absolute;
    left: 16px;
    top: calc(50% - 11px);
}

.iconSpinner {
    width: 34px;
    height: 34px;
    flex-shrink: 0;
}

.input {
    flex-grow: 1;
    height: var(--LocationAutocompleteInput_inputHeight);
    padding-left: 10px;
    margin: 0;
    line-height: unset;

    margin-left: 0;
    padding-left: 64px;
    height: 60px;

    /* Safari bugfix: without this Safari will print placeholder to a wrong place */
    &::-webkit-input-placeholder {
        line-height: normal;
    }

    &:hover {
        outline: none;
    }

    &:focus {
        @apply --inputFocused;

        /* Main icon */
        & ~ .icon {
            & path {
                fill: var(--marketplaceColor);
            }
        }

        & ~ div > svg > path {
            fill: var(--marketplaceColor);
        }

        /** IconCloseCircleL */
        & ~ svg {
            opacity: 1;
        }
    }
}

/*
Predictions container can be overriden with new container styles for
size and position, etc.
*/
.predictionsRoot {
    position: absolute;
    width: 100%;
    top: var(--LocationAutocompleteInput_inputHeight);
    left: 0;
    background-color: var(--marketplaceColor);
    border-bottom-left-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
    box-shadow: var(--boxShadowPopup);
    z-index: calc(var(--zIndexPopup) + 1);
}

/* List of predictions, with a responsive padding size */
.predictions {
    color: #1a2b49;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    margin: 0;

    @media (--viewportMedium) {
        font-size: 18px;
        line-height: 28px;
    }

    & li {
        display: flex;
        align-items: center;
        transition: var(--transitionStyleButton);
        margin: 0;
        padding: 8px 16px;

        @media (--viewportSmallMax) {
            touch-action: none;
        }

        &:hover,
        &.highlighted {
            cursor: pointer;
            color: #1a2b49;
            border: none;
            border-radius: 0;

            background: rgba(0, 0, 0, 0.025);
        }

        &.currentLocation {
            & path {
                stroke: var(--marketplaceColor);
                fill: unset;
            }
        }

        & svg {
            margin-right: 8px;
            margin-left: 0;
            fill: none;
            width: 24px !important;
            height: 24px !important;

            & path {
                fill: var(--marketplaceColor);
            }
        }
    }
}

.currentLocationIcon {
    margin-right: 10px;
}

.fieldset {
    @apply --fieldset;

    left: 64px;
    top: calc(50% - 14px);
}

.valid {
    @apply --inputSuccess;

    & ~ div > svg > path {
        fill: var(--successColor);
    }

    &:focus {
        & ~ div > svg > path {
            fill: var(--successColor);
        }
    }
}

.invalid {
    @apply --inputError;

    & ~ div > svg > path {
        fill: var(--failColor);
    }

    &:focus {
        & ~ div > svg > path {
            fill: var(--failColor);
        }
    }
}

.active {
    & ~ svg {
        opacity: 1;
    }

    & ~ div > svg > path {
        fill: var(--marketplaceColor);
    }

    &:focus {
        & ~ div > svg > path {
            fill: var(--marketplaceColor);
        }
    }
}

input:focus,
input.filledInput {
    padding: var(--focusedPadding);

    & + .fieldset {
        @apply --fieldsetVisible;
    }
}

.loading {
    & + [data-type='error-message'] {
        display: none;
    }
}

.magnifyIcon {
    height: 34px;
    width: 34px;
    min-width: unset;
    position: absolute;
    padding: 0;
    right: 4px;
    top: 3px;
    background-color: var(--marketplaceColor);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    & svg {
        height: 20px;
        width: 20px;
        & path {
            stroke: #fff !important;
        }
    }
}
