@import '../../marketplace.css';

.root {
    position: relative;
    display: flex;
    border-radius: inherit;

    @apply --formFieldsItem;
}

.selected {
    @apply --formFieldsItem-selected;
}

.input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:hover + label .radioButtonWrapper > svg {
        & circle {
            &:first-of-type {
                fill: transparent;
                stroke: var(--marketplaceColor);
            }
        }
    }

    & + label .radioButtonWrapper > svg {
        & circle {
            transition: 0.35s;

            &:first-of-type {
                fill: transparent;
                stroke: #b2b2b2;
            }

            &:last-of-type {
                fill: transparent;
                stroke: transparent;
            }
        }
    }

    &:checked {
        & + label {
            background-color: #f9f4fa;

            &:hover {
                background-color: #f9f4fa;
            }

            & > code > svg {
                & circle {
                    &:first-of-type {
                        stroke: var(--marketplaceColor);
                    }

                    &:last-of-type {
                        fill: var(--marketplaceColor);
                    }
                }
            }
        }
    }

    &:disabled {
        & + label {
            &:hover {
                cursor: not-allowed;
                background-color: unset;
            }

            &:hover + label .radioButtonWrapper > svg {
                & circle {
                    &:first-of-type {
                        fill: transparent;
                        stroke: #b2b2b2;
                    }
                }
            }

            & .text {
                color: #b2b2b2;
            }

            & .radioButtonWrapper {
                cursor: not-allowed;

                & > svg {
                    & circle {
                        &:first-of-type {
                            stroke: #b2b2b2;
                        }
                    }
                }
            }
        }
    }
}

.label {
    display: flex;
    align-items: center;
    justify-content: start;

    width: 100%;
    height: 100%;
    padding: 17px 16px;

    border-radius: inherit;
}

.radioButtonWrapper {
    /* This should follow line-height */
    margin-right: 12px;
    display: flex;
    cursor: pointer;
}

.text {
    color: var(--matterColor);
    @apply --fontSizeMM;

    @media (--viewportSmallMax) {
        @apply --fontSizeSM;
    }
}
