@import '../../marketplace.css';

.root {
    border: none;
    padding: 0;
}

.modalContent {
    max-height: 720px;
    overflow: auto;
}

/* Title of the modal */
.modalTitle {
    @apply --fontSizeXXL;
    font-weight: 700;
}

.reviewee {
    white-space: nowrap;
}

/* Paragraph for the Modal */
.modalMessage {
    margin: 0 0 32px 0;
}

.error {
    color: red;
    text-align: center;
    font-size: 13px;
}
