@import '../../common.css';

.heading {
    text-align: left;
}

.root {
    margin-top: 64px;

    @media (--viewportMediumMax) {
        margin-bottom: 48px;
        /* 114 - 64px + height of slider buttons positioned absolutely */
        margin-top: 114px;
    }
}

.description {
    margin-bottom: 32px;
}
