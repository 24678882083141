@import '../../../marketplace.css';

.faqItem {
    border-bottom: 1px solid #e6e6e6;
    padding: 24px 0;

    &:hover {
        cursor: pointer;
    }

    & svg {
        transition: 0.35s;
        transform: rotate(-90deg);
        flex-shrink: 0;
    }

    &.open {
        & svg {
            transform: rotate(90deg);
        }

        & .faqHeading > *:not(svg) {
            color: #8f2593;
        }
    }

    & .faqHeading > *:not(svg) {
        margin: 0;
        color: #1a2b49;

        @apply --fontSizeXXL;

        @media (--viewportSmallMax) {
            @apply --fontSizeXL;
            font-weight: 700;
        }
    }

    & p {
        margin: 0;

        cursor: auto;
    }
}

.faqHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.open {
        margin-bottom: 24px;
    }
}
