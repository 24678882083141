@import '../../marketplace.css';

.suggestionItem {
  color: var(--marketplaceColor);
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}
.suggestionHolder {
  @apply (--authSubsection);
}
.domainItem {
  @apply --fontSizeSM;

  margin: 0;
  padding: 8px 16px;

  &:hover {
    cursor: pointer;
    background-color: #f2f2f2;
  }
}
