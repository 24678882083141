@import '../common.css';

.root {
    @apply --sectionRoot;
}

.sectionTitle {
    @apply --sectionTitle;
}

.sectionMinAge {
    @apply --sectionParagraphWithIcon;

    & svg {
        & path {
            fill: #8f2593;
        }
    }
}
