@import '../../marketplace.css';

.modalContent {
    max-height: 720px;
    overflow: auto;

    & h3 {
        margin-top: 0;
    }
}

/* Title of the modal */
.modalTitle {
    @apply --fontSizeXXL;
    font-weight: 700;
}

/* Paragraph for the Modal */
.modalMessage {
    margin: 0 0 32px 0;
}

.submitButton {
    color: red;

    &:disabled {
        background: white;
        color: #b2b2b2;
    }
}

.sideNote {
    @apply --paragraphAntiSM;
    text-align: center;
}

.error {
    color: red;
    text-align: center;
    font-size: 13px;
}
