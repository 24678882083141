@import '../../marketplace.css';

/* Child component's size (and positioning) should be given here */
.menu {
    /* Font */
    @apply --marketplaceH5FontStyles;
    color: var(--matterColor);

    /* Layout */
    display: flex;
    width: auto;
    border-width: 0px;
    background-color: transparent;

    position: relative;

    padding: 8px 16px;
    margin: 0;

    /* Hovers */
    &:enabled {
        cursor: pointer;
    }
    &:enabled:hover,
    &:enabled:active {
        background-color: transparent;
        box-shadow: none;
    }
    &:disabled {
        background-color: transparent;
        cursor: auto;
    }
}

.notificationDot {
    @apply --notificationDot;

    top: 8px;
    right: 16px;
}

.topBarModalRoot {
    & > div {
        background-color: white !important;
    }
}

.modalContainerMobile {
    @apply --marketplaceModalBaseStylesCore;

    min-height: 100vh;
    padding: 0;
    border-radius: 0;
}
