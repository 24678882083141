@import '../../marketplace.css';

.createAccountWrapper {
    color: var(--marketplaceColorDarkMedium, #1A2B49);
    font-family: 'sofiapro', Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.25px;
    margin: 32px 0;
    display: block;
    width: 100%;
}

.createAccountColumn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 55%;
}

.createAccountTitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.5px;
    margin-bottom: 32px;
}

.createAccountTitleColor {
    color: var(--marketplaceColor, #8F2593);
}

.createAccountColumn:last-child {
    flex: 45%;
    margin-top: 32px;
}

.subLinkPrimary {
    width: 100%;
    min-width: 130px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    background: var(--marketplaceColor, #8F2593);
    padding: 18px 16px;
    margin: auto;
    transition: 0.35s;
}

.subLinkPrimary:hover {
    text-decoration: none;
    background: var(--marketplaceColorDark);
}

.createAccountColumnContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 400px;
    margin: auto;
}

.voucherNotification {
    display: flex;
    align-items: flex-start;
    padding: 16px;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--successColor, #1E9F55);
    background: var(--successColorLight, #F0FFF6);
    color: var(--matterColor, #1A2B49);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.25px;
}

.voucherIcon {
    margin-right: 16px;
    flex-shrink: 0;
}

.voucherTitle {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

.voucherDescription {
    margin: 8px 0 0;
}

.registrationForm {
    display: flex;
    flex-direction: column;
    height: auto;
}

.registrationForm > div:last-child p {
    margin-bottom: 0;
}

.inputField {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.passwordWrapper {
    position: relative;
    width: 100%;
}

.passwordIcon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}

.createAccountButton {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    color: #fff;
    background-color: #ccc;
    border: none;
    border-radius: 4px;
    margin-top: 10px;
    cursor: not-allowed;
}

.createAccountButton:enabled {
    background-color: var(--marketplaceColor, #8F2593);
    cursor: pointer;
}

.dataPrivacyNote {
    font-size: 12px;
    color: #999;
    margin-top: 10px;
    text-align: center;
}

.benefitsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 28px;
}

.benefitsRow {
    display: flex;
    align-items: center;
    gap: 16px;
}

.followersHolder {
    margin-top: 32px !important;
}

.userTypeRadio {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 20px;
}

.radioLabel {
    display: inline-flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    padding: 0;
}

.radioLabel span {
    color: var(--Brand-Secondary, #1A2B49);
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.25px;
}

.radioInput {
    appearance: none;
    width: 24px;
    height: 24px;
    display: inline-block;
    border: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><circle cx="12" cy="12" r="11.25" stroke="%23B2B2B2" stroke-width="1.5"/></svg>');
}

.radioInput:checked {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><circle cx="12" cy="12" r="11" stroke="%238F2593" stroke-width="2"/><circle cx="12" cy="12" r="6" fill="%238F2593"/></svg>');
}

.radioInput:focus {
    padding: 0;
}

/* viewport medium */
/* min-width: 768px */
@media (--viewportMedium) {
    .createAccountWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .followersHolder {
        margin-top: 24px !important;
        margin-left: 0 !important;
    }

    .createAccountColumn {
        justify-content: normal;
    }

    .createAccountTitle {
        font-size: 40px;
        line-height: 48px;
        margin-top: initial;
    }

    .subLinkPrimary {
        width: 80%;
    }

    .createAccountColumn:last-child {
        margin-top: initial;
    }

    .benefitsWrapper {
        width: 80%;
    }

    .ridersTrust {
        width: 60%;
    }
}
