@import '../../../marketplace.css';

.inquiryDisabledTooltip {
    @apply --tooltipContent;

    @media (--viewportLarge) {
        width: 312px;
    }
}

.acceptInquirySection {
    & button {
        width: 100%;

        &.enquired {
            @apply --marketplaceButtonStylesSecondary;
            padding: unset;
        }
    }

    @media (--viewportMediumMax) {
        border-top: 1px solid #e6e6e6;

        position: fixed;
        bottom: 0;
        /* less than mobilesearch modal */
        z-index: 54;
        left: 0;
        right: 0;
        background: white;
        padding: 12px 24px;

        & p {
            margin-bottom: 8px;
        }
    }

    @media (--viewportLarge) {
        width: 312px;
        margin-left: auto;
        align-self: center;
    }
}
