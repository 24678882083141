@import '../../marketplace.css';

.root {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.prev,
.next {
    @apply --sliderButton;
    flex-shrink: 0;
    color: #1a2b49;

    &.disabledLink {
        @apply --sliderButtonDisabled;

        &:hover {
            border: 1px solid #e6e6e6;
        }
    }

    @media (--viewportSmallMax) {
        width: 40px;
        height: 40px;
    }
}

.prev {
    @apply --sliderButtonBack;
    margin-right: 8px;

    @media (--viewportSmallMax) {
        & svg {
            transform: rotate(180deg) !important;
        }
    }
}
.next {
    @apply --sliderButtonNext;
    margin-left: 8px;

    @media (--viewportSmallMax) {
        & svg {
            transform: unset !important;
        }
    }
}

.arrowIcon {
    /* Color for svg icons */
    fill: var(--marketplaceColor);
    stroke: var(--marketplaceColor);

    &:hover {
        fill: var(--marketplaceColorDark);
        stroke: var(--marketplaceColorDark);
    }
}

.disabled,
.disabled:hover {
    fill: var(--matterColorAnti);
    stroke: var(--matterColorAnti);
}

.pageNumberList {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/**
 * Using 'justify-content: space-between;' we can deal with very narrow mobile screens.
 * However, since the length of pageNumberList can vary up to 7,
 * we need to keep track of how much space is allocated for the list
 * Maximum length of pageNumbersNavLinks is 7 (e.g. [1, '…', 4, 5, 6, '…', 9])
 */
.pageNumberList1Items {
    flex-basis: 30px;
}

.pageNumberList2Items {
    flex-basis: 60px;
}

.pageNumberList3Items {
    flex-basis: 100px;
}

.pageNumberList4Items {
    flex-basis: 160px;
}

.pageNumberList5Items {
    flex-basis: 200px;
}

.pageNumberList6Items {
    flex-basis: 250px;
}

.pageNumberList7Items {
    flex-basis: 275px;
}
.pageNumberList2Items,
.pageNumberList3Items,
.pageNumberList4Items,
.pageNumberList5Items,
.pageNumberList6Items,
.pageNumberList7Items {
    column-gap: 8px;

    @media (--viewportSmallMax) {
        column-gap: 4px;
    }
}

.toPageLink {
    border: 1px solid #e6e6e6;
    background-color: white;
    color: #1a2b49;

    transition: 0.35s;

    &:hover {
        border: 1px solid;
    }
}

.currentPage {
    background-color: #8f2593;
    color: white;
    border: none;
}

.toPageLink,
.currentPage {
    height: 48px;
    width: 48px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    &,
    &:hover {
        text-decoration: none;
    }

    @media (--viewportSmallMax) {
        width: 40px;
        height: 40px;
        font-size: 16px;
    }
}

.paginationGap {
    /* Dimensions */
    padding: 21px 5px;

    /* Color for svg icons */
    color: var(--matterColor);

    /* Ellipsis is raised from baseline */
    line-height: 13px;
}
