@import '../../../marketplace.css';

.qualificationSection {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.qualificationItem {
  background: #8f2593;

  padding: 16px;
  color: white;
  border-radius: 4px;

  & > p:nth-of-type(1) {
    font-weight: 600;
    margin-bottom: 8px;
  }

  & > p:nth-of-type(2) {
    font-weight: 600;
    margin-bottom: 16px;
  }

  & > p:last-of-type {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & a {
      color: white;
    }
  }

  @media (--viewportMedium) {
    width: calc(50% - 12px);

    &:nth-of-type(even) {
      margin-left: 24px;
    }

    &:nth-of-type(n + 3) {
      margin-top: 24px;
    }
  }

  @media (--viewportSmallMax) {
    width: 100%;

    margin-top: 16px;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.placeholderWrapper {
  display: flex;

  & > div {
    padding: 16px;

    height: 134px;
    width: 50%;

    background-color: #e6e6e6;

    border-radius: 4px;

    &:nth-of-type(1) {
      margin-right: 24px;
    }
  }
}

.placeholderItem {
  background-color: #f9f9f9;

  &:nth-of-type(1) {
    width: 104px;
    margin-bottom: 16px;
  }
  &:nth-of-type(2) {
    width: 144px;
    margin-bottom: 32px;
  }
  &:nth-of-type(3) {
    width: 176px;
    border-radius: 16px;
  }

  @media (--viewportSmallMax) {
    display: none;
  }
}

.viewProfileRequestWrapper {
  max-height: 284px;
}

aside.viewProfileRequestAction {
  height: 80%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.1) 90%,
    rgba(255, 255, 255, 0) 100%
  );
}
