@import '../../marketplace.css';

.modalContainer {
    @apply --scrollBarStyled;
    flex-basis: 800px;

    @media (--viewportMedium) {
        border-radius: 16px;
    }
}

.modalContent {
    overflow: auto;

    @media (--viewportMedium) {
        /* modal container desktop top margins */
        max-height: calc(100vh - 144px);
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;

        padding: 70px 24px 0 24px;
    }
}

.closeButton {
    @media (--viewportMedium) {
        border-radius: 16px 16px 0 0 !important;
    }
}

.modalHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0 0 16px 0;

    @media (--viewportLarge) {
        margin: 0 0 24px 0;
        justify-content: center;
    }
}

.modalHeader {
    @apply --fontSizeXXL;
    text-align: left;
    margin: 0;

    @media (--viewportLarge) {
        @apply --fontSizeXL;
        font-weight: 700;
    }
}

.separatorLine {
    @apply --separatorLine;
    display: block;
    margin: 0 -24px;
}

.showListingsButtonWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

.resetAllButton {
    @apply --marketplaceH5FontStyles;
    @apply --fontSizeXS;
    font-weight: 600;
    display: inline;
    float: right;

    margin: 0;
    padding: 0;

    text-decoration-line: underline;
    outline: none;
    border: none;

    color: var(--matterColor);

    &:hover {
        cursor: pointer;
    }

    @media (--viewportLarge) {
        @apply --fontSizeSM;
        font-weight: 600;
        margin: 17px 0 0 0;
    }

    &:disabled {
        color: #b2b2b2;

        &:hover {
            cursor: not-allowed;
        }
    }
}

.showListingsButton {
    @apply --marketplaceButtonStyles;
    @apply --fontSizeMM;
    font-weight: 600;

    display: inline-block;
    max-width: 100%;

    @media (--viewportLarge) {
        max-width: 278px;
    }
}

.labelClass {
    margin-right: auto;
}
