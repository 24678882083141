@import '../../marketplace.css';

.favoriteWrapper {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 5;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  transition: 0.35s;

  & svg {
    display: block;
    fill: #0000004d;
    stroke: white;
    overflow: visible;
  }

  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}

.inWishlist {
  & svg {
    fill: rgb(255, 56, 92);
    stroke: white;
  }
}
