@import '../../marketplace.css';

.notification {
    background: #1a2b49;

    @apply --fontSizeSM;
    text-align: left;
    color: white;

    padding: 16px 24px;
    margin: 0;

    & > span:first-of-type {
        display: none;
    }
    & > span:last-of-type {
        display: inline;
    }

    @media (--viewportSmallMax) {
        @apply --flex-centered;
        justify-content: space-between;
    }

    @media (--viewportMedium) {
        text-align: center;
        padding: 14px;

        & > span:first-of-type {
            display: inline;
        }
        & > span:last-of-type {
            display: none;
        }
    }

    & button {
        border: 1px solid white;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 600;

        padding: 3px 10px;

        cursor: pointer;
        display: block;

        margin-left: 0;

        @media (--viewportMedium) {
            display: inline;
            margin-left: 24px;
        }
    }
}
