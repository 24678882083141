@import '../../marketplace.css';

:root {
}

.error {
    @apply --paragraphAntiSM;
    color: var(--failColor);

    margin-bottom: 16px;
    margin-top: 24px;
}

.collectInfoHeader {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 32px;
    letter-spacing: -0.5px;
    margin-top: 0;
    margin-bottom: 16px;

    white-space: break-spaces;

    @media (--viewportMedium) {
        font-size: 32px;
        line-height: 40px;
    }
}

.collectInfoDescription {
    @apply --fontSizeSM;

    margin-bottom: 0;

    color: var(--matterColorDark);
    margin-bottom: 32px;

    @media (--viewportMedium) {
        margin-bottom: 32px;
        @apply --fontSizeMM;
    }

    & svg {
        fill: none;
    }
}

.formRow {
    display: flex;
}

.fieldItem {
    position: relative;
    margin-top: 24px;

    & input,
    & textarea,
    & select {
        font-size: 18px;
        line-height: 27px;
    }

    & label {
        @apply --fontSizeMM;
        font-weight: 400;

        padding-bottom: 4px;
    }

    @media (--viewportSmallMax) {
        flex-basis: calc(100% - 12px);
    }

    & textarea {
        @media (--viewportSmallMax) {
            max-height: 200px;
        }
    }
}
.selectSubItem {
    padding: 0 16px 16px 16px;
}

.disabled {
    background: #e6e6e6;
    color: #b2b2b2;
    font-weight: 600;

    &:hover,
    &:focus {
        background: #e6e6e6;
        cursor: not-allowed;
    }

    & path {
        stroke: #b2b2b2;
        fill: #b2b2b2;
    }
}

.formElementsSection {
    text-align: left;
    display: block;

    @media (--viewportMedium) {
        display: flex;

        & > div {
            max-width: 108px;
            margin-right: 16px;
        }
    }
}

.formElement {
    margin-bottom: 24px;

    & svg {
        right: 16px;
        top: 20px;
    }

    & input {
        padding: 17px 30px 4px 17px;
    }

    @media (--viewportMedium) {
        margin-bottom: 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.form {
    height: calc(100% - 56px);
    display: flex;
    flex-direction: column;
    padding-bottom: 176px;

    @media (--viewportMedium) {
        display: block;
        height: auto;
    }
}

.notificationContainer {
    border: 1px solid var(--attentionColor);
    background-color: var(--attentionColorLight);
    padding: 17px;
    max-width: 400px;
    margin: 35px auto 0 auto;
    border-radius: 4px;
}
.notificationHeading {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}
.notificationMessage {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
.notificationFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.notificationFooter > button {
    max-width: 120px;
    max-height: 40px;
    min-height: 40px;
    height: 40px;
}
.notificationBtnPrimary {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
}
.notificationBtnSecondary {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    background: transparent;
    border: none;
    text-decoration: underline;

    &:hover,
    &:focus {
        text-decoration: underline;
        background: transparent;
    }
}

/**
  check it formFieldsHolder
*/
.checkboxHolder {
    position: relative;

    & > *:not(code) {
        margin: 0;
    }
}

.radioButtonEelemRoot {
    display: flex;
    align-items: center;
}

.boxSuccess {
    stroke: var(--marketplaceColor);
    fill: var(--marketplaceColor);
}
.checkedSuccess {
    stroke: white;
}

.showMoreDisciplines {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: white;
    padding: 0;
    z-index: 2;
    box-shadow: var(--boxShadow);
    left: calc(50% - 15px);
    top: 458px;
    cursor: pointer;

    @media (--viewportMedium) {
        top: 472px;
    }

    @media (max-width: 460px) {
        top: 480px;
    }

    & path {
        stroke: #1a2b49;
        fill: #1a2b49;
    }
}
.documentsSection {
    @apply --documentsSection;
}

.uploadSection {
    margin-right: 32px;
}

.wordNumSection {
    margin-top: 8px;

    & > div {
        height: 8px;
        background-color: #e6e6e6;
        border-radius: 4px;

        & > div {
            height: 100%;
            transition: 0.35s;
            background-color: #8f2593;
            border-radius: 4px;
        }
    }

    & p {
        @apply --paragraphAntiSM;

        margin: 8px 0 0 0;
    }
}
.recipientsListection {
    & > aside {
        margin-top: 8px;
        margin-bottom: 24px;
        @apply --paragraphAntiSM;
    }
}

/* .locationPrediction {
    @apply --locationPredictionsPrimary;
} */

.levelDescription {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    white-space: break-spaces;

    & img {
        @media (--viewportMedium) {
            margin-left: 4px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    & > span:last-of-type {
        margin-right: 2px;
    }
}

.levelTooltip {
    width: 384px;

    & + div {
        display: flex;
    }

    @media (--viewportSmallMax) {
        width: calc(100vw - 54px);
    }

    & svg {
        @apply --tooltipStaticCloseIcon;
    }

    & p {
        margin-bottom: 0;

        &:first-of-type {
            font-weight: 600;
            margin-bottom: 10px;
        }
    }

    @media (--viewportMedium) {
        min-width: 384px;
    }
}

.levelItem {
    display: inline-block;
    margin-left: 16px;
    transition: 0.35s;

    /* background: none; */
    border: 1px solid #8f2593;
    border-radius: 8px;

    max-width: 96px;
    width: calc(25% - 8px);

    height: 16px;

    @media (--viewportSmallMax) {
        margin-left: 8px;
    }

    &:first-of-type {
        margin-left: 0;
    }
}

.levelNoItem {
    @apply --paragraphAntiSM;
    @apply --fontSizeMM;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 116px;

    @media (--viewportSmallMax) {
        @apply --fontSizeSM;
    }
}

.disciplineLevelHoler {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
}

.disciplineLevel {
    border-top: 1px solid #e6e6e6;
    padding: 16px;

    &:first-of-type {
        border-top: none;
    }
}

.progressBar {
    margin: 24px 0;
}

.generateTextSection {
    & textarea {
        min-height: 184px;

        @media (--viewportSmallMax) {
            min-height: 116px;
        }
    }
}

.generateTextAlert {
    margin: 32px 0;

    /* close icon */
    & > span {
        display: block !important;

        @media (--viewportMedium) {
            top: calc(50% - 16px);
        }
    }
}
